import { call, put, select, takeLatest } from "redux-saga/effects";
import { GET_CHANGES_REQUEST, DELETE_CHANGES_REQUEST } from "~/actions/types";
import {
  getChangesSuccess,
  getChangesFailure,
  getDeleteSuccess
} from "~/actions";
import * as api from "~/services/ChangesService";

function* getChangesData(action) {
  const state = yield select();
  let pageNumber =
    action.pageNumber !== undefined ? action.pageNumber : state.changes.number;
  try {
    let changes = yield call(
      api.getChanges,
      pageNumber,
      action.sorting,
      action.searchParams,
      action.filters,
      action.author,
      action.documentId
    );
    yield put(getChangesSuccess(changes, pageNumber));
  } catch (error) {
    yield put(getChangesFailure(error));
  }
}

export function* getChanges() {
  yield takeLatest(GET_CHANGES_REQUEST, getChangesData);
}

export function* deleteChangesData(action) {
  try {
    yield call(api.deleteChange, action.id);
    yield put(getDeleteSuccess(action.id));
  } catch (error) {}
}

export function* deleteChanges() {
  yield takeLatest(DELETE_CHANGES_REQUEST, deleteChangesData);
}
