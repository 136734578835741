import React from "react";
import { Skeleton } from "./Skeleton";

interface UserActivityRowSkeletonProps {
  className?: string;
}

export const UserActivityRowSkeleton = ({
  className
}: UserActivityRowSkeletonProps) => {
  return (
    <div className={className}>
      <Skeleton width={126} height={20} viewBox="0 0 126 20">
        <rect x="0" y="0" rx="6" ry="6" width="126" height="20" />
      </Skeleton>
      <Skeleton width={126} height={20} viewBox="0 0 126 20">
        <rect x="0" y="0" rx="6" ry="6" width="126" height="20" />
      </Skeleton>
      <Skeleton width={45} height={20} viewBox="0 0 45 20">
        <rect x="0" y="0" rx="6" ry="6" width="45" height="20" />
      </Skeleton>
      <Skeleton width={45} height={20} viewBox="0 0 45 20">
        <rect x="0" y="0" rx="6" ry="6" width="45" height="20" />
      </Skeleton>
      <Skeleton
        style={{ marginLeft: "auto" }}
        width={34}
        height={20}
        viewBox="0 0 34 20"
      >
        <rect x="0" y="0" rx="6" ry="6" width="34" height="20" />
      </Skeleton>
    </div>
  );
};
