import {
  TRACK_EVENT,
  CLEAR_EVENTS_QUEUE,
  IS_USER_ACTIVE
} from "~/actions/types";
import { TrackedEvent } from "~/services/analytics/types";

interface AnalyticsState {
  events: TrackedEvent[];
  isUserActive: boolean;
}

const initialState: AnalyticsState = {
  events: [],
  isUserActive: true
};

export interface ClearEventsAction {
  type: typeof CLEAR_EVENTS_QUEUE;
}

export interface TrackEventAction {
  type: typeof TRACK_EVENT;
  event: TrackedEvent;
}

export interface IsUserActiveAction {
  type: typeof IS_USER_ACTIVE;
  payload: boolean;
}

type AnalyticsAction =
  | ClearEventsAction
  | TrackEventAction
  | IsUserActiveAction;

export const analytics = (
  state: AnalyticsState = initialState,
  action: AnalyticsAction
): AnalyticsState => {
  switch (action.type) {
    case TRACK_EVENT:
      return {
        ...state,
        events: [...state.events, action.event]
      };
    case IS_USER_ACTIVE:
      return {
        ...state,
        isUserActive: action.payload
      };
    case CLEAR_EVENTS_QUEUE:
      return {
        ...initialState,
        isUserActive: state.isUserActive
      };
    default:
      return state;
  }
};
