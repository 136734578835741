import { uniqBy } from "lodash";
import {
  GET_USERS_SUCCESS,
  CLEAR_USERS_STATE,
  CLOSE_USERS_TAB,
  CHANGE_USERS_TAB,
  UPDATE_USER_SUCCESS,
  INVITE_USER_SUCCESS,
  GET_USERS_BY_NAME_SUCCESS,
  REMOVE_USER_SUCCESS
} from "~/actions/types";

const initialState = {
  usersNames: [],
  content: [],
  totalPages: 0,
  number: 0,
  totalElements: 0
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      if (action.group === null) {
        return {
          ...state,
          usersNames: action.users
        };
      } else
        return {
          ...state,
          content: uniqBy([...state.content, ...action.users.content], "id"),
          group: action.group ? action.group : "All",
          totalPages: action.users.totalPages,
          activePage: action.users.number + 1,
          totalElements: action.users.totalElements
        };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        content: [
          ...state.content.map(user => {
            if (user.id === action.id) {
              return { ...user, role: action.role };
            } else {
              return user;
            }
          })
        ]
      };
    case INVITE_USER_SUCCESS:
      const { user } = action;
      return {
        ...state,
        content: [user, ...state.content]
      };
    case REMOVE_USER_SUCCESS:
      return {
        ...state,
        content: [...state.content.filter(user => user.id !== action.id)]
      };

    case GET_USERS_BY_NAME_SUCCESS:
      return {
        ...state,
        content: action.users.content
      };
    case CLOSE_USERS_TAB:
      return {
        ...state,
        activetab: ""
      };
    case CHANGE_USERS_TAB:
      return {
        ...state,
        activetab: action.activeTab
      };
    case CLEAR_USERS_STATE:
      return initialState;
    default:
      return state;
  }
};

export default users;
