import React from "react";
import classNames from "classnames";

import styles from "./Wrapper.module.scss";

interface WrapperProps {
  title?: string;
  showTitle?: boolean;
  children: React.ReactNode;
  className?: string;
  headerClassName?: string;
  wrapperTitleClassName?: string;
  ExtendedHeader?: React.ComponentType<{}>;
}

const Wrapper = ({
  title,
  showTitle,
  children,
  className,
  ExtendedHeader,
  headerClassName
}: WrapperProps) => (
  <div className={classNames(styles.wrapper, className)}>
    {ExtendedHeader && (
      <header
        className={classNames(
          styles.wrapperHeader,
          ExtendedHeader && styles.wrapperHeaderBetween,
          headerClassName
        )}
      >
        {showTitle && title && (
          <h2
            className={classNames(styles.title, styles.wrapperTitleClassName)}
          >
            {title}
          </h2>
        )}
        <ExtendedHeader />
      </header>
    )}
    {children}
  </div>
);

export default Wrapper;
