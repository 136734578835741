import React from "react";
import { Icon } from "semantic-ui-react";
import styles from "./ExternalLink.module.scss";

interface ExternalLinkProps {
  name: string;
  url: string;
}

export const ExternalLink = ({ name, url }: ExternalLinkProps) => {
  return (
    <span className={styles.external}>
      {name}
      <a target="_blank" rel="noopener noreferrer" href={url}>
        <Icon link name="external" />
      </a>
    </span>
  );
};
