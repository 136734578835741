import React, { useCallback, useEffect, useState } from "react";
import { Segment } from "semantic-ui-react";
import { fetchStats } from "~/actions";
import { useSelector, useThunkDispatch } from "~/configureStore";
import { StatisticSegment } from "./StatisticsSegment";

import { ReactComponent as CopySVG } from "~/assets/icons/statistics-copy.svg";
import { ReactComponent as EmailSVG } from "~/assets/icons/statistics-email.svg";
import { ReactComponent as ExchangeSVG } from "~/assets/icons/statistics-exchange.svg";
import { ReactComponent as GroupSVG } from "~/assets/icons/statistics-group.svg";
import { ReactComponent as ReportSVG } from "~/assets/icons/statistics-report.svg";

const GeneralStatisticsComponent = () => {
  const generalStatisticsData = useSelector(({ statistics: { stats } }) => ({
    totalQueries: stats.queries.total,
    users: stats.users,
    totalIssues: stats.issues.total,
    documents: stats.documents,
    answers: stats.answers
  }));

  const [loading, setIsLoading] = useState(true);

  const dispatch = useThunkDispatch();

  const getStatistics = useCallback(async () => {
    try {
      await dispatch(fetchStats());
    } finally {
      setIsLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    getStatistics();
  }, []);

  return (
    <Segment.Group horizontal>
      <StatisticSegment
        name="statistics.general.messages"
        icon={<EmailSVG />}
        tip="statistics.general.sum_of_sent_q_and_a"
        value={generalStatisticsData.totalQueries}
        loading={loading}
      />
      <StatisticSegment
        name="statistics.general.users"
        icon={<GroupSVG />}
        value={generalStatisticsData.users}
        loading={loading}
      />
      <StatisticSegment
        name="statistics.general.issues"
        icon={<ReportSVG />}
        value={generalStatisticsData.totalIssues}
        loading={loading}
      />
      <StatisticSegment
        name="statistics.general.documents"
        icon={<CopySVG />}
        value={generalStatisticsData.documents}
        loading={loading}
      />
      <StatisticSegment
        name="statistics.general.changes"
        icon={<ExchangeSVG />}
        tip="statistics.general.number_of_data_in_knowledge_base_by_users"
        value={generalStatisticsData.answers}
        loading={loading}
      />
    </Segment.Group>
  );
};

export default GeneralStatisticsComponent;
