!(function () {
  "use strict";
  tinymce.PluginManager.add("phonenumber", function (o) {
    function n(e) {
      return e.selection.getNode();
    }
    function e() {
      var e;
      o.windowManager.open({
        title: "Add clickable phone number",
        body: {
          type: "panel",
          items: [
            {
              type: "input",
              name: "phoneNumber",
              label: "Phone number",
              placeholder: "+48 123 456 789"
            },
            {
              type: "input",
              name: "anchorText",
              label: "Anchor text",
              placeholder: "e.g. phone number"
            }
          ]
        },
        buttons: [
          { type: "cancel", text: "Close" },
          { type: "submit", text: "Save", primary: !0 }
        ],
        initialData: {
          phoneNumber:
            (null == (e = null == (e = n(o)) ? void 0 : e.getAttribute("href"))
              ? void 0
              : e.replace("tel:", "")) || "",
          anchorText: (null == (e = n(o)) ? void 0 : e.textContent) || ""
        },
        onSubmit: function (e) {
          var n = e.getData(),
            t = n.anchorText,
            n = n.phoneNumber;
          t &&
            n &&
            o.insertContent('<a href="tel:'.concat(n, '">').concat(t, "</a>")),
            e.close();
        }
      });
    }
    o.ui.registry.addIcon(
      "phonenumber",
      '<svg version="1.1" id="phone" width="16" height="16" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 513.64 513.64" style="enable-background:new 0 0 513.64 513.64;" xml:space="preserve"><g><path fill="rgb(141 147 171)" d="M499.66,376.96l-71.68-71.68c-25.6-25.6-69.12-15.359-79.36,17.92c-7.68,23.041-33.28,35.841-56.32,30.72c-51.2-12.8-120.32-79.36-133.12-133.12c-7.68-23.041,7.68-48.641,30.72-56.32c33.28-10.24,43.52-53.76,17.92-79.36l-71.68-71.68c-20.48-17.92-51.2-17.92-69.12,0l-48.64,48.64c-48.64,51.2,5.12,186.88,125.44,307.2c120.32,120.32,256,176.641,307.2,125.44l48.64-48.64C517.581,425.6,517.581,394.88,499.66,376.96z"/></g></svg>'
    ),
      o.ui.registry.addToggleButton("phonenumber", {
        icon: "phonenumber",
        onAction: function () {
          e();
        },
        tooltip: "Add/edit a phone number",
        active: !1,
        onSetup: function (e) {
          return o.selection.selectorChangedWithUnbind("a", e.setActive).unbind;
        }
      }),
      o.ui.registry.addToggleMenuItem("phonenumber", {
        icon: "phonenumber",
        onAction: function () {
          e();
        }
      });
  });
})();
