import React, { createContext, useCallback, useContext, useMemo } from "react";
import { isEqual } from "lodash";
import mixpanel from "mixpanel-browser";
import { TENANT } from "~/constants/app";
import { useSelector } from "~/configureStore";
import { MIXPANEL_EVENTS } from "./event-types";

mixpanel.init(
  "6f8ed10189c788426f17605bdaa33b90",
  process.env.NODE_ENV === "development" ? { debug: true } : undefined
);

export interface MixpanelContextValue {
  trackEvent: (eventName: MIXPANEL_EVENTS) => void;
}

const MixpanelContext = createContext<undefined | MixpanelContextValue>(
  undefined
);

const useMixpanelContextValue = (): MixpanelContextValue => {
  const {
    role,
    user: userName,
    email
  } = useSelector(({ login }) => login, isEqual);
  const language = useSelector(
    ({ projects }) => projects?.current?.language?.toLowerCase?.() ?? null
  );

  const trackEvent: MixpanelContextValue["trackEvent"] = useCallback(
    eventName => {
      if (!Object.values(MIXPANEL_EVENTS).includes(eventName)) return;
      if (!role || !userName) return;
      if (process.env.NODE_ENV !== "production") return;

      return mixpanel.track(eventName, {
        role,
        userName,
        email,
        language,
        tenant: TENANT
      });
    },
    [role, userName, email, language]
  );

  return useMemo(
    () => ({
      trackEvent
    }),
    [trackEvent]
  );
};

interface MixpanelContextProviderProps {
  children: React.ReactNode;
}

export const MixpanelContextProvider = ({
  children
}: MixpanelContextProviderProps) => {
  const contextValue = useMixpanelContextValue();

  return (
    <MixpanelContext.Provider value={contextValue}>
      {children}
    </MixpanelContext.Provider>
  );
};

export const useMixpanelContext = () => {
  const context = useContext(MixpanelContext);

  if (!context)
    throw new Error(
      "If you want to use Mixpanel context, you need to add its provider on the parent element!"
    );

  return context;
};

export const WithMixpanelContext =
  () =>
  <T extends MixpanelContextValue = MixpanelContextValue>(
    WrappedComponent: React.ComponentType<T>
  ) => {
    const displayName =
      WrappedComponent.displayName || WrappedComponent.name || "Component";

    const EnhancedComponent = (props: Omit<T, keyof MixpanelContextValue>) => {
      const context = useMixpanelContext();

      return <WrappedComponent {...(props as T)} {...context} />;
    };

    EnhancedComponent.displayName = `${displayName}(WithMixpanelContext)`;

    return EnhancedComponent;
  };
