import React, { useCallback, useMemo } from "react";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { v4 as uuid } from "uuid";
import { truncate } from "lodash";
import { DocumentLocation } from "~/services/api-types";
import { useThunkDispatch } from "~/configureStore";
import { DOCUMENTS } from "~/constants/routes";
import { useToggle } from "~/hooks/useToggle";
import {
  getDocuments,
  getFolders,
  getFoldersPathSuccess,
  previewDocWithId
} from "~/actions/DocumentsActions";
import { getFileIcon, getFolderIcon } from "~/components/Documents/utils";

import styles from "./FolderBreadcrumbs.module.scss";

import { ReactComponent as BreadcrumbArrowIcon } from "~/assets/icons/breadcrumb_arrow.svg";

export type DefaultBreadcrumbType = Pick<DocumentLocation, "name"> & {
  id: string | number;
};

const folderShorthand: DefaultBreadcrumbType = {
  id: uuid(),
  name: "..."
};

interface FolderBreadcrumbsProps {
  maxLength?: number;
  maxBreadcrumbLength?: number;
  breadcrumbs: (DocumentLocation | DefaultBreadcrumbType)[];
  fileExtension: string;
  fileName: string;
  documentId: number;
  className?: string;
  breadcrumbClass?: string;
  containerTestId?: string;
  breadcrumbsTestId?: string;
  isArticle?: boolean;
}

const FolderBreadcrumbs = ({
  breadcrumbs: breadcrumbsData,
  maxLength,
  fileExtension,
  fileName,
  breadcrumbClass,
  className,
  documentId,
  maxBreadcrumbLength = 10,
  breadcrumbsTestId,
  containerTestId,
  isArticle
}: FolderBreadcrumbsProps) => {
  const [showFullBreadcrumbsPath, setShowFullBreadcrumbsPath] =
    useToggle(false);

  const handleShowFullBreadcrumbPath = () => setShowFullBreadcrumbsPath(true);

  const fullBreadcrumbsPath = useMemo(
    () => [...breadcrumbsData].reverse(),
    [breadcrumbsData]
  );

  const shortenedBreadcrumbs = useMemo(() => {
    if (!maxLength || fullBreadcrumbsPath.length === 1)
      return fullBreadcrumbsPath;
    if (!fullBreadcrumbsPath.length || maxLength < 2) return [];
    if (maxLength === 2) return [fullBreadcrumbsPath[0]];

    if (fullBreadcrumbsPath.length === maxLength - 1)
      return fullBreadcrumbsPath;

    return [
      fullBreadcrumbsPath[0],
      folderShorthand,
      ...[...fullBreadcrumbsPath].slice(-maxLength + 2)
    ];
  }, [fullBreadcrumbsPath, maxLength]);

  const dispatch = useThunkDispatch();

  const updateDocuments = useCallback(
    (docParentId: number) => {
      const crumbs = [...breadcrumbsData].reverse();

      const path = crumbs.slice(
        0,
        crumbs.findIndex(({ id }) => id === docParentId) + 1
      );

      dispatch(getDocuments(0, docParentId));
      dispatch(getFolders(0, docParentId));
      dispatch(getFoldersPathSuccess([...path].reverse()));
    },
    [dispatch, breadcrumbsData]
  );

  const previewDocument = useCallback(
    () => dispatch(previewDocWithId(documentId)),
    [dispatch, documentId]
  );

  const breadcrumbs = useMemo(
    () =>
      showFullBreadcrumbsPath ? fullBreadcrumbsPath : shortenedBreadcrumbs,
    [showFullBreadcrumbsPath, fullBreadcrumbsPath, shortenedBreadcrumbs]
  );

  return (
    <ul
      className={classNames(styles.breadcrumbs, className)}
      data-testid={containerTestId}
    >
      {breadcrumbs.map(({ name, id }) => (
        <Breadcrumb
          isArticle={isArticle}
          maxLength={maxBreadcrumbLength}
          breadcrumbsTestId={breadcrumbsTestId}
          className={breadcrumbClass}
          name={name}
          updateDocuments={() => updateDocuments(id as number)}
          handleShowFullBreadcrumbPath={
            name === folderShorthand.name
              ? handleShowFullBreadcrumbPath
              : undefined
          }
          renderSeparator
          key={id}
        />
      ))}
      <Breadcrumb
        isArticle={isArticle}
        file
        fileExtension={fileExtension}
        fileName={fileName}
        maxLength={maxBreadcrumbLength}
        previewDocument={previewDocument}
        breadcrumbsTestId={breadcrumbsTestId}
        className={breadcrumbClass}
      />
    </ul>
  );
};

export default FolderBreadcrumbs;

interface BreadcrumbBaseProps {
  renderSeparator?: boolean;
  className?: string;
  breadcrumbsTestId?: string;
  maxLength: number;
  isArticle?: boolean;
  handleShowFullBreadcrumbPath?: () => void;
}

interface BreadcrumbDefaultProps extends BreadcrumbBaseProps {
  name: string;
  file?: undefined;
  fileName?: undefined;
  fileExtension?: undefined;
  previewDocument?: undefined;
  updateDocuments: () => void;
}

interface BreadcrumbFileProps extends BreadcrumbBaseProps {
  file: true;
  fileName: string;
  fileExtension: string;
  previewDocument: () => void;
  updateDocuments?: undefined;
  name?: undefined;
}

type BreadcrumbProps = BreadcrumbDefaultProps | BreadcrumbFileProps;

const Breadcrumb = ({
  file,
  isArticle,
  name,
  renderSeparator,
  fileExtension,
  fileName,
  className,
  previewDocument,
  updateDocuments,
  breadcrumbsTestId,
  maxLength,
  handleShowFullBreadcrumbPath
}: BreadcrumbProps) => {
  const tooltipId = useMemo(() => uuid(), []);

  const shortenedBreadcrumb = useMemo(() => {
    if (name) {
      if (name.length > maxLength) return truncate(name, { length: maxLength });

      return name;
    }

    if (fileName) {
      if (fileName.length > maxLength) {
        return `${truncate(fileName, { length: maxLength })}${
          fileName[fileName.length - 1]
        }.${!isArticle ? fileExtension : ""}`;
      }

      return `${fileName}.${!isArticle ? fileExtension : ""}`;
    }

    return "";
  }, [fileName, fileExtension, maxLength, isArticle]);

  const fullBreadcrumb = useMemo(() => {
    if (name) {
      if (name.length > maxLength) return name;

      return null;
    }

    if (fileName && fileName.length > maxLength) {
      return `${fileName}.${!isArticle ? fileExtension : ""}`;
    }

    return null;
  }, [fileName, fileExtension, maxLength, isArticle]);

  if (file) {
    return (
      <li
        className={classNames(
          styles.breadcrumb,
          styles.breadcrumbDoc,
          className
        )}
        tabIndex={0}
        onClick={previewDocument}
      >
        <div className={styles.breadcrumbIcon}>
          {getFileIcon(fileExtension as string, isArticle)}
        </div>
        <span
          className={styles.breadcrumbText}
          data-testid={breadcrumbsTestId}
          {...(fullBreadcrumb
            ? { "data-for": tooltipId, "data-tip": fullBreadcrumb }
            : {})}
        >
          {shortenedBreadcrumb}
          {fullBreadcrumb && (
            <ReactTooltip id={tooltipId} effect="solid" place="top" />
          )}
        </span>
      </li>
    );
  }

  return (
    <>
      <li className={classNames(styles.breadcrumb, className)}>
        {name === "..." ? (
          <span
            className={classNames(
              styles.breadcrumbText,
              styles.breadcrumbShorthand
            )}
            data-testid={breadcrumbsTestId}
            onClick={handleShowFullBreadcrumbPath}
          >
            {name}
          </span>
        ) : (
          <Link
            to={DOCUMENTS}
            onClick={updateDocuments}
            className={styles.breadcrumbLink}
          >
            <div className={styles.breadcrumbIcon}>
              {getFolderIcon(name as string)}
            </div>
            <span
              className={styles.breadcrumbText}
              data-testid={breadcrumbsTestId}
              {...(fullBreadcrumb
                ? { "data-for": tooltipId, "data-tip": fullBreadcrumb }
                : {})}
            >
              {shortenedBreadcrumb}
              {fullBreadcrumb && (
                <ReactTooltip id={tooltipId} effect="solid" place="top" />
              )}
            </span>
          </Link>
        )}
      </li>
      {renderSeparator && (
        <li className={styles.breadcrumbSeparator}>
          <BreadcrumbArrowIcon />
        </li>
      )}
    </>
  );
};
