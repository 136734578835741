import classNames from "classnames";
import React from "react";

import styles from "./ErrorMessage.module.scss";

interface ErrorMessageProps {
  error: string;
  className?: string;
}

export const ErrorMessage = ({ error, className }: ErrorMessageProps) => (
  <p className={classNames(styles.wrapper, className)}>{error}</p>
);
