import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useThunkDispatch } from "~/configureStore";
import { resetFilters } from "~/actions/SearchActions";
import { Icon } from "semantic-ui-react";
import ButtonIcon from "~/atoms/ButtonIcon/ButtonIcon";
import ReactTooltip from "react-tooltip";

import { ReactComponent as FiltersIcon } from "~/assets/icons/FiltersIcon.svg";
import { ReactComponent as CloseSVG } from "~/assets/icons/close.svg";

import styles from "./FiltersHead.module.scss";

export const FiltersHead = ({ handleClose }: { handleClose: () => void }) => {
  const { t } = useTranslation();
  const appliedFilters = useSelector(
    ({ searchNew: { filtersUsed } }) => filtersUsed
  );

  const dispatch = useThunkDispatch();

  const appliedFiltersCount = useMemo(() => {
    let count = 0;

    if (appliedFilters.modifiedUntil || appliedFilters.modifiedSince) {
      count += 1;
    }
    if (appliedFilters.modifiedBy) {
      count += 1;
    }
    if (appliedFilters.documentType?.length) {
      count += 1;
    }
    if (appliedFilters.knowledgeSource?.length) {
      count += 1;
    }

    return count;
  }, [appliedFilters]);

  const handleResetFilters = useCallback(() => {
    dispatch(resetFilters());
  }, [dispatch]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.filtersCountAndClearContainer}>
        <div className={styles.filtersCount}>
          <FiltersIcon />
          <span>
            {t("search.filters_head.filters", { count: appliedFiltersCount })}
          </span>
        </div>
        <div className={styles.filtersClear}>
          <ButtonIcon
            className={styles.clearButton}
            onClick={handleResetFilters}
          >
            <Icon name="close" />
            {t("search.filters_head.clear")}
          </ButtonIcon>
          <div className={styles.closeButtonContainer}>
            <ButtonIcon
              onClick={handleClose}
              className={styles.closeButton}
              data-for="closeFiltersButton"
              data-tip={t("related.close")}
            >
              <CloseSVG className={styles.closeButtonIcon} />
            </ButtonIcon>
            <ReactTooltip
              id="closeFiltersButton"
              place="top"
              type="light"
              effect="solid"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
