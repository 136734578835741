import { AppThunkDispatch } from "~/configureStore";
import { getReport } from "~/services/ReportsService";
import {
  GET_MESSAGES_REPORT_FAILURE,
  GET_MESSAGES_REPORT_REQUEST,
  GET_MESSAGES_REPORT_SUCCESS
} from "./types";

const getMessagesReportRequest = () => ({
  type: GET_MESSAGES_REPORT_REQUEST
});

const getMessagesReportSuccess = () => ({
  type: GET_MESSAGES_REPORT_SUCCESS
});

const getMessagesReportFailure = () => ({
  type: GET_MESSAGES_REPORT_FAILURE
});

export const getMessagesReport =
  (params: string) => async (dispatch: AppThunkDispatch) => {
    dispatch(getMessagesReportRequest());

    try {
      await getReport(params);

      dispatch(getMessagesReportSuccess());
    } catch (error) {
      dispatch(getMessagesReportFailure());
    }
  };
