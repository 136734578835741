/* eslint-disable react/prop-types */
import classNames from "classnames";
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef
} from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip, {
  Place,
  TooltipProps as ReactTooltipProps
} from "react-tooltip";
import { v4 as uuid } from "uuid";

import styles from "./Tooltip.module.scss";

interface TooltipProps
  extends Omit<ReactTooltipProps, "children" | "id" | "effect"> {
  children: React.ReactNode;
  tip?: string;
  light?: boolean;
  wrapperClassName?: string;
  size?: "small" | "normal";
  className?: string;
  place?: Place;
}

export interface TooltipHandle {
  show: () => void;
  hide: () => void;
}
const Tooltip = forwardRef<TooltipHandle, TooltipProps>(
  (
    {
      tip,
      place = "top",
      className,
      children,
      wrapperClassName,
      light,
      size = "normal",
      ...rest
    },
    ref
  ) => {
    const { t } = useTranslation();

    const tooltipRef = useRef<HTMLSpanElement>(null);

    const tooltipId = useMemo(() => uuid(), []);

    const show = useCallback(
      () =>
        tooltipRef.current ? ReactTooltip.show(tooltipRef.current) : undefined,
      [tooltipRef.current]
    );
    const hide = useCallback(
      () =>
        tooltipRef.current ? ReactTooltip.hide(tooltipRef.current) : undefined,
      [tooltipRef.current]
    );

    useImperativeHandle(
      ref,
      () => ({
        show,
        hide
      }),
      [show, hide]
    );

    return (
      <>
        <span
          data-tip={tip ? t(tip) : undefined}
          data-for={tooltipId}
          ref={tooltipRef}
          className={wrapperClassName}
        >
          {children}
        </span>
        <ReactTooltip
          place={place}
          effect="solid"
          id={tooltipId}
          className={classNames(
            styles.tooltip,
            className,
            light && styles.light,
            size === "small" && styles.small
          )}
          {...rest}
        />
      </>
    );
  }
);

Tooltip.displayName = "(Tooltip)";

export { Tooltip };

export default Tooltip;
