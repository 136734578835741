import request from "./request";
import type { ProjectsResponseType } from "./api-types";

const projectsUrl = "/projects";

export const getProjectsRequest = () =>
  request<ProjectsResponseType>({
    url: projectsUrl,
    method: "GET"
  });
