const initialState = {
  openError: false,
  message: ""
};

const errors = (state = initialState, action) => {
  let errorMessage = "Skontaktuj się z administratorem systemu";
  // BTW-414 temporarily disable double error handling
  // const failure = action.type.includes("_FAILURE") && action.error !== 400;
  const responseError = action.type.includes("RESPONSE_ERROR");
  const customError = action.type === "CUSTOM_ERROR";
  if (responseError || customError) {
    if (action.hasOwnProperty("payload") && action.payload.message) {
      errorMessage = action.payload.message;
    }
    if (action.hasOwnProperty("error")) {
      errorMessage = action.error.message;
    }
    return {
      openError: true,
      message: errorMessage
    };
  } else if (action.type === "CLEAR_ERROR_STATE") {
    return initialState;
  } else {
    return state;
  }
};

export default errors;
