import { truncate } from "lodash";
import { NodeProps } from "react-flow-renderer";
import { SupportingQuestionModal } from "~/atoms/SupportingQuestionModal/SupportingQuestionModal";
import { SupportingQuestionNodeData } from "~/components/types";
import { useToggle } from "~/hooks/useToggle";
import { DefaultNode } from "../DefaultNode/DefaultNode";

import styles from "./SupportingQuestion.module.scss";

const MAX_DISPLAYED_QUESTION_LENGHT = 120;

interface SupportingQuestionProps
  extends NodeProps<SupportingQuestionNodeData> {
  previewMode?: boolean;
}

export const SupportingQuestion = ({
  data: { description, question },
  xPos,
  yPos,
  id,
  previewMode
}: SupportingQuestionProps) => {
  const [isModalOpened, toggleModal] = useToggle();

  return (
    <DefaultNode
      source
      target
      extensible
      nodeData={{ id, position: { x: xPos, y: yPos } }}
      className={styles.wrapper}
      onEdit={() => toggleModal()}
      previewMode={previewMode}
    >
      <h4 className={styles.question}>
        {truncate(question, { length: MAX_DISPLAYED_QUESTION_LENGHT })}
      </h4>
      {isModalOpened && (
        <SupportingQuestionModal
          id={id}
          xPos={xPos}
          yPos={yPos}
          question={question}
          description={description}
          onModalClose={() => toggleModal()}
          previewMode={previewMode}
        />
      )}
    </DefaultNode>
  );
};
