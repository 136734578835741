import {
  CLEAR_AUTOCOMPLETE_STATE,
  GET_QUERY_SUGGESTIONS,
  GET_QUERY_SUGGESTIONS_SUCCESS
} from "~/actions/types";
import type { AutocompleteSuggestionType } from "~/services/api-types";

export interface AutocompleteState {
  querySuggestions: AutocompleteSuggestionType[];
  query: string;
}

export interface GetQuerySuggestionsAction {
  type: typeof GET_QUERY_SUGGESTIONS;
  query: string;
  withLoader: false;
}

export interface GetQuerySuggestionsSuccessAction {
  type: typeof GET_QUERY_SUGGESTIONS_SUCCESS;
  suggestions: AutocompleteSuggestionType[];
  withLoader: false;
}

export interface ClearAutoCompleteAction {
  type: typeof CLEAR_AUTOCOMPLETE_STATE;
  withLoader: false;
}

type AutocopleteActions =
  | GetQuerySuggestionsAction
  | GetQuerySuggestionsSuccessAction
  | ClearAutoCompleteAction;

const initialState: AutocompleteState = {
  query: "",
  querySuggestions: []
};

const autocomplete = (
  state = initialState,
  action: AutocopleteActions
): AutocompleteState => {
  switch (action.type) {
    case GET_QUERY_SUGGESTIONS:
      return {
        ...state,
        query: action.query,
        // clear suggestions when a user enters a completely new query
        querySuggestions: action.query.startsWith(state.query)
          ? state.querySuggestions
          : []
      };
    case GET_QUERY_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        querySuggestions: action.suggestions
      };
    case CLEAR_AUTOCOMPLETE_STATE:
      return initialState;
    default:
      return state;
  }
};

export default autocomplete;
