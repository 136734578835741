import {
  CLEAR_EDITOR_STATE,
  EDIT_CHANGE,
  FETCH_ANSWER_REQUEST,
  FETCH_ANSWER_SUCCESS,
  CHANGE_CHOSEN_ANSWER,
  CLEAR_ANSWER
} from "~/actions/types";

const initialState = {
  question: "",
  fetchingAnswers: false,
  answers: [],
  chosenAnswer: {},
  isEditingChange: false
};

const editor = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ANSWER_REQUEST:
      return {
        ...state,
        question: action.question,
        fetchingAnswers: true
      };
    case FETCH_ANSWER_SUCCESS:
      return {
        ...state,
        fetchingAnswers: false,
        answers: action.answers
      };
    case CLEAR_ANSWER:
      return {
        ...state,
        answers: []
      };
    case CHANGE_CHOSEN_ANSWER:
      return {
        ...state,
        chosenAnswer: { ...action.data }
      };
    case EDIT_CHANGE:
      return {
        ...state,
        isEditingChange: action.isEditing ?? true,
        change: action.change
      };
    case CLEAR_EDITOR_STATE:
      return {
        ...state,
        isEditingChange: false,
        chosenAnswer: {}
      };
    default:
      return state;
  }
};

export default editor;
