import React, { useCallback, useEffect, useRef, useState } from "react";
import PdfViewer from "./PdfViewer";
import HTMLParagraph from "~/components/HTMLParagraphWithLinks";

import type {
  DocumentFragmentAnchorsType,
  DocumentType
} from "~/services/api-types";
import classNames from "classnames";
import { availableFormats, FormatType } from "~/components/Documents/utils";
import styles from "./DocumentPreviewContent.module.scss";
import { scrollAndHighlightFragment } from "./scrollWithHighlight";
import { debounce } from "lodash";
import { trackEvent } from "~/helpers/tracking";

interface DocumentPreviewContentProps extends DocumentType {
  html: string | null;
  className?: string;
  scrollToAnchors: DocumentFragmentAnchorsType | null;
  eventsOnHtmlElement?: ((htmlElement: HTMLElement | null) => void)[];
}

export const DocumentPreviewContent = ({
  eventsOnHtmlElement = [],
  scrollToAnchors,
  ...props
}: DocumentPreviewContentProps) => {
  const { html, className, extension } = props;
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);
  const isImage = availableFormats[extension?.toLowerCase()] === FormatType.IMG;
  const [isScrollingToHighlight, setIsScrollingToHighlight] = useState(false);

  const scrollingToHighlightTimeout = useRef<NodeJS.Timeout | null>(null);

  const computeContentHeight = () => {
    const childHtmlParagraph = contentRef.current?.children?.[0];
    setContentHeight(childHtmlParagraph?.clientHeight ?? 0);
  };

  useEffect(() => computeContentHeight());

  const computeHeightWhenImageLoads = useCallback(
    (htmlElement: HTMLElement | null) => {
      if (htmlElement) {
        const images = htmlElement.getElementsByTagName("img");

        Array.from(images).forEach(image =>
          image.addEventListener("load", computeContentHeight)
        );
      }
    },
    []
  );

  const scrollToFragment = useCallback(
    (htmlElement: HTMLElement | null) => {
      if (scrollToAnchors && htmlElement?.parentElement) {
        setIsScrollingToHighlight(true);
        scrollAndHighlightFragment(htmlElement?.parentElement, scrollToAnchors);

        scrollingToHighlightTimeout.current = setTimeout(
          () => setIsScrollingToHighlight(false),
          1000
        );
      }
    },
    [scrollToAnchors, contentHeight]
  );
  const findParentElement = (parent: Element): Element => {
    if (parent.children.length === 1)
      return findParentElement(parent.children[0]);

    return parent;
  };

  const [_, setLoggedIds] = useState<string[]>([]);

  const collectReadFragment = useCallback(
    (element: HTMLElement | null) => {
      if (!element || isScrollingToHighlight) return;

      if (element.id) {
        setLoggedIds(prevState => {
          if (prevState.find(item => item === element.id)) return prevState;

          trackEvent({
            type: "VIEWED_DOCUMENT_FRAGMENT",
            fragmentId: element.id,
            documentId: props.id
          });

          return [...prevState, element.id];
        });
      } else {
        collectReadFragment(element.parentElement);
      }
    },
    [setLoggedIds, isScrollingToHighlight, props.id]
  );

  const observeCallback = useCallback(
    debounce((entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          collectReadFragment(entry.target as HTMLElement);
        }
      });
    }, 100),
    [collectReadFragment]
  );

  const handleObserveElements = useCallback(
    (htmlElement: Element | null) => {
      if (htmlElement) {
        const root = findParentElement(htmlElement);

        const observer = new IntersectionObserver(observeCallback);

        const handleObserveChild = (child: Element) => {
          if (child.children.length === 0) {
            observer.observe(child);
          } else {
            Array.from(child.children).forEach(handleObserveChild);
          }
        };

        Array.from(root.children).forEach(handleObserveChild);
      }
    },
    [observeCallback]
  );

  useEffect(() => {
    if (contentRef.current) {
      handleObserveElements(contentRef.current);
    }
  }, []);

  if (extension?.toLowerCase() === "pdf") {
    return <PdfViewer {...props} />;
  } else if (html) {
    return (
      <div
        ref={contentRef}
        className={classNames(
          "documentView",
          className,
          isImage && styles.centeredImagePreview
        )}
      >
        <HTMLParagraph
          eventsOnHtmlElement={[
            ...eventsOnHtmlElement,
            scrollToFragment,
            computeHeightWhenImageLoads
          ]}
          html={html}
        />
      </div>
    );
  }
  return null;
};
