import React, { ReactNode } from "react";
import ContentLoader, { IContentLoaderProps } from "react-content-loader";

interface SkeletonProps extends IContentLoaderProps {
  speed?: number;
  children: ReactNode;
}

export const Skeleton = ({ children, speed = 1, ...props }: SkeletonProps) => {
  return (
    <ContentLoader
      {...props}
      speed={speed}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      {children}
    </ContentLoader>
  );
};
