import React, { useCallback } from "react";
import { Confirm } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useSelector, useThunkDispatch } from "~/configureStore";
import { hideConfirmModal } from "~/actions";

import type { DeleteSynonymTermProps } from "~/reducers/modal";

export const DeleteTermModal = () => {
  const { t } = useTranslation();

  const { id, removeFn } = useSelector(
    ({ modal: { modalProps } }) => modalProps
  ) as DeleteSynonymTermProps;

  const dispatch = useThunkDispatch();

  const cancel = useCallback(() => {
    dispatch(hideConfirmModal());
  }, [dispatch]);

  const handleConfirm = useCallback(() => {
    dispatch(removeFn(id, true));
    cancel();
  }, [id, removeFn]);

  return (
    <Confirm
      basic
      size="small"
      content={
        <>
          <h3 style={{ color: "white" }}>
            {t("synonyms.modal.are_you_sure_deleting_term")}
          </h3>
          <p>{t("synonyms.modal.description")}</p>
        </>
      }
      cancelButton={t("documents.modal.cancel")}
      confirmButton={t("documents.modal.delete")}
      open
      onCancel={cancel}
      onConfirm={handleConfirm}
    />
  );
};
