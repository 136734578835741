import { memo, useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { NodeProps } from "react-flow-renderer";
import { useTranslation } from "react-i18next";
import { updateWorkflowTreeAction } from "~/actions/workflow/actions";
import Button from "~/atoms/Button/Button";
import { Modal, ModalHandle } from "~/atoms/Modal/Modal";
import { NodeTypes, StartNodeData } from "~/components/types";
import { useThunkDispatch } from "~/configureStore";
import { InputText } from "~/atoms/Inputs/InputText/InputText";
import { FLOW_MODAL_CONTAINER_ID } from "../../Flow";
import { DefaultNode } from "../DefaultNode/DefaultNode";
import { useElementSize } from "~/hooks/useElementSize";

import { ReactComponent as RocketSVG } from "~/assets/icons/rocket.svg";

import styles from "./StartNode.module.scss";

interface StartNodeProps extends NodeProps<StartNodeData> {
  previewMode?: boolean;
}

export const StartNode = memo(
  ({ data: { question }, id, xPos, yPos, previewMode }: StartNodeProps) => {
    const { t } = useTranslation();

    const [portalContainer, setPortalContainer] = useState<HTMLElement | null>(
      null
    );

    const dispatch = useThunkDispatch();

    const inputRef = useRef<HTMLInputElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const modalRef = useRef<ModalHandle>(null);

    const elementSize = useElementSize(buttonRef);

    const saveQuestion = useCallback(
      (question: string) =>
        dispatch(
          updateWorkflowTreeAction({
            node: {
              data: { question },
              position: {
                x: xPos,
                y: yPos
              },
              id,
              type: NodeTypes.START,
              ...elementSize
            }
          })
        ),
      [id, xPos, yPos, dispatch, elementSize]
    );

    useEffect(() => {
      const portalContainer = document.getElementById(FLOW_MODAL_CONTAINER_ID);

      setPortalContainer(portalContainer);
    }, []);

    return (
      <DefaultNode
        source
        {...(question
          ? { extensible: true, withoutRemove: true }
          : ({} as any))}
        container={!question}
        nodeData={{ id, position: { x: xPos, y: yPos } }}
        className={styles.wrapperContainer}
        previewMode={previewMode}
      >
        <Button
          onClick={() => modalRef.current?.openModal()}
          className={styles.wrapper}
          ref={buttonRef}
        >
          <RocketSVG className={styles.icon} />
          {t("flow.start_node.start")}
        </Button>

        {portalContainer &&
          createPortal(
            <Modal
              ref={modalRef}
              onConfirm={() => {
                saveQuestion(inputRef.current?.value || "");
                modalRef.current?.closeModal();
              }}
            >
              <InputText
                label={t("flow.start_node.question")}
                placeholder={t("flow.start_node.add_question")}
                maxLength={200}
                ref={inputRef}
                defaultValue={question}
                disabled={previewMode}
              />
            </Modal>,
            portalContainer!
          )}
      </DefaultNode>
    );
  }
);

StartNode.displayName = "(StartNode)";
