import download from "./download";

const reportsUrl = "/reports/questions";

/**
 *
 * @param {string} params URLSearchParams
 */

export const getReport = params => {
  if (typeof params !== "string") throw new Error("Params must be string");

  return download({
    url: reportsUrl + `?${params}`,
    method: "GET"
  });
};
