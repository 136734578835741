import { AppThunkDispatch } from "~/configureStore";
import { GetProjectsSuccessAction } from "~/reducers/projects";
import { ProjectType } from "~/services/api-types";
import { getConfigRequestService } from "~/services/LoginService";
import { loginSuccess } from "./LoginActions";
import { fetchWelcomeResponse } from "./MessagesActions";
import {
  GET_PROJECTS_REQUEST,
  GET_PROJECTS_SUCCESS,
  GET_PROJECTS_FAILURE
} from "./types";

export const getProjects = () => ({ type: GET_PROJECTS_REQUEST });

export const getProjectsSuccess = (
  projects: ProjectType[]
): GetProjectsSuccessAction => ({
  type: GET_PROJECTS_SUCCESS,
  payload: projects
});

export const getProjectsFailure = (error: unknown) => ({
  type: GET_PROJECTS_FAILURE,
  error
});

export const refreshLoginData = () => async (dispatch: AppThunkDispatch) => {
  try {
    const { email, role, userName } = await getConfigRequestService();

    dispatch(loginSuccess(userName, email, role));
    dispatch(getProjects());
    dispatch(fetchWelcomeResponse());
  } catch (err) {
    getProjectsFailure(err);
  }
};
