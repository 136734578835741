import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS
} from "./types";
import { clearState } from "./ClearActions";
import { fetchWelcomeResponse } from "./MessagesActions";
import { getProjects } from "./ProjectsActions";
import { authenticate } from "~/services/LoginService";
import { USER_ROLES } from "~/constants/app";
import { AppThunkDispatch } from "~/configureStore";
import {
  LoginRequestFailureAction,
  LoginRequestSuccessAction,
  LogoutSuccessAction
} from "~/reducers/login";
import { removeTokens } from "~/helpers/tokens";
import { CHAT, LOGIN } from "~/constants/routes";
import axios from "axios";

export function loginRequest() {
  return {
    type: LOGIN_REQUEST
  };
}

export function loginSuccess(
  user: string,
  email: string,
  role: USER_ROLES
): LoginRequestSuccessAction {
  return {
    type: LOGIN_SUCCESS,
    user,
    email,
    role
  };
}

export function loginFailure(error: unknown): LoginRequestFailureAction {
  return {
    type: LOGIN_FAILURE,
    error
  };
}

export function login(username: string, password: string) {
  return (dispatch: AppThunkDispatch) => {
    dispatch(loginRequest());

    authenticate({ username, password })
      .then(data => {
        if (data && "userName" in data && "email" in data && "role" in data) {
          dispatch(loginSuccess(data.userName, data.email, data.role));
          dispatch(getProjects());
          dispatch(fetchWelcomeResponse());

          // return window.history.pushState(undefined, "", CHAT);
        }
      })
      .catch((err: unknown) => {
        // we have to omit situation when user is logged out becuse refresh token expired (a long message is displayed and we do not want that)
        if (axios.isAxiosError(err)) {
          if (err.response?.status !== 401) {
            dispatch(loginFailure(err.message));
          }
        } else if (err instanceof Error) {
          dispatch(loginFailure(err.message));
        }
      });
  };
}

export function logoutSuccess(): LogoutSuccessAction {
  return {
    type: LOGOUT_SUCCESS
  };
}

export function logout() {
  return (dispatch: AppThunkDispatch) => {
    dispatch(clearState());
    removeTokens();
    window.history.pushState(undefined, "", LOGIN);
    // for now there is no logout request to be sent
    // so we assume success
    dispatch(logoutSuccess());
  };
}
