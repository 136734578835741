import { ReactComponent as ChatbotIcon } from "~/assets/icons/menu_chat.svg";
import { ReactComponent as SearchIcon } from "~/assets/icons/menu_search.svg";
import { ReactComponent as IssuesIcon } from "~/assets/icons/menu_issues.svg";
import { ReactComponent as ChangesIcon } from "~/assets/icons/menu_changes.svg";
import { ReactComponent as StatsIcon } from "~/assets/icons/menu_stats.svg";
import { ReactComponent as UsersIcon } from "~/assets/icons/menu_users.svg";
import {
  CHAT,
  DOCUMENTS,
  ISSUES,
  KNOWLEDGE_BASE,
  KNOWLEDGE_FRAGMENTS,
  SEARCH,
  STATISTICS,
  SYNONYMS,
  USERS,
  WORKFLOW
} from "~/constants/routes";
import { TENANT } from "./config";

export * from "./config";

export const LANGUAGES = ["pl", "en"];
export const DEFAULT_LANGUAGE = "pl";
export const FALLBACK_LANGUAGE = "en";
export const COOKIES_LANGUAGE = "USER_LANGUAGE";

// Locales cache
export const TRANSLATIONS_URL = "/translations/{{lng}}/translations.json";
export const FALLBACK_TRANSLATIONS_URL = "/translations/en/translations.json";

export const MOST_LIKELY_ANSWERS_IN_EDITOR = 5;
export const SEARCH_QUERY_SIZE = 10;

export const chartTypeOptions = [
  {
    value: "messages",
    text: "constants.sent_messages"
  },
  {
    value: "reported_messages",
    text: "constants.reported_answers"
  },
  {
    value: "solved_issues",
    text: "constants.solved_issues"
  },
  {
    value: "added_answers",
    text: "constants.added_answers"
  },
  {
    value: "added_documents",
    text: "constants.added_documents"
  }
];

export const timeRangeOptions = [
  {
    value: "1h",
    text: "constants.last_hour"
  },
  {
    value: "6h",
    text: "constants.last_six_hours"
  },
  {
    value: "12h",
    text: "constants.last_twelve_hours"
  },
  {
    value: "24h",
    text: "constants.last_twenty_four_hour"
  },
  {
    value: "3d",
    text: "constants.last_three_days"
  },
  {
    value: "7d",
    text: "constants.last_seven_days"
  },
  {
    value: "1m",
    text: "constants.last_month"
  },
  {
    value: "3m",
    text: "constants.last_three_months"
  }
];

export const userOptions = [
  {
    key: "all",
    value: "all",
    text: "constants.everyone"
  },
  {
    key: "konsultant",
    value: "user",
    text: "constants.consultants"
  },
  {
    key: "nauczyciel",
    value: "editor",
    text: "constants.teachers"
  },
  {
    key: "manager",
    value: "manager",
    text: "constants.managers"
  }
];

export const chartColors = [
  [230, 25, 75],
  [60, 180, 75],
  [255, 225, 25],
  [0, 130, 200],
  [245, 130, 48],
  [145, 30, 180],
  [70, 240, 240],
  [240, 50, 230],
  [210, 245, 60],
  [250, 190, 190],
  [0, 128, 128],
  [230, 190, 255],
  [170, 110, 40],
  [255, 250, 200],
  [128, 0, 0],
  [170, 255, 195],
  [128, 128, 0],
  [255, 215, 180],
  [0, 0, 128],
  [128, 128, 128],
  [255, 255, 255],
  [0, 0, 0]
];

export const listLoadingOptions = {
  debounceTime: 200,
  offsetBottom: 130
};

export enum USER_ROLES {
  MANAGER = "MANAGER",
  EDITOR = "EDITOR",
  ADMIN = "ADMIN",
  USER = "USER"
}

export const USER_ROLE_OPTIONS = [
  { text: "constants.consultant", value: USER_ROLES.USER },
  { text: "constants.teacher", value: USER_ROLES.EDITOR },
  { text: "constants.manager", value: USER_ROLES.MANAGER }
];

export const MENU_ITEMS = [
  {
    href: CHAT,
    name: "constants.chatbot",
    icon: ChatbotIcon
  },
  {
    href: SEARCH,
    name: "constants.search",
    icon: SearchIcon
  },
  {
    href: ISSUES,
    name: "constants.issues",
    icon: IssuesIcon,
    authorizedRoles: [USER_ROLES.EDITOR, USER_ROLES.MANAGER, USER_ROLES.ADMIN]
  },
  {
    href: KNOWLEDGE_BASE,
    name: "constants.knolwedge_base",
    icon: ChangesIcon,
    items: [
      {
        href: DOCUMENTS,
        name: "constants.documents"
      },
      {
        href: KNOWLEDGE_FRAGMENTS,
        name: "constants.fragments",
        authorizedRoles: [
          USER_ROLES.MANAGER,
          USER_ROLES.ADMIN,
          USER_ROLES.EDITOR
        ]
      },
      {
        href: SYNONYMS,
        name: "constants.synonyms",
        authorizedRoles: [
          USER_ROLES.MANAGER,
          USER_ROLES.ADMIN,
          USER_ROLES.EDITOR
        ]
      },
      ...(((TENANT === "demo" || TENANT === "english-test") &&
        process.env.NODE_ENV === "production") ||
      process.env.NODE_ENV === "development"
        ? [
            {
              href: WORKFLOW,
              name: "constants.workflow",
              authorizedRoles: [
                USER_ROLES.MANAGER,
                USER_ROLES.ADMIN,
                USER_ROLES.EDITOR
              ]
            }
          ]
        : [])
    ]
  },
  {
    href: STATISTICS,
    name: "constants.statistics",
    icon: StatsIcon,
    authorizedRoles: [USER_ROLES.MANAGER, USER_ROLES.ADMIN]
  },
  {
    href: USERS,
    name: "constants.users",
    icon: UsersIcon,
    authorizedRoles: [USER_ROLES.MANAGER, USER_ROLES.ADMIN]
  }
];

export const NEW_FRAGMENT_QUESTION_MAX_LENGTH = 50;

export const canUserDownloadDocuments = (userRole: string) => {
  return (
    userRole &&
    [USER_ROLES.EDITOR, USER_ROLES.MANAGER, USER_ROLES.ADMIN].indexOf(
      userRole as USER_ROLES
    ) > -1
  );
};

export const DATE_TIME_ENTRY_FORMAT = "DD MMM YYYY HH:mm";
