import React from "react";
import styles from "./MessageLoader.module.scss";

export const MessageLoader = () => (
  <div className={styles.MessageLoader}>
    <div className={styles.b1}></div>
    <div className={styles.b2}></div>
    <div className={styles.b3}></div>
  </div>
);
