import React from "react";
import PropTypes from "prop-types";
import { FileLabel } from "~/components/FileLabel";
import { useTranslation } from "react-i18next";

import { ReactComponent as SuggestionArrowSVG } from "~/assets/suggestionArrow.svg";

import styles from "./Changes.module.scss";

const ChangesDetails = ({
  content,
  document,
  previewDoc,
  images,
  suggestions
}) => {
  const { t } = useTranslation();

  const documentLocation = document?.location
    ?.map(({ name }) => name)
    .reverse();

  return (
    <div className={styles.changesDetails}>
      {content}
      {/* {this.renderImages(imgList, obj.id, true)} */}
      {images}
      {suggestions && (
        <div className={styles.changesDetailsSuggestions}>
          <p className={styles.descriptionLabel}>
            {t("changes.details.suggestions")}
          </p>
          {suggestions}
        </div>
      )}
      {document && (
        <>
          <span className={styles.descriptionLabel}>
            {t("changes.details.documents")}
          </span>
          <div className={styles.FileLabelWrapper}>
            {documentLocation && (
              <>
                {documentLocation.map((location, index) => (
                  <span className={styles.location} key={`${index + location}`}>
                    {location}
                    <SuggestionArrowSVG />
                  </span>
                ))}
              </>
            )}
            <FileLabel
              labelStyle={{
                color: "#000"
              }}
              {...document}
              onClick={previewDoc}
            />
          </div>
        </>
      )}
    </div>
  );
};

ChangesDetails.propTypes = {
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.element]).isRequired,
  document: PropTypes.object,
  previewDoc: PropTypes.func,
  hideDetails: PropTypes.func,
  images: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  suggestions: PropTypes.oneOfType([PropTypes.node, PropTypes.element])
};

ChangesDetails.defaultProps = {
  document: {},
  previewDoc: () => {},
  hideDetails: () => {},
  images: null,
  suggestions: null
};

export default ChangesDetails;
