import { delay, call, put, select, takeLatest } from "redux-saga/effects";
import * as api from "~/services/AnalyticsService";
import type { StoreState } from "~/configureStore";
import {
  clearEventsQueue,
  publishEventsFailure,
  trackEvent
} from "~/actions/AnalyticsActions";
import { IS_USER_ACTIVE } from "~/actions/types";

const customActivityInterval = Number(
  process.env.REACT_APP_USER_ACTIVITY_INTERVAL
);
const TRACK_USER_ACTIVITY_INTERVAL =
  customActivityInterval && !isNaN(customActivityInterval)
    ? customActivityInterval
    : 60_000;

const PUBLISH_CONTINUOUSLY_DELAY = 10000;
export const PUBLISH_CONTINUOUSLY_ACTION = "PUBLISH_EVENTS_CONTINUOUSLY";

export function* postEventsContinuously() {
  const {
    analytics: { events },
    login: { isAuthenticated }
  }: StoreState = yield select();
  if (events.length !== 0 && isAuthenticated) {
    try {
      yield call(api.postAggregatedEvents, events);
      yield put(clearEventsQueue());
    } catch (error) {
      yield put(publishEventsFailure());
    }
  }
  yield delay(PUBLISH_CONTINUOUSLY_DELAY);
  yield put({ type: PUBLISH_CONTINUOUSLY_ACTION });
}

export function* publishEventsSaga() {
  yield takeLatest(PUBLISH_CONTINUOUSLY_ACTION, postEventsContinuously);
}

export function* trackUserActivity() {
  while (true) {
    const {
      analytics: { isUserActive },
      login: { isAuthenticated }
    }: StoreState = yield select();

    if (isAuthenticated) {
      yield put(
        trackEvent({
          timestamp: new Date().toISOString(),
          type: "USER_ACTIVITY",
          data: { active: isUserActive }
        })
      );
      if (isUserActive) {
        yield put({ type: IS_USER_ACTIVE, payload: false });
      }
    }
    yield delay(TRACK_USER_ACTIVITY_INTERVAL);
  }
}

export function* trackUserActivitySaga() {
  yield call(trackUserActivity);
}
