import React, { useCallback, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CheckboxProps } from "semantic-ui-react";
import { connect } from "react-redux";
import { AppThunkDispatch } from "~/configureStore";
import { KNOWLEDGE_FRAGMENTS } from "~/constants/routes";
import {
  addNewFragment,
  NewFragmentData,
  SuggestionType
} from "~/actions/EditorActions";
import { BubbleNewMessage } from "~/atoms/BubbleNewMessage/BubbleNewMessage";
import Wrapper from "~/atoms/Wrapper/Wrapper";
import Button from "~/atoms/Button/Button";
import EditableBotMessage from "../EditableBotMessage";

import styles from "./NewFragmentForm.module.scss";
import editStyles from "./EditAnswer.module.scss";
import classNames from "classnames";

interface NewFragmentFormProps {
  saveForm: (data: NewFragmentData) => Promise<void>;
}

type ErrorsState = { message: boolean; answer: boolean };

const NewFragmentForm = ({ saveForm }: NewFragmentFormProps) => {
  const [questionValue, setQuestionValue] = useState("");
  const [answerValue, setAnswerValue] = useState("");
  const [chosenDocumentID, setChosenDocumentID] = useState<number | null>(null);
  const [suggestions, setSuggestions] = useState<SuggestionType[]>([]);
  const [combineSuggestionsWithQuery, setCombineSuggestionsWithQuery] =
    useState(false);

  const [errors, setErrors] = useReducer(
    (state: ErrorsState, newState: Partial<ErrorsState>) => ({
      ...state,
      ...newState
    }),
    {
      message: false,
      answer: false
    }
  );

  const { t } = useTranslation();
  const { push } = useHistory();

  const handleUserInput = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (errors.message) {
        setErrors({ message: false });
      }
      return setQuestionValue(value);
    },
    [errors.message]
  );

  const handleAnswerChange = useCallback(
    (value: string) => {
      if (errors.answer) {
        setErrors({ answer: false });
      }

      return setAnswerValue(value);
    },
    [errors.answer]
  );

  const handleCombineSuggestionsWithQuery = (
    _: React.FormEvent<HTMLInputElement>,
    e: CheckboxProps
  ) => {
    const { checked = false } = e;

    return setCombineSuggestionsWithQuery(checked);
  };

  const handleDocumentChange = (docId: number) => setChosenDocumentID(docId);

  const handleSuggestionsChange = (
    newSugggestions: Omit<SuggestionType, "query">[]
  ) =>
    setSuggestions(
      newSugggestions.map(
        ({ text, featured }): SuggestionType => ({
          text,
          featured,
          query:
            combineSuggestionsWithQuery && questionValue
              ? `${questionValue} ${text}`
              : text
        })
      )
    );

  const goBack = () => push(KNOWLEDGE_FRAGMENTS);

  const save = () => {
    let error = false;

    if (!questionValue) {
      setErrors({ message: true });
      error = true;
    }
    if (!answerValue) {
      setErrors({ answer: true });
      error = true;
    }

    if (error) return;

    const mappedSuggestions = combineSuggestionsWithQuery
      ? suggestions.map(({ text, query, featured }) => ({
          text,
          featured,
          query: query.includes(questionValue)
            ? query
            : `${questionValue} ${query}`
        }))
      : suggestions.map(({ text, featured }) => ({
          text,
          query: text,
          featured
        }));

    const data = {
      question: questionValue.trim(),
      answer: answerValue,
      suggestions: mappedSuggestions,
      documentId: chosenDocumentID
    };

    return saveForm(data).then(() => {
      setAnswerValue("");
      setChosenDocumentID(null);
      setSuggestions([]);
      setQuestionValue("");
      setErrors({ answer: false, message: false });

      return goBack();
    });
  };

  return (
    <>
      <Wrapper
        title={t("edit_answer.add_new_answer")}
        className={editStyles.window}
      >
        <div className={`${editStyles.view} scrollCatcher`}>
          <Step count={1} text="new_fragment.add_question" />
          <BubbleNewMessage
            isError={errors.message}
            value={questionValue}
            onChange={handleUserInput}
          />
          <Step count={2} text="new_fragment.add_answer" />
          <EditableBotMessage
            pinToolbar={true}
            disableUserInfo={true}
            text={answerValue}
            messageContainerClass={classNames(
              styles.botNewMessage,
              errors.answer ? styles.botMessageContainerError : ""
            )}
            combinedSuggestionQuery={combineSuggestionsWithQuery}
            handleCombinedSuggestionQueryChange={
              handleCombineSuggestionsWithQuery
            }
            handleSuggestionsChange={handleSuggestionsChange}
            documentId={chosenDocumentID}
            handleDocumentsChange={handleDocumentChange}
            handleTextChange={handleAnswerChange}
          />
        </div>
      </Wrapper>
      <div className={editStyles.submitButtons}>
        <Button className={editStyles.submitButtonsBack} onClick={goBack}>
          {t("edit_answer.back") as string}
        </Button>
        <Button
          secondary
          className={editStyles.submitButtonsSave}
          onClick={save}
          disabled={!questionValue || !answerValue}
        >
          {t("edit_answer.save") as string}
        </Button>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  saveForm: (data: NewFragmentData) => dispatch(addNewFragment(data))
});

const connectedNewFragmentForm = connect(
  null,
  mapDispatchToProps
)(NewFragmentForm);

export { connectedNewFragmentForm as NewFragmentForm };

interface StepProps {
  count: number;
  text: string;
}

const Step = ({ count, text }: StepProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.formStep}>
      <h2 className={styles.formStepTitle}>
        {t("new_fragment.step_count", { count })}
      </h2>
      <p className={styles.formStepDesc}>{t(text)}</p>
    </div>
  );
};
