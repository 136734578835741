import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";
import HTMLParagraphWithLinks from "~/components/HTMLParagraphWithLinks";

import styles from "./SearchGreetingMessage.module.scss";

export const SearchGreetingMessage = ({ message }: { message: string }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.heading}>
        <>
          <Icon name="info" className={styles.headingIcon} />
          {t("search.greeting.title")}
        </>
      </h1>
      <HTMLParagraphWithLinks
        html={message}
        as="div"
        className={styles.content}
      />
    </div>
  );
};
