import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import messages from "./messages";
import autocomplete from "./autocomplete";
import login from "./login";
import editor from "./editor";
import issues from "./issues";
import documents from "./documents";
import statistics from "./statistics";
import changes from "./changes";
import modal from "./modal";
import users from "./users";
import errors from "./errors";
import projects from "./projects";
import fetching from "./fetching";
import search from "./search";
import chat from "./chat";
import { synonyms } from "./synonyms";
import { appAlert } from "./AppAlert";
import { searchNew } from "./search-new";
import { workflows } from "./workflows";
import { utility } from "./utility";
import { analytics } from "./analytics";

const chatbotApp = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    autocomplete,
    messages,
    login,
    editor,
    issues,
    documents,
    statistics,
    changes,
    modal,
    users,
    errors,
    projects,
    fetching,
    search,
    chat,
    appAlert,
    synonyms,
    searchNew,
    workflows,
    utility,
    analytics
  });

export default chatbotApp;
