import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { HOME } from "~/constants/routes";
import { useMatchMedia } from "~/hooks/useMatchMedia";
import { useSelector, useThunkDispatch } from "~/configureStore";
import { toggleSidebarState } from "~/actions/Utility/UtilityActions";
import { ArrowButton } from "~/molecules/ArrowButton/ArrowButton";
import SidebarMenu from "~/components/molecules/SidebarMenu/SidebarMenu";

import { ReactComponent as BotwiseLogoSVG } from "~/assets/botwise_logo.svg";

import styles from "./Sidebar.module.scss";
import { isWhiteLabel } from "~/constants/config";

export const Sidebar = () => {
  const isWideScreen = useMatchMedia("min-width: 1600px");
  const isSidebarExpanded = useSelector(
    ({ utility: { isSidebarExpanded } }) => isSidebarExpanded
  );
  const dispatch = useThunkDispatch();

  const changeState = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      dispatch(toggleSidebarState(!isSidebarExpanded));
      return (e.target as HTMLElement).blur();
    },
    [isSidebarExpanded, dispatch]
  );

  useEffect(() => {
    dispatch(toggleSidebarState(isWideScreen));
  }, [isWideScreen]);

  return (
    <div
      className={classNames(
        styles.sidebar,
        isSidebarExpanded ? styles.sidebarExpanded : styles.sidebarReduced
      )}
    >
      <Link to={HOME} className={styles.sidebar_logo}>
        {!isWhiteLabel() && (
          <BotwiseLogoSVG
            className={isSidebarExpanded ? styles.expanded : styles.reduced}
          />
        )}
      </Link>
      <ArrowButton
        currentState={isSidebarExpanded ? "left" : "right"}
        onClick={changeState}
      />
      <SidebarMenu />
    </div>
  );
};
