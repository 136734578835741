export const HOME = "/";
export const LOGIN = "/login";
export const KNOWLEDGE_BASE = "/knowledge";
export const KNOWLEDGE_FRAGMENTS = KNOWLEDGE_BASE + "/fragments";
export const DOCUMENTS = KNOWLEDGE_BASE + "/documents";
export const DOCUMENTS_ID = KNOWLEDGE_BASE + "/documents/:id";
export const USERS = "/users";
export const CHAT = "/chat";
export const SEARCH = "/search";
export const STATISTICS = "/stats";
export const TRAIN = "/train";
export const ISSUES = TRAIN + "/issues";
export const ISSUES_SOLVE_ID = ISSUES + "/:id/solve";
export const ISSUES_SOLVE_NEW = ISSUES + "/solve/new-fragment";
export const TRAIN_FRAGMENT = TRAIN + KNOWLEDGE_BASE + "/fragments";
export const TRAIN_FRAGMENT_BRAND_NEW = TRAIN_FRAGMENT + "/brand-new";
export const TRAIN_FRAGMENT_EDIT = TRAIN_FRAGMENT + "/edit";
export const SYNONYMS = KNOWLEDGE_BASE + "/synonyms";
export const WORKFLOW = "/workflow";
export const WORKFLOW_CREATE = WORKFLOW + "/create";
export const WORKFLOW_EDIT = WORKFLOW + "/edit";
export const WORKFLOW_EDIT_ID = WORKFLOW_EDIT + "/:id";
export const ARTICLE = DOCUMENTS + "/article";
export const NEW_ARTICLE = ARTICLE + "/new";
export const EDIT_ARTICLE = ARTICLE + "/edit/:id";
