import { connect } from "react-redux";
import BotMessageActions from "~/components/BotMessageActions";
import { editAnswerToQuestionKey, showReportMessageModal } from "~/actions";

const mapDispatchToProps = dispatch => {
  return {
    changeAnswer: botMessageKey => {
      dispatch(editAnswerToQuestionKey(botMessageKey));
    },
    report: id => {
      dispatch(
        showReportMessageModal({
          id
        })
      );
    }
  };
};

const mapStateToProps = (state, ownProps) => {
  const role = state.login.role;
  return {
    authorizedToTrain: ["EDITOR", "MANAGER", "ADMIN"].includes(role)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BotMessageActions);
