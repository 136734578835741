import React from "react";
import classnames from "classnames";
import styles from "./TitleWithContext.module.scss";

interface TitleWithContextProps {
  className?: string;
  contextParts: string[];
  children: React.ReactNode;
}

export const TitleWithContext = ({
  className,
  children,
  contextParts
}: TitleWithContextProps) => {
  return (
    <h3 className={classnames(styles.title, className)}>
      <span className={styles.question}>{children}</span>
      {contextParts &&
        contextParts.length > 0 &&
        " (" + contextParts.join(" → ") + ")"}
    </h3>
  );
};
