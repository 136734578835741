import React from "react";
import MessageOption from "~/containers/MessageOptionContainer";
import type { SuggestionType } from "~/actions/EditorActions";

import styles from "./MessageOptionList.module.scss";

interface MessageOptionListProps {
  items: SuggestionType[];
  disabled?: boolean;
}

const MessageOptionList = ({
  items,
  disabled = false
}: MessageOptionListProps) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <div className={styles.list}>
      {items.map((item, index) => (
        <MessageOption key={index} item={item} disabled={disabled} />
      ))}
    </div>
  );
};

export default MessageOptionList;
