export interface GenericActionType {
  payload: any;
  type: string;
}

export function createAction(type: string, ...argNames: string[]) {
  return function (...args: any[]) {
    const action: GenericActionType = { type, payload: {} };
    argNames.forEach((arg, index) => {
      if (arg === "payload") {
        action["payload"] = args[index];
      } else {
        action["payload"][arg] = args[index];
      }
    });
    return action;
  };
}
