import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest
} from "../helpers/axios";
import {
  ArticleDraftDto,
  DocumentType,
  PageableData,
  UpdateHistoryType
} from "./api-types";
import { DOCUMENTS } from "./api-urls";

export const convertToArticle = (documentId: number) => {
  return postRequest<DocumentType>(
    `${DOCUMENTS}/${documentId}/convertToArticle`
  );
};

export const convertBatchToArticle = (documentIds: number[]) => {
  const documentIdsParam = documentIds.join(",");
  return postRequest<DocumentType>(
    `${DOCUMENTS}/convertToArticle?documentIds=${documentIdsParam}`
  );
};

export const newArticle = (
  parentId: number,
  name: string,
  content = "<p></p>"
) => {
  const file = new File([new Blob([content])], `${name}.html`, {
    type: "text/plain"
  });
  return uploadArticle(file, parentId, name);
};

export const uploadArticle = (file: File, parentId: number, name: string) => {
  return postRequest<DocumentType>(`${DOCUMENTS}/upload?type=ARTICLE`, {
    data: newFormData(file, {
      parentId,
      name
    })
  });
};

export const updateDraft = (
  articleId: number,
  content: string,
  name: string
) => {
  const file = new File([new Blob([content])], `${name}.html`, {
    type: "text/plain"
  });

  return putRequest<ArticleDraftDto>(`${DOCUMENTS}/${articleId}/draft`, {
    data: newFormData(file, { name })
  });
};

export const getDraft = (articleId: number) => {
  return getRequest<ArticleDraftDto>(`${DOCUMENTS}/${articleId}/draft`);
};

export const getUpdatesHistory = (articleId: number, page = 0) => {
  return getRequest<PageableData<UpdateHistoryType>>(
    `${DOCUMENTS}/${articleId}/revisions?page=${page}&sort=createdAt,desc`
  );
};

export const getDraftContent = (articleId: number) => {
  return getRequest<string>(`${DOCUMENTS}/${articleId}/draft/htmlContent`);
};

export const deleteDraft = (articleId: number) => {
  return deleteRequest<ArticleDraftDto>(`${DOCUMENTS}/${articleId}/draft`);
};

export const publishDraft = (
  articleId: number,
  description: string = "",
  notifyUsers: boolean = false,
  group: string | null = null
) => {
  return postRequest<ArticleDraftDto>(
    `${DOCUMENTS}/${articleId}/revisions?source=DRAFT`,
    {
      data: {
        description,
        notifyUsers,
        group
      }
    }
  );
};

const newFormData = (file: File, properties: any) => {
  const data = new FormData();
  data.append("file", file);
  data.append(
    "properties",
    new Blob([JSON.stringify(properties)], {
      type: "application/json"
    })
  );
  return data;
};
