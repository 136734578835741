import { AppThunkDispatch, dispatch, StoreState } from "~/configureStore";
import {
  GenerateNodeData,
  NodeEditData,
  Workflow,
  WorkflowData,
  WorkflowMetadata,
  WorkflowTree,
  WorkflowTreeUpdateData
} from "~/reducers/types";
import type {
  LoadWorkflowsRequest,
  LoadWorkflowsSuccess,
  LoadWorkflowsFailure,
  InitWorkflow,
  SetWorkflowMetadata,
  UpdateWorkflowTree,
  RemoveWorkflowItem
} from "~/reducers/workflows";
import {
  LOAD_WORKFLOWS_REQUEST,
  LOAD_WORKFLOWS_FAILURE,
  LOAD_WORKFLOWS_SUCCESS,
  SET_WORKFLOW_METADATA,
  UPDATE_WORKFLOW_TREE,
  WORKFLOW_INIT,
  CLEAR_WORKFLOW_TREE,
  REMOVE_WORKFLOW,
  REMOVE_WORKFLOW_ITEM,
  UPDATE_WORKFLOW_TREE_NODES
} from "./types";
import { v4 as uuid } from "uuid";
import {
  addWorkflow,
  getWorkflow,
  getWorkflows,
  removeCurrentWorkflow,
  removeWorkflow
} from "~/services/localStorage/workflows";
import { delay } from "~/utils";
import { createNodeFixture, generateEdge } from "~/helpers/workflows";
import { EdgeTypes, NodesData, NodeTypes } from "~/components/types";
import { Node, NodeChange } from "react-flow-renderer";
import { DEFAULT_EDGE_SIZE } from "~/constants/workflows";

const DEFAULT_NODE_VERTICAL_OFFSET = 50;
const DEFAULT_NODE_HORIZONTAL_OFFSET = 200;

const loadWorkflowsRequest = (): LoadWorkflowsRequest => ({
  type: LOAD_WORKFLOWS_REQUEST
});

const loadWorkflowsFailure = (error: unknown): LoadWorkflowsFailure => ({
  type: LOAD_WORKFLOWS_FAILURE,
  error
});

const loadWorkflowsSuccess = (data: Workflow[]): LoadWorkflowsSuccess => ({
  type: LOAD_WORKFLOWS_SUCCESS,
  data
});

type RemoveItemData =
  | { edgeId: string; nodeId?: undefined }
  | { nodeId: string; edgeId?: undefined };

export const removeWorkflowItemAction = (
  data: RemoveItemData
): RemoveWorkflowItem => ({
  type: REMOVE_WORKFLOW_ITEM,
  data
});

export const removeWorkflowAction =
  (id: string) => async (dispatch: AppThunkDispatch) => {
    try {
      await delay(300);

      removeWorkflow(id);

      dispatch({ type: REMOVE_WORKFLOW, id });
    } catch {}
  };

export const loadEditWorkflowData =
  (id: string) => async (dispatch: AppThunkDispatch) => {
    try {
      await delay(500);

      const workflow = getWorkflow(id);

      if (!workflow?.data) throw new Error("Workflow not found");

      dispatch(initWorkflowAction(workflow.data));

      await delay(100);
    } catch (err) {
      /** @todo handle errors */
      if (err instanceof Error) {
        throw err.message;
      }

      throw err;
    }
  };

export const removeCurrentWorkflowAction =
  () => (dispatch: AppThunkDispatch) => {
    removeCurrentWorkflow();
    dispatch({ type: CLEAR_WORKFLOW_TREE });
  };

export const loadWorkflowsAction = () => async (dispatch: AppThunkDispatch) => {
  dispatch(loadWorkflowsRequest());

  try {
    // fetch
    await delay(500);

    dispatch(loadWorkflowsSuccess(getWorkflows()));
  } catch (err) {
    dispatch(loadWorkflowsFailure(err));
  }
};

export const createWorkflowAction =
  (data: WorkflowData) =>
  async (dispatch: AppThunkDispatch, getState: () => StoreState) => {
    await delay(200);

    const user = getState().login.user;

    addWorkflow({
      ...data,
      id: uuid(),
      createdBy: { userName: user! },
      createdAt: new Date().toString()
    });
    removeCurrentWorkflow();

    dispatch({ type: CLEAR_WORKFLOW_TREE });
  };

export const initWorkflowAction = (data: WorkflowData): InitWorkflow => ({
  type: WORKFLOW_INIT,
  data
});

export const setWorkflowMetadataAction = (
  data: WorkflowMetadata
): SetWorkflowMetadata => ({
  type: SET_WORKFLOW_METADATA,
  data
});

export const generateNewNodeAction =
  (creator: GenerateNodeData) => (dispatch: AppThunkDispatch) => {
    const nodeFixture = createNodeFixture();

    const nodeHorizontalOffset =
      creator.position.x +
      (creator.width || DEFAULT_NODE_HORIZONTAL_OFFSET) +
      DEFAULT_EDGE_SIZE.width;

    const nodeVerticalOffset =
      creator.position.y +
      (creator.height || DEFAULT_NODE_VERTICAL_OFFSET) +
      DEFAULT_EDGE_SIZE.height;

    dispatch({
      type: UPDATE_WORKFLOW_TREE,
      data: {
        node: {
          ...nodeFixture,
          position: {
            x: nodeHorizontalOffset,
            y: nodeVerticalOffset
          }
        },
        edge: generateEdge(creator.id, nodeFixture.id, EdgeTypes.INPUT, {
          text: ""
        })
      }
    });
  };

export const updateWorkflowTreeAction =
  (data: WorkflowTreeUpdateData) =>
  (dispatch: AppThunkDispatch, getState: () => StoreState) => {
    dispatch({
      type: UPDATE_WORKFLOW_TREE,
      data
    });

    const currentEdges = getState().workflows.currentWorkFlow!.tree.edges;

    const addedNode = data.node;

    if (addedNode && addedNode.type === NodeTypes.START) {
      if (currentEdges.find(edge => edge.source === addedNode.id)) return;

      dispatch(generateNewNodeAction(addedNode));
    }
  };

export const updateWorkflowTreeNodes = (nodes: Node<NodesData>[]) => ({
  type: UPDATE_WORKFLOW_TREE_NODES,
  nodes
});
