import React, { useCallback, useEffect, useState } from "react";
import ButtonIcon from "~/atoms/ButtonIcon/ButtonIcon";
import { TableOfContents } from "~/atoms/TableOfContents/TableOfContents";
import Tooltip from "~/atoms/Tooltip/Tooltip";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Editor as EditorInstance } from "tinymce";

import { ReactComponent as ContentsSVG } from "~/assets/icons/contents.svg";
import { ReactComponent as CloseSVG } from "~/assets/icons/close.svg";

import styles from "./Contents.module.scss";

interface ContentsProps {
  content: HTMLBodyElement | null;
  editor: React.RefObject<EditorInstance>;
  editorContent: string;
  isOpened: boolean;
  toggleContents: () => void;
}

export const Contents = ({
  content,
  isOpened,
  toggleContents,
  editorContent,
  editor
}: ContentsProps) => {
  const [tableOfContents, setTableOfContents] =
    useState<NodeListOf<HTMLHeadingElement> | null>(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (content) {
      const headings = content.querySelectorAll(
        "h1, h2, h3, h4, h5, h6"
      ) as NodeListOf<HTMLHeadingElement>;

      setTableOfContents(headings);
    }
  }, [editorContent, isOpened, content]);

  const handleAnchorClick = useCallback(
    (anchor: string) => {
      if (editor.current) {
        const item = editor.current.getDoc().getElementById(anchor);

        if (item) {
          item.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      }
    },
    [editor.current]
  );

  const contentsHeader = () => (
    <div className={styles.contentsHeader}>
      <div className={styles.iconWithTitle}>
        <ContentsSVG className={styles.contentsIcon} />
        <h2>{t("documents.preview_modal.table_of_contents")}</h2>
      </div>
      <Tooltip light tip={t("documents.new_article_contents_close")}>
        <ButtonIcon onClick={toggleContents} className={styles.closeButton}>
          <CloseSVG className={styles.icon} />
        </ButtonIcon>
      </Tooltip>
    </div>
  );

  return (
    <>
      <TableOfContents
        articleMode
        className={classNames(
          styles.table,
          isOpened ? styles.opened : styles.closed
        )}
        items={tableOfContents}
        headerExtend={contentsHeader}
        onAnchorClick={handleAnchorClick}
      />
      {!isOpened && (
        <Tooltip light tip={t("documents.new_article_contents")}>
          <ButtonIcon
            onClick={toggleContents}
            className={styles.contentsButton}
          >
            <ContentsSVG />
          </ButtonIcon>
        </Tooltip>
      )}
    </>
  );
};
