import React from "react";
import PropTypes from "prop-types";
import { Image } from "semantic-ui-react";
import { truncate } from "lodash/string";

import { ExternalLink } from "~/atoms/ExternalLink/ExternalLink";

import { availableFormats } from "~/components/Documents/utils";

import styles from "./FileLabel.module.scss";

import docIcon from "~/assets/file-icons/doc.svg";
import imgIcon from "~/assets/file-icons/img.svg";
import pdfIcon from "~/assets/file-icons/pdf.svg";
import pptIcon from "~/assets/file-icons/ppt.svg";
import txtIcon from "~/assets/file-icons/txt.svg";
import xlsIcon from "~/assets/file-icons/xls.svg";
import articleIcon from "~/assets/file-icons/wiki.svg";
import htmlIcon from "~/assets/file-icons/html.svg";

const extensionIconMap = {
  doc: docIcon,
  img: imgIcon,
  pdf: pdfIcon,
  ppt: pptIcon,
  txt: txtIcon,
  xls: xlsIcon,
  article: articleIcon,
  html: htmlIcon
};

const getFileName = (name, extension, isArticle) => {
  return name && extension
    ? !isArticle
      ? `${truncate(name, {
          length: 60,
          separator: /,?\.* +/
        })}.${extension}`
      : `${truncate(name, { length: 60, separator: /,?\.* +/ })}`
    : "";
};
const getIcon = (extension, isArticle) => {
  return !isArticle
    ? extensionIconMap[availableFormats[extension?.toLowerCase()]]
    : extensionIconMap.article;
};

export const FileLabel = ({
  bigLabel,
  noBorder,
  name,
  extension,
  fileName,
  external,
  url,
  onClick,
  isArticle
}) => {
  if (external) {
    name = url;
  }

  const parsedName = getFileName(name, extension, isArticle);
  const fileNameExtension =
    fileName && fileName.split(".")[fileName.split(".").length - 1];
  const noBorderClass = noBorder ? styles.noBorder : "";
  const noFilenameClass = !parsedName ? styles.noFilename : "";
  const bigLabelClass = bigLabel ? styles.bigLabel : "";
  const additionalClasses = ` ${noBorderClass} ${noFilenameClass} ${bigLabelClass}`;

  let nameElement = null;
  if (parsedName) {
    nameElement = <span>{parsedName}</span>;
  } else {
    if (fileName) {
      nameElement = (
        <span>{truncate(fileName, { length: 60, separator: /,?\.* +/ })}</span>
      );
    } else {
      nameElement = (
        <span>
          {truncate(name, { length: 60, separator: /,?\.* +/ })}
          {extension}
        </span>
      );
    }
  }

  if (external) {
    nameElement = <ExternalLink name={nameElement} url={url} />;
  }

  return (
    <div
      className={`${styles.FileLabel}${additionalClasses}`}
      onClick={onClick}
    >
      <Image
        className={styles.FileLabelIcon}
        src={
          getIcon(extension, isArticle) ||
          getIcon(fileNameExtension, isArticle) ||
          extensionIconMap["txt"]
        }
        rounded
      />
      {nameElement}
    </div>
  );
};

FileLabel.propTypes = {
  bigLabel: PropTypes.bool,
  noBorder: PropTypes.bool,
  name: PropTypes.string,
  extension: PropTypes.string,
  fileName: PropTypes.string,
  external: PropTypes.bool,
  url: PropTypes.string,
  onClick: PropTypes.func,
  isArticle: PropTypes.bool
};
