import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, Icon } from "semantic-ui-react";
import classNames from "classnames";

import styles from "./BotMessageActions.module.scss";
import LinkButton from "~/components/LinkButton";
import { ReactComponent as Flag } from "~/assets/Flag.svg";
import { ReactComponent as Settings } from "~/assets/Settings.svg";
import { withTranslation } from "react-i18next";
import { TRAIN_FRAGMENT, TRAIN_FRAGMENT_EDIT } from "~/constants/routes";

const MessageActions = withTranslation()(props => (
  <ul className={styles.list}>
    {props.authorizedToTrain && (
      <li className={styles.listItem}>
        <Settings />
        <Link
          className={styles.action}
          to={TRAIN_FRAGMENT}
          onClick={() => props.changeAnswer(props.id)}
        >
          {props.t("bot_message_actions.manage")}
        </Link>
      </li>
    )}
    <li className={styles.listItem}>
      <LinkButton
        img={<Flag />}
        className={styles.action}
        onClick={() => props.report(props.id)}
      >
        {props.t("bot_message_actions.report")}
      </LinkButton>
    </li>
  </ul>
));

MessageActions.propTypes = {
  authorizedToTrain: PropTypes.bool,
  id: PropTypes.string,
  changeAnswer: PropTypes.func,
  report: PropTypes.func,
  t: PropTypes.func.isRequired
};

class BotMessageActionsComponent extends React.Component {
  renderEditButton = () => (
    <Button.Group basic>
      <Button as={Link} to={TRAIN_FRAGMENT_EDIT}>
        <Icon name="edit" color="blue" />
        {this.props.t("bot_message_actions.edit")}
      </Button>
    </Button.Group>
  );

  render() {
    if (this.props.editable) {
      return (
        <div
          className={classNames(
            styles.actions,
            this.props.className,
            this.props.hideActions && "hidden",
            this.props.alignLeft && styles.actionsAlignLeft
          )}
        >
          <MessageActions {...this.props} />
        </div>
      );
    } else if (this.props.editMode) {
      return this.renderEditButton();
    } else {
      return null;
    }
  }
}

BotMessageActionsComponent.propTypes = {
  editable: PropTypes.bool,
  editMode: PropTypes.bool,
  hideActions: PropTypes.bool,
  alignLeft: PropTypes.bool,
  margin: PropTypes.number,
  className: PropTypes.string,
  t: PropTypes.func.isRequired
};

export default withTranslation()(BotMessageActionsComponent);
