import React, { useCallback } from "react";
import { captureException } from "@sentry/browser";
import { withRouter, RouteComponentProps } from "react-router";
import { previewDocWithId } from "~/actions/DocumentsActions";
import { useThunkDispatch } from "~/configureStore";
import { DOCUMENTS } from "~/constants/routes";
import { getDocumentIdFromUrl } from "~/helpers/url";
import HTMLParagraph, { HTMLParagraphProps } from "../HTMLParagraph";

interface HTMLParagraphWithLinksProps
  extends RouteComponentProps,
    HTMLParagraphProps {}

const HTMLParagraphWithLinks = ({
  eventsOnHtmlElement = [],
  ...props
}: HTMLParagraphWithLinksProps) => {
  const dispatch = useThunkDispatch();

  const openDocument = useCallback(
    (id: number) => dispatch(previewDocWithId(id)),
    [dispatch]
  );

  const handleHtmlEvents = useCallback(
    (htmlElement: HTMLElement | null) => {
      if (htmlElement) {
        const allLinks = htmlElement.getElementsByTagName("a");

        Array.from(allLinks).forEach(link => {
          if (
            link.href.includes(DOCUMENTS) &&
            (link.target === "_self" || !link.target)
          ) {
            link.addEventListener("click", e => {
              e.preventDefault();
              e.stopPropagation();

              const origin = window.location.origin;

              const linkHref = link.href.includes(origin)
                ? link.href.slice(origin.length)
                : link.href;

              const href =
                linkHref.indexOf("/") !== 0 ? `/${linkHref}` : linkHref;

              const id = getDocumentIdFromUrl(href);

              if (!id) {
                captureException(
                  new Error(`Document link has no id. Url: ${href}`)
                );
                return;
              }

              return openDocument(id);
            });
          }
        });
      }
    },
    [props.html]
  );

  return (
    <HTMLParagraph
      {...props}
      eventsOnHtmlElement={[handleHtmlEvents, ...eventsOnHtmlElement]}
    />
  );
};

export default withRouter(HTMLParagraphWithLinks);
