import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./Table.module.scss";

import { ReactComponent as UpIcon } from "~/assets/up_small.svg";

const TableHeadItem = ({ title, sortable, handleSortAction, active, size }) => {
  const [isAscending, setIsAscending] = useState(true);

  const handleItemClick = () => {
    if (!active) {
      handleSortAction(isAscending);
      setIsAscending(isAscending);
      return;
    }

    handleSortAction(!isAscending);
    setIsAscending(!isAscending);
  };

  return (
    <div
      className={classnames(
        styles.tableHeadItem,
        size,
        sortable && styles.tableHeadItemSortable,
        active && styles.tableHeadItemActive
      )}
      onClick={handleItemClick}
      tabIndex={sortable ? "0" : "-1"}
    >
      {title}
      {sortable && (
        <UpIcon
          className={classnames(
            styles.tableHeadItemIcon,
            isAscending && styles.tableHeadItemIconAscending,
            active && styles.tableHeadItemIconActive
          )}
        />
      )}
    </div>
  );
};

TableHeadItem.propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  sortable: PropTypes.bool,
  active: PropTypes.bool,
  handleSortAction: PropTypes.func
};

TableHeadItem.defaultProps = {
  sortable: false,
  active: false,
  handleSortAction: () => {}
};

export default TableHeadItem;
