import tinymce from "tinymce/tinymce";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";
import { getLocaleISO } from "~/helpers/locale";
import classNames from "classnames";

import "../../lib/phonenumber";
import "tinymce/plugins/preview";
import "tinymce/plugins/autoresize";
import "tinymce/plugins/directionality";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/codesample";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/advlist";
import "tinymce/plugins/autolink";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/lists";
import "tinymce/plugins/charmap";
import "tinymce/plugins/anchor";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/code";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/media";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/table";
import "tinymce/plugins/template";
import "tinymce/plugins/help";

import styles from "./RichTextEditor.module.scss";

interface RichTextEditorProps {
  value: string;
  onChange: (value: string, editor: TinyMCEEditor) => void;
  onBlur?: Editor["props"]["onBlur"];
  pinToolbar?: boolean;
  className?: string;
  setHtml?: (htmlElement: HTMLBodyElement | null) => void;
  editorRef?: React.MutableRefObject<TinyMCEEditor | null> | null;
  contentStyle?: string;
  placeholder?: string;
  additionalOffset?: number;
  // when height is specified it will not be calculated automatically
  editorHeight?: number;
}

const RichTextEditor = ({
  pinToolbar: _pinToolbar,
  value,
  additionalOffset = 0,
  onChange,
  onBlur,
  className,
  editorRef = null,
  setHtml,
  contentStyle,
  placeholder,
  editorHeight
}: RichTextEditorProps) => {
  const handleEditorChange = useCallback(
    (value: string, editor: TinyMCEEditor) => {
      onChange(value, editor);
    },
    [onChange]
  );

  const [toolbarOffset, setToolbarOffset] = useState(0);

  const toolbarContainerRef = useRef<HTMLDivElement>(null);

  const handleObserveChild: ResizeObserverCallback = entries => {
    const entry = entries[0];

    const parent =
      ((entry.target as HTMLElement).offsetParent as HTMLElement) || undefined;

    const offsetTop = (parent && parent.getBoundingClientRect().top) || 0;
    0;

    if (entry) {
      setToolbarOffset(entry.contentRect.height + offsetTop);
    }
  };

  const resizeObserver = new ResizeObserver(handleObserveChild);

  useEffect(() => {
    if (toolbarContainerRef.current && !editorHeight) {
      resizeObserver.observe(toolbarContainerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [editorHeight]);

  return (
    <div className={classNames(styles.Editor, className)}>
      <div id="rte-toolbar-container" ref={toolbarContainerRef} />
      <div
        style={
          editorHeight
            ? {
                height: editorHeight,
                overflowY: "auto"
              }
            : {
                height: `calc(100vh - ${toolbarOffset}px - ${additionalOffset}px)`,
                overflowY: "auto"
              }
        }
        className={styles.EditorContent}
      >
        <Editor
          onInit={(evt, editor) => {
            editorRef ? (editorRef.current = editor) : null;
            setHtml ? setHtml(editor?.getBody() as HTMLBodyElement) : null;
          }}
          init={{
            placeholder,
            language: getLocaleISO(),
            language_url:
              getLocaleISO() === "pl"
                ? process.env.PUBLIC_URL + "/js/pl.js"
                : undefined,
            plugins: [
              "preview",
              "autoresize",
              "directionality",
              "visualblocks",
              "visualchars",
              "codesample",
              "pagebreak",
              "advlist",
              "autolink",
              "link",
              "image",
              "lists",
              "charmap",
              "anchor",
              "searchreplace",
              "wordcount",
              "code",
              "fullscreen",
              "insertdatetime",
              "media",
              "nonbreaking",
              "table",
              "template",
              "help",
              "phonenumber"
            ],
            external_plugins: {
              phonenumber: "../../utils/tinemce_custom/phonenumber"
            },
            toolbar1:
              "undo redo | blocks | headings bold italic underline strikethrough forecolor backcolor | link phonenumber image media table | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
            force_br_newlines: true,
            content_style: `${contentStyle ?? ""}`,
            branding: false,
            menubar: false,
            statusbar: false,
            autoresize_bottom_margin: 25,
            file_picker_types: "image",
            toolbar_sticky: true,
            inline: true,
            fixed_toolbar_container: "#rte-toolbar-container",
            toolbar_persist: true,
            // and here's our custom image picker
            // @ts-ignore
            file_picker_callback: function (cb, value, meta) {
              const input = document.createElement("input");
              input.setAttribute("type", "file");
              input.setAttribute("accept", "image/*");

              input.onchange = function (e) {
                const element = e.target as HTMLInputElement;

                const file = element?.files?.[0];

                if (!file) return;

                const reader = new FileReader();
                reader.onload = function () {
                  const id = "blobid" + new Date().getTime();
                  const blobCache =
                    tinymce.activeEditor?.editorUpload.blobCache;
                  if (blobCache) {
                    const base64 = (reader.result as string).split(",")[1];
                    const blobInfo = blobCache.create(id, file, base64);
                    blobCache.add(blobInfo);

                    cb(blobInfo.blobUri(), { title: file.name });
                  }
                };
                reader.readAsDataURL(file);
              };

              input.click();
            }
          }}
          value={value}
          onEditorChange={handleEditorChange}
          onBlur={onBlur}
        />
      </div>
    </div>
  );
};

export default RichTextEditor;
