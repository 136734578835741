import {
  GET_DOCUMENTS,
  GET_DOCUMENTS_SUCCESS,
  DELETE_DOCUMENT_SUCCESS,
  GET_DOCUMENTS_FAILURE,
  LIST_DOCUMENTS,
  LIST_DOCUMENTS_SUCCESS,
  GET_FOLDERS,
  GET_FOLDERS_SUCCESS,
  GET_FOLDERS_FAILURE,
  UPDATE_FOLDER,
  NAME_DOCUMENT,
  GET_FOLDERS_PATH,
  GET_FOLDERS_PATH_SUCCESS,
  GET_MOVE_FOLDERS_PATH_SUCCESS,
  CURRENT_FOLDER,
  UPDATE_FOLDERS_PATH
} from "./types";
import { showConfirmModal } from "./ModalActions";
import * as api from "~/services/DocumentsService";
import { createAction } from "./createAction";
import {
  FILE_QUEUE_CLEAR,
  FILE_QUEUE_END,
  FILE_QUEUE_START,
  FILE_UPDATE_CLEAR,
  FILE_UPDATE_END,
  FILE_UPDATE_START,
  FILE_URL_COPIED
} from "./AppAlertActions";
import { AppThunkDispatch } from "~/configureStore";
import { DocumentFragmentAnchorsType } from "~/services/api-types";
import { DEFAULT_ALERT_DISPLAY_TIME } from "~/reducers/AppAlert";
import { Breadcrumb } from "~/helpers/types";

export const getDocuments = createAction(
  GET_DOCUMENTS,
  "pageNumber",
  "parentId",
  "sort",
  "documentId"
);
export const getDocumentsSuccess = createAction(
  GET_DOCUMENTS_SUCCESS,
  "documents",
  "pageNumber"
);
export const getDocumentsFailure = createAction(GET_DOCUMENTS_FAILURE, "error");
export const getFolders = createAction(
  GET_FOLDERS,
  "pageNumber",
  "parentId",
  "sort"
);
export const getFoldersPath = createAction(
  GET_FOLDERS_PATH,
  "pageNumber",
  "parentId"
);
export const getFoldersSuccess = createAction(
  GET_FOLDERS_SUCCESS,
  "folders",
  "pageNumber"
);

export const setMoveFoldersList = createAction(
  GET_MOVE_FOLDERS_PATH_SUCCESS,
  "folders"
);

export const getFoldersPathSuccess = createAction(
  GET_FOLDERS_PATH_SUCCESS,
  "folders"
);
export const getFoldersFailure = createAction(GET_FOLDERS_FAILURE, "error");
// export const updateFolder = createAction(UPDATE_FOLDER, "id", "name");
export const nameDocument = createAction(NAME_DOCUMENT, "id", "name");
export const listDocumentsSuccess = createAction(
  LIST_DOCUMENTS_SUCCESS,
  "documentsList"
);

export const uploadFileAction =
  (files: File[], parentId: number) => (dispatch: AppThunkDispatch) => {
    dispatch({
      type: FILE_QUEUE_START,
      payload: files.map(({ name }) => ({ name }))
    });

    return api
      .uploadFiles(files, parentId)
      .then(res => {
        const payload = res.map(({ name, extension }) => ({
          name: name + "." + extension
        }));

        dispatch({
          type: FILE_QUEUE_END,
          payload
        });

        setTimeout(
          () =>
            dispatch({
              type: FILE_QUEUE_CLEAR,
              payload
            }),
          DEFAULT_ALERT_DISPLAY_TIME
        );
      })
      .catch(err => {});
    // type: UPLOAD_FILE,
    // payload: api.uploadFiles(files, parentId)
  };

export const updateFileAction =
  (file: File, docId: number) => (dispatch: AppThunkDispatch) => {
    dispatch({
      type: FILE_UPDATE_START
    });

    return api
      .updateFile(file, docId)
      .then(() => {
        dispatch({
          type: FILE_UPDATE_END
        });

        setTimeout(
          () =>
            dispatch({
              type: FILE_UPDATE_CLEAR
            }),
          DEFAULT_ALERT_DISPLAY_TIME
        );
      })
      .catch(err => {});
  };

export const updateWebDocument =
  (docId: number) => (dispatch: AppThunkDispatch) => {
    dispatch({
      type: FILE_UPDATE_START
    });

    return api
      .updateWebDocument(docId)
      .then(() => {
        dispatch({
          type: FILE_UPDATE_END
        });

        setTimeout(
          () =>
            dispatch({
              type: FILE_UPDATE_CLEAR
            }),
          DEFAULT_ALERT_DISPLAY_TIME
        );
      })
      .catch(err => {});
  };

export const uploadFromURL =
  (url: string, parentId: number) => (dispatch: AppThunkDispatch) => {
    const payload = [{ url }];

    dispatch({
      type: FILE_QUEUE_START,
      payload
    });

    return api
      .uploadFromURL(url, parentId)
      .then(() => {
        dispatch({
          type: FILE_QUEUE_END,
          payload
        });

        setTimeout(
          () =>
            dispatch({
              type: FILE_QUEUE_CLEAR,
              payload
            }),
          DEFAULT_ALERT_DISPLAY_TIME
        );
      })
      .catch(err => {});
  };

export const updateFolder = (id: number, name: string) => ({
  type: UPDATE_FOLDER,
  payload: api.updateFolder(id, name)
});

export const listDocuments = () => ({
  type: LIST_DOCUMENTS,
  payload: api.listDocuments()
});

export const toggleDocumentUse = (id: number, enabled: boolean) => ({
  type: "TOGGLE_DOCUMENT_USE",
  payload: api.toggleDocument(id, enabled)
});

export const addFolder = (parentId: number, name: string) => ({
  type: "ADD_FOLDER",
  payload: api.addFolder(parentId, name)
});

export const deleteDocument = (id: number) => {
  api.deleteFile(id);

  return {
    type: DELETE_DOCUMENT_SUCCESS,
    payload: { id }
  };
};

export const deleteFolderAction = (id: number) => ({
  type: "DELETE_FOLDER",
  payload: api.deleteFolder(id)
});

export const previewDocWithId = (id: number) => (dispatch: AppThunkDispatch) =>
  dispatch(showConfirmModal("PREVIEW_DOCUMENT", { id }));

export const previewDocPartWithId =
  (id: number, anchors: DocumentFragmentAnchorsType) =>
  (dispatch: AppThunkDispatch) =>
    dispatch(showConfirmModal("PREVIEW_DOCUMENT", { id, anchors }));

export function downloadDocument(id: number) {
  return () => {
    api.downloadFile(id).catch(error => console.log(error));
  };
}

export function buildFileUrl(id: number) {
  return () => {
    return api.buildFileUrl(id);
  };
}

export const setCurrentFolderAction = (id: number) => ({
  type: CURRENT_FOLDER,
  payload: id
});

export const updateFoldersPath = (foldersPath: Breadcrumb[]) => ({
  type: UPDATE_FOLDERS_PATH,
  payload: foldersPath
});

export const copyURL = () => (dispatch: AppThunkDispatch) => {
  dispatch({
    type: FILE_URL_COPIED,
    payload: true
  });

  return setTimeout(
    () => dispatch({ type: FILE_URL_COPIED, payload: false }),
    DEFAULT_ALERT_DISPLAY_TIME
  );
};

export const updateArticleAction =
  (
    articleContent: string,
    articleTitle: string,
    docId: number | null,
    quietly: boolean
  ) =>
  (dispatch: AppThunkDispatch) => {
    if (!quietly) {
      dispatch({
        type: FILE_UPDATE_START
      });
    }

    const blob = new Blob([articleContent], { type: "text/plain" });
    const article = new File([blob], `${articleTitle}.html`, {
      type: "text/plain"
    });
    return api
      .updateFile(article, docId)
      .then(() => {
        if (!quietly) {
          dispatch({
            type: FILE_UPDATE_END
          });

          setTimeout(
            () =>
              dispatch({
                type: FILE_UPDATE_CLEAR
              }),
            DEFAULT_ALERT_DISPLAY_TIME
          );
        }
      })
      .catch(err => {});
  };

export const uploadArticleAction =
  (
    articleContent: string,
    articleTitle: string,
    parentId: number,
    isArticle = true
  ) =>
  (dispatch: AppThunkDispatch) => {
    const blob = new Blob([articleContent], { type: "text/plain" });
    const article = [
      new File([blob], `${articleTitle}.html`, { type: "text/plain" })
    ];

    dispatch({
      type: FILE_QUEUE_START,
      payload: article.map(({ name }) => ({ name }))
    });

    return api
      .uploadFiles(article, parentId, isArticle)
      .then(res => {
        const payload = res.map(({ name, extension }) => ({
          name: name + "." + extension
        }));

        dispatch({
          type: FILE_QUEUE_END,
          payload
        });

        setTimeout(
          () =>
            dispatch({
              type: FILE_QUEUE_CLEAR,
              payload
            }),
          DEFAULT_ALERT_DISPLAY_TIME
        );
      })
      .catch(err => {});
  };
