import { SynonymsDataType, SynonymTermType } from "./api-types";
import request from "./request";

const SYNONYMS_API_URL = "/knowledge/synonyms";

export const getAllSynonyms = () =>
  request<SynonymsDataType>({ url: SYNONYMS_API_URL + "?sort=updatedAt,desc" });

export const addNewSynonym = (term: string, synonyms: string[]) =>
  request<SynonymTermType>({
    url: SYNONYMS_API_URL,
    method: "POST",
    data: { primary: term, synonyms }
  });

export const deleteSynonymTerm = (id: string) =>
  request<void>({ url: SYNONYMS_API_URL + `/${id}`, method: "DELETE" });

export const updateSynonymTerm = (
  term: string,
  synonyms: string[],
  id: string
) =>
  request<SynonymTermType>({
    url: SYNONYMS_API_URL + `/${id}`,
    method: "PUT",
    data: { primary: term, synonyms }
  });
