import { createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import createSagaMiddleware from "redux-saga";
import chatbotApp from "~/reducers";
import { apiMiddleware } from "./apiMiddleware";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";

export const history = { ...createBrowserHistory(), ...(window.history || {}) };
const sagaMiddleware = createSagaMiddleware();

const routingMiddleware = routerMiddleware(history);

const composeEnhancers = (
  middlewares: (
    | typeof thunkMiddleware
    | typeof sagaMiddleware
    | typeof apiMiddleware
    | typeof routingMiddleware
  )[]
) => {
  if (process.env.NODE_ENV !== "production") {
    return composeWithDevTools(compose(applyMiddleware(...middlewares)));
  }

  return compose(applyMiddleware(...middlewares));
};

export const rootReducer = chatbotApp(history);

export function configureStore() {
  const middlewares = [
    thunkMiddleware,
    sagaMiddleware,
    apiMiddleware,
    routingMiddleware
  ];

  return createStore(rootReducer, composeEnhancers(middlewares));
}

export { sagaMiddleware };
export default configureStore;
