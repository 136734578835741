import React, { useCallback } from "react";
import {
  DocumentFragmentAnchorsType,
  DocumentType
} from "~/services/api-types";
import { useThunkDispatch } from "~/configureStore";
import { previewDocPartWithId } from "~/actions/DocumentsActions";
import { Icon } from "semantic-ui-react";
import { Tooltip } from "~/atoms/Tooltip/Tooltip";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import styles from "./DocumentAnchorsLink.module.scss";

interface DocumentAnchorsLinkProps {
  document: DocumentType;
  documentAnchors: DocumentFragmentAnchorsType;
  className?: string;
}
export const DocumentAnchorsLink = ({
  document,
  documentAnchors,
  className
}: DocumentAnchorsLinkProps) => {
  const { t } = useTranslation();
  const dispatch = useThunkDispatch();

  const previewDocument = useCallback(
    (id: number) => dispatch(previewDocPartWithId(id, documentAnchors)),
    [dispatch]
  );

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.stopPropagation();
      previewDocument(document.id);
    },
    [document, previewDocument]
  );

  if (document.extension === "pdf") {
    return null;
  }
  return (
    <Tooltip
      light
      size="small"
      tip={t("documents.view_fragment_in_document")}
      delayShow={500}
    >
      <span className={classNames(styles.link, className)} onClick={onClick}>
        <Icon link name="external" />
      </span>
    </Tooltip>
  );
};
