import classNames from "classnames";
import { debounce } from "lodash";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import ReactTooltip from "react-tooltip";
import { Icon, IconProps } from "semantic-ui-react";
import { v4 as uuid } from "uuid";

import styles from "./Textarea.module.scss";

interface TextareaProps
  extends Omit<
    React.DetailedHTMLProps<
      React.TextareaHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    >,
    "value" | "onChange"
  > {
  label?: React.ReactNode;
  defaultValue?: string;
  value?: string;
  onChange?: (value: string) => void;
  debounceTime?: number;
  icon?: IconProps["name"];
  iconClass?: string;
  wrapperClass?: string;
  error?: string;
  maxLength?: number;
  className?: string;
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      label,
      value,
      className,
      onChange,
      debounceTime = 0,
      icon,
      iconClass,
      wrapperClass,
      error,
      maxLength,
      ...rest
    },
    ref
  ) => {
    const [textareaValue, setTextareaValue] = useState<string>("");

    const handleUserTextarea = useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { value: textareaValue } = e.target;

        if (typeof value !== "undefined" && typeof onChange === "function") {
          onChange(textareaValue);
        }

        return setTextareaValue(textareaValue);
      },
      [value, onChange]
    );

    useEffect(() => {
      if (typeof onChange === "function" && typeof value === "undefined") {
        debounce(() => onChange(textareaValue), debounceTime)();
      }
    }, [textareaValue, value, onChange, debounce]);

    const tooltipId = useMemo(() => uuid(), []);

    return (
      <label
        className={classNames(
          styles.wrapper,
          wrapperClass,
          typeof error === "string" && styles.error
        )}
      >
        {label && <span className={styles.label}>{label}</span>}
        <textarea
          className={classNames(styles.textarea, className)}
          value={typeof value === "string" ? value : textareaValue}
          ref={ref}
          maxLength={maxLength}
          onChange={handleUserTextarea}
          data-for={tooltipId}
          data-tip={typeof error === "string" ? error : null}
          data-hj-allow
          {...rest}
        />
        {error && (
          <ReactTooltip
            id={tooltipId}
            place="top"
            type="error"
            effect="solid"
          />
        )}
        {icon && <Icon name={icon} className={classNames(iconClass)} />}
        {typeof maxLength === "number" && (
          <span className={styles.maxLength}>
            {(typeof value === "string" ? value : textareaValue)?.length ?? 0}/
            {maxLength}
          </span>
        )}
      </label>
    );
  }
);

Textarea.displayName = "ForwardRef(Textarea)";

export { Textarea };
