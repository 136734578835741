import {
  SEARCH_DOCUMENTS_TYPES_FILTERS,
  SEARCH_KNOWLEDGE_SOURCES_FILTERS,
  SEARCH_URL
} from "~/constants/api";
import { SEARCH_QUERY_SIZE } from "~/constants/app";
import { AppliedSearchFilters } from "~/reducers/search-new";
import { SearchFiltersResponseType, SearchResponseType } from "./api-types";
import request from "./request";

export const searchApi = (
  question: string,
  page = 0,
  filters: AppliedSearchFilters = {}
) => {
  const {
    modifiedSince,
    modifiedUntil,
    modifiedBy,
    knowledgeSource,
    documentType
  } = filters;
  const params = {
    query: question,
    size: SEARCH_QUERY_SIZE,
    page,
    ...(modifiedSince && { modifiedSince }),
    ...(modifiedUntil && { modifiedUntil }),
    ...(modifiedBy && { modifiedBy }),
    ...(knowledgeSource?.length && {
      knowledgeSource: knowledgeSource.toString()
    }),
    ...(documentType?.length && { documentType: documentType.toString() }),
    highlightMatches: true
  };

  return request<SearchResponseType>(
    {
      method: "GET",
      url: SEARCH_URL,
      params
    },
    true
  );
};

export const getSearchDocumentsTypesFilters = () => {
  return request<SearchFiltersResponseType[]>(
    {
      url: SEARCH_DOCUMENTS_TYPES_FILTERS,
      method: "GET"
    },
    true
  );
};

export const getSearchKnowledgeSourcesFilters = () => {
  return request<SearchFiltersResponseType[]>(
    {
      url: SEARCH_KNOWLEDGE_SOURCES_FILTERS,
      method: "GET"
    },
    true
  );
};
