import React from "react";
import ButtonIcon from "~/atoms/ButtonIcon/ButtonIcon";
import { UserActivityType } from "~/services/api-types";

import { ReactComponent as EyeSVG } from "~/assets/icons/eye.svg";
import { ReactComponent as SignGreen } from "~/assets/icons/user-last-activity-green.svg";
import { ReactComponent as SignYellow } from "~/assets/icons/user-last-activity-yellow.svg";
import { ReactComponent as SignRed } from "~/assets/icons/user-last-activity-red.svg";

import styles from "./UsersActivityRow.module.scss";
import moment from "moment";

interface UsersActivityRowProps {
  userData: UserActivityType;
}

export const UsersActivityRow = ({ userData }: UsersActivityRowProps) => {
  const getActivityIcon = (lastActivity: Date | null) => {
    const threeDaysAgo = moment().subtract(3, "d");
    const sevenDaysAgo = moment().subtract(1, "w");

    if (moment(lastActivity).isBetween(sevenDaysAgo, threeDaysAgo)) {
      return <SignYellow />;
    }

    if (moment(lastActivity).isBefore(sevenDaysAgo)) {
      return <SignRed />;
    }

    if (moment(lastActivity).isAfter(threeDaysAgo)) {
      return <SignGreen />;
    }

    return null;
  };

  const timeSpentCalc = (milisDuration: number) => {
    let tempTime = moment.duration(milisDuration);

    let timeObj = {
      d: tempTime.days(),
      h: tempTime.hours(),
      m: tempTime.minutes()
    };

    const timeArr = [];

    for (let k in timeObj) {
      if (Number(timeObj[k as "d" | "h" | "m"]) > 0) {
        timeArr.push(`${timeObj[k as "d" | "h" | "m"]}${k}`);
      }
      if (timeArr.length == 2) {
        break;
      }
    }

    return timeArr.length ? timeArr.join(" ") : "-";
  };

  return (
    <div className={styles.row}>
      <div className={styles.userName}>{userData.user.userName}</div>
      <div className={styles.lastActivity}>
        {getActivityIcon(userData.lastActivity)}
        {userData.lastActivity ? moment(userData.lastActivity).fromNow() : "-"}
      </div>
      <div className={styles.dataCell}>
        {userData.stats.totalQuestionsAsked > 0
          ? userData.stats.totalQuestionsAsked
          : "-"}
      </div>
      <div className={styles.dataCell}>
        {timeSpentCalc(userData.stats.timeSpentInDocuments)}
      </div>
      <div className={styles.dataCell}>
        {timeSpentCalc(userData.stats.timeActive)}
      </div>
      <div className={styles.eyeButtonWrapper}>
        {/* <ButtonIcon className={styles.button} onClick={() => {}}>
          <EyeSVG />
        </ButtonIcon> */}
      </div>
    </div>
  );
};
