import React, { useCallback } from "react";
import { Button } from "semantic-ui-react";

import styles from "./MessageOption.module.scss";
import { ConnectedOptionProps } from "~/containers/MessageOptionContainer";
import { SuggestionType } from "~/actions/EditorActions";
import classNames from "classnames";
import { useMixpanelContext } from "~/contexts/mixpanel";
import { MIXPANEL_EVENTS } from "~/contexts/mixpanel/event-types";

interface MessageOptionProps extends ConnectedOptionProps {
  item: SuggestionType;
  disabled?: boolean;
}

const MessageOption = ({ item, sendMessage, disabled }: MessageOptionProps) => {
  const { trackEvent } = useMixpanelContext();

  const handleClick = useCallback(() => {
    sendMessage(item.text, item.query);
    trackEvent(MIXPANEL_EVENTS.CHATBOT_QUERY);
  }, [item]);

  return (
    <Button
      size="tiny"
      compact
      className={classNames(
        styles.button,
        item.featured && styles.buttonFeatured
      )}
      disabled={disabled}
      onClick={handleClick}
    >
      {item.text}
    </Button>
  );
};

export default MessageOption;
