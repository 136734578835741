import classNames from "classnames";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Loader, Modal, Radio } from "semantic-ui-react";
import Button from "~/atoms/Button/Button";
import { Textarea } from "~/atoms/Inputs/Textarea/Textarea";
import { ErrorMessage } from "~/atoms/ErrorMessage/ErrorMessage";
import { DOCUMENTS } from "~/constants/routes";
import { getDocumentIdFromUrl } from "~/helpers/url";
import { useThunkDispatch } from "~/configureStore";

import styles from "./PublishModal.module.scss";
import { publishDraftAction } from "~/actions/ArticlesActions";

enum NOTIFY_GROUP {
  NONE = "None",
  ALL = "All",
  MANAGERS = "manager"
}

interface DraftModalProps {
  handleClose: () => void;
  isOpen: boolean;
  articleId: number | null;
}

export const PublishModal = ({
  handleClose,
  isOpen,
  articleId: id
}: DraftModalProps) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [notifyGroup, setNotifyGroup] = useState(NOTIFY_GROUP.NONE);
  const [description, setDescription] = useState("");
  const { t } = useTranslation();

  const { push } = useHistory();

  const dispatch = useThunkDispatch();

  const { url } = useRouteMatch();

  const articleId = useMemo(() => getDocumentIdFromUrl(url) ?? id, [url, id]);

  const handleDescription = (value: string) => {
    setDescription(value);
  };

  const handleNotifyGroup = (notifyGroup: NOTIFY_GROUP) => {
    setNotifyGroup(notifyGroup);
  };

  const handlePublish = useCallback(async () => {
    setErrorMessage("");
    setIsSubmitting(true);

    try {
      await dispatch(publishDraftAction(articleId as number, description));
      return push(DOCUMENTS);
    } catch (error) {
      if (typeof error === "string") {
        return setErrorMessage(error);
      }

      return setErrorMessage("documents.article_draft_publish_error");
    } finally {
      setIsSubmitting(false);
    }
  }, [articleId, description, dispatch]);

  return (
    <Modal
      className={styles.modal}
      open={isOpen}
      size="tiny"
      closeOnDimmerClick
    >
      <div className={styles.wrapper}>
        {errorMessage && (
          <ErrorMessage
            className={styles.errorMessage}
            error={t(errorMessage)}
          />
        )}
        <h4 className={styles.heading}>
          {t("documents.draft_article_modal_heading")}
        </h4>
        <Textarea
          onChange={handleDescription}
          value={description}
          placeholder={t(
            "documents.draft_article_modal_description_placeholder"
          )}
        />
        {/* <h4 className={classNames(styles.heading, styles.second)}>
          {t("documents.draft_article_modal_heading_notify")}
        </h4> */}
        {/* <div className={styles.notifyRadios}>
          <Radio
            className={styles.radio}
            label={t("documents.draft_article_modal_notify_none")}
            checked={notifyGroup === NOTIFY_GROUP.NONE}
            onChange={() => handleNotifyGroup(NOTIFY_GROUP.NONE)}
          />
          <Radio
            className={styles.radio}
            label={t("documents.draft_article_modal_notify_all")}
            checked={notifyGroup === NOTIFY_GROUP.ALL}
            onChange={() => handleNotifyGroup(NOTIFY_GROUP.ALL)}
          />
          <Radio
            className={styles.radio}
            label={t("documents.draft_article_modal_notify_managers")}
            checked={notifyGroup === NOTIFY_GROUP.MANAGERS}
            onChange={() => handleNotifyGroup(NOTIFY_GROUP.MANAGERS)}
          />
        </div> */}
        <div className={styles.buttons}>
          <Button
            disabled={isSubmitting}
            className={styles.cancelButton}
            onClick={handleClose}
          >
            {t("documents.new_article_footer_cancel_button")}
          </Button>
          <Button
            className={classNames(
              styles.publishButton,
              isSubmitting && styles.isSubmitting
            )}
            onClick={handlePublish}
            disabled={isSubmitting}
          >
            {t("documents.new_article_footer_publish_button")}
            {isSubmitting && <Loader size="mini" />}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
