import { dispatch } from "~/configureStore";
import { PUBLISH_CONTINUOUSLY_ACTION } from "../sagas/analytics";
import { TrackableEvent } from "~/services/analytics/types";
import { trackEvent as trackEventAction } from "../actions";

dispatch({ type: PUBLISH_CONTINUOUSLY_ACTION });

export function trackEvent(event: TrackableEvent, timestamp?: Date) {
  const date = timestamp ?? new Date();
  const { type, ...data } = event;
  dispatch(trackEventAction({ timestamp: date.toISOString(), type, data }));
}
