import React from "react";
import DeleteDocumentModal from "~/components/Documents/DeleteDocumentModal";
import UploadFromURLModal from "~/components/Documents/UploadFromURLModal";
import ReportMessageModal from "~/components/BotMessage/ReportMessageModal";
import InviteUserModal from "~/components/Users/InviteUserModal";
import DeleteUserModal from "~/components/Users/DeleteUserModal";
import UserInvitedModal from "~/components/Users/UserInvitedModal";
import DeleteFolderModal from "~/components/Documents/DeleteFolderModal";
import DeleteChangeModal from "~/components/Changes/DeleteChangeModal";
import UpdateUserModal from "~/components/Users/UpdateUserModal";
import UserUpdatedModal from "~/components/Users/UserUpdatedModal";
import CloseIssuesModal from "~/components/Issues/CloseIssuesModal";
import { DeleteTermModal } from "~/components/molecules/SynonymsRow/DeleteTermModal";
import { LeaveArticleModal } from "~/molecules/Article/LeaveArticleViewModal/LeaveArticleViewModal";
import { useSelector } from "~/configureStore";

import type { ModalTypes } from "~/reducers/modal";
import { ConvertToArticleModal } from "~/molecules/Article/ConvertToArticleModal/ConvertToArticleModal";

type ModalComponentsValues =
  | typeof DeleteDocumentModal
  | typeof UploadFromURLModal
  | typeof ReportMessageModal
  | typeof InviteUserModal
  | typeof DeleteUserModal
  | typeof UserInvitedModal
  | typeof DeleteFolderModal
  | typeof DeleteChangeModal
  | typeof UpdateUserModal
  | typeof UserUpdatedModal
  | typeof CloseIssuesModal
  | typeof DeleteTermModal
  | typeof LeaveArticleModal
  | typeof ConvertToArticleModal;

// @ts-ignore
export const MODAL_COMPONENTS: Record<
  keyof Omit<ModalTypes, "PREVIEW_MODAL" & "DELETE_DOCUMENT_SUCCESS">,
  ModalComponentsValues | null
> = {
  DELETE_DOCUMENT: DeleteDocumentModal,
  DELETE_FOLDER: DeleteFolderModal,
  UPLOAD_FROM_URL: UploadFromURLModal,
  REPORT_MESSAGE: ReportMessageModal,
  INVITE_USER: InviteUserModal,
  DELETE_USER: DeleteUserModal,
  UPDATE_USER: UpdateUserModal,
  UPDATE_USER_SUCCESS: UserUpdatedModal,
  INVITE_USER_SUCCESS: UserInvitedModal,
  DELETE_CHANGE: DeleteChangeModal,
  ISSUES_MODAL: CloseIssuesModal,
  DELETE_SYNONYM_TERM: DeleteTermModal,
  LEAVE_ARTICLE_VIEW: LeaveArticleModal,
  CONVERT_TO_ARTICLE: ConvertToArticleModal
};

const AppModalRoot = () => {
  const { modalProps, modalType } = useSelector(({ modal }) => modal);

  if (!modalType) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[modalType];

  if (!SpecificModal) return null;

  //@ts-ignore
  return <SpecificModal {...modalProps} />;
};

export default AppModalRoot;
