import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ChainedBackend from "i18next-chained-backend";
import Backend from "i18next-xhr-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import {
  DEFAULT_LANGUAGE,
  FALLBACK_LANGUAGE,
  FALLBACK_TRANSLATIONS_URL,
  TRANSLATIONS_URL
} from "~/constants/app";

i18n
  .use(initReactI18next)
  .use(ChainedBackend)
  .init({
    lng: DEFAULT_LANGUAGE,
    fallbackLng: FALLBACK_LANGUAGE,
    fallbackNS: FALLBACK_TRANSLATIONS_URL,
    backend: {
      backends: [LocalStorageBackend, Backend],
      backendOptions: [
        {
          prefix: "i18next_res_",
          // 7 days cache
          expirationTime:
            process.env.NODE_ENV === "development" ? 1 : 3600 * 24 * 1000 * 7,
          defaultVersion: "",
          versions: {
            pl: "v1.22",
            en: "v1.22"
          },
          store: window.localStorage
        },
        {
          overrideMimeType: "application/json",
          crossDomain: true,
          loadPath: TRANSLATIONS_URL
        }
      ]
    },
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: true
    },
    keySeparator: false
  });

export default i18n;
