import { call, put, select, takeLatest } from "redux-saga/effects";
import { GET_DOCUMENTS, GET_FOLDERS, GET_FOLDERS_PATH } from "~/actions/types";
import {
  getDocumentsSuccess,
  getDocumentsFailure,
  getFoldersSuccess,
  getFoldersFailure,
  getFoldersPathSuccess,
  setMoveFoldersList
} from "~/actions";
import * as api from "~/services/DocumentsService";

function* getDocumentsData(action) {
  const state = yield select();
  let pageNumber =
    action.payload.pageNumber !== undefined
      ? action.payload.pageNumber
      : state.documents.number;
  let parentId = action.payload.parentId || "";

  try {
    let documents = yield call(
      api.getDocuments,
      pageNumber,
      parentId,
      action.payload.sort,
      action.payload.documentId
    );
    yield put(getDocumentsSuccess(documents, pageNumber));
  } catch (error) {
    yield put(getDocumentsFailure(error));
  }
}

function* getFoldersData(action) {
  let parentId = action.payload.parentId || "";
  try {
    let folders = yield call(
      api.getFolders,
      action.payload.pageNumber,
      parentId,
      action.payload.sort
    );
    yield put(getFoldersSuccess(folders, action.payload.pageNumber));
  } catch (error) {
    yield put(getFoldersFailure(error));
  }
}

function* getFoldersPathData(action) {
  let parentId = action.payload.parentId || "";
  try {
    let folders = yield call(api.getFoldersPath, 0, parentId);
    // yield put(getFoldersPathSuccess(folders));
    yield put(setMoveFoldersList(folders));
  } catch (error) {
    yield put(getFoldersFailure(error));
  }
}

export function* getDocuments() {
  yield takeLatest(GET_DOCUMENTS, getDocumentsData);
}

export function* getFolders() {
  yield takeLatest(GET_FOLDERS, getFoldersData);
}

export function* getFoldersPath() {
  yield takeLatest(GET_FOLDERS_PATH, getFoldersPathData);
}
