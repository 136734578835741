import React, {
  useRef,
  useEffect,
  useReducer,
  ElementType,
  DetailedHTMLProps,
  HTMLAttributes
} from "react";
import sanitizeHtml from "sanitize-html";
import { useHtmlSnippetEvent } from "~/hooks/useHtmlSnippetEvent";
import { sanitizeSettings } from "~/constants/sanitizeSettings";
import { ImageWindow } from "~/components/ImageWindow";

export interface HTMLParagraphProps
  extends Omit<
    DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>,
    "onClick" | "as" | "ref" | "dangerouslySetInnerHTML"
  > {
  html: string;
  onClick?: (e: React.MouseEvent<HTMLParagraphElement>) => void;
  as?: ElementType;
  eventsOnHtmlElement?: ((htmlElement: HTMLElement | null) => void)[];
}

interface ImageState {
  src: string | null;
  alt: string | null;
}

const HTMLParagraph = ({
  html,
  onClick,
  as: Tag = "p",
  eventsOnHtmlElement = [],
  className,
  ...rest
}: HTMLParagraphProps) => {
  const containerRef = useRef<HTMLParagraphElement>(null);

  const [image, setImage] = useReducer(
    (_: ImageState, newState: ImageState) => newState,
    (state: ImageState) => state,
    () => ({ src: null, alt: null })
  );

  for (const event of eventsOnHtmlElement) {
    useHtmlSnippetEvent(containerRef.current, event);
  }

  useEffect(() => {
    if (containerRef.current) {
      const images = containerRef.current.getElementsByTagName("img");

      Array.from(images).forEach(image =>
        image.addEventListener("click", () =>
          setImage({
            alt: image.alt ?? null,
            src: image.src ?? null
          })
        )
      );
    }
  }, [html]);

  return (
    <>
      {image.src && (
        <ImageWindow
          src={image.src}
          alt={image.alt ?? ""}
          click={() =>
            setImage({
              alt: null,
              src: null
            })
          }
        />
      )}
      <Tag
        ref={containerRef}
        className={className}
        onClick={onClick}
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(html, sanitizeSettings)
        }}
        {...rest}
      />
    </>
  );
};

export default HTMLParagraph;
