import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, CheckboxProps } from "semantic-ui-react";
import {
  changeSearchFiltersAndFetch,
  getSearchDocumentsTypesFiltersAction
} from "~/actions/SearchActions";
import { getFileIconByFormat, FormatType } from "~/components/Documents/utils";
import LoaderInline from "~/components/LoaderInline/LoaderInline";
import { useSelector, useThunkDispatch } from "~/configureStore";
import { useToggle } from "~/hooks/useToggle";
import { useMixpanelContext } from "~/contexts/mixpanel";
import { MIXPANEL_EVENTS } from "~/contexts/mixpanel/event-types";

import styles from "./TypeFilter.module.scss";

enum DocumentTypeFilter {
  IMAGES = "IMAGES",
  DOCUMENTS = "DOCUMENTS",
  SPREADSHEETS = "SPREADSHEETS",
  PRESENTATIONS = "PRESENTATIONS",
  WEB_PAGES = "WEB_PAGES",
  PDF_FILES = "PDF_FILES",
  VIDEOS = "VIDEOS",
  AUDIO = "AUDIO",
  OTHER = "OTHER",
  ARTICLES = "ARTICLES"
}

const filterTypeToFormatTypeMap: {
  [key in DocumentTypeFilter]: FormatType;
} = {
  IMAGES: FormatType.IMG,
  DOCUMENTS: FormatType.DOC,
  SPREADSHEETS: FormatType.XLS,
  PRESENTATIONS: FormatType.PPT,
  WEB_PAGES: FormatType.HTML,
  PDF_FILES: FormatType.PDF,
  VIDEOS: FormatType.UNKNOWN,
  AUDIO: FormatType.UNKNOWN,
  OTHER: FormatType.UNKNOWN,
  ARTICLES: FormatType.article
};

export const TypeFilter = () => {
  const [isLoading, toggleLoading] = useToggle();
  const { trackEvent } = useMixpanelContext();
  const { t } = useTranslation();
  const dispatch = useThunkDispatch();
  const documentFilters = useSelector(
    ({ searchNew: { filters } }) => filters?.documentType ?? []
  );
  const appliedFilters = useSelector(
    ({ searchNew: { filtersUsed } }) => filtersUsed?.documentType ?? []
  );

  const fetchDocumentTypeFilters = useCallback(async () => {
    try {
      toggleLoading(true);
      await dispatch(getSearchDocumentsTypesFiltersAction());
    } finally {
      toggleLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchDocumentTypeFilters();
  }, []);

  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
      if (data.checked) {
        dispatch(
          changeSearchFiltersAndFetch({
            documentType: [...appliedFilters, data.value as number]
          })
        );
        trackEvent(MIXPANEL_EVENTS.SEARCH_FILTERS_DOCUMENT_TYPE);
      } else {
        const removedIds = appliedFilters.filter(id => id !== data.value);

        dispatch(
          changeSearchFiltersAndFetch({
            documentType: removedIds
          })
        );
        trackEvent(MIXPANEL_EVENTS.SEARCH_FILTERS_DOCUMENT_TYPE);
      }
    },
    [dispatch, appliedFilters]
  );

  const getDocumentTypeIcon = (name: string) => {
    const format = filterTypeToFormatTypeMap[name as DocumentTypeFilter];
    return getFileIconByFormat(format);
  };

  return (
    <div className={styles.wrapper}>
      <h6>{t("search.filters.type_label")}</h6>
      <div className={styles.optionsWrapper}>
        {!isLoading &&
          documentFilters.length > 0 &&
          documentFilters.map(filter => (
            <Checkbox
              toggle
              key={filter.id}
              value={filter.id}
              label={
                <label>
                  {getDocumentTypeIcon(filter.name)}
                  <span>{filter.title}</span>
                </label>
              }
              checked={appliedFilters.includes(filter.id)}
              onChange={handleChange}
            />
          ))}
        <LoaderInline className={styles.loading} isFetching={isLoading} />
      </div>
    </div>
  );
};
