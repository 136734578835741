import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "~/atoms/Button/Button";
import moment from "moment";
import DatePicker from "react-datepicker";
import { getMessagesReport } from "~/actions/ReportsActions";
import { getLocaleISO } from "~/helpers/locale";
import { useMixpanelContext } from "~/contexts/mixpanel";
import { MIXPANEL_EVENTS } from "~/contexts/mixpanel/event-types";
import { useThunkDispatch } from "~/configureStore";

import { ReactComponent as CalendarSVG } from "~/assets/icons/calendar.svg";

import styles from "./GetMessagesReport.module.scss";

const GetMessagesReport = () => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const { trackEvent } = useMixpanelContext();

  const dispatch = useThunkDispatch();

  const [since, setSince] = useState("");
  const [before, setBefore] = useState("");

  useEffect(() => {
    const date = startDate ? moment(startDate).format("YYYY-MM-DD") : startDate;
    setSince(date ?? "");
  }, [startDate]);

  useEffect(() => {
    const date = endDate ? moment(endDate).format("YYYY-MM-DD") : endDate;
    setBefore(date ?? "");
  }, [endDate]);

  const handleStartDateChange = (date: Date | null) => setStartDate(date);
  const handleEndDateChange = (date: Date | null) => setEndDate(date);

  const clearForm = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleSubmit = useCallback(() => {
    const searchParams = new URLSearchParams();
    searchParams.set("since", since);
    searchParams.set("before", before);

    trackEvent(MIXPANEL_EVENTS.QUERIES_REPORT_GENERATION);

    return dispatch(getMessagesReport(searchParams.toString())).then(() =>
      clearForm()
    );
  }, [since, before, dispatch]);

  const maxDate = new Date();

  return (
    <div className={styles.reportContainer}>
      <h2 className={styles.reportContainer__heading}>
        {t("statistics.get_report_of_q_and_a")}
      </h2>
      <div className={styles.reportContainer__form}>
        <div className={styles.wrapper}>
          <DatePicker
            locale={getLocaleISO()}
            placeholderText={t("statistics.choose_date_from")}
            className={`${startDate ? "act" : ""}`}
            calendarClassName="calendarDays"
            selected={startDate}
            onChange={handleStartDateChange}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            dateFormat="dd/MM/yyyy"
            maxDate={maxDate}
            wrapperClassName={styles.datePicker}
          />
          <CalendarSVG />
        </div>
        <div className={styles.wrapper}>
          <DatePicker
            locale={getLocaleISO()}
            placeholderText={t("statistics.choose_date_to")}
            className={`${endDate ? "act" : ""}`}
            calendarClassName="calendarDays"
            selected={endDate}
            onChange={handleEndDateChange}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            dateFormat="dd/MM/yyyy"
            maxDate={maxDate}
            wrapperClassName={styles.datePicker}
          />
          <CalendarSVG />
        </div>
        <Button
          className={styles.reportContainer__form__button}
          onClick={handleSubmit}
        >
          {t("statistics.generate_report")}
        </Button>
      </div>
      <p className={styles.reportContainer__disclaimer}>
        {t("statistics.without_date_range_report_will_include_all_time_data")}
      </p>
    </div>
  );
};

export default GetMessagesReport;
