import {
  FETCH_SEARCH_REQUEST,
  FETCH_SEARCH_SUCCESS,
  SET_DOCUMENTS_LOCATION,
  SAVE_SEARCH_RESULTS,
  FETCH_SEARCH_FAILURE
} from "~/actions/types";
import type { HintType } from "~/services/api-types";

type LocationType = {
  name: string;
  id: number;
};
interface SearchState {
  question: string;
  answers: HintType[];
  parentId: number | null;
  location: LocationType[];
  prevChatQuestion: string;
  prevChatCount: number;
  loading: boolean;
}

const initialState: SearchState = {
  question: "",
  answers: [],
  parentId: null,
  location: [],
  prevChatQuestion: "",
  prevChatCount: 0,
  loading: false
};

export type FetchSearchRequest = {
  type: typeof FETCH_SEARCH_REQUEST;
  question: string;
};

export type FetchSearchSuccess = {
  type: typeof FETCH_SEARCH_SUCCESS;
  answers: HintType[];
};

export type FetchSearchFailure = {
  type: typeof FETCH_SEARCH_FAILURE;
};

export type SetDocumentsLocation = {
  type: typeof SET_DOCUMENTS_LOCATION;
  parentId: number;
  location: LocationType[];
};

export type SaveSearchResults = {
  type: typeof SAVE_SEARCH_RESULTS;
  question: string;
  count: number;
};

type SearchActions =
  | FetchSearchRequest
  | FetchSearchSuccess
  | FetchSearchFailure
  | SetDocumentsLocation
  | SaveSearchResults;

const search = (
  state: SearchState = initialState,
  action: SearchActions
): SearchState => {
  switch (action.type) {
    case FETCH_SEARCH_REQUEST:
      return {
        ...state,
        question: action.question,
        loading: true
      };
    case FETCH_SEARCH_SUCCESS:
      return {
        ...state,
        answers: action.answers,
        loading: false
      };
    case FETCH_SEARCH_FAILURE:
      return {
        ...state,
        loading: false
      };
    case SET_DOCUMENTS_LOCATION:
      return {
        ...state,
        parentId: action.parentId,
        location: action.location
      };
    case SAVE_SEARCH_RESULTS:
      return {
        ...state,
        prevChatQuestion: action.question,
        prevChatCount: action.count
      };
    default:
      return state;
  }
};

export default search;
