import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/browser";
import { store } from "./configureStore";
import App from "./App";
import { unregister } from "./registerServiceWorker";
import "./index.scss";
import "pdfjs-dist/build/pdf.worker.entry";
import { TENANT, ENVIRONMENT, VERSION } from "~/constants/app";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { MixpanelContextProvider } from "./contexts/mixpanel";

Sentry.init({
  dsn: "https://0dfc2e4c4a544842ac1f28801e2cfcae@sentry.io/1215120",
  environment: ENVIRONMENT,
  release: VERSION,
  ...(process.env.NODE_ENV === "development"
    ? {
        integrations: [new Sentry.Integrations.Breadcrumbs({ console: false })]
      }
    : {})
});
Sentry.configureScope(scope => {
  scope.setTag("tenant", TENANT);
});

const rootEl = document.getElementById("root");

const root = createRoot(rootEl!);

root.render(
  <Suspense fallback={null}>
    <DndProvider backend={HTML5Backend}>
      <Provider store={store}>
        <MixpanelContextProvider>
          <App />
        </MixpanelContextProvider>
      </Provider>
    </DndProvider>
  </Suspense>
);

unregister();

var hotjarEnabled = process.env.REACT_APP_HOTJAR_ENABLED
  ? process.env.REACT_APP_HOTJAR_ENABLED.toLowerCase() === "true"
  : false;
if (hotjarEnabled) {
  (function (h: any, o: any, t: any, j: any, a?: any, r?: any) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: 1257703, hjsv: 6 };
    a = o.getElementsByTagName("head")[0];
    r = o.createElement("script");
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
}
