import { useCallback, useEffect, useRef, useState } from "react";
import { EdgeProps, getBezierPath, getEdgeCenter } from "react-flow-renderer";
import Button from "~/atoms/Button/Button";
import { DEFAULT_EDGE_SIZE } from "~/constants/workflows";
import { ButtonEdgeData, EdgeTypes } from "~/components/types";

import styles from "./ButtonEdge.module.scss";
import { useThunkDispatch } from "~/configureStore";
import { updateWorkflowTreeAction } from "~/actions/workflow/actions";

export const ButtonEdge = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  id,
  markerEnd,
  data,
  source,
  target
}: EdgeProps<ButtonEdgeData>) => {
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  });
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY
  });

  const [buttonSize, setButtonSize] = useState(DEFAULT_EDGE_SIZE);

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (buttonRef.current) {
      const { offsetWidth, offsetHeight } = buttonRef.current;
      setButtonSize({ height: offsetHeight, width: offsetWidth });
    }
  }, []);

  const dispatch = useThunkDispatch();

  const editButton = useCallback(
    () =>
      dispatch(
        updateWorkflowTreeAction({
          edge: {
            id,
            source,
            target,
            data,
            type: EdgeTypes.INPUT
          }
        })
      ),
    [dispatch, id, data, source, target]
  );

  return (
    <>
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={buttonSize.width}
        height={buttonSize.height}
        x={edgeCenterX - buttonSize.width / 2}
        y={edgeCenterY - buttonSize.height / 2}
      >
        <Button className={styles.button} onClick={editButton} ref={buttonRef}>
          {data?.text}
        </Button>
      </foreignObject>
    </>
  );
};
