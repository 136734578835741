import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Confirm } from "semantic-ui-react";

import { removeUser, hideConfirmModal } from "~/actions";

const DeleteUserModal = ({ cancel, id, userName, deleteUser, role }) => {
  const { t } = useTranslation();
  return (
    <Confirm
      basic
      size="mini"
      content={t("user.are_you_sure_deleting_user", { userName })}
      cancelButton={t("user.cancel")}
      confirmButton={t("user.delete")}
      open
      onCancel={cancel}
      onConfirm={() => deleteUser(id, role.toLowerCase())}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...state.modal.modalProps
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteUser: (id, group) => {
      dispatch(removeUser(id, group));
      dispatch(hideConfirmModal());
    },
    cancel: () => {
      dispatch(hideConfirmModal());
    }
  };
};

DeleteUserModal.propTypes = {
  userName: PropTypes.string,
  cancel: PropTypes.func,
  deleteUser: PropTypes.func,
  id: PropTypes.string,
  role: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteUserModal);
