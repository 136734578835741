import { useEffect } from "react";
import { NodeProps } from "react-flow-renderer";
import { truncate } from "lodash";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { KNOWLEDGE_ENTRY } from "~/services/api-urls";
import { useFetch } from "~/hooks/useFetch";
import { useToggle } from "~/hooks/useToggle";
import { KnowledgeEntryModal } from "~/atoms/KnowledgeEntryModal/KnowledgeEntryModal";
import { KnowlegdeNodeData } from "~/components/types";
import LoaderInline from "~/components/LoaderInline/LoaderInline";
import { DefaultNode } from "../DefaultNode/DefaultNode";

import type { HintType } from "~/services/api-types";

import styles from "./KnowledgeBaseEntry.module.scss";

const MAX_ENTRY_STRING_LENGTH = 150;

interface KnowledgeBaseEntryProps extends NodeProps<KnowlegdeNodeData> {
  previewMode?: boolean;
}

export const KnowledgeBaseEntry = ({
  data: { id: knowledgeEntryId },
  id,
  xPos,
  yPos,
  previewMode
}: KnowledgeBaseEntryProps) => {
  const { t } = useTranslation();

  const { error, isLoading, result, refetch } = useFetch<HintType>(
    KNOWLEDGE_ENTRY + `/${knowledgeEntryId}`
  );

  useEffect(() => {
    refetch(true);
  }, [knowledgeEntryId]);

  const [isModalOpened, toggleModal] = useToggle();

  return (
    <DefaultNode
      source
      target
      extensible
      nodeData={{ id, position: { x: xPos, y: yPos } }}
      className={classNames(styles.wrapper, error ? styles.wrapperError : "")}
      targetHandleClassName={styles.targetHandle}
      onEdit={() => toggleModal()}
      previewMode={previewMode}
    >
      <>
        {!isLoading && !error && result && (
          <p className={styles.text}>
            {truncate(
              result.question ||
                result.answerText ||
                t("flow.knowledge_base_entry.no_content_presented"),
              { length: MAX_ENTRY_STRING_LENGTH }
            )}
          </p>
        )}
        {isLoading && <LoaderInline isFetching label="" />}
        {!isLoading && error && (
          <p className={styles.text}>
            {t("flow.knowledge_base_entry.error_while_fetching")}
          </p>
        )}
        {isModalOpened && (
          <KnowledgeEntryModal
            nodeData={{ id, position: { x: xPos, y: yPos } }}
            onModalClose={() => toggleModal(false)}
            currentFragment={result}
          />
        )}
      </>
    </DefaultNode>
  );
};
