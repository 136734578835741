import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { isEqual } from "lodash";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router";
import { Link, Switch, Route } from "react-router-dom";
import {
  loadWorkflowsAction,
  removeWorkflowAction,
  setWorkflowMetadataAction
} from "~/actions/workflow/actions";
import Button from "~/atoms/Button/Button";
import { InputText } from "~/atoms/Inputs/InputText/InputText";
import { Textarea } from "~/atoms/Inputs/Textarea/Textarea";
import { Modal, ModalHandle } from "~/atoms/Modal/Modal";
import Wrapper from "~/atoms/Wrapper/Wrapper";
import { useSelector, useThunkDispatch } from "~/configureStore";
import {
  WORKFLOW,
  WORKFLOW_CREATE,
  WORKFLOW_EDIT,
  WORKFLOW_EDIT_ID
} from "~/constants/routes";
import { WorkflowMetadata } from "~/reducers/types";
import { Create } from "./Create/Create";
import { Edit } from "./Edit/Edit";
import ButtonIcon from "~/atoms/ButtonIcon/ButtonIcon";
import { formatDate } from "~/helpers/date";
import { WorkflowPreview } from "~/organisms/Workflows/WorkflowPreview/WorkflowPreview";
import Table, { RowItemsProps, TableHeadItemProps } from "~/atoms/Table/Table";

import styles from "./Workflow.module.scss";

import { ReactComponent as EyeSVG } from "~/assets/icons/eye.svg";
import { ReactComponent as TrashSVG } from "~/assets/icons/trash.svg";
import { ReactComponent as EditSVG } from "~/assets/icons/edit.svg";

export const Workflow = () => {
  const [titleError, setTitleError] = useState<string | undefined>();

  const [currentPreviewId, setCurrentPrevievId] = useState<
    string | undefined
  >();

  const toggleCurrentPreviewId = useCallback(
    (id: string) =>
      setCurrentPrevievId(id !== currentPreviewId ? id : undefined),
    [currentPreviewId]
  );

  const {
    currentWorkFlow,
    workflows: { data: workflows }
  } = useSelector(
    ({ workflows }) => workflows,
    (left, right) => isEqual(left, right)
  );

  const dispatch = useThunkDispatch();

  const getWorkflows = useCallback(
    () => dispatch(loadWorkflowsAction()),
    [dispatch]
  );

  const setWorkflowMeta = useCallback(
    (data: WorkflowMetadata) => dispatch(setWorkflowMetadataAction(data)),
    [dispatch]
  );

  useEffect(() => {
    getWorkflows();
  }, []);

  const titleRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLTextAreaElement>(null);

  const { push: redirect } = useHistory();

  const { t } = useTranslation();

  const modalRef = useRef<ModalHandle>(null);

  const validateTitle = () => {
    if (titleRef.current) {
      if (!titleRef.current.value) {
        setTitleError(t("workflow.modal.flow_title_is_required"));
      } else {
        setTitleError(undefined);
      }
    }
  };

  const removeFlow = (id: string) => dispatch(removeWorkflowAction(id));

  const saveFlowMeta = () => {
    validateTitle();

    if (titleRef.current?.value) {
      setWorkflowMeta({
        description: descriptionRef.current?.value,
        name: titleRef.current.value
      });

      modalRef.current?.closeModal();
    }
  };

  const Actions = ({ id }: { id: string }) => {
    return (
      <div className={styles.actions}>
        <ButtonIcon
          iconMode
          className={styles.actionsBtn}
          onClick={() => toggleCurrentPreviewId(id)}
        >
          <EyeSVG className={styles.icon_eye} />
        </ButtonIcon>
        <ButtonIcon
          negative
          iconMode
          className={styles.actionsBtn}
          onClick={() => removeFlow(id)}
        >
          <TrashSVG className={styles.icon_trash} />
        </ButtonIcon>
        <Link to={`${WORKFLOW_EDIT}/${id}`}>
          <ButtonIcon iconMode className={styles.actionsBtn}>
            <EditSVG className={styles.icon_edit} />
          </ButtonIcon>
        </Link>
      </div>
    );
  };

  const tableContents: RowItemsProps[] = useMemo(
    () =>
      workflows.map(({ createdAt, createdBy, description, id, name }) => ({
        contents: [
          {
            size: "col-2",
            content: (
              <div className={styles.creationDetails}>
                {formatDate(createdAt)}
                <br />
                {createdBy.userName}
              </div>
            ),
            rowItemClassname: styles.tableRowItem,
            id: id + "-date"
          },
          {
            size: "col-8",
            content: (
              <div className={styles.details}>
                <h3 className={styles.detailsQuestion}>{name}</h3>
                {description && (
                  <p className={styles.detailsDescription}>{description}</p>
                )}
              </div>
            ),
            id: id + "-question"
          },
          {
            size: "col-2",
            content: <Actions id={id} />,
            id: id + "-actions"
          }
        ]
      })),
    [workflows]
  );

  const tableHeads: TableHeadItemProps[] = useMemo(
    () => [
      {
        size: "col-2",
        title: t("workflow.date_and_author")
      },
      {
        size: "col-8",
        title: t("workflow.entry")
      },
      {
        size: "col-2",
        title: `${t("workflow.sort_by")}:`
      }
    ],
    []
  );

  const matchesEdit = useRouteMatch({ path: WORKFLOW_EDIT_ID });

  return (
    <Switch>
      <Route>
        <WorkflowPreview workflowId={currentPreviewId} />
        <Modal
          title={
            matchesEdit
              ? t("workflow.modal.edit_details")
              : t("workflow.modal.add_details")
          }
          onClose={() => (!currentWorkFlow ? redirect(WORKFLOW) : undefined)}
          onConfirm={saveFlowMeta}
          ref={modalRef}
          contentClassName={styles.modalContent}
        >
          <InputText
            name="flow-title"
            defaultValue={currentWorkFlow?.name}
            label={t("workflow.modal.flow_title") + "*"}
            placeholder={t("workflow.modal.flow_title_placeholder")}
            onChange={() => (titleError ? validateTitle() : undefined)}
            ref={titleRef}
            error={titleError}
            onBlur={validateTitle}
          />
          <Textarea
            name="flow-description"
            label={t("workflow.modal.flow_description")}
            placeholder={t("workflow.modal.flow_description_placeholder")}
            defaultValue={currentWorkFlow?.description}
            ref={descriptionRef}
            wrapperClass={styles.modalDescription}
          />
        </Modal>
        <Route exact path={WORKFLOW}>
          <Wrapper
            className={styles.wrapper}
            title={t("workflow.workflows")}
            ExtendedHeader={() => (
              <Link to={WORKFLOW_CREATE}>
                <Button onClick={() => {}} className={styles.messageButton}>
                  {t("workflow.create_workflow")}!
                </Button>
              </Link>
            )}
          >
            {workflows.length === 0 ? (
              <div className={styles.empty}>
                <p className={styles.message}>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Repudiandae nobis corporis optio explicabo eius, doloribus
                  magnam vero tempora delectus alias nesciunt architecto id
                  eligendi sed ex asperiores nostrum, harum exercitationem!
                  <Link to={WORKFLOW_CREATE}>
                    <Button onClick={() => {}} className={styles.messageButton}>
                      {t("workflow.create_workflow")}!
                    </Button>
                  </Link>
                </p>
              </div>
            ) : (
              <Table items={tableContents} tableHead={tableHeads} />
            )}
          </Wrapper>
        </Route>
        <Route path={WORKFLOW_EDIT_ID}>
          <Edit />
        </Route>
        <Route path={WORKFLOW_CREATE}>
          <Create
            openModal={() => modalRef.current?.openModal()}
            modalOpened={modalRef.current?.isModalOpened || false}
          />
        </Route>
      </Route>
    </Switch>
  );
};
