import { useCallback, useState } from "react";

export const useToggle = (
  initialState = false
): [boolean, (newState?: boolean) => void] => {
  const [state, setState] = useState(initialState);

  const toggleState = useCallback(
    (newState?: boolean) =>
      setState(typeof newState === "boolean" ? newState : !state),
    [state]
  );

  return [state, toggleState];
};
