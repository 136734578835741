import React from "react";
import classNames from "classnames";

import styles from "./Button.module.scss";

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  className?: string;
  onClick: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  secondary?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  negative?: boolean;
  type?: "button" | "submit" | "reset";
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, onClick, secondary, disabled, negative, children, ...rest },
    ref
  ) => {
    const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      onClick(e);
      return (e.target as HTMLElement).blur();
    };

    return (
      <button
        onClick={handleButtonClick}
        className={classNames(
          styles.button,
          secondary && styles.buttonSecondary,
          negative && styles.negative,
          className
        )}
        disabled={disabled}
        ref={ref}
        {...rest}
      >
        {children}
      </button>
    );
  }
);

Button.displayName = "(Button)";

export { Button };

export default Button;
