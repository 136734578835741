import React from "react";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import { TENANT } from "~/constants/app";
import { useSelector } from "~/configureStore";

const IntercomPropsUpdater = () => {
  const { update } = useIntercom();
  const { role, user: userName, email } = useSelector(({ login }) => login);
  const language = useSelector(
    ({ projects }) => projects?.current?.language?.toLowerCase?.() ?? null
  );

  React.useEffect(() => {
    update({
      name: userName,
      email: email,
      languageOverride: language || undefined,
      customAttributes: {
        role: role,
        company_name: TENANT
      }
    });
  }, [userName, role, email, language]);
  return null;
};

export const Intercom = () => {
  return (
    <IntercomProvider
      autoBoot
      appId={process.env.REACT_APP_INTERCOM_APP_ID ?? ""}
    >
      <IntercomPropsUpdater />
    </IntercomProvider>
  );
};
