import React from "react";
import styles from "./ImageWindow.module.scss";

interface ImageWindowProps {
  click?: (e: React.MouseEvent<HTMLDivElement>) => void;
  alt?: string;
  src: string;
}

export const ImageWindow = ({ click, src, alt }: ImageWindowProps) => (
  <>
    <div className={styles.ImageDimmer} onClick={click} />
    <div className={styles.Full} onClick={click}>
      <img className={styles.FullImage} src={src} alt={alt} />
    </div>
  </>
);
