import React, { useCallback, useRef, useState } from "react";
import classNames from "classnames";
import { debounce } from "lodash";
import { useDrag, useDrop } from "react-dnd";
import ButtonIcon from "~/atoms/ButtonIcon/ButtonIcon";
import { DND_SUGGESTION_TYPE, EditSuggestionType } from ".";

import { ReactComponent as CloseIconSVG } from "~/assets/icons/close.svg";
import { ReactComponent as StarIconSVG } from "~/assets/icons/star.svg";
import { ReactComponent as StarEmptyIconSVG } from "~/assets/icons/star_empty.svg";
import { ReactComponent as DragIconSVG } from "~/assets/icons/drag_icon.svg";

import styles from "./SuggestionsEditor.module.scss";

export interface DragSuggestionType extends EditSuggestionType {
  index: number;
}

interface SuggestionProps {
  value: string;
  id: string;
  featured: boolean;
  toggleFeatureSuggestion: (id: string) => void;
  handleRemoveSuggestion: (id: string) => void;
  handleEditSuggestion: (newValue: string, suggestionId: string) => void;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
}

const Suggestion = ({
  value,
  id,
  featured,
  index,
  moveItem,
  handleRemoveSuggestion,
  toggleFeatureSuggestion,
  handleEditSuggestion
}: SuggestionProps) => {
  const itemRef = useRef<HTMLLIElement>(null);

  const [, drop] = useDrop(
    () => ({
      accept: DND_SUGGESTION_TYPE,
      hover: (item: DragSuggestionType, monitor) => {
        if (!itemRef.current) {
          return;
        }

        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) return;

        const hoveredRect = itemRef.current.getBoundingClientRect();
        const hoverMiddleY = (hoveredRect.bottom - hoveredRect.top) / 2;
        const mousePosition = monitor.getClientOffset();

        if (!mousePosition) return;

        const hoverClientY = mousePosition.y - hoveredRect.top;

        // check if dragged item is higher or lower the middle of item its hovering
        if (
          (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
          (dragIndex > hoverIndex && hoverClientY < hoverMiddleY)
        )
          return;

        moveItem(dragIndex, hoverIndex);

        item.index = hoverIndex;
      }
    }),
    [index, moveItem]
  );

  const [{ isDragging }, drag] = useDrag<
    DragSuggestionType,
    {},
    { isDragging: boolean }
  >(
    () => ({
      type: DND_SUGGESTION_TYPE,
      item: { id, text: value, featured, index },
      collect: monitor => ({
        isDragging: !!monitor.isDragging()
      })
    }),
    [index]
  );

  const [suggestionValue, setSuggestionValue] = useState(value ?? "");

  const handleTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setSuggestionValue(value);

    return debounce(() => handleEditSuggestion(value, id), 400)();
  };

  const handleToggleFeatured = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      (e.target as HTMLElement).blur();

      return toggleFeatureSuggestion(id);
    },
    [toggleFeatureSuggestion, id]
  );

  drag(drop(itemRef));

  const handleInputDragStart = (e: React.MouseEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <li
      className={styles.suggestionsListItem}
      style={isDragging ? { opacity: "0", visibility: "hidden" } : {}}
      ref={itemRef}
    >
      <input
        className={styles.suggestionsNewTagInput}
        type="text"
        onChange={handleTagChange}
        value={suggestionValue}
        // this is a workaround to not allow dragging by input but let user select text
        draggable
        onDragStart={handleInputDragStart}
      />
      <div className={styles.suggestionsDragIcon}>
        <DragIconSVG />
      </div>
      <ButtonIcon
        className={styles.suggestionsListItemBtn}
        onClick={handleToggleFeatured}
      >
        {featured ? <StarIconSVG /> : <StarEmptyIconSVG />}
      </ButtonIcon>
      <ButtonIcon
        className={classNames(
          styles.suggestionsListItemBtn,
          styles.suggestionsListItemBtnRemove
        )}
        onClick={() => handleRemoveSuggestion(id)}
      >
        <CloseIconSVG className={styles.suggestionsListItemBtnRemoveIcon} />
      </ButtonIcon>
    </li>
  );
};

export default Suggestion;
