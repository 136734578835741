import { debounce } from "lodash";
import { useCallback, useEffect } from "react";
import { useSelector, useThunkDispatch } from "~/configureStore";
import { trackUserActivity } from "../actions";

const USER_ACTIVITY_DEBOUNCE_TIME = 100;

export const useTrackUserActivity = () => {
  const dispatch = useThunkDispatch();

  const setUserActive = useCallback(
    (isActive: boolean) => dispatch(trackUserActivity(isActive)),
    [dispatch]
  );

  const { isUserActive } = useSelector(({ analytics }) => analytics);

  const trackActivity = useCallback(
    debounce(() => setUserActive(true), USER_ACTIVITY_DEBOUNCE_TIME),
    [setUserActive]
  );

  useEffect(() => {
    if (isUserActive) {
      window.removeEventListener("touchstart", trackActivity);
      window.removeEventListener("keydown", trackActivity);
      window.removeEventListener("mousemove", trackActivity);
    } else {
      window.addEventListener("touchstart", trackActivity);
      window.addEventListener("keydown", trackActivity);
      window.addEventListener("mousemove", trackActivity);
    }

    return () => {
      window.removeEventListener("touchstart", trackActivity);
      window.removeEventListener("keydown", trackActivity);
      window.removeEventListener("mousemove", trackActivity);
    };
  }, [isUserActive, setUserActive]);
};
