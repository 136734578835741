import React, { useCallback } from "react";
import classNames from "classnames";

import styles from "./ButtonIcon.module.scss";

interface ButtonIconProps
  extends Omit<
    React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    "children" | "onClick"
  > {
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  secondary?: boolean;
  darken?: boolean;
  negative?: boolean;
  iconMode?: boolean;
}

const ButtonIcon = ({
  children,
  onClick,
  className,
  secondary,
  darken,
  negative,
  iconMode,
  ...rest
}: ButtonIconProps) => {
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      (e.target as HTMLElement).blur();

      if (typeof onClick === "function") return onClick(e);

      return;
    },
    [onClick]
  );

  return (
    <button
      className={classNames(
        styles.buttonIcon,
        className,
        secondary && styles.secondary,
        darken && styles.darken,
        negative && styles.negative,
        iconMode && styles.iconMode
      )}
      onClick={handleClick}
      {...rest}
    >
      {children}
    </button>
  );
};

export default ButtonIcon;
