import { useCallback, useEffect, useRef, useState } from "react";
import { EdgeProps, getBezierPath, getEdgeCenter } from "react-flow-renderer";
import { useTranslation } from "react-i18next";
import { InputText } from "~/atoms/Inputs/InputText/InputText";
import ButtonIcon from "~/atoms/ButtonIcon/ButtonIcon";
import { DEFAULT_EDGE_SIZE } from "~/constants/workflows";
import { EdgeTypes, InputEdgeData } from "~/components/types";
import { useThunkDispatch } from "~/configureStore";
import { updateWorkflowTreeAction } from "~/actions/workflow/actions";

import { ReactComponent as CheckmarkSVG } from "~/assets/icons/checkmark.svg";

import styles from "./InputEdge.module.scss";

const MIN_BUTTON_LENGTH = 2;

export const InputEdge = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  id,
  markerEnd,
  data,
  source,
  target
}: EdgeProps<InputEdgeData>) => {
  const { t } = useTranslation();

  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  });
  const [edgeCenterX, edgeCenterY] = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY
  });

  const [edgeSize, setEdgeSize] = useState(DEFAULT_EDGE_SIZE);

  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const { offsetWidth, offsetHeight } = containerRef.current;
      setEdgeSize({ height: offsetHeight, width: offsetWidth });
    }
  }, []);

  const dispatch = useThunkDispatch();

  const createButton = useCallback(() => {
    if (inputRef.current?.value) {
      return dispatch(
        updateWorkflowTreeAction({
          edge: {
            id,
            source,
            target,
            data: {
              text: inputRef.current.value
            },
            type: EdgeTypes.BUTTON
          }
        })
      );
    }
  }, [dispatch, id, source, target]);

  return (
    <>
      <path
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <foreignObject
        width={edgeSize.width}
        height={edgeSize.height}
        x={edgeCenterX - edgeSize.width / 2}
        y={edgeCenterY - edgeSize.height / 2}
      >
        <div ref={containerRef} className={styles.wrapper}>
          <InputText
            ref={inputRef}
            defaultValue={data?.text}
            className={styles.input}
            placeholder={t("input_edge.write_hint_text")}
            min={MIN_BUTTON_LENGTH}
          />
          <ButtonIcon onClick={createButton} className={styles.button}>
            <CheckmarkSVG />
          </ButtonIcon>
        </div>
      </foreignObject>
    </>
  );
};
