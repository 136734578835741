import { call, put, debounce } from "redux-saga/effects";
import { GET_QUERY_SUGGESTIONS } from "~/actions/types";
import {
  getQuerySuggestionsSuccess,
  getQuerySuggestionsFailure
} from "~/actions";
import * as api from "~/services/ChatbotService";
import { GetQuerySuggestionsAction } from "~/reducers/autocomplete";

export function* getQuerySuggestionsSaga() {
  yield debounce(100, GET_QUERY_SUGGESTIONS, getQuerySuggestionsData);
}

export function* getQuerySuggestionsData(action: GetQuerySuggestionsAction) {
  try {
    const suggestions = call(api.getQuerySuggestions, action.query);

    yield put(getQuerySuggestionsSuccess(yield suggestions));
  } catch (error) {
    yield put(getQuerySuggestionsFailure(error));
  }
}
