import type { Workflow, WorkflowData } from "~/reducers/types";
import { v4 as uuid } from "uuid";

const WORKFLOWS = "WORKFLOWS";
const CURRENT_WORKFLOW = "CURRENT_WORKFLOW";
const CURRENT_EDIT_WORKFLOW = "CURRENT_EDIT_WORKFLOW";

type localDataKeys =
  | typeof WORKFLOWS
  | typeof CURRENT_WORKFLOW
  | typeof CURRENT_EDIT_WORKFLOW;

const save = <T = any>(key: localDataKeys, data: T) =>
  localStorage.setItem(key, JSON.stringify(data));

const remove = (key: localDataKeys) => localStorage.removeItem(key);

const get = <T = any>(key: localDataKeys): T | undefined => {
  const data = localStorage.getItem(key);

  if (data) {
    return JSON.parse(data);
  }

  return undefined;
};

export const getWorkflows = () => get<Workflow[]>(WORKFLOWS) || [];

export const getWorkflow = (id: string) => {
  const workflows = getWorkflows();

  const foundWorkflow = workflows.find(workflow => workflow.id === id);
  const foundWorkflowIndex = workflows.findIndex(
    workflow => workflow.id === id
  );

  if (!foundWorkflow) return undefined;

  return { data: foundWorkflow, index: foundWorkflowIndex };
};

export const addWorkflow = (newData: Workflow) => {
  const workflows = getWorkflows();
  const workflow = getWorkflow(newData.id);

  if (!workflow) {
    const workflowData = {
      ...newData,
      id: uuid(),
      createdAt: new Date().toString(),
      createdBy: { userName: "botwise" }
    };

    if (workflows.length) {
      return save<Workflow[]>(WORKFLOWS, [...workflows, workflowData]);
    } else {
      return save<Workflow[]>(WORKFLOWS, [workflowData]);
    }
  }

  const { data, index } = workflow;

  const updated = [...workflows].splice(index, 1, { ...data, ...newData });

  return save<Workflow[]>(WORKFLOWS, updated);
};

export const removeWorkflow = (id: string) => {
  const workflows = getWorkflows();
  const workflow = getWorkflow(id);

  if (!workflow) {
    return undefined;
  }

  if (getCurrentWorkflow(id)) {
    removeCurrentWorkflow(id);
  }

  return save<Workflow[]>(
    WORKFLOWS,
    [...workflows].filter(workflow => workflow.id !== id)
  );
};

export const saveCurrentWorkflow = (data: WorkflowData | Workflow) => {
  // edit
  if ("id" in data) {
    return save<Workflow>(CURRENT_EDIT_WORKFLOW, data);
  } else {
    // new
    return save<WorkflowData>(CURRENT_WORKFLOW, data);
  }
};

export const getCurrentWorkflow = (id?: string) => {
  // edit
  if (id) {
    return get<Workflow>(CURRENT_EDIT_WORKFLOW);
  } else {
    // new
    return get<WorkflowData>(CURRENT_WORKFLOW);
  }
};

export const removeCurrentWorkflow = (id?: string) => {
  // edit
  if (id) {
    return remove(CURRENT_EDIT_WORKFLOW);
  } else {
    // new
    return remove(CURRENT_WORKFLOW);
  }
};
