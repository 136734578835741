import axios from "axios";
import classNames from "classnames";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Editor as EditorInstance } from "tinymce";
import { ErrorMessage } from "~/atoms/ErrorMessage/ErrorMessage";
import LoaderInline from "~/components/LoaderInline/LoaderInline";
import RouteLeavingGuard from "~/components/RouteLeavingGuard/RouteLeavingGuard";
import { useSelector } from "~/configureStore";
import { DOCUMENTS } from "~/constants/routes";
import { getDocumentIdFromUrl } from "~/helpers/url";
import { useMatchMedia } from "~/hooks/useMatchMedia";
import { useToggle } from "~/hooks/useToggle";
import { LeaveArticleModal } from "~/molecules/Article/LeaveArticleViewModal/LeaveArticleViewModal";
import { Contents } from "~/organisms/Article/Contents/Contents";
import { PublishModal } from "~/organisms/Article/Modals/PublishModal";
import { Editor } from "~/organisms/Article/Editor/Editor";
import { Footer } from "~/organisms/Article/Footer/Footer";
import { Header } from "~/organisms/Article/Header/Header";
import {
  deleteDraft,
  getDraft,
  getDraftContent,
  newArticle,
  updateDraft
} from "~/services/ArticlesService";
import { OverwriteWarningModal } from "~/organisms/Article/Modals/OverwriteWarningModal";
import { getDocument, previewFile } from "~/services/DocumentsService";
import { DocumentStatuses } from "~/services/api-types";

import styles from "./Article.module.scss";
import UpdatesHistory from "~/organisms/Article/UpdatesHistory/UpdatesHistory";

interface NewArticleProps {
  editMode?: boolean;
}

export const Article = ({ editMode = false }: NewArticleProps) => {
  const [hasTitleChanged, setHasTitleChanged] = useState(false);
  const [hasContentChanged, setHasContentChanged] = useState(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSavingError, setIsSavingError] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [html, setHtml] = useState<HTMLBodyElement | null>(null);
  const [articleTitle, setArticleTitle] = useState("");
  const shouldContentsCollapse = useMatchMedia("max-width: 1200px");
  const [modifiedBy, setModifiedBy] = useState("");
  const [articleStatus, setArticleStatus] = useState<null | DocumentStatuses>(
    null
  );
  const [articleId, setArticleId] = useState<number | null>(null);
  const [hasDraft, setHasDraft] = useState(false);
  const [isOverwriting, setIsOverwriting] = useState(false);

  const editorRef = useRef<EditorInstance>(null);

  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<null | string>(null);

  const [isModalPublishOpened, toggleModalPublishOpened] = useToggle();

  const [isModalOverwriteOpened, toggleModalOverwriteOpened] = useToggle();

  const [isContentsOpened, setIsContentsOpened] = useToggle(true);

  const [isUpdatesHistoryOpened, setIsUpdatesHistoryOpened] = useToggle();

  const { url } = useRouteMatch();

  const { t } = useTranslation();

  const user = useSelector(({ login: { user } }) => user ?? "");

  const currentFolderId = useSelector(
    ({ documents }) => documents.currentFolder
  );

  const { push } = useHistory();

  const onEditorContentChange = (value: string, editor: EditorInstance) => {
    setEditorContent(value);
    setHasContentChanged(true);

    if (editor) {
      setHtml(editor.getBody() as HTMLBodyElement | null);
    }
  };
  const onEditorBlur = () => {
    setHasContentChanged(false);
  };

  const handleEditorFocus = useCallback(() => {
    if (editorRef.current) {
      editorRef.current.focus(false);
    }
  }, [editorRef.current]);

  const handleArticleTitleChange = (value: string) => {
    setHasTitleChanged(true);
    return setArticleTitle(value);
  };

  const handleNewArticleUpload = useCallback(async () => {
    setError(null);
    setIsSubmitting(true);

    try {
      await newArticle(currentFolderId, articleTitle).then(
        ({ data: { id } }) => {
          setArticleId(id);
          setHasDraft(true);
        }
      );
    } catch (error) {
      if (typeof error === "string") {
        return setError(error);
      }

      return setError("documents.new_article_add_error");
    } finally {
      setIsSubmitting(false);
    }
  }, [editorContent, articleTitle, currentFolderId]);

  const handleArticleUpdate = useCallback(async () => {
    setError(null);
    setIsSubmitting(true);
    setIsSaving(true);
    setIsSavingError(false);

    try {
      await updateDraft(articleId as number, editorContent, articleTitle).then(
        () => {
          setHasContentChanged(false);
          setHasTitleChanged(false);
          setHasDraft(true);
        }
      );
    } catch (error) {
      setIsSavingError(true);
      if (typeof error === "string") {
        return setError(error);
      }

      return setError("documents.new_article_update_error");
    } finally {
      setIsSubmitting(false);
      setIsSaving(false);
    }
  }, [editorContent, articleId, articleTitle]);

  const fetchArticleContent = useCallback(
    async (documentId: number | null = null) => {
      setLoading(true);
      setError(null);

      try {
        if (typeof articleId === "number") {
          const { name, articleDraft } = await getDocument(articleId).then(
            ({ articleDraft, status, name }) => {
              setHasDraft(articleDraft ? true : false);
              setArticleStatus(status);

              return {
                articleDraft,
                name
              };
            }
          );

          if (articleDraft) {
            const {
              data: {
                name,
                modifiedBy: { userName }
              }
            } = await getDraft((documentId || articleId) as number);

            setArticleTitle(name);
            setModifiedBy(userName);

            const { data } =
              (await getDraftContent((documentId || articleId) as number)) ??
              null;

            return setEditorContent(data);
          } else {
            const content = await previewFile(articleId as number);

            setArticleTitle(name);
            setEditorContent(content);
          }
        }
      } catch (err: unknown) {
        if (typeof err === "string") {
          return setError(err);
        }

        if (axios.isAxiosError(err)) {
          if (err.response?.status === 404) {
            return setError("document_preview.document_not_found");
          }
        }

        return setError("error_message.error_while_fetching");
      } finally {
        setLoading(false);
      }
    },
    [articleId, editorContent, handleArticleUpdate]
  );

  const handleDiscardDraft = useCallback(async () => {
    setError("");
    setIsSubmitting(true);

    try {
      await deleteDraft(articleId as number).then(() => push(DOCUMENTS));
    } catch (err) {
      if (typeof err === "string") {
        return setError(err);
      }

      return setError(t("documents.article_draft_discard_error"));
    } finally {
      setIsSubmitting(false);
    }
  }, [articleId]);

  const handleOverwrite = useCallback(async () => {
    setError("");
    setIsSubmitting(true);

    try {
      await deleteDraft(articleId as number).then(() => {
        setIsOverwriting(false);
        handleArticleUpdate();
      });
    } catch (err) {
      if (typeof err === "string") {
        return setError(err);
      }

      return setError(t("documents.edit_modal_error"));
    } finally {
      setIsSubmitting(false);
    }
  }, [articleId, handleArticleUpdate]);

  const handleContentsToggle = useCallback(() => {
    if (editorRef.current) {
      setHtml(editorRef.current.getBody() as HTMLBodyElement | null);
    }

    setIsContentsOpened(!isContentsOpened);
  }, [editorRef.current, isContentsOpened]);

  useEffect(() => {
    if (!editMode && !articleId && (articleTitle || hasContentChanged)) {
      const delayDebounceFn = setTimeout(() => {
        handleNewArticleUpload();
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [editMode, articleId, articleTitle, hasContentChanged]);

  useEffect(() => {
    if (modifiedBy && user !== modifiedBy) {
      toggleModalOverwriteOpened(true);
      setIsOverwriting(true);
    }
  }, [user, modifiedBy]);

  useEffect(() => {
    setArticleId(getDocumentIdFromUrl(url));
  }, [url]);

  useEffect(() => {
    if (editMode) {
      fetchArticleContent(articleId);
    }
  }, [editMode, articleId]);

  useEffect(() => {
    if (shouldContentsCollapse) {
      setIsContentsOpened(false);
    } else {
      setIsContentsOpened(true);
    }
  }, [shouldContentsCollapse]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (
        (hasContentChanged && articleId && !editorRef.current?.isNotDirty) ||
        (hasTitleChanged && editMode) ||
        (hasTitleChanged && articleId)
      ) {
        if (!isOverwriting) {
          handleArticleUpdate();
        } else {
          handleOverwrite();
        }
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [
    hasContentChanged,
    hasTitleChanged,
    handleArticleUpdate,
    editMode,
    isOverwriting,
    handleOverwrite,
    articleId,
    editorRef.current?.isNotDirty
  ]);

  return (
    <div
      className={classNames(
        styles.newArticleWrapper,
        !isUpdatesHistoryOpened && styles.updatesHidden
      )}
    >
      <RouteLeavingGuard
        when={isSavingError || isSaving}
        navigate={(path: string) => push(path)}
      >
        <LeaveArticleModal />
      </RouteLeavingGuard>
      {!loading && !error && (
        <>
          <Header
            articleTitle={articleTitle}
            handleArticleTitleChange={handleArticleTitleChange}
            handleArticleUpdate={handleArticleUpdate}
            handleUpdatesHistoryOpen={() => setIsUpdatesHistoryOpened(true)}
            articleId={articleId}
            editMode={editMode}
            isSaving={isSaving}
            isSavingError={isSavingError}
            hasDraft={hasDraft}
            articleStatus={articleStatus}
            isUpdatesHistoryOpened={isUpdatesHistoryOpened}
          />
          <div
            className={classNames(
              styles.editor,
              isContentsOpened && styles.withContents
            )}
          >
            <Contents
              editor={editorRef}
              content={html}
              editorContent={editorContent}
              toggleContents={handleContentsToggle}
              isOpened={isContentsOpened}
            />
            <Editor
              editorRef={editorRef}
              editorContent={editorContent}
              onEditorContentChange={onEditorContentChange}
              setHtml={setHtml}
              onEditorBlur={onEditorBlur}
              handleEditorFocus={handleEditorFocus}
            />
          </div>
          <UpdatesHistory
            isOpened={isUpdatesHistoryOpened}
            close={() => setIsUpdatesHistoryOpened(false)}
            articleId={articleId}
          />
          <Footer
            editMode={editMode}
            isSubmitting={isSubmitting}
            hasDraft={hasDraft}
            handleArticlePublish={() => toggleModalPublishOpened(true)}
            handleDiscardDraft={handleDiscardDraft}
            articleStatus={articleStatus}
            isAllowedToPublish={articleTitle.length > 0}
            hasChanged={
              (hasContentChanged && !editorRef.current?.isNotDirty) ||
              hasTitleChanged
            }
          />
        </>
      )}
      <LoaderInline className={styles.loading} isFetching={loading} />
      {!loading && error && (
        <ErrorMessage className={styles.errorMessage} error={t(error)} />
      )}
      <PublishModal
        isOpen={isModalPublishOpened}
        handleClose={() => toggleModalPublishOpened(false)}
        articleId={articleId}
      />
      <OverwriteWarningModal
        isOpen={isModalOverwriteOpened}
        modifiedBy={modifiedBy}
        handleClose={() => toggleModalOverwriteOpened(false)}
      />
    </div>
  );
};
