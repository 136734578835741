import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Breadcrumb } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { useThunkDispatch } from "~/configureStore";
import { DOCUMENTS } from "~/constants/routes";
import {
  getDocuments,
  getFolders,
  getFoldersPathSuccess
} from "~/actions/DocumentsActions";
import { BreadcrumbLogo } from "./BreadcrumbLogo";
import { isWhiteLabel } from "~/constants/config";

import type { DocumentLocation } from "~/services/api-types";
import type { WithOptional } from "~/helpers/types";

import botwiseWhite from "~/assets/logo_white.svg";

import styles from "./Breadcrumb.module.scss";

const BreadcrumbLogoMap = {
  BOTWISE: {
    label: "BOTWISE",
    iconImageSrc: botwiseWhite,
    backgroundColor: "#2FB67D"
  }
};

interface DocBreadcrumbProps {
  handleClick?: () => void;
  data: DocumentLocation[];
  parent: DocumentLocation | null;
  className?: string;
}

const DocBreadcrumb = ({
  handleClick,
  data,
  parent,
  className
}: DocBreadcrumbProps) => {
  const { t } = useTranslation();

  const defaultBreadcrumb = useMemo(
    () => ({
      name: t("doc_breadcrumbs.documents"),
      id: 0
    }),
    [t]
  );

  const [breadcrumbs, setBreadcrumbs] = useState<
    WithOptional<DocumentLocation, "author" | "createdAt" | "updatedAt">[]
  >([defaultBreadcrumb]);

  useEffect(() => {
    if (data?.length) {
      const newBreadcrumbs = [defaultBreadcrumb, ...data.reverse()];

      setBreadcrumbs(newBreadcrumbs);
    }
  }, [data]);

  const dispatch = useThunkDispatch();

  const updateDocuments = useCallback(
    (docParentId: number, path: typeof breadcrumbs) => {
      dispatch(getDocuments(0, docParentId));
      dispatch(getFolders(0, docParentId));
      dispatch(getFoldersPathSuccess(path));

      if (typeof handleClick === "function") {
        return handleClick();
      }
    },
    [dispatch, handleClick]
  );

  const handleBreadcrumbClick = useCallback(
    (id: number) => {
      const newPath: typeof breadcrumbs = [];

      for (const breadcrumb of breadcrumbs) {
        newPath.push(breadcrumb);
        if (breadcrumb.id === id) {
          break;
        }
      }

      return updateDocuments(id, newPath);
    },
    [breadcrumbs]
  );

  return (
    <Breadcrumb className={classNames(styles.breadSection, className)}>
      {!isWhiteLabel() && (
        <>
          <BreadcrumbLogo {...BreadcrumbLogoMap["BOTWISE"]} />
          <span className="divider">{">"}</span>
        </>
      )}
      {breadcrumbs.map(({ id, name }, index) => (
        <React.Fragment key={id}>
          <Breadcrumb.Section
            className={classNames(styles.customBread, styles.previewBread)}
            link
            as={Link}
            to={DOCUMENTS}
            active={parent === null || id === parent.id}
            onClick={() => handleBreadcrumbClick(id)}
          >
            {name}
          </Breadcrumb.Section>
          {breadcrumbs.length - 1 !== index && (
            <span className="divider">{">"}</span>
          )}
        </React.Fragment>
      ))}
    </Breadcrumb>
  );
};

export default DocBreadcrumb;
