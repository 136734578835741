const initialState = {
  isFetching: false,
  lastRequestUrl: "",
  lastRequestData: {}
};

const fetching = (state = initialState, action) => {
  const withLoader = action.withLoader ?? true;
  if (!withLoader) {
    return state;
  }

  const isRequested =
    action.type.includes("_REQUEST") ||
    (action.type.includes("GET_") &&
      !action.type.includes("_FAILURE") &&
      !action.type.includes("_SUCCESS"));
  if (isRequested) {
    return {
      isFetching: true,
      lastRequestUrl: action.data ? action.data.url : state.lastRequestUrl,
      lastRequestData: action.data ? action.data.request : state.lastRequestData
    };
  }
  if (action.type.includes("_FAILURE") || action.type.includes("_SUCCESS")) {
    return {
      ...state,
      isFetching: false
    };
  }
  return state;
};

export default fetching;
