import classNames from "classnames";
import { Handle, Position } from "react-flow-renderer";
import ButtonIcon from "~/atoms/ButtonIcon/ButtonIcon";

import { ReactComponent as PlusIcon } from "~/assets/icons/plus.svg";
import { ReactComponent as EditIcon } from "~/assets/icons/edit.svg";
import { ReactComponent as TrashIcon } from "~/assets/icons/trash.svg";

import styles from "./DefaultNode.module.scss";
import React, { useCallback, useRef } from "react";
import { useThunkDispatch } from "~/configureStore";
import {
  generateNewNodeAction,
  removeWorkflowItemAction
} from "~/actions/workflow/actions";
import { GenerateNodeData } from "~/reducers/types";
import { useElementSize } from "~/hooks/useElementSize";

interface DefaultNodeProps {
  source?: boolean;
  target?: boolean;
  targetHandleClassName?: string;
  sourceHandleClassName?: string;
  className?: string;
  children: React.ReactNode;
  previewMode?: boolean;
  nodeData?: GenerateNodeData;
}

interface ExtensibleNodeProps extends DefaultNodeProps {
  extensible: true;
  container?: true;
  onEdit?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onAddNode?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onRemoveNode?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  withoutRemove?: boolean;
}

interface NonExtensibleNodeProps extends DefaultNodeProps {
  extensible?: undefined;
  onEdit?: undefined;
  onAddNode?: undefined;
  onRemoveNode?: undefined;
  withoutRemove?: undefined;
  container?: boolean;
}

type Props = ExtensibleNodeProps | NonExtensibleNodeProps;

export const DefaultNode = ({
  source,
  target,
  container = true,
  extensible,
  className,
  onAddNode,
  onEdit,
  onRemoveNode,
  nodeData,
  withoutRemove,
  targetHandleClassName,
  sourceHandleClassName,
  previewMode,
  children
}: Props) => {
  const dispatch = useThunkDispatch();

  const containerRef = useRef<HTMLDivElement>(null);

  const { height, width } = useElementSize(containerRef);

  const handleDeleteNode = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      dispatch(removeWorkflowItemAction({ nodeId: nodeData!.id }));

      if (typeof onRemoveNode === "function") {
        onRemoveNode(e);
      }
    },
    [dispatch, nodeData?.id, onRemoveNode]
  );

  const handleAddNode = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      dispatch(generateNewNodeAction({ ...nodeData!, width, height }));

      if (typeof onAddNode === "function") {
        onAddNode(e);
      }
    },
    [dispatch, onAddNode, nodeData, height, width]
  );

  return (
    <>
      {target && (
        <Handle
          type="target"
          position={Position.Left}
          className={classNames(
            styles.handle,
            styles.handleLeft,
            targetHandleClassName
          )}
        />
      )}
      {container ? (
        <div
          className={classNames(styles.wrapper, className)}
          ref={containerRef}
        >
          {children}
          {!previewMode && extensible && (
            <div className={styles.actions}>
              <ButtonIcon
                className={styles.actionButton}
                onClick={handleAddNode}
              >
                <PlusIcon />
              </ButtonIcon>
              <ButtonIcon
                className={styles.actionButton}
                onClick={onEdit}
                darken
              >
                <EditIcon />
              </ButtonIcon>
              {!withoutRemove && (
                <ButtonIcon
                  className={styles.actionButton}
                  onClick={handleDeleteNode}
                  darken
                  negative
                >
                  <TrashIcon />
                </ButtonIcon>
              )}
            </div>
          )}
        </div>
      ) : (
        children
      )}
      {source && (
        <Handle
          type="source"
          position={Position.Right}
          className={classNames(
            styles.handle,
            styles.handleRight,
            sourceHandleClassName
          )}
        />
      )}
    </>
  );
};
