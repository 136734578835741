import { useCallback, useState } from "react";
import { NodeProps } from "react-flow-renderer";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useThunkDispatch } from "~/configureStore";
import { CreateNodeData } from "~/components/types";
import { removeWorkflowItemAction } from "~/actions/workflow/actions";
import Button from "~/atoms/Button/Button";
import ButtonIcon from "~/atoms/ButtonIcon/ButtonIcon";
import { DefaultNode } from "../DefaultNode/DefaultNode";
import { KnowledgeEntryModal } from "~/atoms/KnowledgeEntryModal/KnowledgeEntryModal";
import { SupportingQuestionModal } from "~/atoms/SupportingQuestionModal/SupportingQuestionModal";

import { ReactComponent as CrossIcon } from "~/assets/icons/close.svg";
import { ReactComponent as AnswerSVG } from "~/assets/icons/chat_typing.svg";
import { ReactComponent as KnowledgeSVG } from "~/assets/icons/menu_issues.svg";

import styles from "./NewResponse.module.scss";

enum NewResponseModalTypes {
  RESPONSE = 1,
  KNOWLEDGE_ENTRY = 2
}

interface NewResponseProps extends NodeProps<CreateNodeData> {
  previewMode?: boolean;
}

export const NewResponse = ({
  id,
  xPos,
  yPos,
  previewMode
}: NewResponseProps) => {
  const { t } = useTranslation();

  const [modalType, setModalType] = useState<
    NewResponseModalTypes | undefined
  >();

  const dispatch = useThunkDispatch();

  const removeNode = useCallback(() => {
    if (previewMode) return;

    return dispatch(removeWorkflowItemAction({ nodeId: id }));
  }, [dispatch, id, previewMode]);

  const handleOpenModal = useCallback(
    (type: NewResponseModalTypes) => {
      if (previewMode) return;
      return setModalType(type);
    },
    [previewMode]
  );

  return (
    <DefaultNode
      source
      target
      className={styles.wrapper}
      previewMode={previewMode}
    >
      <h4 className={styles.title}>{t("flow.new_response.select")}</h4>
      <Button
        onClick={() => handleOpenModal(NewResponseModalTypes.KNOWLEDGE_ENTRY)}
        className={classNames(styles.btn, styles.btnKnowledge)}
        disabled={previewMode}
      >
        <KnowledgeSVG className={styles.knowledgeIcon} />
        {t("flow.new_response.knowledge_base_entry")}
      </Button>
      <Button
        onClick={() => handleOpenModal(NewResponseModalTypes.RESPONSE)}
        className={styles.btn}
        disabled={previewMode}
      >
        <AnswerSVG />
        {t("flow.new_response.supporting_question")}
      </Button>
      <ButtonIcon
        onClick={removeNode}
        className={styles.removeButton}
        disabled={previewMode}
      >
        <CrossIcon />
      </ButtonIcon>
      {!previewMode && modalType === NewResponseModalTypes.KNOWLEDGE_ENTRY && (
        <KnowledgeEntryModal
          nodeData={{ id, position: { y: yPos, x: xPos } }}
          onModalClose={() => setModalType(undefined)}
        />
      )}
      {!previewMode && modalType === NewResponseModalTypes.RESPONSE && (
        <SupportingQuestionModal
          id={id}
          xPos={xPos}
          yPos={yPos}
          onModalClose={() => setModalType(undefined)}
        />
      )}
    </DefaultNode>
  );
};
