import React from "react";
import { Loader } from "semantic-ui-react";
import styles from "./Loader.module.scss";
import {
  CHAT,
  DOCUMENTS,
  ISSUES,
  KNOWLEDGE_FRAGMENTS,
  SEARCH
} from "~/constants/routes";
import { useLocation } from "react-router-dom";

const filteredLocations = [
  CHAT,
  KNOWLEDGE_FRAGMENTS,
  SEARCH,
  DOCUMENTS,
  ISSUES
];

interface FetchLoaderProps {
  isFetching: boolean;
}

const FetchLoader = ({ isFetching }: FetchLoaderProps) => {
  const location = useLocation();

  return isFetching && !filteredLocations.includes(location.pathname) ? (
    <div className={styles.loaderDimmer}>
      <Loader active />
    </div>
  ) : null;
};

export default FetchLoader;
