import React, { useEffect } from "react";

type OutsideClickHandler = (e: MouseEvent | TouchEvent) => void;
type ElementRef = React.MutableRefObject<HTMLElement | null>;

export const useOnClickOutside = (
  ref: ElementRef,
  handler: OutsideClickHandler
) => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current.contains(event.target as HTMLElement)) {
        return;
      }

      return handler(event);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};
