import { TOGGLE_SIDEBAR_STATE } from "~/actions/Utility/types";

interface UtilityState {
  isSidebarExpanded: boolean;
}

export interface ToggleSidebarAction {
  type: typeof TOGGLE_SIDEBAR_STATE;
  isSidebarExpanded: boolean;
}

const defaultState: UtilityState = { isSidebarExpanded: false };

type UtilityActions = ToggleSidebarAction;

export const utility = (
  state: UtilityState = defaultState,
  action: UtilityActions
): UtilityState => {
  switch (action.type) {
    case TOGGLE_SIDEBAR_STATE:
      return { isSidebarExpanded: action.isSidebarExpanded };
    default:
      return state;
  }
};
