import React from "react";
import ButtonIcon from "~/atoms/ButtonIcon/ButtonIcon";
import { ReactComponent as ArrowSVG } from "~/assets/next.svg";
import styles from "./ArrowButton.module.scss";
import classNames from "classnames";

enum ArrowButtonState {
  LEFT = "left",
  RIGHT = "right"
}

interface ArrowButtonProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  currentState?: "left" | "right";
  className?: string;
}

export const ArrowButton = ({
  onClick,
  currentState = ArrowButtonState.RIGHT,
  className
}: ArrowButtonProps) => (
  <ButtonIcon
    className={classNames(styles.arrowButton, className)}
    onClick={onClick}
  >
    <ArrowSVG
      className={
        currentState == ArrowButtonState.LEFT ? styles.left : styles.right
      }
    />
  </ButtonIcon>
);
