import request from "./request";
import download from "./download";

const documentsUrl = "/documents";
const foldersUrl = "/folders";

/**
 *
 * @param {File[]} files
 * @param {*} parentId
 * @returns { Promise<{ name: string, extension: string, createdAt: Date, author: { userName: string }, enabled: boolean, id: number, location: Array, parent: ?string, size: number, source: string, status: string, updatedAt: Date, url: ?string, version: number, versionUpdatedAt: Date }[]> }
 */

export function uploadFiles(files, parentId, isArticle = false) {
  const data = new FormData();

  Array.from(files).forEach(file => {
    data.append("file", file);
  });
  data.append(
    "properties",
    new Blob(
      [
        JSON.stringify({
          parentId: parentId,
          isArticle: isArticle
        })
      ],
      {
        type: "application/json"
      }
    )
  );
  return request(
    {
      url: documentsUrl + "/upload",
      method: "POST",
      data
    },
    false
  );
}

export function updateFile(file, docId) {
  const data = new FormData();
  data.append("file", file);
  return request(
    {
      url: `${documentsUrl}/${docId}/revisions`,
      method: "POST",
      data
    },
    false
  );
}

export const updateWebDocument = docId => {
  return request({
    url: `${documentsUrl}/${docId}/revisions`,
    method: "POST"
  });
};

export const uploadFromURL = (url, parentId) => {
  return request({
    url: documentsUrl + "/upload_url",
    method: "POST",
    data: JSON.stringify({ url, parentId })
  });
};

/**
 *
 * @param {number} documentId
 * @returns {Promise<import("./api-types").DocumentType>}
 */
export const getDocument = documentId => {
  if (typeof documentId !== "number") {
    return Promise.reject();
  }

  return request({ url: `${documentsUrl}/${documentId}` }, true, true);
};

export const getDocuments = (pageNumber, parentId, sort, documentId) => {
  let field;
  let direction;
  if (sort) {
    field = sort.by;
    direction = sort.direction;
  } else {
    field = "versionUpdatedAt";
    direction = "desc";
  }
  let urlParam =
    documentsUrl +
    "?page=" +
    encodeURIComponent(pageNumber) +
    `&size=25&sort=${field},${direction}`;
  if (parentId) {
    urlParam =
      documentsUrl +
      `?parentId=${parentId}` +
      "&page=" +
      encodeURIComponent(pageNumber) +
      `&size=25&sort=${field},${direction}`;
  }
  if (documentId && !isNaN(documentId)) {
    urlParam = documentsUrl + `/${documentId}`;
  }

  return request({
    url: urlParam,
    method: "GET"
  });
};

export const getFolders = (pageNumber, parentId, sort) => {
  let field;
  let direction;

  if (sort?.by === "size") {
    sort.by = "versionUpdatedAt";
  }

  if (sort) {
    field = sort.by === "versionUpdatedAt" ? "updatedAt" : sort.by;
    direction = sort.direction;
  } else {
    field = "updatedAt";
    direction = "desc";
  }
  let urlParam =
    foldersUrl +
    "?page=" +
    encodeURIComponent(pageNumber) +
    `&size=25&sort=${field},${direction}`;
  if (parentId) {
    urlParam =
      foldersUrl +
      `?parentId=${parentId}` +
      "&page=" +
      encodeURIComponent(pageNumber) +
      `&size=25&sort=${field},${direction}`;
  }

  return request({
    url: urlParam,
    method: "GET"
  });
};

export const getFoldersPath = (pageNumber, parentId) => {
  let urlParam =
    foldersUrl +
    "?page=" +
    encodeURIComponent(pageNumber) +
    "&size=1000&sort=createdAt,desc";
  if (parentId) {
    urlParam =
      foldersUrl +
      `?parentId=${parentId}` +
      "&page=" +
      encodeURIComponent(pageNumber) +
      "&size=1000&sort=createdAt,desc";
  }

  return request({
    url: urlParam,
    method: "GET"
  });
};

export const addFolder = (name, parentId) => {
  const id = parentId || null;
  return dispatch =>
    request({
      url: foldersUrl,
      method: "POST",
      data: JSON.stringify({ name, parentId: id })
    });
};

export const updateFolder = (name, id) => {
  return dispatch =>
    request({
      url: foldersUrl + `/${id}/name`,
      method: "PUT",
      data: JSON.stringify({ name })
    });
};

export const deleteFolder = id =>
  request(
    {
      url: foldersUrl + `/${id}`,
      method: "DELETE"
    },
    false
  );

export const listDocuments = () =>
  request({
    url: documentsUrl + "?list",
    method: "GET"
  });

export const deleteFile = id =>
  request(
    {
      url: documentsUrl + "/" + id,
      method: "DELETE"
    },
    false
  );

export const nameDocument = (name, id) => dispatch =>
  request({
    url: documentsUrl + "/" + id + "/name",
    method: "PUT",
    data: JSON.stringify({ name })
  });

export const toggleDocument = (id, enabled) =>
  request({
    url: documentsUrl + "/" + id + "/enabled",
    method: "PUT",
    data: JSON.stringify({ enabled })
  });

/**
 *
 * @param {number} id
 * @returns {Promise<string>} Document html data
 */
export function previewFile(id) {
  const resourceUrl = documentsUrl + "/" + id + "/preview";

  return request(
    {
      url: resourceUrl,
      method: "GET"
    },
    false,
    true,
    true
  )
    .then(res => {
      if (res?.response?.status === 204) {
        throw "error_message.requested_file_was_empty";
      }

      return res.data;
    })
    .catch(err => {
      throw err;
    });
}

export function downloadFile(id) {
  const resourceUrl = documentsUrl + "/" + id + "/download";

  return download({
    url: resourceUrl,
    method: "GET"
  });
}

export function buildFileUrl(id) {
  const resourceUrl = documentsUrl + "/" + id + "/download";

  return request(
    {
      url: resourceUrl,
      method: "GET",
      responseType: "blob"
    },
    false
  ).then(data => {
    const url = window.URL.createObjectURL(data);
    return url;
  });
}

export const moveFolders = (id, parentId = 0) => {
  return dispatch =>
    request({
      url: `${foldersUrl}/${id}/parentId`,
      method: "PUT",
      data: JSON.stringify({ parentId })
    });
};

export const moveDocuments = (id, parentId) => {
  return dispatch =>
    request({
      url: `${documentsUrl}/${id}/parentId`,
      method: "PUT",
      data: JSON.stringify({ parentId })
    });
};
