import cookies, { CookieAttributes } from "js-cookie";

const setCookie = (
  name: string,
  value: string,
  options: CookieAttributes | undefined = undefined
) => {
  if (!navigator?.cookieEnabled) return;

  cookies.set(name, value, options);
};

const getCookie = (name: string) => {
  if (!navigator?.cookieEnabled) return null;

  const result = cookies.get(name);

  if (result) {
    return result;
  }

  return null;
};

const removeCookie = (
  name: string,
  options: CookieAttributes | undefined = undefined
) => {
  if (!navigator.cookieEnabled) return;

  cookies.remove(name, options);
};

export { setCookie, getCookie, removeCookie };
