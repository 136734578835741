import { clearEventsQueue } from "./AnalyticsActions";
import {
  CLEAR_LOGIN_STATE,
  CLEAR_AUTOCOMPLETE_STATE,
  CLEAR_PROJECTS_STATE,
  CLEAR_MESSAGES_STATE,
  CLEAR_EDITOR_STATE,
  CLEAR_ISSUES_STATE,
  CLEAR_DOCUMENTS_STATE,
  CLEAR_STATS_STATE,
  CLEAR_USERS_STATE
} from "./types";

export function clearLoginState() {
  return {
    type: CLEAR_LOGIN_STATE
  };
}

export function clearMessagesState() {
  return {
    type: CLEAR_MESSAGES_STATE
  };
}

export function clearEditorState() {
  return {
    type: CLEAR_EDITOR_STATE
  };
}

export function clearIssuesState() {
  return {
    type: CLEAR_ISSUES_STATE
  };
}

export function clearDocumentsState() {
  return {
    type: CLEAR_DOCUMENTS_STATE
  };
}

export function clearStatsState() {
  return {
    type: CLEAR_STATS_STATE
  };
}

export function clearUsersState() {
  return {
    type: CLEAR_USERS_STATE
  };
}

export function clearProjectsState() {
  return {
    type: CLEAR_PROJECTS_STATE
  };
}

export function clearAutocompleteState() {
  return {
    type: CLEAR_AUTOCOMPLETE_STATE
  };
}

export function clearState() {
  return dispatch => {
    dispatch(clearLoginState());
    dispatch(clearMessagesState());
    dispatch(clearAutocompleteState());
    dispatch(clearEditorState());
    dispatch(clearIssuesState());
    dispatch(clearDocumentsState());
    dispatch(clearStatsState());
    dispatch(clearUsersState());
    dispatch(clearProjectsState());
    dispatch(clearEventsQueue());
  };
}
