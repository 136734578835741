import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Confirm } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import {
  deleteFolderAction,
  getDocuments,
  getFolders,
  getFoldersPath,
  hideConfirmModal
} from "~/actions";

class DeleteFolderModal extends Component {
  render() {
    return (
      <Confirm
        basic
        size="mini"
        content={this.props.t(
          "documents.modal.are_you_sure_deleting_this_folder"
        )}
        cancelButton={this.props.t("documents.modal.cancel")}
        confirmButton={this.props.t("documents.modal.delete")}
        open
        onCancel={this.props.cancel}
        onConfirm={() =>
          this.props.deleteFolder(
            this.props.modalProps.id,
            this.props.modalProps.parentId
          )
        }
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return state.modal;
};

const mapDispatchToProps = dispatch => {
  return {
    deleteFolder: (id, parentId) => {
      dispatch(deleteFolderAction(id));
      setTimeout(() => {
        dispatch(getDocuments(0, parentId));
      }, 1000);
      setTimeout(() => {
        dispatch(getFolders(0, parentId));
      }, 1000);
      setTimeout(() => {
        dispatch(getFoldersPath(0, parentId));
      }, 1000);
      dispatch(hideConfirmModal());
    },
    cancel: () => {
      dispatch(hideConfirmModal());
    }
  };
};

DeleteFolderModal.propTypes = {
  modalProps: PropTypes.shape({
    id: PropTypes.string,
    parentId: PropTypes.string
  }),
  deleteFolder: PropTypes.func,
  cancel: PropTypes.func,
  t: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(DeleteFolderModal));
