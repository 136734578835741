import { deleteSynonymTermAction } from "~/actions/SynonymsActions";
import {
  SHOW_CONFIRM_MODAL,
  HIDE_CONFIRM_MODAL,
  DELETE_DOCUMENT_SUCCESS
} from "~/actions/types";
import { DocumentFragmentAnchorsType } from "~/services/api-types";

export interface ModalTypes {
  DELETE_DOCUMENT: "DELETE_DOCUMENT";
  DELETE_DOCUMENT_SUCCESS: "DELETE_DOCUMENT_SUCCESS";
  DELETE_FOLDER: "DELETE_FOLDER";
  UPLOAD_FROM_URL: "UPLOAD_FROM_URL";
  PREVIEW_DOCUMENT: "PREVIEW_DOCUMENT";
  REPORT_MESSAGE: "REPORT_MESSAGE";
  INVITE_USER: "INVITE_USER";
  DELETE_USER: "DELETE_USER";
  UPDATE_USER: "UPDATE_USER";
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS";
  INVITE_USER_SUCCESS: "INVITE_USER_SUCCESS";
  DELETE_CHANGE: "DELETE_CHANGE";
  ISSUES_MODAL: "ISSUES_MODAL";
  DELETE_SYNONYM_TERM: "DELETE_SYNONYM_TERM";
  LEAVE_ARTICLE_VIEW: "LEAVE_ARTICLE_VIEW";
  CONVERT_TO_ARTICLE: "CONVERT_TO_ARTICLE";
}

export interface DocumentPreviewProps {
  id: number;
  anchors: DocumentFragmentAnchorsType;
}

export interface DeleteDocumentProps {
  id: number;
  parentId: number;
}

export interface DeleteSynonymTermProps {
  id: string;
  removeFn: typeof deleteSynonymTermAction;
}

interface DeleteDocumentSuccess {
  type: typeof DELETE_DOCUMENT_SUCCESS;
  payload: {
    id: number;
  };
}

interface PreviewDocumentModal {
  type: typeof SHOW_CONFIRM_MODAL;
  modalType: ModalTypes["PREVIEW_DOCUMENT"];
  modalProps: DocumentPreviewProps;
}

interface DeleteDocumentModal {
  type: typeof SHOW_CONFIRM_MODAL;
  modalType: ModalTypes["DELETE_DOCUMENT"];
  modalProps: DeleteDocumentProps;
}

export interface DeleteSynonymTerm {
  type: typeof SHOW_CONFIRM_MODAL;
  modalType: ModalTypes["DELETE_SYNONYM_TERM"];
  modalProps: DeleteSynonymTermProps;
}

export interface ConvertToArticleModal {
  type: typeof SHOW_CONFIRM_MODAL;
  modalType: ModalTypes["CONVERT_TO_ARTICLE"];
}

interface InitialState {
  modalType: keyof ModalTypes | null;
  modalProps: {};
}

interface HideConfirmModal extends InitialState {
  type: typeof HIDE_CONFIRM_MODAL;
  modalType: null;
}

export type ModalActions =
  | PreviewDocumentModal
  | DeleteDocumentModal
  | DeleteDocumentSuccess
  | HideConfirmModal
  | DeleteSynonymTerm;

export type ModalState =
  | Omit<PreviewDocumentModal, "type">
  | Omit<DeleteDocumentModal, "type">
  | InitialState;

const initialState: InitialState = {
  modalType: null,
  modalProps: {}
};

const modal = (
  state: ModalState = initialState,
  action: ModalActions
): ModalState => {
  switch (action.type) {
    case SHOW_CONFIRM_MODAL:
      return {
        modalType: action.modalType,
        modalProps: action.modalProps
      };
    case HIDE_CONFIRM_MODAL:
      return initialState;
    case DELETE_DOCUMENT_SUCCESS:
      return {
        ...initialState,
        modalType: "DELETE_DOCUMENT_SUCCESS"
      };
    default:
      return state;
  }
};

export default modal;
