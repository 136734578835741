import axios, { AxiosRequestConfig } from "axios";
import { logoutUser } from "./logout";
import { getAccessToken, getRefreshToken, setLoginTokens } from "./tokens";
import { API_URL, TENANT } from "~/constants/app";
import { AuthorizationType } from "~/services/api-types";
import { OAUTH, TOKEN } from "~/services/api-urls";

declare module "axios" {
  export interface AxiosRequestConfig {
    apiType?: string;
    _refreshing?: boolean;
  }
}

const defaultHeaders = {
  "Content-Type": "application/json",
  "X-Tenant-Id": TENANT
};

const defaultConfig: AxiosRequestConfig = {
  baseURL: API_URL,
  apiType: "/api",
  headers: defaultHeaders
};

const axiosInstance = axios.create({
  ...defaultConfig
});

axiosInstance.interceptors.request.use(
  config => {
    const accessToken = getAccessToken();

    config.headers = {
      ...(config.headers || {}),
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {})
    };

    config.url = (config.apiType as string) + config.url || "";

    return config;
  },
  error => {
    throw error;
  }
);

interface FailedRequestQueueData {
  resolve: (value: string | PromiseLike<string>) => void;
  reject: (err: unknown) => void;
}

const failedRequestQueue: FailedRequestQueueData[] = [];

const handleProcessFailedRequestQueue = (token?: string, err?: unknown) => {
  failedRequestQueue.forEach(promise => {
    if (token) {
      promise.resolve(token);
    } else {
      promise.reject(err);
    }
  });
};

let isRefreshingToken = false;

axiosInstance.interceptors.response.use(
  res => res,
  async err => {
    if (axios.isAxiosError(err)) {
      if (err.response?.status === 401) {
        const requestConfig = err.config;

        const refresh_token = getRefreshToken();

        if (!refresh_token || requestConfig.params?.refresh_token) {
          if (failedRequestQueue.length) failedRequestQueue.length = 0;

          logoutUser();
          throw err;
        }

        if (requestConfig.url) {
          if (requestConfig._refreshing || isRefreshingToken) {
            return new Promise<string>((resolve, reject) => {
              failedRequestQueue.push({ resolve, reject });
            })
              .then(
                async token =>
                  await axios({
                    ...requestConfig,
                    headers: {
                      ...requestConfig.headers,
                      Authorization: `Bearer ${token}`
                    }
                  })
              )
              .catch(err => {
                throw err;
              });
          }

          requestConfig._refreshing = true;
          isRefreshingToken = true;

          try {
            const { data } = await postRequest<AuthorizationType>(TOKEN, {
              params: {
                grant_type: "refresh_token",
                refresh_token
              },
              apiType: OAUTH,
              headers: {
                Authorization: "Basic bXlDbGllbnQ6bXlDbGllbnRTZWNyZXQ="
              }
            });

            setLoginTokens(data);

            if (failedRequestQueue.length > 0) {
              handleProcessFailedRequestQueue(data.access_token);

              failedRequestQueue.length = 0;
            }

            return axios({
              ...requestConfig,
              headers: {
                ...(requestConfig.headers ? requestConfig.headers : {}),
                Authorization: `Bearer ${data.access_token}`
              }
            });
          } catch (err) {
            handleProcessFailedRequestQueue(undefined, err);

            logoutUser();

            // logout
            throw err;
          } finally {
            if (requestConfig._refreshing) {
              delete requestConfig._refreshing;
              isRefreshingToken = false;
            }
          }
        } else {
          logoutUser();
          // logout
          throw err;
        }
      }

      throw err;
    }

    throw err;
  }
);

export { axiosInstance };

export const getRequest = <R = unknown>(
  url: string,
  config: AxiosRequestConfig = {}
) => {
  return axiosInstance.get<R>(url, config);
};

export const postRequest = <R = unknown>(
  url: string,
  config: AxiosRequestConfig = {}
) => {
  const { data, ...rest } = config;

  return axiosInstance.post<R>(url, data, rest);
};

export const putRequest = <R = unknown>(
  url: string,
  config: Omit<AxiosRequestConfig, "params"> = {}
) => {
  const { data, ...rest } = config;

  return axiosInstance.put<R>(url, data, rest);
};

export const patchRequest = <R = unknown>(
  url: string,
  config: Omit<AxiosRequestConfig, "params"> = {}
) => {
  const { data, ...rest } = config;

  return axiosInstance.patch<R>(url, data, rest);
};

export const deleteRequest = <R = unknown>(
  url: string,
  config: AxiosRequestConfig = {}
) => {
  return axiosInstance.delete<R>(url, config);
};
