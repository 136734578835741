import React, { useEffect, useMemo } from "react";
import { Flow } from "~/atoms/Flow/Flow";
import { Modal } from "~/atoms/Modal/Modal";
import LoaderInline from "~/components/LoaderInline/LoaderInline";
import { useToggle } from "~/hooks/useToggle";
import { getWorkflow } from "~/services/localStorage/workflows";

import styles from "./WorkflowPreview.module.scss";

interface WorkflowPreviewProps {
  workflowId?: string;
}

export const WorkflowPreview = ({ workflowId }: WorkflowPreviewProps) => {
  const [state, toggleState] = useToggle(false);

  //   const { error, isLoading, result: currentWorkflow } = useFetch()

  useEffect(() => {
    if (workflowId) {
      toggleState(true);
    }
  }, [workflowId]);

  const currentWorkflow = useMemo(
    () => (workflowId ? getWorkflow(workflowId) : undefined),
    [workflowId]
  );

  return (
    <Modal
      opened={state}
      onClose={() => toggleState(false)}
      contentClassName={styles.modalWrapper}
      title="Preview your Flow"
    >
      {currentWorkflow ? (
        <Flow previewMode data={currentWorkflow.data} className={styles.flow} />
      ) : (
        <LoaderInline isFetching />
      )}
    </Modal>
  );
};
