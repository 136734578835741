import React from "react";
import GetMessagesReport from "~/components/Statistics/GetMessagesReport/GetMessagesReport";
import { useTrackMixpanelEvent } from "~/hooks/useTrackMixpanelEvent";
import { MIXPANEL_EVENTS } from "~/contexts/mixpanel/event-types";
import GeneralStatistics from "~/components/Statistics/Overview/GeneralStatistics";
import SelectChartForm from "~/components/Statistics/Overview/SelectChartForm";

import styles from "./Statistics.module.scss";
import { UsersActivity } from "~/organisms/Statistics/UsersActivity";
import LineChart from "~/components/Statistics/Overview/LineChart";

export const Statistics = () => {
  useTrackMixpanelEvent(MIXPANEL_EVENTS.STATS_GENERAL_VISIT);

  return (
    <div className={styles.content}>
      <div className={styles.overview}>
        <GeneralStatistics />
        <GetMessagesReport />
      </div>
      <UsersActivity />
      <div className={styles.usersContainer}>
        <SelectChartForm />
        <div className={styles.usersChart}>
          <LineChart />
        </div>
      </div>
    </div>
  );
};
