import request from "./request";
import { stringifyUrlQuery } from "~/helpers/url";
import type { IssuesType } from "./api-types";

const issuesUrl = "/issues";

export const reportIssue = (data: any) =>
  request({
    url: issuesUrl,
    method: "POST",
    data: JSON.stringify(data)
  });

export const getIssues = (page: number, labels: string[] = []) => {
  return request<IssuesType>({
    url: stringifyUrlQuery(issuesUrl, {
      page,
      size: 20,
      sort: ["createdAt", "desc"],
      labels
    }),
    method: "GET"
  });
};

export const getSingleIssue = (issueId: any) => {
  return request({
    url: issuesUrl + "/" + issueId,
    method: "GET"
  });
};

export const solveIssue = (id: any) =>
  request({
    url: issuesUrl + "/" + id + "/solve",
    method: "PUT"
  });

export const getReportReasons = () =>
  request({
    url: issuesUrl + "/reasons",
    method: "GET"
  });

export const getIssuesLabels = () =>
  request<string[]>({
    url: issuesUrl + "/labels",
    method: "GET"
  });

export const updateIssueLabels = (issueId: number, labels: string[] = []) =>
  request<void>({
    url: `${issuesUrl}/${issueId}/labels`,
    method: "PUT",
    data: { labels }
  });
