import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, CheckboxProps } from "semantic-ui-react";
import {
  changeSearchFiltersAndFetch,
  getSearchKnowledgeSourcesFiltersAction
} from "~/actions/SearchActions";
import LoaderInline from "~/components/LoaderInline/LoaderInline";
import { useSelector, useThunkDispatch } from "~/configureStore";
import { useToggle } from "~/hooks/useToggle";
import { useMixpanelContext } from "~/contexts/mixpanel";
import { MIXPANEL_EVENTS } from "~/contexts/mixpanel/event-types";

import { ReactComponent as FolderIcon } from "~/assets/folder 2 copy.svg";
import { ReactComponent as ConfluenceIcon } from "~/assets/icons/confluence.svg";
import { ReactComponent as SharepointIcon } from "~/assets/icons/sharepoint.svg";
import { ReactComponent as NextcloudIcon } from "~/assets/icons/nextcloud.svg";
import { ReactComponent as BotwiseIcon } from "~/assets/source-icons/botwise_source_icon.svg";
import { ReactComponent as WebIcon } from "~/assets/file-icons/html.svg";

import styles from "./TypeFilter.module.scss";

enum SourceFilterIcons {
  BOTWISE = "BOTWISE",
  WEB = "WEB",
  CONFLUENCE = "CONFLUENCE",
  SHAREPOINT = "SHAREPOINT",
  NEXTCLOUD = "NEXTCLOUD"
}

export const SourceFilter = () => {
  const { t } = useTranslation();
  const { trackEvent } = useMixpanelContext();
  const [isLoading, toggleLoading] = useToggle();
  const knowledgeFilters = useSelector(
    ({ searchNew: { filters } }) => filters?.knowledgeSource ?? []
  );

  const appliedFilters = useSelector(
    ({ searchNew: { filtersUsed } }) => filtersUsed?.knowledgeSource ?? []
  );

  const dispatch = useThunkDispatch();

  const fetchKnowledgeSourcesFilters = useCallback(async () => {
    try {
      toggleLoading(true);
      await dispatch(getSearchKnowledgeSourcesFiltersAction());
    } finally {
      toggleLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchKnowledgeSourcesFilters();
  }, []);

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
      if (data.checked && typeof data.value === "number") {
        dispatch(
          changeSearchFiltersAndFetch({
            knowledgeSource: [...appliedFilters, data.value]
          })
        );
        trackEvent(MIXPANEL_EVENTS.SEARCH_FILTERS_SOURCE);
      } else {
        const removedIds = appliedFilters.filter(id => id !== data.value);

        dispatch(
          changeSearchFiltersAndFetch({
            knowledgeSource: removedIds
          })
        );
        trackEvent(MIXPANEL_EVENTS.SEARCH_FILTERS_SOURCE);
      }
    },
    [dispatch, appliedFilters]
  );

  const getSourceIcon = (name: string) => {
    switch (name) {
      case SourceFilterIcons.CONFLUENCE:
        return <ConfluenceIcon />;
      case SourceFilterIcons.SHAREPOINT:
        return <SharepointIcon />;
      case SourceFilterIcons.NEXTCLOUD:
        return <NextcloudIcon />;
      case SourceFilterIcons.BOTWISE:
        return <BotwiseIcon />;
      case SourceFilterIcons.WEB:
        return <WebIcon />;
      default:
        return <FolderIcon />;
    }
  };

  return (
    <div className={styles.wrapper}>
      <h6>{t("search.filters.source_label")}</h6>
      <div className={styles.optionsWrapper}>
        {!isLoading &&
          knowledgeFilters.length > 0 &&
          knowledgeFilters.map(filter => (
            <Checkbox
              toggle
              key={filter.id}
              value={filter.id}
              label={
                <label>
                  {getSourceIcon(filter.name)}
                  <span>{filter.title}</span>
                </label>
              }
              checked={appliedFilters.includes(filter.id)}
              onChange={handleChange}
            />
          ))}
        <LoaderInline className={styles.loading} isFetching={isLoading} />
      </div>
    </div>
  );
};
