import {
  FILE_QUEUE_END,
  FILE_QUEUE_START,
  FILE_QUEUE_CLEAR,
  FILE_UPDATE_START,
  FILE_UPDATE_END,
  FILE_UPDATE_CLEAR,
  FILE_URL_COPIED,
  ALERT
} from "~/actions/AppAlertActions";
import { QueueFile } from "./types";

export const DEFAULT_ALERT_DISPLAY_TIME = 3000;

interface AppAlertState {
  showModal: boolean;
  uploading: QueueFile[];
  uploaded: QueueFile[];
  updating: boolean;
  updated: boolean;
  copied: boolean;
  alertMessage: string;
}

const defaultState: AppAlertState = {
  showModal: false,
  uploading: [],
  uploaded: [],
  updating: false,
  updated: false,
  copied: false,
  alertMessage: ""
};

interface FileUpdateClearAction {
  type: typeof FILE_UPDATE_CLEAR;
}

interface FileUpdateStartAction {
  type: typeof FILE_UPDATE_START;
}

interface FileUpdateEndAction {
  type: typeof FILE_UPDATE_END;
}

interface FileQueueStartAction {
  type: typeof FILE_QUEUE_START;
  payload: QueueFile[];
}

interface FileQueueEndAction {
  type: typeof FILE_QUEUE_END;
  payload: QueueFile[];
}

interface FileQueueClearAction {
  type: typeof FILE_QUEUE_CLEAR;
  payload: QueueFile[];
}

interface FileUrlCopiedAction {
  type: typeof FILE_URL_COPIED;
  payload: boolean;
}

interface AlertAction {
  type: typeof ALERT;
  showModal: boolean;
  alert: string;
}

type AppAlertActionTypes =
  | FileQueueStartAction
  | FileQueueEndAction
  | FileQueueClearAction
  | FileUpdateStartAction
  | FileUpdateEndAction
  | FileUpdateClearAction
  | FileUrlCopiedAction
  | AlertAction;

export const appAlert = (
  state: AppAlertState = defaultState,
  action: AppAlertActionTypes
): AppAlertState => {
  switch (action.type) {
    case FILE_QUEUE_START: {
      return {
        ...state,
        uploading: [...state.uploading, ...action.payload],
        showModal: true
      };
    }
    case FILE_QUEUE_END: {
      return {
        ...state,
        uploading: state.uploading.filter(
          ({ name }) => !action.payload.find(item => item.name === name)
        ),
        uploaded: [...state.uploaded, ...action.payload]
      };
    }
    case FILE_QUEUE_CLEAR: {
      return {
        ...state,
        uploaded: state.uploaded.filter(
          ({ name }) => !action.payload.find(item => item.name === name)
        ),
        showModal: false
      };
    }
    case FILE_UPDATE_START: {
      return {
        ...state,
        updating: true,
        showModal: true
      };
    }
    case FILE_UPDATE_END: {
      return {
        ...state,
        updating: false,
        updated: true
      };
    }
    case FILE_UPDATE_CLEAR: {
      return {
        ...state,
        updating: false,
        updated: false,
        showModal: false
      };
    }
    case FILE_URL_COPIED: {
      return {
        ...state,
        copied: action.payload,
        showModal: action.payload
      };
    }
    case ALERT: {
      return {
        ...state,
        showModal: action.showModal ?? true,
        alertMessage: action.alert
      };
    }
    default:
      return state;
  }
};
