export enum ACTIVITY_PERIOD {
  LAST_DAY = "lastDay",
  LAST_3_DAYS = "last3Days",
  LAST_7_DAYS = "last7Days",
  LAST_14_DAYS = "last14Days",
  LAST_30_DAYS = "last30Days"
}

export enum ACTIVITY_SORT_BY {
  userName = "userName",
  lastActivity = "lastActivity",
  totalQuestionsAsked = "totalQuestionsAsked",
  timeSpentInDocuments = "timeSpentInDocuments",
  timeActive = "timeActive"
}

export type ACTIVITY_SORT_DIRECTION = "asc" | "desc";

export const activityPeriods = [
  {
    key: 0,
    text: "statistics.users_activity_period_last_day",
    value: ACTIVITY_PERIOD.LAST_DAY
  },
  {
    key: 1,
    text: "statistics.users_activity_period_last_3_days",
    value: ACTIVITY_PERIOD.LAST_3_DAYS
  },
  {
    key: 2,
    text: "statistics.users_activity_period_last_7_days",
    value: ACTIVITY_PERIOD.LAST_7_DAYS
  },
  {
    key: 3,
    text: "statistics.users_activity_period_last_14_days",
    value: ACTIVITY_PERIOD.LAST_14_DAYS
  },
  {
    key: 4,
    text: "statistics.users_activity_period_last_30_days",
    value: ACTIVITY_PERIOD.LAST_30_DAYS
  }
];
