export function getOffsetTop(
  element: HTMLElement,
  finalOffsetParent: HTMLElement
) {
  let current = element;
  let offsetTop = 0;
  while (current && current !== finalOffsetParent) {
    offsetTop += current.offsetTop;
    current = current.offsetParent as HTMLElement;
  }
  return offsetTop;
}
