import React, { ReactNode } from "react";
import { Icon, Segment, Statistic, Loader } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import Tooltip from "~/atoms/Tooltip/Tooltip";

interface StatisticSegmentProps {
  value: number;
  icon: ReactNode;
  name: string;
  tip?: string;
  loading: boolean;
}

export const StatisticSegment = ({
  icon,
  name,
  tip,
  value,
  loading
}: StatisticSegmentProps) => {
  const { t } = useTranslation();
  return (
    <Segment basic textAlign="center">
      <Statistic size="tiny">
        <Statistic.Value>
          {loading && (
            <div className="loading">
              <Loader active size="small" />
            </div>
          )}
          {!loading && <span>{value}</span>}
          {icon}
        </Statistic.Value>
        <Statistic.Label className="statistic-label">
          {t(name)}
          {tip && (
            <Tooltip size="small" light tip={tip}>
              <Icon name="info circle" className="statistic-tip" />
            </Tooltip>
          )}
        </Statistic.Label>
      </Statistic>
    </Segment>
  );
};

export default StatisticSegment;
