import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Input } from "semantic-ui-react";
import { NEW_FRAGMENT_QUESTION_MAX_LENGTH } from "~/constants/app";
import DefaultAvatar from "~/components/DefaultAvatar";
import MessageTimer from "~/components/MessageTimer";
import classNames from "classnames";

import { ReactComponent as Trophy } from "~/assets/trophy.svg";

import styles from "./UserMessage.module.scss";

const UserMessageComponent = ({
  additionalBubbleClass,
  text,
  additionalFlexClass,
  additionalBlockClass,
  noUserName,
  editMode,
  startDate,
  newMode,
  noUserAvatar = false,
  primaryAnswer = false
}) => {
  const { t } = useTranslation();
  const [brandNewQuestionValue, setBrandNewQuestionValue] = useState();

  /**
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e
   */

  const handleBrandNewQuestion = e => {
    const { value } = e.target;

    return setBrandNewQuestionValue(
      value.slice(0, NEW_FRAGMENT_QUESTION_MAX_LENGTH)
    );
  };

  return (
    <div className={styles.item}>
      <div className={`${styles.flex} ${additionalFlexClass}`}>
        {!noUserAvatar && <DefaultAvatar className={styles.avatar} />}
        <div className={classNames(styles.block, additionalBlockClass)}>
          {!noUserName && (
            <span className={styles.header}>{t("user.consultant")}</span>
          )}
          {!newMode && (
            <p className={`${styles.message} ${additionalBubbleClass}`}>
              {text}
              {primaryAnswer}
            </p>
          )}
          {newMode && (
            <Input
              value={brandNewQuestionValue}
              onChange={handleBrandNewQuestion}
              maxLength={NEW_FRAGMENT_QUESTION_MAX_LENGTH}
            />
          )}
        </div>
      </div>
      {!editMode && (
        <MessageTimer className={styles.timer} startDate={startDate} />
      )}
    </div>
  );
};

UserMessageComponent.propTypes = {
  text: PropTypes.string.isRequired,
  noUserName: PropTypes.bool,
  additionalBubbleClass: PropTypes.string,
  additionalFlexClass: PropTypes.string,
  additionalBlockClass: PropTypes.string,
  editMode: PropTypes.func.isRequired,
  startDate: PropTypes.objectOf(Date),
  newMode: PropTypes.bool,
  noUserAvatar: PropTypes.bool,
  primaryAnswer: PropTypes.bool
};

export default UserMessageComponent;
