import {
  APPLY_FILTERS,
  CHANGE_SEARCH_FINDER_INDEX,
  CHANGE_SEARCH_FINDER_MAX_INDEX,
  CHAT_LAST_MESSAGE,
  GET_SEARCH_DOCUMENTS_TYPES_FILTERS,
  GET_SEARCH_KNOWLEDGE_SOURCES_FILTERS,
  SEARCH_CLEAR,
  SEARCH_ERROR,
  SEARCH_FILTERS_CLEAR,
  SEARCH_PAGE_CHANGE,
  SEARCH_PAGE_CHANGE_SUCCESS,
  SEARCH_REQUEST,
  SEARCH_SUCCESS
} from "~/actions/types";
import { FilterPeriodTypes } from "~/molecules/Filters/FiltersSettings";
import {
  AuthorType,
  DocumentType,
  HintType,
  SearchFiltersResponseType,
  SearchResponseType,
  SearchResultsType
} from "~/services/api-types";
import { groupSearchResultsByDocumentId } from "~/helpers";

export interface AppliedSearchFilters {
  knowledgeSource?: number[];
  documentType?: number[];
  modifiedSince?: string | null;
  modifiedUntil?: string | null;
  periodModification?: FilterPeriodTypes;
  modifiedBy?: string;
}

export interface SearchFilters {
  knowledgeSource?: SearchFiltersResponseType[];
  documentType?: SearchFiltersResponseType[];
  author?: AuthorType[];
}

type SearchFragmentResult = HintType & { position: number };

export interface GroupedByDocumentType {
  document: DocumentType | null;
  accuracy: number;
  latestEditor: { editor: string; updatedAt: Date };
  position: number;
  content: SearchFragmentResult[];
}

type SearchResults = Pick<
  SearchResponseType,
  "suggestions" | "featuredSnippet" | "searchEventId"
> & {
  results: Pick<
    SearchResultsType,
    "totalElements" | "totalPages" | "numberOfElements" | "size"
  > & { content: GroupedByDocumentType[] };
};

interface SearchState {
  items: SearchResults;
  page: number;
  loading: boolean;
  error: null | unknown;
  currentQuestion: string;
  prevChatQuestion: string;
  filters: SearchFilters | null;
  filtersUsed: AppliedSearchFilters;
  queryMatches: {
    currentPosition: number;
    totalPositions: number;
  };
}

const defaultState: SearchState = {
  currentQuestion: "",
  prevChatQuestion: "",
  page: 0,
  items: {
    featuredSnippet: null,
    results: {
      content: [],
      totalElements: 0,
      totalPages: 0,
      numberOfElements: 0,
      size: 0
    },
    suggestions: [],
    searchEventId: ""
  },
  loading: false,
  error: null,
  filters: null,
  filtersUsed: {
    knowledgeSource: [],
    documentType: [],
    modifiedSince: "",
    modifiedUntil: "",
    // for now we have to use default string value instead of enum because tests has a problem with interpreting enums for some reason
    /**
     * @todo Fix typescript enums in tests
     */
    //@ts-ignore
    periodModification: "all",
    modifiedBy: ""
  },
  queryMatches: { currentPosition: -1, totalPositions: 0 }
};

export interface SearchRequestAction {
  type: typeof SEARCH_REQUEST;
  payload: {
    question: string;
  };
  withLoader: false;
}

export interface SearchPageChangeAction {
  type: typeof SEARCH_PAGE_CHANGE;
  payload: {
    page: number;
  };
}

export interface SearchSuccessAction {
  type: typeof SEARCH_SUCCESS;
  payload: SearchResponseType;
  withLoader: false;
}

export interface SearchPageChangeSuccessAction {
  type: typeof SEARCH_PAGE_CHANGE_SUCCESS;
  payload: SearchResponseType;
  withLoader: false;
}

export interface SearchErrorAction {
  type: typeof SEARCH_ERROR;
  payload: unknown;
  withLoader: false;
}

export interface SearchClearAction {
  type: typeof SEARCH_CLEAR;
}

export interface SearchFiltersClearAction {
  type: typeof SEARCH_FILTERS_CLEAR;
}

export interface SaveLastChatMessageAction {
  type: typeof CHAT_LAST_MESSAGE;
  payload: string;
}

export interface ApplyFiltersAction {
  type: typeof APPLY_FILTERS;
  payload: AppliedSearchFilters;
}

export interface getSearchDocumentsTypesFiltersAction {
  type: typeof GET_SEARCH_DOCUMENTS_TYPES_FILTERS;
  payload: SearchFiltersResponseType[];
}

export interface getSearchKnowledgeSourcesFiltersAction {
  type: typeof GET_SEARCH_KNOWLEDGE_SOURCES_FILTERS;
  payload: SearchFiltersResponseType[];
}

export interface ChangeSearchFinderIndexAction {
  type: typeof CHANGE_SEARCH_FINDER_INDEX;
  position: number;
}

export interface ChangeSearchFinderMaxIndexAction {
  type: typeof CHANGE_SEARCH_FINDER_MAX_INDEX;
  totalPositions: number;
}

type SearchActions =
  | SearchRequestAction
  | SearchPageChangeAction
  | SearchPageChangeSuccessAction
  | SearchSuccessAction
  | SearchErrorAction
  | SearchClearAction
  | SaveLastChatMessageAction
  | ApplyFiltersAction
  | getSearchDocumentsTypesFiltersAction
  | getSearchKnowledgeSourcesFiltersAction
  | SearchFiltersClearAction
  | ChangeSearchFinderIndexAction
  | ChangeSearchFinderMaxIndexAction;

export const searchNew = (
  state: SearchState = defaultState,
  action: SearchActions
): SearchState => {
  switch (action.type) {
    case SEARCH_REQUEST:
      return {
        ...state,
        currentQuestion: action.payload.question,
        loading: true,
        queryMatches: {
          currentPosition: -1,
          totalPositions: 0
        }
      };
    case SEARCH_SUCCESS: {
      const {
        featuredSnippet,
        results: { content, ...results },
        suggestions,
        searchEventId
      } = action.payload;

      const groupedByDocumentId = groupSearchResultsByDocumentId(content);

      const combinedContent = mergeContentMarkingPositions(
        [],
        groupedByDocumentId
      );

      return {
        ...state,
        items: {
          featuredSnippet,
          results: {
            ...results,
            content: combinedContent
          },
          suggestions,
          searchEventId
        },
        page: results.number,
        loading: false,
        queryMatches: {
          currentPosition: -1,
          totalPositions: 0
        }
      };
    }
    case SEARCH_PAGE_CHANGE_SUCCESS:
      const {
        results: { content, numberOfElements, ...results }
      } = action.payload;

      const groupedByDocumentId = groupSearchResultsByDocumentId(content);

      const combinedContent = mergeContentMarkingPositions(
        state.items.results.content,
        groupedByDocumentId
      );

      return {
        ...state,
        items: {
          ...state.items,
          results: {
            ...results,
            content: combinedContent,
            numberOfElements:
              state.items.results.numberOfElements + numberOfElements
          }
        }
      };
    case SEARCH_PAGE_CHANGE:
      return {
        ...state,
        page: action.payload.page
      };
    case SEARCH_ERROR:
      return {
        ...defaultState,
        error: action.payload
      };
    case SEARCH_CLEAR:
      return {
        ...defaultState,
        filters: state.filters
      };
    case CHAT_LAST_MESSAGE:
      return {
        ...state,
        prevChatQuestion: action.payload
      };
    case APPLY_FILTERS:
      return {
        ...state,
        filtersUsed: action.payload,
        loading: true
      };
    case SEARCH_FILTERS_CLEAR:
      return {
        ...state,
        filtersUsed: defaultState.filtersUsed
      };
    case GET_SEARCH_DOCUMENTS_TYPES_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          documentType: action.payload
        }
      };
    case GET_SEARCH_KNOWLEDGE_SOURCES_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          knowledgeSource: action.payload
        }
      };
    case CHANGE_SEARCH_FINDER_INDEX:
      return {
        ...state,
        queryMatches: {
          ...state.queryMatches,
          currentPosition: Math.min(
            Math.max(action.position, -1),
            state.queryMatches.totalPositions - 1
          )
        }
      };
    case CHANGE_SEARCH_FINDER_MAX_INDEX:
      return {
        ...state,
        queryMatches: {
          ...state.queryMatches,
          totalPositions: action.totalPositions
        }
      };
    default:
      return state;
  }
};

function mergeContentMarkingPositions(
  existingContent: GroupedByDocumentType[],
  newContent: GroupedByDocumentType[]
) {
  const content = [...existingContent];
  let position: number;
  for (const groupedByDocument of newContent) {
    position = content.flatMap(item => item.content).length;
    content.push({
      ...groupedByDocument,
      content: [
        ...groupedByDocument.content.map((item, index) => ({
          ...item,
          position: position + index
        }))
      ],
      position
    });
  }
  return content;
}
