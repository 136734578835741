import { call, put, takeLatest } from "redux-saga/effects";
import { GET_ISSUES_REQUEST } from "~/actions/types";
import {
  getIssuesSuccess,
  getIssuesFailure,
  getIssues as getIssuesAction
} from "~/actions";
import * as api from "~/services/IssuesService";

function* getIssuesData(action: ReturnType<typeof getIssuesAction>) {
  const pageNumber = action.pageNumber ?? 0;
  const labels = action.labels ?? [];

  try {
    const issues = call(api.getIssues, pageNumber, labels);
    yield put(getIssuesSuccess(yield issues));
  } catch (error) {
    yield put(getIssuesFailure(error));
  }
}

export function* getIssues() {
  yield takeLatest(GET_ISSUES_REQUEST, getIssuesData);
}
