import React, { useCallback } from "react";
import { Confirm } from "semantic-ui-react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector, useThunkDispatch } from "~/configureStore";
import { deleteDocument, hideConfirmModal } from "~/actions";

import type { DeleteDocumentProps } from "~/reducers/modal";

interface DeleteDocumentModalProps {
  onCancel?: () => void;
}

const DeleteDocumentModal = ({ onCancel }: DeleteDocumentModalProps) => {
  const { t } = useTranslation();

  const { id } = useSelector(
    ({ modal: { modalProps } }) => modalProps
  ) as DeleteDocumentProps;

  const dispatch = useThunkDispatch();

  const deleteFile = useCallback(
    (id: number) => {
      dispatch(deleteDocument(id));

      // its important to leave this timeout for preview document modal to catch delete success action before modal state cleans up
      setTimeout(() => dispatch(hideConfirmModal()), 0);
    },
    [dispatch]
  );

  const cancel = useCallback(() => {
    dispatch(hideConfirmModal());

    if (typeof onCancel === "function") {
      onCancel();
    }
  }, [dispatch]);

  return (
    <Confirm
      basic
      size="small"
      content={
        <>
          <h3 style={{ color: "white" }}>
            {t("documents.modal.are_you_sure_deleting_this_document")}
          </h3>
          <p>
            <Trans i18nKey="documents.modal.caution_deleting_document_will_delete_all_related">
              <span style={{ color: "red" }}>Uwaga!</span> Usunięcie dokumentu
              spowoduje usunięcie WSZYSTKICH powiązanych z tym dokumentem wpisów
              w Bazie Wiedzy, także tych dodanych ręcznie przez użytkowników.
            </Trans>
          </p>
        </>
      }
      cancelButton={t("documents.modal.cancel")}
      confirmButton={t("documents.modal.delete")}
      open
      onCancel={cancel}
      onConfirm={() => deleteFile(id)}
    />
  );
};

export default DeleteDocumentModal;
