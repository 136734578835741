import classNames from "classnames";
import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { showConfirmModal } from "~/actions/ModalActions";
import Button from "~/atoms/Button/Button";
import Tooltip, { TooltipHandle } from "~/atoms/Tooltip/Tooltip";
import { useThunkDispatch } from "~/configureStore";
import { DOCUMENTS } from "~/constants/routes";
import { DocumentStatuses } from "~/services/api-types";

import styles from "./Footer.module.scss";

interface FooterProps {
  hasDraft: boolean;
  editMode: boolean;
  isSubmitting: boolean;
  isAllowedToPublish: boolean;
  hasChanged: boolean;
  articleStatus: DocumentStatuses | null;
  handleArticlePublish: () => void;
  handleDiscardDraft: () => Promise<void>;
}

export const Footer = ({
  hasDraft,
  handleArticlePublish,
  handleDiscardDraft,
  articleStatus,
  editMode,
  hasChanged,
  isSubmitting,
  isAllowedToPublish
}: FooterProps) => {
  const { t } = useTranslation();
  const tooltip = useRef<TooltipHandle>(null);

  const { replace } = useHistory();

  const dispatch = useThunkDispatch();

  const openConfirmModal = useCallback(() => {
    dispatch(
      showConfirmModal("LEAVE_ARTICLE_VIEW", {
        confirm: handleDiscardDraft,
        withDiscard: true
      })
    );
  }, [dispatch]);

  const handleMouseOver = useCallback(() => {
    if (tooltip.current) {
      tooltip.current.show();
    }
  }, [tooltip.current]);

  return (
    <div className={styles.buttonsWrapper}>
      {hasDraft && editMode && articleStatus !== DocumentStatuses.UNPUBLISHED && (
        <Button
          className={classNames(styles.cancelButton, styles.discard)}
          onClick={openConfirmModal}
        >
          {t("documents.new_article_footer_discard_button")}
        </Button>
      )}
      <Tooltip
        ref={tooltip}
        light
        size="small"
        tip={!isAllowedToPublish ? t("documents.new_article_save_tip") : ""}
      >
        <Button
          onMouseOver={handleMouseOver}
          className={styles.saveButton}
          onClick={handleArticlePublish}
          disabled={
            isSubmitting || !hasDraft || !isAllowedToPublish || hasChanged
          }
        >
          {t("documents.new_article_footer_publish_button")}
        </Button>
      </Tooltip>
      <Button
        className={classNames(
          styles.cancelButton,
          editMode && styles.withDiscard
        )}
        disabled={isSubmitting || hasChanged}
        onClick={() => replace(DOCUMENTS)}
      >
        {t("documents.new_article_footer_cancel_button")}
      </Button>
    </div>
  );
};
