import React from "react";
import PropTypes from "prop-types";
import Button from "~/components/atoms/Button/Button";
import UserMessage from "~/components/UserMessage";
import UserComment from "~/components/UserComment";
import { withTranslation } from "react-i18next";

import styles from "./EditAnswer.module.scss";
import EditableBotMessage from "~/components/EditableBotMessage";
import Wrapper from "~/components/atoms/Wrapper/Wrapper";
import { TRAIN_FRAGMENT_EDIT } from "~/constants/routes";
import { WithMixpanelContext } from "~/contexts/mixpanel";
import { MIXPANEL_EVENTS } from "~/contexts/mixpanel/event-types";

export function suggestionQueriesCombined(suggestions) {
  if (!suggestions || !suggestions.length) {
    return false;
  }
  let questions = suggestions.map(suggestion =>
    suggestion.query.substring(0, suggestion.query.indexOf(suggestion.text))
  );
  return questions.every((val, i, arr) => val && val === arr[0]);
}

class EditAnswerComponent extends React.Component {
  constructor(props) {
    super(props);
    const { pathname } = props.location;

    if (pathname === TRAIN_FRAGMENT_EDIT) {
      this.state = {
        pinToolbar: false,
        answer: {
          ...props.answer,
          combinedSuggestionQuery: suggestionQueriesCombined(
            props.answer.suggestions
          ),
          documentId: props.answer.document && props.answer.document.id
        },
        editAnswer: true
      };
    } else {
      this.state = {
        pinToolbar: false,
        answer: props.answer?.createNew
          ? {
              text: props.answer.answer,
              suggestions: props.answer.suggestions
            }
          : {},
        editAnswer: false
      };
    }
  }

  componentDidMount() {
    const pageElement = document.getElementsByClassName("scrollCatcher")[0];
    pageElement.addEventListener("scroll", this.pageScroll);

    window.scrollTo({ top: 0 });

    // eslint-disable-next-line
    this.props.expandContainer?.(true);
  }

  componentWillUnmount() {
    const pageElement = document.getElementsByClassName("scrollCatcher")[0];
    pageElement.removeEventListener("scroll", this.pageScroll);
    this.props.clearState();

    // eslint-disable-next-line
    this.props.expandContainer?.(false);
  }

  pageScroll = event => {
    const distanceScrolled = event.target.scrollTop;
    const RTEScrollCatcherOffset =
      document.getElementsByClassName("RTEScrollCatcher")[0].offsetTop;

    if (distanceScrolled - RTEScrollCatcherOffset > -12) {
      // -12 is sweet spot offset from the RTEScrollCatcherOffset
      // so the transision is smooth
      this.setState({ pinToolbar: true });
    } else {
      this.setState({ pinToolbar: false });
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  save = () => {
    if (!this.checkHTMLLength(this.state.answer.text)) {
      this.props.customError(
        this.props.t("edit_answer.answer_field_cannot_be_empty")
      );
      return;
    }
    if (this.props.isEditingChange) {
      this.props.updateChange(
        this.state.answer,
        this.props.history.goBack,
        this.props.answer?.changeByEdit ?? false,
        this.props.answer?.issueId ?? null
      );
      this.props.trackEvent(MIXPANEL_EVENTS.FRAGMENT_EDIT);
    } else {
      // Go back 2 times, in result going back to either chat or issues list
      // pass to actions due to async functionality
      this.props.saveAnswer(
        this.state.answer,
        this.props.history.goBack,
        this.props.answer?.createNew ?? false,
        this.props.answer?.issueId ?? null
      );
      this.props.trackEvent(MIXPANEL_EVENTS.FRAGMENT_ADD);
    }
  };

  renderContent = () => {
    const message = this.state.answer;
    return (
      <>
        <h4 className={styles.editAdd}>
          {this.state.editAnswer
            ? this.props.t("edit_answer")
            : this.props.t("edit_answer.add_new_answer")}
        </h4>
        <EditableBotMessage
          pinToolbar={this.state.pinToolbar}
          disableUserInfo={true}
          text={message.text}
          suggestions={message.suggestions}
          combinedSuggestionQuery={this.state.answer.combinedSuggestionQuery}
          handleCombinedSuggestionQueryChange={
            this.handleCombinedSuggestionQueryChange
          }
          documentId={message.document && message.document.id}
          handleTextChange={this.handleTextChange}
          handleSuggestionsChange={this.handleSuggestionsChange}
          handleDocumentsChange={this.handleDocumentsChange}
        />
      </>
    );
  };

  render() {
    return (
      <>
        <Wrapper
          className={styles.window}
          title={
            <span className={styles.editHeader}>
              {this.state.editAnswer
                ? this.props.t("edit_answer.edit_fragment")
                : this.props.t("edit_answer.add_new_fragment")}
            </span>
          }
        >
          <div className={`${styles.view} scrollCatcher`}>
            {this.props.comment && <UserComment comment={this.props.comment} />}
            {this.props.question && (
              <>
                <h4 className={styles.question}>
                  {this.props.t("edit_answer.question")}
                </h4>
                <UserMessage
                  additionalBubbleClass={styles.editViewBubble}
                  additionalFlexClass={styles.editViewFlex}
                  additionalBlockClass={styles.editViewBlock}
                  noUserName={true}
                  primaryAnswer
                  noUserAvatar
                  text={this.props.question}
                  editMode
                />
              </>
            )}
            {this.renderContent()}
          </div>
        </Wrapper>
        <div className={styles.submitButtons}>
          <Button className={styles.submitButtonsBack} onClick={this.goBack}>
            {this.props.t("edit_answer.back")}
          </Button>
          <Button
            secondary
            className={styles.submitButtonsSave}
            onClick={this.save}
          >
            {this.props.t("edit_answer.save")}
          </Button>
        </div>
      </>
    );
  }

  mapDataFromComponent = data => {
    if (!data || data.length === 0) return [];

    const isCombined = this.state.answer.combinedSuggestionQuery;
    const { question } = this.props;

    return data.map(({ text, featured }) => ({
      text,
      query: isCombined ? `${question} ${text}` : text,
      featured
    }));
  };

  checkHTMLLength = html => {
    if (!html) return 0;
    const el = document.createElement("html");
    el.innerHTML = html.replace(/\s/g, "");
    return el.innerText.length;
  };

  handleTextChange = value => {
    this.setState({
      answer: {
        ...this.state.answer,
        text: value
      }
    });
  };

  handleSuggestionsChange = newSuggestions => {
    const suggestions = this.mapDataFromComponent(newSuggestions);

    this.setState({
      answer: {
        ...this.state.answer,
        suggestions
      }
    });
  };

  handleDocumentsChange = documentId => {
    this.setState({
      answer: {
        ...this.state.answer,
        documentId: documentId
      }
    });
  };

  handleCombinedSuggestionQueryChange = () => {
    let newSuggestions;
    if (!this.state.answer.suggestions) {
      return;
    }
    if (!this.state.answer.combinedSuggestionQuery) {
      newSuggestions = this.state.answer.suggestions.map(suggestion => {
        suggestion.query = this.props.question + " " + suggestion.text;
        return suggestion;
      });
    } else {
      newSuggestions = this.state.answer.suggestions.map(suggestion => {
        suggestion.query = suggestion.text;
        return suggestion;
      });
    }
    this.setState({
      answer: {
        ...this.state.answer,
        suggestions: newSuggestions,
        combinedSuggestionQuery: !this.state.answer.combinedSuggestionQuery
      }
    });
  };
}

EditAnswerComponent.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  updateChange: PropTypes.func,
  saveAnswer: PropTypes.func,
  clearState: PropTypes.func,
  customError: PropTypes.func,
  isEditingChange: PropTypes.bool,
  comment: PropTypes.string,
  question: PropTypes.string.isRequired,
  answer: PropTypes.shape({
    text: PropTypes.string.isRequired,
    document: PropTypes.object,
    suggestions: PropTypes.arrayOf(PropTypes.object),
    answer: PropTypes.string,
    changeByEdit: PropTypes.bool,
    issueId: PropTypes.string,
    createNew: PropTypes.bool
  }).isRequired,
  t: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired
};

EditAnswerComponent.defaultProps = {
  answer: {
    text: ""
  }
};

export default withTranslation()(WithMixpanelContext()(EditAnswerComponent));
