import React from "react";

import { ReactComponent as DocIcon } from "~/assets/file-icons/doc.svg";
import { ReactComponent as ImgIcon } from "~/assets/file-icons/img.svg";
import { ReactComponent as PdfIcon } from "~/assets/file-icons/pdf.svg";
import { ReactComponent as PptIcon } from "~/assets/file-icons/ppt.svg";
import { ReactComponent as TxtIcon } from "~/assets/file-icons/txt.svg";
import { ReactComponent as XlsIcon } from "~/assets/file-icons/xls.svg";
import { ReactComponent as FileIcon } from "~/assets/file-icons/txt.svg";
import { ReactComponent as HTMLIcon } from "~/assets/file-icons/html.svg";

import { ReactComponent as FolderIcon } from "~/assets/folder 2 copy.svg";
import { ReactComponent as ConfluenceIcon } from "~/assets/icons/confluence.svg";
import { ReactComponent as SharepointIcon } from "~/assets/icons/sharepoint.svg";
import { ReactComponent as NextcloudIcon } from "~/assets/icons/nextcloud.svg";
import { ReactComponent as ArticleIcon } from "~/assets/file-icons/wiki.svg";
import { getMimeType } from "~/constants/mime-types";

export enum FormatType {
  IMG = "img",
  DOC = "doc",
  PDF = "pdf",
  PPT = "ppt",
  TXT = "txt",
  XLS = "xls",
  HTML = "html",
  UNKNOWN = "unknown",
  article = "article"
}

const extensionIconMap: { [key in FormatType]: React.ReactElement } = {
  doc: <DocIcon />,
  img: <ImgIcon />,
  pdf: <PdfIcon />,
  ppt: <PptIcon />,
  txt: <TxtIcon />,
  xls: <XlsIcon />,
  html: <HTMLIcon />,
  unknown: <FileIcon />,
  article: <ArticleIcon />
};

const folderIconMap = {
  confluence: <ConfluenceIcon />,
  sharepoint: <SharepointIcon />,
  nextcloud: <NextcloudIcon />,
  default: <FolderIcon />
};

export const formatsByType = {
  [FormatType.IMG]: ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp"],
  [FormatType.DOC]: ["doc", "docx", "rtf"],
  [FormatType.PDF]: ["pdf"],
  [FormatType.PPT]: ["ppt", "pptx"],
  [FormatType.TXT]: ["txt"],
  [FormatType.XLS]: ["xls", "xlsx", "csv"],
  [FormatType.HTML]: ["html"]
};

export const availableFormats: { [format: string]: FormatType } =
  Object.entries(formatsByType).reduce(
    (acc: { [format: string]: FormatType }, [type, formats]) => ({
      ...acc,
      ...formats.reduce((acc, format) => ({ ...acc, [format]: type }), {})
    }),
    {}
  );

const findProperFileExtension = (extension: string): FormatType => {
  if (!extension) return FormatType.UNKNOWN;

  extension = extension.toLowerCase();

  if (extension in availableFormats) {
    return availableFormats[extension];
  }

  return FormatType.UNKNOWN;
};

export const getFileIconByFormat = (format: FormatType): React.ReactElement => {
  return extensionIconMap[format];
};

export const getFileIcon = (extension: string, isArticle: boolean = false) => {
  const format = findProperFileExtension(extension);

  return !isArticle ? extensionIconMap[format] : extensionIconMap.article;
};

export const getFolderIcon = (folderName: string): React.ReactElement => {
  if (!folderName) return folderIconMap.default;

  folderName = folderName.toLowerCase();

  if (folderName in folderIconMap)
    return folderIconMap[folderName as keyof typeof folderIconMap];

  return folderIconMap.default;
};

export const getFilenames = (files: File[]) =>
  [...files].map(file => file.name);

export const getFilteredFiles = (files: File[]) =>
  [...files].filter(file => {
    console.log(availableFormats);
    return Object.keys(availableFormats).includes(getMimeType(file.type));
  });
