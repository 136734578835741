import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback
} from "react";
import { connect } from "react-redux";
import { Document, Page, PageProps } from "react-pdf";
import { Button } from "semantic-ui-react";
import { buildFileUrl } from "~/actions";
import { AppThunkDispatch } from "~/configureStore";
import LoaderInline from "../../LoaderInline/LoaderInline";
import { useTranslation } from "react-i18next";
import { captureException } from "@sentry/browser";
import classNames from "classnames";

import { ReactComponent as ZoomIn } from "~/assets/zoomInIcon.svg";
import { ReactComponent as ZoomOut } from "~/assets/zoomOutIcon.svg";

const SCALE_POSITIVE_VALUE = 0.1;
const SCALE_NEGATIVE_VALUE = -0.1;
const MAX_PAGES_SINGLE_RENDER = 10;

const PageContainer = ({
  setPageHeight,
  ...rest
}: {
  setPageHeight: (height: number) => void;
  onRenderSuccess: () => void;
  scale: number;
} & PageProps) => {
  const pageRef = useRef<HTMLDivElement>(null);

  const prevScale = useRef<number>(rest.scale);

  const handlePageRender = () => {
    if (rest.pageNumber === 1) {
      setPageHeight(pageRef.current?.clientHeight ?? 0);
    }

    return rest.onRenderSuccess();
  };

  useEffect(() => {
    if (prevScale.current !== rest.scale) {
      setPageHeight(pageRef.current?.clientHeight ?? 0);
      prevScale.current = rest.scale;
    }
  }, [rest.scale]);

  return (
    <div ref={pageRef}>
      <Page
        {...rest}
        onRenderSuccess={handlePageRender}
        loading={<LoaderInline isFetching={true} />}
      />
    </div>
  );
};

interface PdfViewerProps extends ConnectedProps {
  id: number;
  className?: string;
}

const getPagesArray = (pagesCount: number) =>
  new Array(pagesCount).fill("").map((_, index) => index + 1);

const PdfViewer = ({ buildFileUrl, id, className }: PdfViewerProps) => {
  const [fileUrl, setFileUrl] = useState<string>("");
  const [numPages, setNumPages] = useState<number>(0);
  const [scale, setScale] = useState(1);
  const [clientWidth, setClientWidth] = useState(0);
  const [pageHeight, setPageHeight] = useState(0);
  const [renderedPages, setRenderedPages] = useState(0);
  const [pagesToRender, setPagesToRender] = useState<number[]>([]);
  const [fetchingPages, setFetchingPages] = useState(false);
  const [error, setError] = useState("");

  const handleSetPageHeight = (height: number) => setPageHeight(height);

  const documentRef = useRef<HTMLDivElement>(null);
  const pdfContainerRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  useEffect(() => {
    const getFile = async () => {
      setFetchingPages(true);
      setError("");

      try {
        const url = await buildFileUrl(id);
        return setFileUrl(url);
      } catch (err) {
        captureException(err);
        setError("pdf_viewer.an_error_occured_try_again_later");
      } finally {
        setFetchingPages(false);
      }
    };

    getFile();
  }, [buildFileUrl, id]);

  useEffect(() => {
    if (documentRef.current) {
      setClientWidth(documentRef.current.clientWidth);
    }
  }, []);

  const onDocumentLoadSuccess: Document["props"]["onLoadSuccess"] = ({
    numPages
  }) => {
    setRenderedPages(0);

    if (numPages > MAX_PAGES_SINGLE_RENDER) {
      setPagesToRender(getPagesArray(MAX_PAGES_SINGLE_RENDER));
    } else {
      setPagesToRender(getPagesArray(numPages));
    }

    return setNumPages(numPages);
  };

  const changeScale = (scale: number) =>
    setScale(prevScale => {
      const value = prevScale + scale;
      if (value > 0.1 && value < 2.1) {
        return value;
      }
      return prevScale;
    });

  const loadingPages = useMemo(
    () => pagesToRender.length !== renderedPages,
    [pagesToRender.length, renderedPages]
  );

  const handleScroll = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const { scrollTop } = e.currentTarget;

      const currentlyOnPage = Math.ceil(scrollTop / pageHeight);

      if (currentlyOnPage - 5 === pagesToRender.length - 5 && !loadingPages) {
        return setPagesToRender(oldPages => {
          const newPages = oldPages.length + 10;

          if (newPages >= numPages) {
            return getPagesArray(numPages);
          }

          return getPagesArray(newPages);
        });
      }

      return;
    },
    [numPages, pageHeight, loadingPages]
  );

  return (
    <div
      className={classNames("pdf-component", className)}
      onScroll={handleScroll}
    >
      <div className="pdf-container" ref={pdfContainerRef}>
        <div
          className="document"
          style={
            pageHeight > 0 && numPages > 10
              ? { height: numPages * pageHeight }
              : {}
          }
          ref={documentRef}
        >
          <Document
            file={fileUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={
              <LoaderInline
                className="pdf-container-loading"
                isFetching={true}
              />
            }
            noData={
              fetchingPages ? (
                <LoaderInline
                  className="pdf-container-loading"
                  isFetching={fetchingPages}
                />
              ) : (
                <p className="pdf-container-message">
                  {error ? t(error) : t("pdf_viewer.requested_file_is_empty")}
                </p>
              )
            }
          >
            {pagesToRender.length === 0 && <LoaderInline isFetching={true} />}
            {pagesToRender.map(pageNumber => (
              <PageContainer
                key={pageNumber}
                className="pdf-page"
                scale={scale}
                pageNumber={pageNumber}
                width={clientWidth}
                setPageHeight={handleSetPageHeight}
                onRenderSuccess={() =>
                  setRenderedPages(previousCount => previousCount + 1)
                }
              />
            ))}
          </Document>
        </div>
        <Button.Group className="zoom-group">
          <ZoomOut
            className="zoom-out"
            onClick={() => changeScale(SCALE_NEGATIVE_VALUE)}
          />
          <ZoomIn
            className="zoom-in"
            onClick={() => changeScale(SCALE_POSITIVE_VALUE)}
          />
        </Button.Group>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  buildFileUrl: (id: number) => dispatch(buildFileUrl(id))
});

type ConnectedProps = ReturnType<typeof mapDispatchToProps>;

export default connect(null, mapDispatchToProps)(PdfViewer);
