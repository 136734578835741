import { useEffect, useLayoutEffect } from "react";
import { useOnQueryMatchInside } from "./useOnQueryMatchInside";

export const useQueryMatchScroll = (
  alreadyExpanded: boolean,
  expand: () => void,
  scrollToMatch: () => void,
  rowRef: HTMLDivElement | null
) => {
  const queryMatchInside = useOnQueryMatchInside(rowRef);

  useEffect(() => {
    if (queryMatchInside() && !alreadyExpanded) {
      expand();
    }
  }, [queryMatchInside]);

  useLayoutEffect(() => {
    if (queryMatchInside() && alreadyExpanded) {
      scrollToMatch?.();
    }
  }, [queryMatchInside, alreadyExpanded, scrollToMatch]);
};
