import React from "react";
import PropTypes from "prop-types";

import styles from "./LinkButton.module.scss";

// used to replace <a> tag without 'href' parameter

const LinkButton = props => {
  const { className, ...otherProps } = props;
  // combine original className with the one provided in props
  return (
    <>
      {props.img}
      <button
        type="button"
        className={styles.button + " " + className}
        {...otherProps}
      ></button>
    </>
  );
};

LinkButton.propTypes = {
  className: PropTypes.string,
  img: PropTypes.node
};

export default LinkButton;
