export const ADD_MESSAGE = "ADD_MESSAGE";

export const EXPAND_CONTAINER = "EXPAND_CONTAINER";

export const RELATED_OPENED = "RELATED_OPENED";
export const SAVE_RELATED_MESSAGE = "SAVE_RELATED_MESSAGE";
export const CLEAR_RELATED_MESSAGE = "CLEAR_RELATED_MESSAGE";

export const BOT_MESSAGE_REQUEST = "BOT_MESSAGE_REQUEST";
export const BOT_MESSAGE_SUCCESS = "BOT_MESSAGE_SUCCESS";
export const BOT_MESSAGE_FAILURE = "BOT_MESSAGE_FAILURE";

export const GET_QUERY_SUGGESTIONS = "GET_QUERY_SUGGESTIONS";
export const GET_QUERY_SUGGESTIONS_SUCCESS = "GET_QUERY_SUGGESTIONS_SUCCESS";
export const GET_QUERY_SUGGESTIONS_FAILURE = "GET_QUERY_SUGGESTIONS_FAILURE";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const FETCH_ANSWER_REQUEST = "FETCH_ANSWER_REQUEST";
export const FETCH_ANSWER_SUCCESS = "FETCH_ANSWER_SUCCESS";
export const FETCH_ANSWER_FAILURE = "FETCH_ANSWER_FAILURE";
export const CLEAR_ANSWER = "CLEAR_ANSWER";
export const FETCH_SEARCH_REQUEST = "FETCH_SEARCH_REQUEST";
export const FETCH_SEARCH_SUCCESS = "FETCH_SEARCH_SUCCESS";
export const FETCH_SEARCH_FAILURE = "FETCH_SEARCH_FAILURE";
export const SET_DOCUMENTS_LOCATION = "SET_DOCUMENTS_LOCATION";
export const SAVE_SEARCH_RESULTS = "SAVE_SEARCH_RESULTS";

export const CHANGE_SEARCH_FINDER_INDEX = "CHANGE_SEARCH_FINDER_INDEX";
export const CHANGE_SEARCH_FINDER_MAX_INDEX = "CHANGE_SEARCH_FINDER_MAX_INDEX";

export const SAVE_ANSWER_FAILURE = "SAVE_ANSWER_FAILURE";

export const CHANGE_CHOSEN_ANSWER = "CHANGE_CHOSEN_ANSWER";

export const CHANGE_UPDATE = "CHANGE_UPDATE";

export const CLEAR_LOGIN_STATE = "CLEAR_LOGIN_STATE";
export const CLEAR_MESSAGES_STATE = "CLEAR_MESSAGES_STATE";
export const CLEAR_AUTOCOMPLETE_STATE = "CLEAR_AUTOCOMPLETE_STATE";
export const CLEAR_EDITOR_STATE = "CLEAR_EDITOR_STATE";
export const CLEAR_ISSUES_STATE = "CLEAR_ISSUES_STATE";
export const CLEAR_DOCUMENTS_STATE = "CLEAR_DOCUMENTS_STATE";
export const CLEAR_STATS_STATE = "CLEAR_STATS_STATE";
export const CLEAR_CHANGES_STATE = "CLEAR_CHANGES_STATE";
export const CLEAR_USERS_STATE = "CLEAR_USERS_STATE";
export const CLEAR_PROJECTS_STATE = "CLEAR_PROJECTS_STATE";

export const UPLOAD_FILE = "UPLOAD_FILE";
export const UPDATE_FILE = "UPDATE_FILE";
export const UPLOAD_FROM_URL = "UPLOAD_FROM_URL";
export const UPDATE_WEB_DOCUMENT = "UPDATE_WEB_DOCUMENT";

export const LIST_DOCUMENTS = "LIST_DOCUMENTS";
export const LIST_DOCUMENTS_SUCCESS = "LIST_DOCUMENTS_SUCCESS";
export const CURRENT_FOLDER = "CURRENT_FOLDER";

// Reports
export const GET_MESSAGES_REPORT_REQUEST = "GET_MESSAGES_REPORT_REQUEST";
export const GET_MESSAGES_REPORT_SUCCESS = "GET_MESSAGES_REPORT_SUCCESS";
export const GET_MESSAGES_REPORT_FAILURE = "GET_MESSAGES_REPORT_FAILURE";

export const GET_STATS_SUCCESS = "GET_STATS_SUCCESS";
export const CHANGE_CHART_TYPE = "CHANGE_CHART_TYPE";
export const CHANGE_TIME_RANGE = "CHANGE_TIME_RANGE";
export const GET_DATASET_SUCCESS = "GET_DATASET_SUCCESS";
export const REMOVE_DATASET = "REMOVE_DATASET";

// MODAL TYPES
export const MODAL_TYPE_INVITE_USER = "INVITE_USER";
export const MODAL_TYPE_DELETE_USER = "DELETE_USER";
export const MODAL_TYPE_UPDATE_USER = "UPDATE_USER";

export const SHOW_CONFIRM_MODAL = "SHOW_CONFIRM_MODAL";
export const HIDE_CONFIRM_MODAL = "HIDE_CONFIRM_MODAL";

// users.js
export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";
export const CLOSE_USERS_TAB = "CLOSE_USERS_TAB";
export const CHANGE_USERS_TAB = "CHANGE_USERS_TAB";

export const INVITE_USER_REQUEST = "INVITE_USER_REQUEST";
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";
export const INVITE_USER_FAILURE = "INVITE_USER_FAILURE";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const REMOVE_USER = "REMOVE_USER";
export const REMOVE_USER_SUCCESS = "REMOVE_USER_SUCCESS";

export const GET_USERS_BY_NAME_REQUEST = "GET_USERS_BY_NAME_REQUEST";
export const GET_USERS_BY_NAME_SUCCESS = "GET_USERS_BY_NAME_SUCCESS";

// issues.js
export const GET_ISSUES_REQUEST = "GET_ISSUES_REQUEST";
export const GET_ISSUES_SUCCESS = "GET_ISSUES_SUCCESS";
export const GET_ISSUES_FAILURE = "GET_ISSUES_FAILURE";
export const GET_SINGLE_ISSUE_REQUEST = "GET_SINGLE_ISSUE_REQUEST";
export const GET_SINGLE_ISSUE_SUCCESS = "GET_SINGLE_ISSUE_SUCCESS";
export const GET_SINGLE_ISSUE_FAILURE = "GET_SINGLE_ISSUE_FAILURE";

export const SET_ISSUE_INDEX = "SET_ISSUE_INDEX";
export const RESET_ISSUE_INDEX = "RESET_ISSUE_INDEX";

export const SET_ISSUES_LABELS = "SET_ISSUES_LABELS";

// documents.js
export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS";
export const GET_DOCUMENTS_FAILURE = "GET_DOCUMENTS_FAILURE";
export const GET_FOLDERS = "GET_FOLDERS";
export const GET_FOLDERS_SUCCESS = "GET_FOLDERS_SUCCESS";
export const GET_MOVE_FOLDERS_PATH_SUCCESS = "GET_MOVE_FOLDERS_PATH_SUCCESS";
export const GET_FOLDERS_FAILURE = "GET_FOLDERS_FAILURE";
export const ADD_FOLDER = "ADD_FOLDER";
export const TOGGLE_DOCUMENT = "TOGGLE_DOCUMENT";
export const TOGGLE_DOCUMENT_SUCCESS = "TOGGLE_DOCUMENT_SUCCESS";
export const TOGGLE_DOCUMENT_FAILURE = "TOGGLE_DOCUMENT_FAILURE";
export const UPDATE_FOLDER = "UPDATE_FOLDER";
export const NAME_DOCUMENT = "NAME_DOCUMENT";
export const GET_FOLDERS_PATH = "GET_FOLDERS_PATH";
export const GET_FOLDERS_PATH_SUCCESS = "GET_FOLDERS_PATH_SUCCESS";
export const DELETE_DOCUMENT_SUCCESS = "DELETE_DOCUMENT_SUCCESS";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const UPDATE_FOLDERS_PATH = "UPDATE_FOLDERS_PATH";

// changes.js
export const GET_CHANGES_REQUEST = "GET_CHANGES_REQUEST";
export const GET_CHANGES_SUCCESS = "GET_CHANGES_SUCCESS";
export const GET_CHANGES_FAILURE = "GET_CHANGES_FAILURE";
export const EDIT_CHANGE = "EDIT_CHANGE";
export const DELETE_CHANGES_SUCCESS = "DELETE_CHANGES_SUCCESS";
export const DELETE_CHANGES_REQUEST = "DELETE_CHANGES_REQUEST";
export const COPY_CHANGES_PARAMS = "COPY_CHANGES_PARAMS";
export const SET_LOCATION = "SET_LOCATION";

// projects.js
export const GET_PROJECTS_REQUEST = "GET_PROJECTS_REQUEST";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_FAILURE = "GET_PROJECTS_FAILURE";

// utils actions, not fitting other categories

// custom (not backend) error
export const CUSTOM_ERROR = "CUSTOM_ERROR";

// dummy request, just to manipulate `isFetching`
export const DUMMY_REQUEST = "DUMMY_REQUEST";
export const DUMMY_SUCCESS = "DUMMY_SUCCESS";

// synonyms

export const GET_SYNONYMS = "GET_SYNONYMS";
export const ADD_SYNONYMS = "ADD_SYNONYMS";
export const DELETE_SYNONYM = "DELETE_SYNONYM";
export const UPDATE_SYNONYM = "UPDATE_SYNONYM";

export const SEARCH_REQUEST = "SEARCH_REQUEST";
export const SEARCH_PAGE_CHANGE = "SEARCH_PAGE_CHANGE";
export const SEARCH_PAGE_CHANGE_SUCCESS = "SEARCH_PAGE_CHANGE_SUCCESS";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_ERROR = "SEARCH_ERROR";
export const SEARCH_CLEAR = "SEARCH_CLEAR";
export const CHAT_LAST_MESSAGE = "CHAT_LAST_MESSAGE";
export const SEARCH_FILTERS_CLEAR = "SEARCH_FILTERS_CLEAR";
export const APPLY_FILTERS = "APPLY_FILTERS";
export const GET_SEARCH_DOCUMENTS_TYPES_FILTERS =
  "GET_SEARCH_DOCUMENTS_TYPES_FILTERS";
export const GET_SEARCH_KNOWLEDGE_SOURCES_FILTERS =
  "GET_SEARCH_KNOWLEDGE_SOURCES_FILTERS";

// STATISTICS ACTIVITY

export const USERS_ACTIVITY_REQUEST = "USERS_ACTIVITY_REQUEST";
export const USERS_ACTIVITY_ERROR = "USERS_ACTIVITY_ERROR";
export const GET_USERS_ACTIVITY_SUCCESS = "GET_USERS_ACTIVITY_SUCCESS";
export const GET_USERS_ACTIVITY_SEARCH_SUCCESS =
  "GET_USERS_ACTIVITY_SEARCH_SUCCESS";
export const GET_USERS_ACTIVITY_NEW_PAGE_SUCCESS =
  "GET_USERS_ACTIVITY_NEW_PAGE_SUCCESS";
export const APPLY_USERS_ACTIVITY_FILTERS = "APPLY_USERS_ACTIVITY_FILTERS";
export const USERS_ACTIVITY_SEARCH = "USERS_ACTIVITY_SEARCH";
export const USERS_ACTIVITY_PAGE_CHANGE = "USERS_ACTIVITY_PAGE_CHANGE";
export const CLEAR_EVENTS_QUEUE = "CLEAR_EVENTS_QUEUE";
export const PUBLISH_EVENTS_FAILURE = "PUBLISH_EVENTS_FAILURE";
export const TRACK_EVENT = "TRACK_EVENT";

export const IS_USER_ACTIVE = "IS_USER_ACTIVE";
