import React, { useCallback, useEffect, useRef } from "react";
import { shallowEqual } from "react-redux";
import { debounce } from "lodash";
import classnames from "classnames";
import TableHeadItem from "./TableHeadItem";
import TableRowItem from "./TableRowItem";
import LoaderInline from "~/components/LoaderInline/LoaderInline";
import { listLoadingOptions } from "~/constants/app";
import { useSelector } from "~/configureStore";

import styles from "./Table.module.scss";

const BOTTOM_PADDING_OFFSET = 32;

type ColSizes =
  | "col-1"
  | "col-2"
  | "col-3"
  | "col-4"
  | "col-5"
  | "col-6"
  | "col-7"
  | "col-8"
  | "col-9"
  | "col-10"
  | "col-11"
  | "col-12";

export interface TableHeadItemProps {
  title: string;
  size: ColSizes;
  sortable?: boolean;
  active?: boolean;
  handleSortAction?: () => void;
}

export interface TableRowItemProps {
  content: JSX.Element | string | number | null;
  size: ColSizes;
  rowItemClassname?: string;
  onRowItemClick?: () => void;
  id: string;
}

export interface RowItemsProps {
  contents: TableRowItemProps[];
  rowClassname?: string;
}

interface TableProps {
  className?: string;
  scrollContainerClass?: string;
  tableHead: TableHeadItemProps[];
  items: RowItemsProps[];
  withLoading?: boolean;
  loadingFunc?: (page: number) => void;
  currentPage?: number;
  totalPages?: number;
  renderTrigger?: boolean;
}

const Table = ({
  className = "",
  tableHead,
  items,
  withLoading = false,
  loadingFunc = () => {},
  currentPage = 0,
  totalPages = 0,
  renderTrigger,
  scrollContainerClass
}: TableProps) => {
  const isFetching = useSelector(
    ({ fetching: { isFetching } }) => isFetching,
    shallowEqual
  );

  const resultsOffset = useRef<number>(0);

  const rowsContainer = useRef<HTMLDivElement>(null);
  const scrollContainer = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);
  const tableHeadRef = useRef<HTMLDivElement>(null);

  const calculateResultsHeight = useCallback(() => {
    if (tableRef.current && tableHeadRef.current) {
      const offset = tableRef.current.offsetTop;
      const headHeight = tableHeadRef.current.offsetHeight;

      if (offset !== resultsOffset.current) {
        resultsOffset.current = offset + headHeight + BOTTOM_PADDING_OFFSET;
      }
    }
  }, [renderTrigger]);

  useEffect(() => {
    calculateResultsHeight();

    document.addEventListener("resize", debounce(calculateResultsHeight, 200));

    return () => {
      document.removeEventListener(
        "resize",
        debounce(calculateResultsHeight, 200)
      );
    };
  }, [calculateResultsHeight]);

  const loadItems = useCallback(
    debounce(e => {
      const { scrollTop, scrollHeight, offsetHeight } = e.target;

      if (
        scrollTop >
          scrollHeight - offsetHeight - listLoadingOptions.offsetBottom &&
        withLoading &&
        totalPages !== currentPage + 1
      ) {
        loadingFunc(currentPage + 1);
      }
    }, listLoadingOptions.debounceTime),
    [loadingFunc, withLoading, currentPage, totalPages]
  );

  useEffect(() => {
    if (!isFetching && currentPage === 0 && scrollContainer.current) {
      scrollContainer.current.scrollTo({ top: 0 });
    }
  }, [currentPage, isFetching]);

  useEffect(() => {
    const scrollContainerEl = scrollContainer.current;

    if (!!withLoading && scrollContainerEl) {
      scrollContainerEl.addEventListener("scroll", loadItems);

      return () => scrollContainerEl.removeEventListener("scroll", loadItems);
    }
  }, [loadItems, withLoading]);

  return (
    <div className={classnames(styles.table, className)} ref={tableRef}>
      <div className={styles.tableHead} ref={tableHeadRef}>
        {tableHead.map(
          ({ title, sortable, handleSortAction, size, active }, index) => (
            <TableHeadItem
              key={title + size + index}
              title={title}
              sortable={sortable}
              handleSortAction={handleSortAction}
              size={size}
              active={active}
            />
          )
        )}
      </div>
      <div
        className={classnames(
          styles.tableScrollContainer,
          scrollContainerClass
        )}
        style={{ height: `calc(100vh - ${resultsOffset.current}px)` }}
        ref={scrollContainer}
      >
        <div className={styles.tableRows} ref={rowsContainer}>
          {items.map(({ contents, rowClassname }, index) => (
            <div
              className={classnames(styles.tableRowsRow, rowClassname)}
              key={index}
            >
              {contents.map(
                ({ size, content, id, onRowItemClick, rowItemClassname }) => (
                  <TableRowItem
                    key={id}
                    content={content}
                    size={size}
                    rowItemClassname={rowItemClassname}
                    onClick={onRowItemClick}
                  />
                )
              )}
            </div>
          ))}
          {isFetching && withLoading && (
            <TableRowItem
              key="loading"
              content={<LoaderInline isFetching={isFetching} />}
              size="col-12"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Table;
