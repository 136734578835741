import { AppThunkDispatch, StoreState } from "~/configureStore";
import { HIGHLIGHTED_SPAN_SELECTOR } from "~/hooks/scroll/scrollToQueryMatch";
import type {
  SearchRequestAction,
  SearchErrorAction,
  SearchSuccessAction,
  SearchClearAction,
  SaveLastChatMessageAction,
  SearchPageChangeAction,
  SearchPageChangeSuccessAction,
  AppliedSearchFilters,
  ApplyFiltersAction,
  ChangeSearchFinderIndexAction,
  ChangeSearchFinderMaxIndexAction
} from "~/reducers/search-new";
import { SearchResponseType } from "~/services/api-types";
import {
  getSearchDocumentsTypesFilters,
  getSearchKnowledgeSourcesFilters,
  searchApi
} from "~/services/SearchService";
import {
  APPLY_FILTERS,
  CHANGE_SEARCH_FINDER_INDEX,
  CHANGE_SEARCH_FINDER_MAX_INDEX,
  CHAT_LAST_MESSAGE,
  GET_SEARCH_DOCUMENTS_TYPES_FILTERS,
  GET_SEARCH_KNOWLEDGE_SOURCES_FILTERS,
  SEARCH_CLEAR,
  SEARCH_ERROR,
  SEARCH_FILTERS_CLEAR,
  SEARCH_PAGE_CHANGE,
  SEARCH_PAGE_CHANGE_SUCCESS,
  SEARCH_REQUEST,
  SEARCH_SUCCESS
} from "./types";

const searchRequest = (question: string): SearchRequestAction => ({
  type: SEARCH_REQUEST,
  payload: {
    question
  },
  withLoader: false
});

const searchPageChange = (page: number): SearchPageChangeAction => ({
  type: SEARCH_PAGE_CHANGE,
  payload: {
    page
  }
});

const searchPageChangeSuccess = (
  items: SearchResponseType
): SearchPageChangeSuccessAction => ({
  type: SEARCH_PAGE_CHANGE_SUCCESS,
  payload: items,
  withLoader: false
});

const searchError = (err: unknown): SearchErrorAction => ({
  type: SEARCH_ERROR,
  payload: err,
  withLoader: false
});

const searchSuccess = (items: SearchResponseType): SearchSuccessAction => ({
  type: SEARCH_SUCCESS,
  payload: items,
  withLoader: false
});

const applyFilters = (filters: AppliedSearchFilters): ApplyFiltersAction => ({
  type: APPLY_FILTERS,
  payload: filters
});

export const saveLastChatMessage = (
  message: string
): SaveLastChatMessageAction => ({
  type: CHAT_LAST_MESSAGE,
  payload: message
});

export const clearSearch = (): SearchClearAction => ({
  type: SEARCH_CLEAR
});

export const searchAction =
  (question: string) =>
  async (dispatch: AppThunkDispatch, getState: () => StoreState) => {
    dispatch(searchRequest(question));

    try {
      const data = (await searchApi(question, 0, {
        ...getState().searchNew.filtersUsed
      })) as SearchResponseType;

      return dispatch(searchSuccess(data));
    } catch (err) {
      dispatch(searchError(err));
      // errors are for now handled by the request api function
    }
  };

export const searchPageAction =
  (question: string, page = 0) =>
  async (dispatch: AppThunkDispatch, getState: () => StoreState) => {
    if (page == getState().searchNew.page) {
      return;
    }
    dispatch(searchPageChange(page));

    try {
      const data = (await searchApi(question, page, {
        ...getState().searchNew.filtersUsed
      })) as SearchResponseType;

      return dispatch(searchPageChangeSuccess(data));
    } catch (err) {
      dispatch(searchError(err));
      // errors are for now handled by the request api function
    }
  };

export const resetFilters =
  () => async (dispatch: AppThunkDispatch, getState: () => StoreState) => {
    dispatch({
      type: SEARCH_FILTERS_CLEAR
    });

    return dispatch(searchAction(getState().searchNew.currentQuestion));
  };

export const changeSearchFiltersAndFetch =
  (filters: AppliedSearchFilters) =>
  async (dispatch: AppThunkDispatch, getState: () => StoreState) => {
    dispatch(applyFilters({ ...getState().searchNew.filtersUsed, ...filters }));
    try {
      const data = (await searchApi(getState().searchNew.currentQuestion, 0, {
        ...getState().searchNew.filtersUsed
      })) as SearchResponseType;

      return dispatch(searchSuccess(data));
    } catch (err) {
      dispatch(searchError(err));
    }
  };

export const getSearchDocumentsTypesFiltersAction =
  () => async (dispatch: AppThunkDispatch) => {
    try {
      const data = await getSearchDocumentsTypesFilters();
      return dispatch({
        type: GET_SEARCH_DOCUMENTS_TYPES_FILTERS,
        payload: data
      });
    } catch (err) {
      throw err;
    }
  };

export const getSearchKnowledgeSourcesFiltersAction =
  () => async (dispatch: AppThunkDispatch) => {
    try {
      const data = await getSearchKnowledgeSourcesFilters();

      return dispatch({
        type: GET_SEARCH_KNOWLEDGE_SOURCES_FILTERS,
        payload: data
      });
    } catch (err) {
      throw err;
    }
  };

export function changeFinderIndex(
  position: number
): ChangeSearchFinderIndexAction {
  return {
    type: CHANGE_SEARCH_FINDER_INDEX,
    position
  };
}

export const nextFinderIndex = () => {
  return (dispatch: AppThunkDispatch, getState: () => StoreState) => {
    const { currentPosition, totalPositions } =
      getState().searchNew.queryMatches;
    const nextPosition = Math.min(currentPosition + 1, totalPositions - 1);
    if (nextPosition != currentPosition) {
      dispatch(changeFinderIndex(nextPosition));
    }
  };
};

export function updateFinderMaxIndex(): ChangeSearchFinderMaxIndexAction {
  return {
    type: CHANGE_SEARCH_FINDER_MAX_INDEX,
    totalPositions: getAllHighlightedPositions()
  };
}

function getAllHighlightedPositions() {
  // in the future we may want to retrieve this number from API
  return window.document.querySelectorAll(HIGHLIGHTED_SPAN_SELECTOR)?.length;
}
