import React, { useCallback, useEffect } from "react";
import classNames from "classnames";
import { useSelector, useThunkDispatch } from "~/configureStore";
import { SearchInput } from "~/molecules/SearchInput/SearchInput";
import { SearchResults } from "~/organisms/SearchResults/SearchResults";
import { useTrackMixpanelEvent } from "~/hooks/useTrackMixpanelEvent";
import { MIXPANEL_EVENTS } from "~/contexts/mixpanel/event-types";
import { Filters } from "~/organisms/Filters/Filters";
import { FiltersButton } from "~/molecules/Filters/FiltersButton";
import { useToggle } from "~/hooks/useToggle";
import { getProjects } from "~/actions/ProjectsActions";

import styles from "./Search.module.scss";

export const Search = () => {
  const {
    items: { suggestions }
  } = useSelector(({ searchNew }) => searchNew);

  const [showFilters, setShowFilters] = useToggle(false);

  useTrackMixpanelEvent(MIXPANEL_EVENTS.SEARCH_VISIT);

  const dispatch = useThunkDispatch();

  const refreshProjectInfo = useCallback(
    () => dispatch(getProjects()),
    [dispatch]
  );

  useEffect(() => {
    refreshProjectInfo();
  }, []);

  return (
    <div
      className={classNames(
        styles.wrapper,
        !suggestions.length && styles.wrapperEmptyMiddle
      )}
    >
      <SearchInput
        className={styles.search}
        suggestionsClass={styles.searchSuggestions}
      />
      <FiltersButton
        className={styles.filtersButton}
        handleShowFilters={() => setShowFilters(true)}
      />
      <SearchResults className={styles.results} />
      <Filters
        filtersOpened={showFilters}
        handleCloseFilters={() => setShowFilters(false)}
        className={classNames(styles.filters, showFilters && styles.opened)}
      />
    </div>
  );
};
