import request from "./request";
import { MOST_LIKELY_ANSWERS_IN_EDITOR } from "~/constants/app";
import {
  HintType,
  MessageType,
  AutocompleteSuggestionType,
  AutocompleteSuggestionContextType
} from "./api-types";

const messageUrl = "/messages";

const hintsUrl = "/knowledge/fragments/hints";
const querySuggestionsUrl = "/query_suggestions";

export function getBotResponse(
  message: string,
  context: AutocompleteSuggestionContextType | undefined
) {
  const bodyDict = {
    text: message,
    contextHint: context
  };

  return request<MessageType[]>({
    url: messageUrl,
    method: "POST",
    data: JSON.stringify(bodyDict)
  });
}

export function getQuerySuggestions(query: string) {
  const bodyDict = {
    text: query,
    size: 5
  };

  return request<AutocompleteSuggestionType[]>({
    url: querySuggestionsUrl,
    method: "POST",
    data: JSON.stringify(bodyDict)
  });
}

export function getBotSuggestion(message: string) {
  const bodyDict = {
    text: message,
    size: MOST_LIKELY_ANSWERS_IN_EDITOR
  };

  return request<HintType[]>({
    url: hintsUrl,
    method: "POST",
    data: JSON.stringify(bodyDict)
  });
}
