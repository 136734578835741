import { connect } from "react-redux";

import Documents from "~/components/Documents";
import {
  showConfirmModal,
  getDocuments,
  getFolders,
  previewDocWithId,
  downloadDocument,
  getFoldersPath
} from "~/actions";

const refreshDocuments = (
  dispatch,
  pageNumber,
  parentId,
  withFolder,
  withDoc,
  sort,
  pageNumberDoc,
  docId
) => {
  dispatch(
    withDoc
      ? getDocuments(
          pageNumberDoc === 0 ? 0 : pageNumberDoc - 1,
          parentId,
          sort,
          docId
        )
      : () => null
  );
  dispatch(
    withFolder
      ? getFolders(pageNumber === 0 ? 0 : pageNumber - 1, parentId, sort, docId)
      : () => null
  );
  dispatch(withFolder ? getFoldersPath(0, parentId) : () => null);
};

const mapStateToProps = state => {
  return {
    folders: state.documents.folders.content,
    documents: state.documents.content,
    totalPagesDoc: state.documents.totalPages,
    totalElemDoc: state.documents.totalElements,
    totalPagesFolders: state.documents.folders.totalPages,
    totalElemFolders: state.documents.folders.totalElements,
    isFetching: state.fetching.isFetching
  };
};

const mapDispatchToProps = dispatch => {
  return {
    confirmDeleteFile: (id, parentId) => {
      dispatch(showConfirmModal("DELETE_DOCUMENT", { id, parentId }));
    },
    confirmDeleteFolder: (id, parentId) => {
      dispatch(showConfirmModal("DELETE_FOLDER", { id, parentId }));
    },
    refresh: (
      pageNumber,
      parentId,
      withFolder,
      sort,
      pageNumberDoc,
      withDoc,
      docId
    ) =>
      refreshDocuments(
        dispatch,
        pageNumber,
        parentId,
        withFolder,
        withDoc,
        sort,
        pageNumberDoc,
        docId
      ),
    getFoldersPath: (pageNumber, parentId) => {
      dispatch(getFoldersPath(0, parentId));
    },
    preview: doc => {
      dispatch(previewDocWithId(doc.id));
    },
    download: id => {
      dispatch(downloadDocument(id));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
