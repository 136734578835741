import React, { useRef } from "react";
import HTMLParagraph from "~/components/HTMLParagraphWithLinks";

import { LeftMessage } from "~/components/LeftMessage/LeftMessage";
import MessageOptionList from "~/components/MessageOptionList";
import BotMessageActions from "~/containers/BotMessageActionsContainer";
import type { SuggestionType } from "~/actions/EditorActions";
import {
  DocumentFragmentAnchorsType,
  DocumentType
} from "~/services/api-types";

import styles from "~/components/LeftMessage/LeftMessage.module.scss";

type MessageProps = {
  html?: string;
  text?: string;
  suggestions?: Array<SuggestionType>;
  editMode?: boolean;
};

const Message = ({
  html = "",
  text = "",
  suggestions = [],
  editMode = false
}: MessageProps) => {
  const messageRef = useRef(null);

  return (
    <div ref={messageRef} className="messageWindow">
      {text ? <HTMLParagraph html={text} /> : html}
      <MessageOptionList items={suggestions} disabled={editMode} />
    </div>
  );
};

type BotMessageProps = {
  id: number;
  text: string;
  isLastMessage: boolean;
  suggestions?: Array<SuggestionType>;
  info?: string;
  document?: DocumentType;
  documentAnchors?: DocumentFragmentAnchorsType;
  previousMessageSender?: "BOT" | "USER";
  customObject?: JSX.Element;
};

export const BotMessage = (props: BotMessageProps) => (
  <div>
    <LeftMessage
      previousMessageSender={props.previousMessageSender}
      info={!!props.info}
      customObject={props.customObject}
      message={<Message {...props} />}
      buttons={
        <BotMessageActions
          className={styles.actions}
          {...props}
          hideActions={!props.isLastMessage}
        />
      }
      lastMessage={props.isLastMessage}
      document={props.document}
      documentAnchors={props.documentAnchors}
    />
  </div>
);
