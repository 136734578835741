import { removeTokens } from "./tokens";
import { clearState, logoutSuccess } from "../actions";
import { LOGIN } from "~/constants/routes";
import { dispatch as storeDispatch } from "~/configureStore";

export const logoutUser = () => {
  removeTokens();

  storeDispatch(logoutSuccess());
  storeDispatch({ type: "CLEAR_ERROR_STATE" });
  storeDispatch(clearState());
  return window.history.pushState(undefined, "", LOGIN);
};
