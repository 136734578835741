import React, { CSSProperties } from "react";
import { Label, Image } from "semantic-ui-react";

interface BreadcrumbLogoProps {
  backgroundColor: Pick<CSSProperties, "backgroundColor">;
  iconImageSrc: string;
  label: React.ReactNode;
}

export const BreadcrumbLogo = ({
  backgroundColor,
  iconImageSrc,
  label
}: BreadcrumbLogoProps) => (
  <Label
    size="tiny"
    style={{
      backgroundColor
    }}
  >
    <Image src={iconImageSrc} />
    &nbsp;
    {label}
  </Label>
);
