import React, { CSSProperties } from "react";
import { Loader } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import styles from "./LoaderInline.module.scss";
import classNames from "classnames";

interface LoaderInlineProps {
  isFetching: boolean;
  label?: string;
  style?: CSSProperties;
  dimmer?: boolean;
  className?: string;
}

const LoaderInline = ({
  isFetching,
  label,
  style,
  dimmer = true,
  className
}: LoaderInlineProps) => {
  const { t } = useTranslation();

  return isFetching ? (
    <div
      className={classNames(dimmer && styles.loaderInlineDimmer, className)}
      style={style}
    >
      <Loader active inline="centered" size="small">
        {label ?? t("loading")}
      </Loader>
    </div>
  ) : null;
};

export default LoaderInline;
