import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";

import styles from "./Issues.module.scss";
import Wrapper from "~/components/atoms/Wrapper/Wrapper";
import Button from "~/components/atoms/Button/Button";
import classNames from "classnames";

import Changes from "~/containers/ChangesContainer";

import { ReactComponent as ChooseSVG } from "~/assets/icons/menu_issues.svg";
import { ReactComponent as EditSVG } from "~/assets/icons/plus.svg";
import { ButtonGroup } from "semantic-ui-react";
import { Dropdown } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { ISSUES_SOLVE_NEW } from "~/constants/routes";
import TagsInput from "~/atoms/TagsInput/TagsInput";
import { isEqual } from "lodash";
import { getIssuesLabels, updateIssueLabels } from "~/services/IssuesService";

class IssuesComponent extends React.Component {
  state = {
    openChange: null,
    chosenAnswer: null,
    labels: [],
    allLabels: [],
    initializedLabels: false
  };

  componentDidMount() {
    // eslint-disable-next-line
    this.props.fetchIssue?.();
    if (
      !this.state.chosenAnswer &&
      this.props.answers?.length > 0 &&
      !this.props.editorChosenAnswer &&
      !this.props.singleIssueFetching
    ) {
      this.setState({
        chosenAnswer: this.props.answers[0].id
      });
    }
    // eslint-disable-next-line
    this.props.expandContainer?.(true);

    getIssuesLabels()
      .then(data => this.setState({ allLabels: data }))
      .finally(() => this.setState({ initializedLabels: true }));
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.state.chosenAnswer &&
      !isEqual(this.props.answers, prevProps.answers) &&
      !this.props.editorChosenAnswer &&
      !this.props.singleIssueFetching
    ) {
      if (this.props.answers?.length > 0) {
        this.setState({
          chosenAnswer: this.props.answers[0].id
        });
      }
    }
    if (this.props.question !== prevProps.question) {
      this.props.changeAnswer(this.props.question);
    }
    if (
      !isEqual(this.state.labels, prevState.labels) &&
      this.state.initializedLabels &&
      prevState.initializedLabels
    ) {
      updateIssueLabels(this.props.issueId, this.state.labels);
    }
  }

  componentWillUnmount() {
    // eslint-disable-next-line
    this.props.expandContainer?.(false);

    if (typeof this.props.changeAnswer === "function") {
      this.props.changeAnswer(null);
    }
  }

  goBack = () => {
    if (this.isSaveDisabled()) {
      return this.props.history.goBack();
    }

    const close = () => {
      this.props.onCloseClick();
      this.props.history.goBack();
    };
    this.props.showConfirmModal({ close });
  };

  save = () => {
    this.props.onSaveButtonClick(this.props.history.goBack);
  };

  openAccordion = idx => {
    this.setState(prevState => ({
      openChange: prevState.openChange === idx ? null : idx
    }));
  };

  resultButton = chosenAnswer => {
    const { question, issueId } = this.props;

    const handleAnswerUpdate = e => {
      e.target.blur();
      if (this.props.changeChosenAnswer) {
        this.props.changeChosenAnswer({ ...chosenAnswer, question, issueId });
      }
      this.setState({ chosenAnswer: chosenAnswer.id });
    };

    const createNewBasedOnCurrent = () => {
      // eslint-disable-next-line
      this.props.onOpenChangeView?.({
        ...chosenAnswer,
        createNew: true,
        issueId,
        changeByEdit: false,
        question
      });
    };

    // const chooseToEdit = () => {
    //   this.props.changeChosenAnswer({ ...chosenAnswer, question, issueId });
    //   // eslint-disable-next-line
    //   this.props.onOpenChangeView?.({
    //     ...chosenAnswer,
    //     issueId,
    //     question,
    //     changeByEdit: true
    //   });
    // };

    return (
      <ButtonGroup className={styles.chooseAnswerButtonGroup}>
        <button
          className={styles.chooseAnswerButton}
          onClick={handleAnswerUpdate}
          disabled={chosenAnswer?.id === this.state.chosenAnswer}
        >
          {this.props.t("issues.choose")}
        </button>
        <Dropdown
          options={[
            {
              key: "createNewFromCurrent",
              icon: null,
              text: (
                <Link to={ISSUES_SOLVE_NEW} onClick={createNewBasedOnCurrent}>
                  {this.props.t("issues.copy_answer_to_edit")}
                </Link>
              ),
              value: "createNewFromCurrent"
            }
            // {
            //   key: "edit",
            //   icon: null,
            //   text: (
            //     <Link to={TRAIN_FRAGMENT_EDIT} onClick={chooseToEdit}>
            //       {this.props.t("issues.edit_and_choose")}
            //     </Link>
            //   ),
            //   value: "edit"
            // }
          ]}
          className="button icon"
          floating
          trigger={<></>}
          closeOnChange
        />
      </ButtonGroup>
    );
  };

  handleRedirect = () => {
    const { issueId } = this.props;

    if (issueId) {
      return this.props.changeChosenAnswer({ issueId });
    }
    // eslint-disable-next-line
    return this.props.onOpenChangeView?.({
      createNew: false,
      changeByEdit: false
    });
  };

  isSaveDisabled = () => {
    const { answers } = this.props;
    const { chosenAnswer } = this.state;

    if (!chosenAnswer) return true;

    return (
      answers?.length > 0 && chosenAnswer && answers[0].id === chosenAnswer
    );
  };

  handleTagsChange = labels => this.setState({ labels });

  infoSectionRef = React.createRef();
  buttonsRef = React.createRef();

  render() {
    const { comment, question, answers, singleIssue } = this.props;

    return (
      <>
        <Wrapper
          title={this.props.t("issues.edit_answer")}
          className={styles.wrapper}
        >
          <div className={styles.infoSection} ref={this.infoSectionRef}>
            <p className={styles.infoSectionTitle}>
              {this.props.t("issues.details")}
            </p>
            {question && (
              <section className={classNames(styles.infoSectionContainer)}>
                <h2 className={styles.infoSectionContainerTitle}>
                  {this.props.t("issues.question")}
                </h2>
                <p
                  className={classNames(
                    styles.infoSectionContent,
                    styles.infoSectionContentQuestion
                  )}
                >
                  {question}
                </p>
              </section>
            )}
            {comment && (
              <section className={styles.infoSectionContainer}>
                <h2 className={styles.infoSectionContainerTitle}>
                  {this.props.t("issues.issue_reason")}
                </h2>
                <p className={styles.infoSectionContent}>{comment} </p>
              </section>
            )}
            {singleIssue && (
              <section
                className={classNames(
                  styles.infoSectionContainer,
                  styles.infoSectionContainerUser
                )}
              >
                <h2 className={styles.infoSectionContainerTitle}>
                  {this.props.t("issues.date_and_author")}
                </h2>
                <p className={styles.infoSectionContent}>
                  {this.props.t("issues.created_by_date", {
                    username: singleIssue?.author?.userName ?? "",
                    date: singleIssue?.createdAt
                      ? moment(singleIssue.createdAt).format("lll")
                      : ""
                  })}
                </p>
              </section>
            )}
            {singleIssue && (
              <div className={styles.infoSectionLabels}>
                <h2
                  className={classNames(
                    styles.infoSectionContainerTitle,
                    styles.infoSectionContainerTitleLabel
                  )}
                >
                  {this.props.t("issues.label")}
                </h2>
                <TagsInput
                  items={this.state.allLabels}
                  addedItems={singleIssue.labels}
                  limit={3}
                  handleTagsChange={this.handleTagsChange}
                  className={styles.infoSectionLabelsInput}
                />
              </div>
            )}{" "}
          </div>
          <div className={styles.buttonsSection} ref={this.buttonsRef}>
            <button
              className={classNames(styles.button, styles.buttonActive)}
              disabled
              onClick={() => {}}
            >
              <ChooseSVG
                className={classNames(
                  styles.buttonIcon,
                  styles.buttonIconChoose
                )}
              />
              <div className={styles.buttonContainer}>
                <p className={styles.buttonContainerTitle}>
                  {this.props.t("issues.pick")}
                </p>
                <span className={styles.buttonContainerDescription}>
                  {this.props.t("issues.pick_from_existing")}
                </span>
              </div>
            </button>
            <Link
              to={ISSUES_SOLVE_NEW}
              onClick={this.handleRedirect}
              className={styles.button}
            >
              <EditSVG
                className={classNames(styles.buttonIcon, styles.buttonIconNew)}
              />
              <div className={styles.buttonContainer}>
                <p className={styles.buttonContainerTitle}>
                  {this.props.t("issues.create")}
                </p>
                <span className={styles.buttonContainerDescription}>
                  {this.props.t("issues.create_new_answer")}
                </span>
              </div>
            </Link>
          </div>
          <div className={styles.suggestedAnswers}>
            <p
              className={classNames(
                styles.infoSectionTitle,
                styles.infoSectionTitleFilters
              )}
            >
              {this.props.t("issues.filters")}
            </p>
            <Changes
              asIssues
              className={styles.changesCopy}
              issuesData={answers ?? []}
              resultButton={this.resultButton}
              chosenAnswer={this.state.chosenAnswer}
              scrollContainerClass={styles.changesCopyTableScroll}
            />
          </div>
        </Wrapper>
        <div className={styles.submitButtons}>
          <Button className={styles.submitButtonsBack} onClick={this.goBack}>
            {this.props.t("issues.go_back")}
          </Button>
          <Button
            secondary
            disabled={this.isSaveDisabled()}
            className={styles.submitButtonsSave}
            onClick={this.save}
          >
            {this.props.t("issues.save")}
          </Button>
        </div>
      </>
    );
  }
}

IssuesComponent.propTypes = {
  question: PropTypes.string.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  onSaveButtonClick: PropTypes.func.isRequired,
  comment: PropTypes.string.isRequired,
  noAnswersFound: PropTypes.bool.isRequired,
  singleIssueFetching: PropTypes.bool.isRequired,
  fetchIssue: PropTypes.func.isRequired,
  answers: PropTypes.arrayOf(PropTypes.object),
  changeChosenAnswer: PropTypes.func,
  issueId: PropTypes.string,
  changeAnswer: PropTypes.func,
  singleIssue: PropTypes.shape({
    createdAt: PropTypes.string,
    author: PropTypes.shape({
      userName: PropTypes.string
    }),
    labels: PropTypes.arrayOf(PropTypes.string).isRequired
  }),
  expandContainer: PropTypes.func,
  editorChosenAnswer: PropTypes.oneOfType([PropTypes.object]),
  showConfirmModal: PropTypes.func,
  t: PropTypes.func.isRequired
};

export default withTranslation()(IssuesComponent);
