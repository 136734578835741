import React, { useCallback, useMemo, useState } from "react";
import { shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import filesize from "filesize";
import moment from "moment";
import { Radio } from "semantic-ui-react";
import { truncate } from "lodash";
import { ReactComponent as Download } from "~/assets/download.svg";
import { ReactComponent as Remove } from "~/assets/delete.svg";
import { ReactComponent as PreviewIcon } from "~/assets/preview.svg";
import { ReactComponent as EditIcon } from "~/assets/icons/edit.svg";
import {
  downloadDocument as downloadDocumentAction,
  showConfirmModal,
  toggleDocumentUse,
  previewDocWithId
} from "~/actions";
import { FileLabel } from "~/components/FileLabel";
import { DocumentType } from "~/services/api-types";
import { useSelector, useThunkDispatch } from "~/configureStore";
import { DELETE_DOCUMENT } from "~/actions/types";
import { generatePath, useHistory } from "react-router";
import { EDIT_ARTICLE } from "~/constants/routes";
import classNames from "classnames";
import { canUserDownloadDocuments } from "~/constants/app";
import { TagLabel } from "~/atoms/TagLabel/TagLabel";

interface DocumentHeaderProps {
  canToggle?: boolean;
  isSearch?: boolean;
  canPreview?: boolean;
  document: DocumentType;
  onClose: () => void;
}

const DocumentHeader = ({
  canPreview = false,
  canToggle = true,
  isSearch = false,
  document,
  onClose
}: DocumentHeaderProps) => {
  const { t } = useTranslation();

  const { replace } = useHistory();

  const [isEnabled, setIsEnabled] = useState(document.enabled);

  const allowedToManage = useSelector(
    ({ login: { role } }) =>
      role ? ["MANAGER", "ADMIN"].indexOf(role) > -1 : false,
    shallowEqual
  );

  const allowedToDownload = useSelector(({ login: { role } }) => {
    return role && canUserDownloadDocuments(role);
  });

  const dispatch = useThunkDispatch();

  const toggleDocument = useCallback(
    ({ enabled, id }: { id: number; enabled: boolean }) =>
      dispatch(toggleDocumentUse(id, enabled)),
    [dispatch]
  );

  const onCancelRemoveFile = useCallback(
    () => dispatch(showConfirmModal("PREVIEW_DOCUMENT", { id: document.id })),
    [dispatch, document.id]
  );

  const removeFile = useCallback(
    () =>
      allowedToManage
        ? dispatch(
            showConfirmModal(DELETE_DOCUMENT, {
              id: document.id,
              onCancel: onCancelRemoveFile
            })
          )
        : undefined,
    [dispatch, allowedToManage, document.id]
  );
  const downloadDocument = useCallback(
    () => dispatch(downloadDocumentAction(document.id)),
    [dispatch, document.id]
  );
  const previewDocument = useCallback(
    (docId: number) => dispatch(previewDocWithId(docId)),
    [dispatch]
  );

  const toggleFileUse = useCallback(() => {
    toggleDocument({ id: document.id, enabled: !document.enabled });
    return setIsEnabled(!document.enabled);
  }, [toggleDocument, document.id, document.enabled]);

  const toggable = useMemo(
    () => (!canToggle ? canToggle : allowedToManage),
    [canToggle, allowedToManage]
  );

  const handleEdit = () => {
    onClose();
    replace(generatePath(EDIT_ARTICLE, { id: document.id }));
  };

  return (
    <div
      className={classNames(
        "document-header",
        document.isArticle && "without-margin"
      )}
      onClick={() =>
        isSearch && canPreview && document
          ? previewDocument(document.id)
          : undefined
      }
      style={isSearch ? { cursor: "pointer" } : {}}
    >
      <div className="group">
        <div>
          {toggable ? (
            <Radio
              toggle
              disabled={!allowedToManage}
              onClick={toggleFileUse}
              checked={isEnabled}
            />
          ) : null}
        </div>
        <FileLabel
          isArticle={document.isArticle}
          extension={document.extension}
          name={document.name}
          external={document.source !== "INTERNAL"}
          url={document.url}
          noBorder
        />
        {document.isArticle && document.articleDraft && (
          <TagLabel
            className="draftLabel"
            tag={t("documents.draft_article_label_changes")}
          />
        )}
      </div>
      <div className="group">
        <div className="createTime">
          {moment(document.createdAt).format(t("documents.header.date_format"))}
        </div>
        <div className="author">{document.author.userName}</div>
        <div className="fileSize">{filesize(Number(document.size))}</div>
        {allowedToManage && document.isArticle && (
          <EditIcon onClick={handleEdit} />
        )}
        {canPreview && (
          <PreviewIcon
            data-tip={t("documents.header.preview")}
            onClick={() =>
              isSearch && canPreview && document
                ? previewDocument(document.id)
                : undefined
            }
          />
        )}
        {allowedToDownload && (
          <Download
            data-tip={t("documents.header.download")}
            onClick={downloadDocument}
          />
        )}
        {allowedToManage && canToggle ? (
          <Remove
            data-tip={t("documents.header.delete")}
            onClick={() => removeFile()}
          />
        ) : null}
      </div>
    </div>
  );
};

export default DocumentHeader;
