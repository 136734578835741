import request from "./request";
import { get } from "lodash";

const changesUrl = "/knowledge/fragments";

export const hintQuestionAnswerPair = (
  question,
  answer,
  suggestions,
  documentId
) => {
  return request({
    url: changesUrl,
    method: "POST",
    data: JSON.stringify({
      question: question,
      answer: answer,
      suggestions: suggestions,
      documentId: documentId
    })
  });
};

export const updateChange = (id, question, answer, suggestions, documentId) => {
  return request({
    url: changesUrl + "/" + id,
    method: "PUT",
    data: JSON.stringify({
      question: question,
      answer: answer,
      suggestions: suggestions,
      documentId: documentId
    })
  });
};

export const getChanges = (
  pageNumber,
  sorting,
  searchParams,
  filters,
  author,
  documentId
) => {
  let field = get(sorting, "by", "updatedAt");
  let direction = get(sorting, "direction", "desc");
  let reqUrl =
    changesUrl +
    "?page=" +
    encodeURIComponent(pageNumber) +
    `&size=25` +
    `&sort=${field},${direction}`;

  if (author) {
    reqUrl += `&modifiedBy=${author}`;
  }
  if (documentId) {
    reqUrl += `&document=${documentId}`;
  }
  if (get(searchParams, "query")) {
    let queryParam = encodeURIComponent(searchParams.query);
    reqUrl += `&query=${queryParam}`;
  }
  if (get(searchParams, "queryFields")) {
    reqUrl += `&queryFields=${searchParams.queryFields}`;
  }
  if (get(filters, "modifiedSince")) {
    reqUrl += `&modifiedSince=${filters.modifiedSince}`;
  }
  if (get(filters, "modifiedUntil")) {
    reqUrl += `&modifiedUntil=${filters.modifiedUntil}`;
  }
  return request({
    url: reqUrl,
    method: "GET"
  });
};

export const deleteChange = id =>
  request(
    {
      url: changesUrl + "/" + id,
      method: "DELETE"
    },
    false
  );
