import { CLEAR_PROJECTS_STATE, GET_PROJECTS_SUCCESS } from "~/actions/types";
import { ProjectType } from "~/services/api-types";

interface ProjectsState {
  current: null | ProjectType;
  projects: ProjectType[];
}

const initialState: ProjectsState = {
  current: null,
  projects: []
};

export interface GetProjectsSuccessAction {
  type: typeof GET_PROJECTS_SUCCESS;
  payload: ProjectType[];
}

export interface ClearProjectsStateAction {
  type: typeof CLEAR_PROJECTS_STATE;
}

type ProjectsActions = GetProjectsSuccessAction | ClearProjectsStateAction;

const projects = (
  state: ProjectsState = initialState,
  action: ProjectsActions
): ProjectsState => {
  switch (action.type) {
    case GET_PROJECTS_SUCCESS:
      return {
        current: action.payload[0],
        projects: action.payload
      };
    case CLEAR_PROJECTS_STATE:
      return initialState;
    default:
      return state;
  }
};

export default projects;
