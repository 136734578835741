import { USER_ROLES } from "~/constants/app";
import axios, { AxiosRequestConfig } from "axios";
import { AuthorizationType } from "./api-types";
import { setLoginTokens } from "~/helpers/tokens";
import { getRequest, postRequest } from "~/helpers/axios";
import { TOKEN, OAUTH, USER_CONFIG } from "./api-urls";

export const getConfigRequestService = async () => {
  try {
    const { data } = await getRequest<{
      role: USER_ROLES;
      userName: string;
      email: string;
    }>(USER_CONFIG);

    return data;
  } catch (err) {
    throw err;
  }
};

export async function authenticate(credentials: {
  username: string;
  password: string;
}) {
  const options: AxiosRequestConfig = {
    headers: {
      Authorization: "Basic bXlDbGllbnQ6bXlDbGllbnRTZWNyZXQ="
    },
    params: {
      grant_type: "password",
      ...credentials
    },
    apiType: OAUTH
  };

  try {
    const { data } = await postRequest<AuthorizationType>(TOKEN, options);
    setLoginTokens(data);

    return await getConfigRequestService();
  } catch (err: unknown) {
    if (axios.isAxiosError(err)) {
      if ((err.response?.data as any)?.error_description) {
        throw new Error((err.response!.data as any).error_description);
      }
    }
    /**
     * @todo Handle authentication error
     */
    throw new Error("error_message.authorization_has_failed");
  }
}
