import React, { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import classNames from "classnames";
import { getUpdatesHistory } from "~/services/ArticlesService";
import { UpdateHistoryType } from "~/services/api-types";
import moment from "moment";
import useInfiniteScroll from "react-infinite-scroll-hook";

import ButtonIcon from "~/atoms/ButtonIcon/ButtonIcon";
import Tooltip from "~/atoms/Tooltip/Tooltip";
import { Skeleton } from "~/components/skeletons/Skeleton";

import { ReactComponent as UpdatesHistorySVG } from "~/assets/icons/time-left.svg";
import { ReactComponent as CloseSVG } from "~/assets/icons/close.svg";

import styles from "./UpdatesHistory.module.scss";
import { ErrorMessage } from "~/atoms/ErrorMessage/ErrorMessage";

interface UpdatesHistoryProps {
  isOpened: boolean;
  articleId: number | null;
  close: () => void;
}

export const UpdatesHistory = ({
  isOpened,
  close,
  articleId
}: UpdatesHistoryProps) => {
  const [historyItems, setHistoryItems] = useState<UpdateHistoryType[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [nextPage, setNextPage] = useState(0);
  const [error, setError] = useState("");
  const { t } = useTranslation();

  const fetchUpdatesHistory = useCallback(
    async (articleId: number, loadingList = false) => {
      loadingList ? setLoadingList(true) : setLoading(true);
      setError("");

      try {
        await getUpdatesHistory(articleId, nextPage).then(
          ({ data: { content, last, number } }) => {
            setHistoryItems([...historyItems, ...content]);
            setHasNextPage(!last);
            setNextPage(number + 1);
          }
        );
      } catch {
        setError(t("documents.new_article_updates_history_error"));
      } finally {
        loadingList ? setLoadingList(false) : setLoading(false);
      }
    },
    [historyItems, nextPage]
  );

  const handleLoadMore = useCallback(() => {
    if (articleId) {
      fetchUpdatesHistory(articleId, true);
    }
  }, [articleId, fetchUpdatesHistory]);

  const [sentryRef] = useInfiniteScroll({
    loading: loadingList,
    hasNextPage,
    onLoadMore: handleLoadMore,
    rootMargin: "0px 0px 400px 0px"
  });

  useEffect(() => {
    if (articleId) {
      fetchUpdatesHistory(articleId);
    }
  }, [articleId]);

  const getLoaders = (n: number) =>
    [...new Array(n)].map((_e, key) => (
      <div className={styles.loader} key={key}>
        <Skeleton height={70} width={220}>
          <rect x="0" y="0" rx="6" ry="6" width="132" height="17" />
          <rect x="0" y="26" rx="6" ry="6" width="165" height="17" />
          <rect x="0" y="52" rx="6" ry="6" width="200" height="17" />
        </Skeleton>
      </div>
    ));

  return (
    <div
      className={classNames(
        styles.wrapper,
        isOpened ? styles.opened : styles.closed
      )}
    >
      <div className={styles.header}>
        <span className={styles.icon}>
          <UpdatesHistorySVG />
        </span>
        <span className={styles.heading}>
          {t("documents.new_article_updates_history")}
        </span>
        <Tooltip
          wrapperClassName={styles.tip}
          light
          tip={t("documents.new_article_contents_close")}
        >
          <ButtonIcon onClick={close} className={styles.closeButton}>
            <CloseSVG className={styles.icon} />
          </ButtonIcon>
        </Tooltip>
      </div>
      <div className={styles.content}>
        {error && !loading && (
          <ErrorMessage className={styles.error} error={t(error)} />
        )}
        {loading && !error && getLoaders(10)}
        {!loading &&
          !error &&
          historyItems.length > 0 &&
          historyItems.map(({ id, createdAt, createdBy, description }) => (
            <div key={id} className={styles.item}>
              <div className={styles.time}>
                <Trans i18nKey="documents.new_article_updates_history_time">
                  {{ when: moment(createdAt).fromNow() }}
                  <span>by</span>
                  {{ userName: createdBy.userName }}
                </Trans>
              </div>
              <div className={styles.description}>
                <Trans i18nKey="documents.new_article_updates_history_comment">
                  <span>Comment</span>
                  {{ comment: description ? description : "-" }}
                </Trans>
              </div>
            </div>
          ))}
        {(loadingList || hasNextPage) && (
          <div style={{ position: "relative" }} ref={sentryRef}>
            {getLoaders(1)}
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdatesHistory;
