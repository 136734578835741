import { getAccessToken } from "~/helpers";
import { dispatch } from "../configureStore";
import { customError } from "~/actions/CustomActions.js";
import { axiosInstance } from "~/helpers/axios";

const download = async options => {
  const headers = {
    Authorization: `Bearer ${getAccessToken()}`
  };

  options = {
    headers,
    ...options,
    responseType: "blob"
  };

  try {
    const response = await axiosInstance(options);

    const disposition = await response.headers["content-disposition"];

    let filename = "file";

    if (disposition) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
        filename = filename.replace(/UTF-8/g, "");
        filename = decodeURI(filename);
      }
    }

    const link = document.createElement("a");
    const url = window.URL.createObjectURL(response.data);

    link.href = url;
    link.setAttribute("download", filename);
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window
      })
    );

    window.URL.revokeObjectURL(url);
    Promise.resolve(url);
  } catch (err) {
    const data = await new Response(err.response.data).json();

    if (data?.message) {
      dispatch(customError(data.message));
      throw new Error(data.message);
    }

    dispatch(customError(err.message));
    throw new Error(err.message);
  }
};

export default download;
