import React, { useMemo } from "react";
import Button from "~/atoms/Button/Button";
import { useTranslation } from "react-i18next";

import { ReactComponent as FiltersIcon } from "~/assets/icons/FiltersIcon.svg";
import { useSelector } from "~/configureStore";

interface FiltersButtonProps {
  className?: string;
  handleShowFilters: () => void;
}

export const FiltersButton = ({
  className,
  handleShowFilters
}: FiltersButtonProps) => {
  const { t } = useTranslation();

  const appliedFilters = useSelector(
    ({ searchNew: { filtersUsed } }) => filtersUsed
  );

  const appliedFiltersCount = useMemo(() => {
    let count = 0;

    if (appliedFilters.modifiedUntil || appliedFilters.modifiedSince) {
      count += 1;
    }
    if (appliedFilters.modifiedBy) {
      count += 1;
    }
    if (appliedFilters.documentType?.length) {
      count += 1;
    }
    if (appliedFilters.knowledgeSource?.length) {
      count += 1;
    }

    return count;
  }, [appliedFilters]);

  return (
    <Button className={className} onClick={handleShowFilters}>
      <FiltersIcon />
      <span>
        {t("search.filters_head.filters", { count: appliedFiltersCount })}
      </span>
    </Button>
  );
};
