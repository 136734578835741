import React from "react";
import PropTypes from "prop-types";
import styles from "./PageWrapper.module.scss";

export const PageWrapper = ({ children, className = "", title }) => {
  return (
    <div className={`${styles.globalPageWrapper} ${className}`}>{children}</div>
  );
};

PageWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string
};
