import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Button } from "semantic-ui-react";
import { debounce, isEqual } from "lodash";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { generatePath, Link } from "react-router-dom";
import LoaderInline from "~/components/LoaderInline/LoaderInline";
import { listLoadingOptions } from "~/constants/app";
import { TFunction, WithTranslation, withTranslation } from "react-i18next";
import { ISSUES_SOLVE_ID } from "~/constants/routes";
import { TagLabel } from "~/atoms/TagLabel/TagLabel";
import TagsInput from "~/atoms/TagsInput/TagsInput";
import classNames from "classnames";
import { PageWrapper } from "~/components/PageWrapper";
import type { IssuesListProps } from "~/containers/IssuesListContainer";
import type { IssueType } from "~/services/api-types";

import styles from "./IssuesList.module.scss";
import { MixpanelContextValue, WithMixpanelContext } from "~/contexts/mixpanel";
import { MIXPANEL_EVENTS } from "~/contexts/mixpanel/event-types";

interface IssuesTableCellProps {
  className?: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

const IssuesTableCell = ({
  children,
  className = "",
  onClick = () => {}
}: IssuesTableCellProps) => (
  <div
    className={classNames(
      styles.issuesTableCell,
      // !!props.onClick ? styles.clickable : "", // will be clickable in future
      className
    )}
    onClick={onClick}
  >
    {children}
  </div>
);

interface IssuesListComponentProps
  extends IssuesListProps,
    MixpanelContextValue {}

interface IssuesListComponentState {
  wrapperHeight: number;
  openChange: null | number;
  paginPage: number;
  openMenuItem: null | number;
  filtersHeight: number;
  pickedLabels: string[];
}

class IssuesListComponent extends Component<
  IssuesListComponentProps & WithTranslation,
  IssuesListComponentState
> {
  state = {
    wrapperHeight: 0,
    openChange: null,
    paginPage: 0,
    openMenuItem: null,
    filtersHeight: 0,
    pickedLabels: []
  };

  filtersRef = React.createRef<HTMLDivElement>();
  container: null | HTMLDivElement = null;

  handlePickedLabels = (pickedLabels: string[]) =>
    this.setState({ pickedLabels });

  componentDidMount() {
    this.props.refresh();
    this.props.getLabels();
    this.updateWindowDimensions();
    const table = document.getElementsByClassName(styles.scrollListener)[0];
    window.addEventListener("resize", this.updateWindowDimensions);
    table.addEventListener("scroll", this.pageScroll);

    this.props.trackEvent(MIXPANEL_EVENTS.ISSUES_VISIT);
  }

  componentDidUpdate(
    prevProps: IssuesListComponentProps,
    prevState: IssuesListComponentState
  ) {
    ReactTooltip.rebuild();

    if (!isEqual(prevState.pickedLabels, this.state.pickedLabels)) {
      this.setState({ paginPage: 0 });
      this.props.refresh(0, this.state.pickedLabels);
    }
  }

  componentWillUnmount() {
    ReactTooltip.hide();
    const table = document.getElementsByClassName(styles.scrollListener)[0];
    window.removeEventListener("resize", this.updateWindowDimensions);
    table.removeEventListener("scroll", this.pageScroll);
  }

  updateWindowDimensions = () => {
    this.setState({
      wrapperHeight: this.container?.offsetHeight ?? 0,
      filtersHeight: this.filtersRef?.current?.offsetHeight ?? 0
    });
  };

  pageScroll = debounce(event => {
    if (
      event.target.clientHeight + event.target.scrollTop >
        event.target.scrollHeight - listLoadingOptions.offsetBottom &&
      this.state.paginPage + 1 < this.props.totalPages
    ) {
      this.props.refresh(this.state.paginPage + 1);
      this.setState((state: IssuesListComponentState) => ({
        paginPage: state.paginPage + 1
      }));
    }
  }, listLoadingOptions.debounceTime);

  // getImgFromPlainAnswer = answer => {
  //   const temp_div = document.createElement("div");
  //   temp_div.innerHTML = answer;
  //   const imgList = temp_div.getElementsByTagName("img");
  //   return imgList;
  // };

  renderButtons = (id: number) => {
    return (
      <Fragment key={id}>
        {this.renderMoreButton(id)}
        {/* available in future */}
        {/* <Icon
          className="arrowIcon"
          name={`angle ${this.state.openChange === index ? "up" : "down"}`}
          size="big"
          onClick={() => this.openAccordion(index)}
        /> */}
      </Fragment>
    );
  };

  // openMoreMenu = (e, idx) => {
  //   this.setState({
  //     openMenuItem: idx
  //   });
  // };

  openAccordion = (idx: number) => {
    return; // na razie zablokowane
    // this.setState(prevState => ({
    //   openChange: prevState.openChange === idx ? null : idx
    // }));
    // {
    //   available in future
    // }
  };

  renderMoreButton = (id: number) => {
    return (
      <Link to={generatePath(ISSUES_SOLVE_ID, { id })}>
        <Button key={`EditIssueButton_${id}`} className={styles.editButton}>
          {this.props.t("issues.edit")}
        </Button>
      </Link>
    );
  };

  renderElementFromObject = (object: IssueType, index: number) => {
    // {
    //   available in future
    // }
    // let imageList = [];
    // let renderImages = false;
    // if (object.answer) {
    //   imageList = this.getImgFromPlainAnswer(object.answer);
    //   renderImages = imageList.length > 0 ? true : false;
    // }
    const isActive = this.state.openChange === index;
    return (
      <div
        key={`Issue_${object.id}`}
        className={`${styles.issuesTableRow} ${isActive ? "activeBkg" : ""}`}
      >
        <IssuesTableCell onClick={() => this.openAccordion(index)}>
          {object.question}
        </IssuesTableCell>
        <IssuesTableCell onClick={() => this.openAccordion(index)}>
          {object.author.userName}
        </IssuesTableCell>
        <IssuesTableCell onClick={() => this.openAccordion(index)}>
          {object.comment}
        </IssuesTableCell>
        <IssuesTableCell onClick={() => this.openAccordion(index)}>
          {moment(object.createdAt).format("lll")}
        </IssuesTableCell>
        <IssuesTableCell className={styles.labels}>
          {object.labels.map(label => (
            <TagLabel className={styles.labelsLabel} key={label} tag={label} />
          ))}
        </IssuesTableCell>
        <IssuesTableCell>{this.renderButtons(object.id)}</IssuesTableCell>
        <div className={styles.divider} />
      </div>
    );
  };

  render() {
    return (
      <PageWrapper
        className={styles.issuesPageWrapper}
        title={this.props.t("issues.title")}
      >
        <div className={styles.filters} ref={this.filtersRef}>
          <div className={styles.filtersFilter}>
            <p className={styles.filtersFilterTitle}>
              {this.props.t("issues.label")}
            </p>
            <TagsInput
              listClassName={styles.tagsList}
              items={this.props.labels}
              readonly
              handleTagsChange={this.handlePickedLabels}
              limit={3}
            />
          </div>
        </div>
        <div ref={el => (this.container = el)} className={styles.scrollDummy}>
          <div
            className={`${styles.issuesTableRow} ${styles.issuesTableCellHead}`}
          >
            <IssuesTableCell>{this.props.t("issues.question")}</IssuesTableCell>
            <IssuesTableCell>{this.props.t("issues.author")}</IssuesTableCell>
            <IssuesTableCell>{this.props.t("issues.comment")}</IssuesTableCell>
            <IssuesTableCell>
              {this.props.t("issues.date_modified")}
            </IssuesTableCell>
            <IssuesTableCell>{this.props.t("issues.labels")}</IssuesTableCell>
            <IssuesTableCell /> {/* akcje */}
          </div>
          <div
            className={`${styles.issuesTableWrapper} ${styles.scrollListener}`}
            style={{
              maxHeight: `calc(100% - ${this.state.filtersHeight}px - 98px)`
            }}
          >
            <div>{this.props.issues.map(this.renderElementFromObject)}</div>
            <LoaderInline
              isFetching={
                (this.props.isFetching || !!this.props.issues.length) &&
                this.state.paginPage + 1 !== this.props.totalPages
              }
            />
          </div>
        </div>
      </PageWrapper>
    );
  }
}

export default withTranslation()(WithMixpanelContext()(IssuesListComponent));
