import {
  CLEAR_RELATED_MESSAGE,
  RELATED_OPENED,
  SAVE_RELATED_MESSAGE
} from "./types";

export const toggleRelatedContainer = state => ({
  type: RELATED_OPENED,
  payload: state
});

export function saveQuestionForRelated(message) {
  return {
    type: SAVE_RELATED_MESSAGE,
    message
  };
}

export function clearRelatedMessage() {
  return {
    type: CLEAR_RELATED_MESSAGE
  };
}
