import { chain, zipObject, isArray, flatten } from "lodash";
import { getAccessToken as getCookieAccessToken } from "./tokens";

export const getAccessToken = () => getCookieAccessToken();

const makeItemsDeepCopy = items => {
  let itemsCopy = [];
  for (var i = 0; i < items.length; i++) {
    itemsCopy.push({});
    for (var key in items[i]) {
      if (key == "items") {
        itemsCopy[i].items = Array.from(items[i].items);
      } else {
        itemsCopy[i][key] = items[i][key];
      }
    }
  }
  return itemsCopy;
};

const filterMenuItemsByRole = (items, role) =>
  items
    .filter(
      ({ authorizedRoles }) =>
        !authorizedRoles || authorizedRoles.indexOf(role.trim()) > -1
    )
    .map(item => ({
      ...item,
      href: item.items ? item.items?.[0]?.href : item.href
    }));

export const filterMenuItems = (items, role) => {
  if (!isArray(items)) throw new Error("Items type must be an array");
  if (!role) return items;

  let newItems = makeItemsDeepCopy(items);
  newItems = newItems.map(item => {
    if (item.items) {
      item.items = filterMenuItemsByRole(item.items, role);
    }

    return item;
  });

  const filteredMenuItems = filterMenuItemsByRole(newItems, role);

  return filteredMenuItems;
};

export const getUserInitials = username => {
  const fullname = username.split(" ");

  if (fullname.length === 1) return fullname[0].slice(0, 2);

  return fullname.map(word => word.slice(0, 1));
};

/**
 *
 * @param {String} string
 * @param {String} query
 * @returns {{ word: string, index: number }[]}
 */
export const searchForQueryInString = (string, query) => {
  if (
    !query ||
    !string ||
    typeof string !== "string" ||
    typeof query !== "string"
  )
    return [];

  try {
    const reg = RegExp(query + "*", "g");

    /**
     * @type {{ word: string, index: number }[]}
     */
    const result = [];

    /**
     * @type {RegExpExecArray | null}
     */
    let arr;
    while ((arr = reg.exec(string)) !== null) {
      result.push({ word: arr[0], index: arr.index });
    }

    return result;
  } catch {
    return [];
  }
};

const createPath = path => {
  if (!path.length) return "";

  return path.join("/");
};

export const groupAutocompleteSuggestions = autocompleteSuggestions => {
  if (!isArray(autocompleteSuggestions)) {
    console.error("Suggestions type must be an array");

    return [];
  }

  const newResults = [];

  for (const suggestion of autocompleteSuggestions) {
    if (!newResults.length) {
      newResults.push({
        name: createPath(suggestion.context.path),
        results: [{ title: suggestion.title }]
      });
    } else {
      const matchingExistingCategoryIndex = newResults.findIndex(
        ({ name }) =>
          name.toLowerCase() ===
          createPath(suggestion.context.path).toLowerCase()
      );

      if (matchingExistingCategoryIndex < 0) {
        newResults.push({
          name: createPath(suggestion.context.path),
          results: [{ title: suggestion.title }]
        });
      } else {
        newResults[matchingExistingCategoryIndex] = {
          name: newResults[matchingExistingCategoryIndex].name,
          results: [
            ...newResults[matchingExistingCategoryIndex].results,
            { title: suggestion.title }
          ]
        };
      }
    }
  }

  return newResults;
};

/**
 * @returns {import("~/reducers/search-new").GroupedByDocumentType[]}
 */

export const groupSearchResultsByDocumentId = content => {
  // add indexes to answers as accuracy indicator where lower index means more accurate answer

  let accuracy = content.map((answer, index) => {
    return { accuracy: index + 1, ...answer };
  });

  let result = chain(accuracy)
    .groupBy("documentId")
    .toPairs()
    .map(currentItem => {
      return zipObject(
        ["documentId", "content"],
        [Number(currentItem[0]), currentItem[1]]
      );
    })
    .value()
    .map(el => {
      const documentData = el.content[0].document;

      if (isNaN(el.documentId)) {
        let noDocIds = [];
        for (const answer of el.content) {
          noDocIds.push({
            documentId: null,
            content: [answer],
            document: documentData
          });
        }
        return noDocIds;
      } else {
        return { document: documentData, ...el };
      }
    });

  const finalResult = flatten(result)
    .map(el => {
      const accuracy = Math.min(...el.content.map(item => item.accuracy));

      const editors = el.content.map(d => {
        return { editor: d.modifiedBy.userName, updatedAt: d.updatedAt };
      });

      const latestEditor = editors.reduce((r, a) => {
        return r.updatedAt > a.updatedAt ? r : a;
      });

      return { ...el, accuracy: accuracy, latestEditor: latestEditor };
    })
    .sort((a, b) => a.accuracy - b.accuracy);

  return finalResult;
};
