import {
  Controls as OriginalControls,
  useReactFlow,
  useStore,
  useStoreApi
} from "react-flow-renderer";
import { useCallback } from "react";
import { ControlGroup } from "./ControlGroup/ControlGroup";
import { DefaultControl } from "./DefaultControl/DefaultControl";

import { ReactComponent as ExpandIcon } from "~/assets/icons/expand_screen.svg";
import { ReactComponent as UnlockIcon } from "~/assets/icons/unlock.svg";
import { ReactComponent as LockIcon } from "~/assets/icons/lock.svg";
import { ReactComponent as MinusIcon } from "~/assets/icons/minus.svg";
import { ReactComponent as PlusIcon } from "~/assets/icons/plus.svg";

import styles from "./Controls.module.scss";

export const Controls = () => {
  const { zoomIn, zoomOut, fitView } = useReactFlow();
  const store = useStoreApi();

  const isInteractive = useStore(
    state =>
      state.nodesDraggable && state.nodesConnectable && state.elementsSelectable
  );

  const handleInteractiveChange = useCallback(() => {
    store.setState({
      nodesDraggable: !isInteractive,
      nodesConnectable: !isInteractive,
      elementsSelectable: !isInteractive
    });
  }, [isInteractive]);

  return (
    <OriginalControls
      className={styles.controls}
      showFitView={false}
      showInteractive={false}
      showZoom={false}
    >
      <DefaultControl onClick={fitView}>
        <ExpandIcon />
      </DefaultControl>
      <DefaultControl onClick={handleInteractiveChange}>
        {isInteractive ? <UnlockIcon /> : <LockIcon />}
      </DefaultControl>
      <ControlGroup>
        <MinusIcon onClick={() => zoomOut()} />
        <PlusIcon onClick={() => zoomIn()} className={styles.plusIcon} />
      </ControlGroup>
    </OriginalControls>
  );
};
