import { useCallback, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { updateWorkflowTreeAction } from "~/actions/workflow/actions";
import RichTextEditor from "~/components/RichTextEditor";
import { NodeTypes } from "~/components/types";
import { useThunkDispatch } from "~/configureStore";
import { useGetElementById } from "~/hooks/useGetElementById";
import { FLOW_MODAL_CONTAINER_ID } from "../Flow/Flow";
import { InputText } from "../Inputs/InputText/InputText";
import { Modal, ModalHandle } from "../Modal/Modal";

import styles from "./SupportingQuestionModal.module.scss";

interface SupportingQuestionModalProps {
  id: string;
  xPos: number;
  yPos: number;
  question?: string;
  description?: string;
  onModalClose?: () => void;
  previewMode?: boolean;
}

export const SupportingQuestionModal = ({
  id,
  xPos,
  yPos,
  question,
  description,
  onModalClose,
  previewMode
}: SupportingQuestionModalProps) => {
  const { t } = useTranslation();

  const [richTextValue, setRichTextValue] = useState<string>(description || "");

  const dispatch = useThunkDispatch();

  const inputRef = useRef<HTMLInputElement>(null);
  const modalRef = useRef<ModalHandle>(null);

  const saveQuestion = useCallback(
    (question: string, description: string) =>
      dispatch(
        updateWorkflowTreeAction({
          node: {
            data: {
              description,
              question
            },
            position: {
              x: xPos,
              y: yPos
            },
            id,
            type: NodeTypes.SUPPORTING_QUESTION
          }
        })
      ),
    [id, xPos, yPos, dispatch]
  );

  const handleCloseModal = useCallback(() => {
    if (typeof onModalClose === "function") onModalClose();

    modalRef.current?.closeModal();
  }, [onModalClose]);

  const handleSave = () => {
    if (previewMode) return;

    saveQuestion(inputRef.current?.value || "", richTextValue);

    modalRef.current?.closeModal();
  };

  const portalContainer = useGetElementById(FLOW_MODAL_CONTAINER_ID);

  return (
    <>
      {portalContainer
        ? createPortal(
            <Modal
              ref={modalRef}
              onConfirm={handleSave}
              onClose={handleCloseModal}
              footerClassName={styles.modalFooter}
              footerBtnClassName={styles.modalFooterBtn}
              opened
              confirmDisabled={previewMode}
            >
              <div className={styles.modalContent}>
                <InputText
                  ref={inputRef}
                  defaultValue={question}
                  className={styles.supportingInput}
                  label={t("flow.new_response.supporting.question")}
                  placeholder={t("flow.new_response.supporting.write_question")}
                  disabled={previewMode}
                />
                <RichTextEditor
                  className={styles.supportingEditor}
                  onChange={setRichTextValue}
                  value={richTextValue}
                  editorHeight={400}
                />
              </div>
            </Modal>,
            portalContainer
          )
        : null}
    </>
  );
};
