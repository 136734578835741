import { RefObject, useEffect, useMemo, useState } from "react";

interface ElementSize {
  width: number;
  height: number;
}

export const useElementSize = (ref: RefObject<HTMLElement>) => {
  const [elementSize, setElementSize] = useState<ElementSize>({
    width: 0,
    height: 0
  });

  useEffect(() => {
    if (ref.current) {
      const width = ref.current.offsetWidth;
      const height = ref.current.offsetHeight;

      setElementSize({ width, height });
    }
  }, [ref.current]);

  return useMemo(() => elementSize, [elementSize]);
};
