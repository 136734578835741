import { AuthorizationType } from "~/services/api-types";
import type { CookieAttributes } from "js-cookie";
import { getCookie, setCookie, removeCookie } from "./cookies";

const settings: CookieAttributes = {
  domain: window.location.hostname,
  path: "/",
  sameSite: "lax",
  secure: window.location.protocol === "https:"
};

const setLoginTokens = (jwtResponse: AuthorizationType) => {
  const { access_token, refresh_token, expires_in } = jwtResponse;

  const now = Date.now();

  setCookie("access_token", access_token, {
    ...settings,
    expires: new Date(now + expires_in * 1000)
  });
  setCookie("refresh_token", refresh_token, {
    ...settings,
    // we're setting refresh token ttl to one year
    expires: new Date(now + 3_600_000 * 24 * 365)
  });
};

const getAccessToken = () => getCookie("access_token");
const getRefreshToken = () => getCookie("refresh_token");

const removeRefreshToken = () => removeCookie("refresh_token", settings);

const removeAccessToken = () => removeCookie("access_token", settings);

const removeTokens = () => {
  removeAccessToken();
  removeRefreshToken();
};

export {
  setLoginTokens,
  getAccessToken,
  getRefreshToken,
  removeAccessToken,
  removeRefreshToken,
  removeTokens
};
