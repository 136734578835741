import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import axios from "axios";
import { useHistory, useLocation, withRouter } from "react-router";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { MIXPANEL_EVENTS } from "~/contexts/mixpanel/event-types";
import { useMixpanelContext } from "~/contexts/mixpanel";
import { useSelector, useThunkDispatch } from "~/configureStore";
import { useOnClickOutside } from "~/hooks/useOnClickOutside";
import { DocumentPreviewProps } from "~/reducers/modal";
import { getDocumentIdFromUrl } from "~/helpers/url";
import { getDocument, previewFile } from "~/services/DocumentsService";
import { DocumentLocation, DocumentType } from "~/services/api-types";
import {
  CHAT,
  DOCUMENTS,
  EDIT_ARTICLE,
  LOGIN,
  SEARCH
} from "~/constants/routes";
import { hideConfirmModal } from "~/actions/ModalActions";
import {
  getDocuments as getDocumentsAction,
  getFolders as getFoldersAction,
  getFoldersPathSuccess
} from "~/actions/DocumentsActions";
import { DELETE_DOCUMENT_SUCCESS } from "~/actions/types";
import { trackEvent as trackBotwiseEvent } from "~/helpers/tracking";
import LoaderInline from "~/components/LoaderInline/LoaderInline";
import ButtonIcon from "~/atoms/ButtonIcon/ButtonIcon";
import DocumentHeader from "~/components/Documents/DocumentHeader";
import DocBreadcrumb from "~/components/DocBreadcrumb";
import { DocumentPreviewContent } from "~/molecules/DocumentPreview/DocumentPreviewContent";
import { TableOfContents } from "~/atoms/TableOfContents/TableOfContents";
import { DocumentOpenedActionSource } from "~/services/analytics/types";

import { ReactComponent as Preview } from "~/assets/preview.svg";
import { ReactComponent as Close } from "~/assets/x.svg";

import styles from "./DocumentPreview.module.scss";
import "~/components/Documents/index.scss";

const ignoredUrls = [EDIT_ARTICLE];

const DOC_VIEW_EVENTS_PATHS = [
  {
    path: CHAT,
    eventName: MIXPANEL_EVENTS.DOC_VIEW_CHATBOT
  },
  {
    path: SEARCH,
    eventName: MIXPANEL_EVENTS.DOC_VIEW_SEARCH
  },
  {
    path: DOCUMENTS,
    eventName: MIXPANEL_EVENTS.DOC_VIEW_DOCUMENTS
  }
];

export const DocumentPreview = withRouter(() => {
  const { t } = useTranslation();

  const [document, setDocument] = useState<
    (DocumentType & { html: string | null }) | null
  >(null);
  const [loading, setLoading] = useState(false);
  const [closing, setClosing] = useState(false);

  const [tableOfContents, setTableOfContents] =
    useState<NodeListOf<HTMLHeadingElement> | null>(null);

  const [error, setError] = useState<null | string>(null);

  const { modalProps, modalType } = useSelector(({ modal }) => ({
    modalProps: modal.modalProps as DocumentPreviewProps,
    modalType: modal.modalType
  }));

  const { pathname: url } = useLocation();

  const { push: redirect } = useHistory();

  const replaceHistory = (pathname: string) =>
    window.history.pushState("", "", pathname);

  const { trackEvent } = useMixpanelContext();

  const dispatch = useThunkDispatch();

  useEffect(() => {
    const docPreviewType = DOC_VIEW_EVENTS_PATHS.find(({ path }) =>
      url.includes(path)
    );

    if (docPreviewType && document) {
      trackEvent(docPreviewType.eventName);
    }
  }, [url, trackEvent, document]);

  const [documentOpenedTimestamp, setDocumentOpenedTimestamp] =
    useState<Date | null>(null);
  useEffect(() => {
    const docPreviewActionSource = (
      url: string
    ): DocumentOpenedActionSource => {
      if (url.includes(CHAT)) {
        return "CHATBOT_RESPONSE";
      }
      if (url.includes(SEARCH)) {
        return "SEARCH_RESULTS";
      }
      if (url.includes(DOCUMENTS)) {
        return "DOCUMENTS_VIEW";
      }
      return "UNKNOWN";
    };

    if (document) {
      const timestamp = new Date();
      trackBotwiseEvent(
        {
          type: "DOCUMENT_OPENED",
          documentId: document.id,
          actionSource: docPreviewActionSource(url)
        },
        timestamp
      );
      setDocumentOpenedTimestamp(timestamp);
    }
  }, [document]);

  const fetchBackgroundData = useCallback(
    (docParentId: number, path: DocumentLocation[]) => {
      dispatch(getDocumentsAction(0, docParentId));
      dispatch(getFoldersAction(0, docParentId));
      dispatch(getFoldersPathSuccess(path));
    },
    [dispatch]
  );

  const clearModalState = useCallback(
    () => dispatch(hideConfirmModal()),
    [dispatch]
  );

  const id = useMemo(() => getDocumentIdFromUrl(url, ignoredUrls), [url]);

  const fetchDocument = useCallback(
    async (documentId: number | null = null) => {
      setLoading(true);
      setDocument(null);
      setError(null);
      setTableOfContents(null);

      try {
        const document: DocumentType = await getDocument(
          (documentId || id) as number
        );

        const html = (await previewFile((documentId || id) as number)) ?? null;

        return setDocument({ ...document, html });
      } catch (err: unknown) {
        setDocument(null);

        if (typeof err === "string") {
          return setError(err);
        }

        if (axios.isAxiosError(err)) {
          if (err.response?.status === 404) {
            return setError("document_preview.document_not_found");
          }
        }

        return setError("error_message.error_while_fetching");
      } finally {
        setLoading(false);
      }
    },
    [id]
  );
  // clicking on toc item is not properly adding hash to url
  useEffect(() => {
    if (!url.includes(LOGIN)) {
      const currentUrl = window.location.pathname + window.location.hash;
      const isSameUrl = url + window.location.hash === currentUrl;

      if (!id && !document && !loading && !isSameUrl) {
        replaceHistory(url + window.location.hash);
      } else if (id && !document && !loading && url && closing) {
        redirect(DOCUMENTS);
      }
    }
  }, [id, document, loading, closing]);

  useEffect(() => {
    if (document?.id && url && !url.includes(LOGIN)) {
      const hash = window.location.hash;

      const newUrl = `${DOCUMENTS}/${document.id}${hash}`;

      if (newUrl !== url + hash) {
        replaceHistory(newUrl);
      }
    }
  }, [document?.id, url]);

  useEffect(() => {
    if (
      modalType === "PREVIEW_DOCUMENT" &&
      modalProps.id !== document?.id &&
      !loading &&
      !closing &&
      !error
    ) {
      fetchDocument(modalProps.id);
    } else if (
      modalType === null &&
      id &&
      document?.id !== id &&
      !loading &&
      !closing &&
      !error
    ) {
      fetchDocument();
    }
  }, [
    modalProps,
    modalType,
    fetchDocument,
    loading,
    document?.id,
    id,
    closing,
    error
  ]);

  useEffect(() => {
    if (url.includes(DOCUMENTS) && document?.parent) {
      if (document.parent.id) {
        const path =
          document.location && document.location.length
            ? document.location.reverse()
            : [];

        fetchBackgroundData(document.parent.id, path);
      }
    }
  }, [url, document?.parent?.id]);

  const contentRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);

  const onClose = () => {
    if (document && documentOpenedTimestamp) {
      trackBotwiseEvent({
        type: "DOCUMENT_CLOSED",
        documentOpenedTimestamp: documentOpenedTimestamp.toISOString(),
        documentId: document.id,
        timeOpened: Date.now() - documentOpenedTimestamp.valueOf()
      });
    }

    setLoading(true);
    clearModalState();
    setDocument(null);
    setClosing(true);
    setError(null);

    setTimeout(() => setLoading(false), 0);
    setTimeout(() => setClosing(false), 50);
  };

  useEffect(() => {
    if (document && modalType === DELETE_DOCUMENT_SUCCESS) {
      onClose();
    }
  }, [modalType, document]);

  const handleOverlayClose = (e: MouseEvent | TouchEvent) => {
    if (
      overlayRef.current &&
      overlayRef.current.contains(e.target as HTMLElement)
    ) {
      return onClose();
    }

    return undefined;
  };

  const checkHtmlElement = useCallback(
    (htmlElement: HTMLElement | null) => {
      // @ts-ignore
      if (document?.extension !== "pdf" && htmlElement) {
        const headings = htmlElement.querySelectorAll(
          "h1, h2, h3, h4, h5, h6"
        ) as NodeListOf<HTMLHeadingElement>;

        setTableOfContents(headings);
      }
    },
    [document?.extension]
  );

  useEffect(() => {
    if (
      document &&
      document.html &&
      document.extension !== "pdf" &&
      tableOfContents
    ) {
      const hash = window.location.hash;

      if (hash) {
        window.document.getElementById(hash.replace("#", ""))?.scrollIntoView();
      }
    }
  }, [document?.html, document?.extension, tableOfContents]);

  useOnClickOutside(contentRef, handleOverlayClose);

  return modalType === "PREVIEW_DOCUMENT" || id ? (
    <div className={styles.modal}>
      <div
        className={classNames(
          styles.content,
          tableOfContents && tableOfContents.length > 0 && styles.contentTOC
        )}
        ref={contentRef}
      >
        <div className={styles.header}>
          <div className={styles.headerTitle}>
            <Preview />
            <p className={styles.headerTitleText}>
              {t("documents.preview_modal.preview")}
            </p>
          </div>
          <ButtonIcon onClick={onClose} className={styles.headerClose}>
            <Close />
          </ButtonIcon>
        </div>
        {document && (
          <DocBreadcrumb
            data={document.location}
            parent={document.parent}
            className={styles.contentBreadcrumbs}
            handleClick={onClose}
          />
        )}
        {!loading && error && <p className={styles.error}>{t(error)}</p>}
        {loading && <LoaderInline isFetching />}
        {document && (
          <div
            className={classNames(
              styles.documentContent,
              tableOfContents &&
                tableOfContents.length > 0 &&
                styles.documentContentTOC
            )}
          >
            <DocumentHeader onClose={onClose} document={document} />
            {/* @ts-ignore */}
            {document.extension !== "pdf" &&
              tableOfContents &&
              tableOfContents.length > 0 && (
                <TableOfContents
                  className={styles.tableOfContents}
                  items={tableOfContents}
                />
              )}
            <DocumentPreviewContent
              className={styles.documentContentHtml}
              scrollToAnchors={modalProps?.anchors}
              eventsOnHtmlElement={[checkHtmlElement]}
              {...document}
            />
          </div>
        )}
      </div>
      <div className={styles.modalOverlay} ref={overlayRef} />
    </div>
  ) : null;
});
