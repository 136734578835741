import React from "react";
import PropTypes from "prop-types";

import styles from "./UserComment.module.scss";
import { useTranslation } from "react-i18next";

const UserComment = ({ comment }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.item}>
      <div className={styles.bubble}>
        <div className={styles.header}>{t("user.consultant")}</div>
        <div className={styles.comment}>{comment}</div>
      </div>
    </div>
  );
};

UserComment.propTypes = {
  comment: PropTypes.string.isRequired
};

export default UserComment;
