import {
  CLEAR_LOGIN_STATE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_SUCCESS
} from "~/actions/types";
import { USER_ROLES } from "~/constants/app";
import { getAccessToken } from "~/helpers/tokens";

interface LoginState {
  isLoggingIn: boolean;
  isAuthenticated: boolean;
  user?: string;
  email?: string;
  role?: string;
  error?: any;
}

export interface LoginRequestAction {
  type: typeof LOGIN_REQUEST;
}

export interface LoginRequestFailureAction {
  type: typeof LOGIN_FAILURE;
  error: any;
}

export interface LoginRequestSuccessAction {
  type: typeof LOGIN_SUCCESS;
  user: string;
  email: string;
  role: USER_ROLES;
}

export interface LogoutSuccessAction {
  type: typeof LOGOUT_SUCCESS;
}

export interface ClearLoginAction {
  type: typeof CLEAR_LOGIN_STATE;
}

type LoginActions =
  | LoginRequestAction
  | LoginRequestFailureAction
  | LoginRequestSuccessAction
  | LogoutSuccessAction
  | ClearLoginAction;

const initialState: LoginState = {
  isLoggingIn: false,
  isAuthenticated: false
};

const login = (state = initialState, action: LoginActions): LoginState => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        isLoggingIn: true,
        isAuthenticated: false
      };
    case LOGIN_FAILURE:
      return {
        isLoggingIn: false,
        isAuthenticated: false,
        error: action.error
      };
    case LOGIN_SUCCESS:
      return {
        isLoggingIn: false,
        isAuthenticated: true,
        user: action.user,
        role: action.role,
        email: action.email
      };
    case LOGOUT_SUCCESS:
    case CLEAR_LOGIN_STATE:
      return initialState;
    default:
      return state;
  }
};

export default login;
