import React from "react";
import { SHOW_CONFIRM_MODAL, HIDE_CONFIRM_MODAL } from "./types";
import { getReportReasons } from "./../services/IssuesService";
import i18next from "i18next";

import { ReactComponent as OffTheTopicReasonIcon } from "~/assets/icons/off_the_topic_icon.svg";
import { ReactComponent as TooLongReasonIcon } from "~/assets/icons/answer_too_long_icon.svg";
import { ReactComponent as NoAnswerReasonIcon } from "~/assets/icons/no_answer_icon.svg";
import { ReactComponent as TooShortReasonIcon } from "~/assets/icons/answer_too_short_icon.svg";

export function showConfirmModal(type, props) {
  return {
    type: SHOW_CONFIRM_MODAL,
    modalType: type,
    modalProps: props
  };
}

export function hideConfirmModal() {
  return {
    type: HIDE_CONFIRM_MODAL,
    modalProps: {
      show: false
    }
  };
}

export function showReportMessageModal(props) {
  const reportReasons = [
    {
      text: i18next.t("modal_actions.no_answer"),
      icon: <NoAnswerReasonIcon />
    },
    {
      text: i18next.t("modal_actions.off_the_topic"),
      icon: <OffTheTopicReasonIcon />
    },
    {
      text: i18next.t("modal_actions.answer_too_long"),
      icon: <TooLongReasonIcon />
    },
    {
      text: i18next.t("modal_actions.answer_too_short"),
      icon: <TooShortReasonIcon />
    }
  ];
  return dispatch => {
    dispatch(
      showConfirmModal("REPORT_MESSAGE", {
        ...props,
        reportReasons
      })
    );
  };
}
