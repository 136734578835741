import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useThunkDispatch, useSelector } from "~/configureStore";
import { getUsers } from "~/actions";
import { Search } from "~/atoms/Search/Search";
import { changeSearchFiltersAndFetch } from "~/actions/SearchActions";
import { useMixpanelContext } from "~/contexts/mixpanel";
import { MIXPANEL_EVENTS } from "~/contexts/mixpanel/event-types";

import styles from "./AuthorFilter.module.scss";

export const AuthorFilter = () => {
  const [hasFocus, sethasFocus] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const dispatch = useThunkDispatch();

  const { trackEvent } = useMixpanelContext();
  const { t } = useTranslation();

  const users = useSelector(
    ({ users }: { users: { usersNames: { userName: string }[] } }) =>
      users.usersNames ?? []
  );
  const modifiedBy = useSelector(
    ({ searchNew: { filtersUsed } }) => filtersUsed.modifiedBy ?? ""
  );

  const usersList = useMemo(
    () =>
      users
        .map(user => user.userName)
        .filter(user => user.includes(modifiedBy)),
    [users, modifiedBy]
  );

  const fetchUsers = useCallback(() => {
    dispatch(getUsers(null, null, true));
  }, [dispatch]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleSearch = useCallback(
    (result: string) => {
      setSearchValue(result);
      if (!result) {
        dispatch(changeSearchFiltersAndFetch({ modifiedBy: "" }));
        trackEvent(MIXPANEL_EVENTS.SEARCH_FILTERS_AUTHOR);
      }
    },
    [dispatch]
  );

  const handleSelect = useCallback(
    (result: string) => {
      setSearchValue(result);
      if (result) {
        dispatch(changeSearchFiltersAndFetch({ modifiedBy: result }));
        trackEvent(MIXPANEL_EVENTS.SEARCH_FILTERS_AUTHOR);
      }
    },
    [dispatch]
  );

  const handleFocus = () => sethasFocus(true);
  const handleBlur = () => sethasFocus(false);

  return (
    <div className={styles.wrapper}>
      <h6>{t("search.filters.author_label")}</h6>
      <Search
        className={styles.search}
        placeholder={t("search.filters.author_search_placeholder")}
        items={usersList}
        onSearchChange={handleSearch}
        onResultSelect={handleSelect}
        icon={false}
        onFocus={handleFocus}
        onBlur={handleBlur}
        open={
          (!modifiedBy && hasFocus) || (searchValue !== modifiedBy && hasFocus)
        }
      />
    </div>
  );
};
