import React, { useCallback } from "react";
import { Dropdown, DropdownProps, Form, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useSelector, useThunkDispatch } from "~/configureStore";
import {
  changeChartType,
  changeTimeRange,
  fetchMissing,
  removeNotNeeded,
  refetch
} from "~/actions";

import {
  chartTypeOptions,
  timeRangeOptions,
  userOptions
} from "~/constants/app";

export const SelectChartForm = () => {
  const type = useSelector(({ statistics }) => statistics.users.type);
  const datasets = useSelector(({ statistics }) =>
    Object.keys(statistics.users.data.datasets || {})
  );
  const timeRange = useSelector(({ statistics }) => statistics.users.timeRange);

  const { t } = useTranslation();

  const dispatch = useThunkDispatch();

  const handleTypeSelection = useCallback(
    (
      _event: React.SyntheticEvent<HTMLElement, Event>,
      { value }: DropdownProps
    ) => {
      if (typeof value === "string") {
        dispatch(changeChartType(value));
        dispatch(refetch());
      }
    },
    [dispatch]
  );

  const handleDatasetSelection = useCallback(
    (
      _event: React.SyntheticEvent<HTMLElement, Event>,
      { value }: DropdownProps
    ) => {
      dispatch(fetchMissing(value as string[]));
      dispatch(removeNotNeeded(value as string[]));
    },
    [dispatch, datasets]
  );

  const handleTimeRangeSelection = useCallback(
    (
      _event: React.SyntheticEvent<HTMLElement, Event>,
      { value }: DropdownProps
    ) => {
      if (typeof value === "string") {
        dispatch(changeTimeRange(value));
        dispatch(refetch());
      }
    },
    [dispatch]
  );

  return (
    <Form>
      <Form.Group widths="equal">
        <Form.Field>
          <label>
            <>
              <Icon name="area chart" /> {t("statistics.chart_type")}
            </>
          </label>
          <Dropdown
            selection
            fluid
            onChange={handleTypeSelection}
            options={chartTypeOptions.map(({ value, text }) => ({
              text: t(text),
              value
            }))}
            defaultValue={type}
          />
        </Form.Field>
        <Form.Field>
          <label>
            <>
              <Icon name="user" /> {t("statistics.group")}
            </>
          </label>
          <Dropdown
            multiple
            selection
            fluid
            onChange={handleDatasetSelection}
            options={userOptions.map(({ key, text, value }) => ({
              key,
              value,
              text: t(text)
            }))}
            defaultValue={datasets}
          />
        </Form.Field>
        <Form.Field>
          <label>
            <>
              <Icon name="time" /> {t("statistics.time_range")}
            </>
          </label>
          <Dropdown
            selection
            fluid
            onChange={handleTimeRangeSelection}
            options={timeRangeOptions.map(({ value, text }) => ({
              text: t(text),
              value
            }))}
            defaultValue={timeRange}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
};

export default SelectChartForm;
