import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  ACTIVITY_SORT_BY,
  ACTIVITY_SORT_DIRECTION
} from "~/constants/statistics";
import classNames from "classnames";
import { useThunkDispatch } from "~/configureStore";
import { changeFiltersAndFetch } from "~/actions/StatisticsActions";

import { ReactComponent as UpIcon } from "~/assets/up_small.svg";

import styles from "./UsersActivityHeader.module.scss";

interface UsersActivityHeaderProps {
  sortBy: ACTIVITY_SORT_BY | null;
  sortDirection: ACTIVITY_SORT_DIRECTION;
}

export const UsersActivityHeader = ({
  sortDirection,
  sortBy
}: UsersActivityHeaderProps) => {
  const { t } = useTranslation();

  const dispatch = useThunkDispatch();

  const handleSorting = useCallback(
    (sort: ACTIVITY_SORT_BY) => {
      if (sort === sortBy) {
        return dispatch(
          changeFiltersAndFetch({
            sortDirection: sortDirection === "desc" ? "asc" : "desc"
          })
        );
      }

      dispatch(changeFiltersAndFetch({ sortBy: sort, sortDirection: "desc" }));
    },
    [sortDirection, sortBy, dispatch]
  );

  const headerItems = [
    {
      title: t("statistics.users_activity_user"),
      value: ACTIVITY_SORT_BY.userName
    },
    {
      title: t("statistics.users_activity_last_activity"),
      value: ACTIVITY_SORT_BY.lastActivity
    },
    {
      title: t("statistics.users_activity_questions_asked"),
      value: ACTIVITY_SORT_BY.totalQuestionsAsked
    },
    {
      title: t("statistics.users_activity_documents_view_time"),
      value: ACTIVITY_SORT_BY.timeSpentInDocuments
    },
    {
      title: t("statistics.users_activity_total_time"),
      value: ACTIVITY_SORT_BY.timeActive
    }
  ];
  return (
    <div className={styles.header}>
      {headerItems.map(({ title, value }) => (
        <div
          key={value}
          onClick={() => handleSorting(value)}
          className={classNames(
            styles.headerItem,
            sortBy === value && styles.active
          )}
        >
          {title}
          {sortBy === value && (
            <UpIcon
              className={classNames(
                styles.upIcon,
                sortDirection === "desc" && styles.descending
              )}
            />
          )}
        </div>
      ))}
      <div></div>
    </div>
  );
};
