import { connect } from "react-redux";

import IssuesList from "~/components/IssuesList";
import { fetchIssuesLabels, getIssues } from "~/actions";
import { AppThunkDispatch, StoreState } from "~/configureStore";

const mapStateToProps = (state: StoreState) => {
  return {
    issues: state.issues.content,
    totalPages: state.issues.totalPages,
    activePage: state.issues.number + 1,
    isFetching: state.fetching.isFetching,
    labels: state.issues.labels
  };
};

const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    refresh: (pageNumber: number = 0, labels: string[] = []) => {
      dispatch(getIssues(pageNumber, labels));
    },
    getLabels: () => dispatch(fetchIssuesLabels())
  };
};

export type IssuesListProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(IssuesList);
