import { CLEAR_MESSAGES_STATE, ADD_MESSAGE } from "~/actions/types";

const initialState = [];

const messages = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MESSAGE:
      return [...state, action.message];
    case CLEAR_MESSAGES_STATE:
      return initialState;
    default:
      return state;
  }
};

export default messages;
