import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import {
  Icon,
  Input,
  Label,
  Button as ActionButton,
  Dropdown
} from "semantic-ui-react";
import Button from "~/atoms/Button/Button";
import Wrapper from "~/atoms/Wrapper/Wrapper";
import { useSelector } from "~/configureStore";
import { USER_ROLES } from "~/constants/app";
import LoaderInline from "~/components/LoaderInline/LoaderInline";
import { listLoadingOptions } from "~/constants/app";
import moment from "moment";
import { debounce } from "lodash";
import classNames from "classnames";
import { SearchNotFound } from "~/atoms/SearchNotFound/SearchNotFound";

import { ReactComponent as DeleteIcon } from "~/assets/delete.svg";

import styles from "./Users.module.scss";

type User = {
  userName: string;
  id: number;
  role: USERS;
  createdAt: Date;
};

export enum USERS {
  ALL = "All",
  MANAGER = "manager",
  EDITOR = "editor",
  USER = "user"
}

const DEFAULT_SORT = USERS.ALL;
const DEFAULT_PAGE = 1;

interface UsersProps {
  allUsers: User[];
  group: USERS;
  activePage: number;
  totalPages: number;
  fetchUsers: (group: USERS, pageNumber: number) => void;
  inviteUser: () => void;
  editUser: (id: number, role: string, userName: string) => void;
  confirmDeleteUser: (id: number, userName: string, role: string) => void;
  getUsersSearch: (userName: string) => void;
}

export const Users = ({
  fetchUsers,
  inviteUser,
  allUsers,
  editUser,
  confirmDeleteUser,
  group,
  activePage,
  totalPages,
  getUsersSearch
}: UsersProps) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const allowedToEdit = [USER_ROLES.ADMIN, USER_ROLES.MANAGER];
  const [loadingNewPage, setLoadingNewPage] = useState(false);
  const currentUserRole = useSelector(({ login: { role } }) => role);
  const currentUserName = useSelector(({ login: { user } }) => user);
  const [sortBy, setSortBy] = useState<USERS>(DEFAULT_SORT);
  const [userSearchResult, setUserSearchResult] = useState<User[]>([]);
  const [isTyping, setIsTyping] = useState(false);

  const resultsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchUsers(USERS.ALL, DEFAULT_PAGE);
  }, []);

  let timeout: NodeJS.Timeout | null = null;

  const loadNextPage = (group: USERS, page: number) => {
    setLoadingNewPage(true);
    fetchUsers(group, page);
    timeout = setTimeout(() => setLoadingNewPage(false), 1000);
  };

  useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [timeout]);

  const handleSort = useCallback(
    (sortBy: USERS) => {
      setSortBy(sortBy);
      fetchUsers(sortBy, 1);
    },
    [sortBy]
  );

  const handleSearch = useMemo(
    () =>
      debounce((q: string) => {
        getUsersSearch(q);
        setIsTyping(false);
      }, listLoadingOptions.debounceTime),
    [isTyping, searchValue]
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setSearchValue(value);
    setIsTyping(true);
    handleSearch(value);
  };

  const handleScrollLoading = useCallback(
    debounce((e: React.UIEvent<HTMLDivElement>) => {
      e.stopPropagation();

      if (loadingNewPage) return;

      const target = e.target as HTMLDivElement;

      const { scrollTop, offsetHeight, scrollHeight } = target;

      if (
        scrollTop >
        scrollHeight - offsetHeight - listLoadingOptions.offsetBottom
      ) {
        const newPage = activePage + 1;

        if (newPage > totalPages) return;

        return loadNextPage(group, newPage);
      }
    }, listLoadingOptions.debounceTime),
    [loadNextPage, loadingNewPage, totalPages]
  );

  useEffect(() => {
    if (!loadingNewPage && activePage === 1 && totalPages > activePage) {
      if (resultsRef.current) {
        const elementHeight = resultsRef.current.children[0]?.clientHeight;
        let pagesToLoad = Math.floor(
          resultsRef.current.clientHeight / (elementHeight * allUsers.length)
        );
        pagesToLoad = Math.min(pagesToLoad, totalPages - activePage);
        if (pagesToLoad > 0) {
          for (let i = 1; i <= pagesToLoad; i++)
            loadNextPage(group, activePage + i);
        }
      }
    }
  }, [activePage, totalPages, loadingNewPage, allUsers]);

  const sortOptions = [
    {
      key: 1,
      text: t("users.sort_by_all"),
      value: "All",
      onClick: () => handleSort(DEFAULT_SORT)
    },
    {
      key: 2,
      text: t("users.sort_by_manager"),
      value: USERS.MANAGER,
      onClick: () => handleSort(USERS.MANAGER)
    },

    {
      key: 3,
      text: t("users.sort_by_consultant"),
      value: USERS.USER,
      onClick: () => handleSort(USERS.USER)
    },

    {
      key: 4,
      text: t("users.sort_by_teacher"),
      value: USERS.EDITOR,
      onClick: () => handleSort(USERS.EDITOR)
    }
  ];

  const getUserRole = (role: string) => {
    switch (role) {
      case USERS.EDITOR:
        return t("users.role_options.teacher");
      case USERS.USER:
        return t("users.role_options.consultant");
      default:
        return role;
    }
  };

  const UserRow = useCallback(
    ({ user, key }: { user: User; key: number }) => (
      <div key={key} className={styles.resultRow}>
        <div className={styles.resultRowUser}>
          <span className={styles.userName}>{user.userName}</span>
        </div>
        <div className={styles.resultRowRole}>
          {getUserRole(user.role.toLowerCase())}
        </div>
        <span className={styles.createdAt}>
          {moment(user.createdAt).format("DD MMM YYYY hh:mm:ss")}
        </span>
        <div className={styles.resultRowActions}>{actionButtons(user)}</div>
      </div>
    ),
    [searchValue, sortBy]
  );

  const actionButtons = (user: User) => {
    return (
      <>
        {allowedToEdit.includes(currentUserRole as USER_ROLES) &&
          currentUserName !== user.userName && (
            <>
              <ActionButton
                onClick={() => editUser(user.id, user.role, user.userName)}
                className={styles.edit}
              >
                {t("users.edit")}
              </ActionButton>
            </>
          )}
        {currentUserName !== user.userName && (
          <ActionButton
            onClick={() => confirmDeleteUser(user.id, user.userName, user.role)}
          >
            <DeleteIcon />
          </ActionButton>
        )}
      </>
    );
  };

  const UsersTableHead = () => (
    <div className={styles.resultsHeader}>
      <p className={styles.resultsHeaderTitle}>{t("users.user")}</p>
      <p className={styles.resultsHeaderTitle}>{t("users.role")}</p>
      <p className={styles.resultsHeaderTitle}>{t("users.creation_date")}</p>
      <p className={styles.resultsHeaderTitle}>{t("users.actions")}</p>
    </div>
  );

  return (
    <Wrapper title={t("users")} className={styles.wrapper}>
      <div className={styles.searchContainer}>
        <Input
          className="filterInput"
          placeholder={t("users.search")}
          onChange={onChange}
          value={searchValue}
        >
          <Label className="sIconLabel srch">
            <Icon className="search" />
          </Label>
          <input />
        </Input>
        <div className={styles.sortByContainer}>
          <span className={styles.sortBy}>{t("users.filter")}</span>
          <Dropdown
            className={styles.sortByDropdown}
            value={sortBy}
            options={sortOptions}
          />
        </div>
        <div className={styles.addUserContainer}>
          <Button className={styles.addUserButton} onClick={inviteUser}>
            {t("users.add_user")}
          </Button>
        </div>
      </div>
      <UsersTableHead />
      <div
        className={styles.resultsContainer}
        ref={resultsRef}
        onScroll={handleScrollLoading}
      >
        {!isTyping &&
          allUsers
            .filter(({ userName }) => userName.includes(searchValue))
            .filter(user =>
              sortBy === DEFAULT_SORT
                ? user
                : user.role.toLowerCase().includes(sortBy)
            )
            .map(user => <UserRow key={user.id} user={user} />)}
        {(loadingNewPage || isTyping) && (
          <LoaderInline
            className={classNames(!loadingNewPage && styles.loader)}
            isFetching
          />
        )}
        {!isTyping && searchValue && allUsers.length === 0 && (
          <SearchNotFound text={t("users.search_not_found")} />
        )}
      </div>
    </Wrapper>
  );
};

export default Users;
