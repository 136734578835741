import React, { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { InputText } from "~/atoms/Inputs/InputText/InputText";
import { ActionsButtons } from "~/molecules/Article/Header/ActionsButtons";
import { DocumentStatuses } from "~/services/api-types";

import { ReactComponent as SavingSVG } from "~/assets/icons/autosave.svg";
import { ReactComponent as SavedSVG } from "~/assets/icons/saved.svg";
import { ReactComponent as SavingErrorSVG } from "~/assets/icons/close.svg";

const MAX_ARTICLE_TITLE_LENGTH = 255;

import styles from "./Header.module.scss";

interface HeaderProps {
  editMode: boolean;
  articleId: number | null;
  articleTitle: string;
  isSaving: boolean;
  isSavingError: boolean;
  hasDraft: boolean;
  isUpdatesHistoryOpened: boolean;
  articleStatus: DocumentStatuses | null;
  handleArticleTitleChange: (value: string) => void;
  handleArticleUpdate: () => Promise<void>;
  handleUpdatesHistoryOpen: () => void;
}

export const Header = ({
  editMode,
  articleId,
  handleArticleTitleChange,
  handleArticleUpdate,
  handleUpdatesHistoryOpen,
  articleTitle,
  isSaving,
  hasDraft,
  isSavingError,
  articleStatus,
  isUpdatesHistoryOpened
}: HeaderProps) => {
  const [width, setWidth] = useState(0);
  const [hasFocus, setHasFocus] = useState(false);

  const dummy = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (dummy.current) {
      setWidth(dummy.current.offsetWidth);
    }
  }, [articleTitle, dummy.current]);

  const focusAndBlurHandler = useCallback(() => {
    setHasFocus(!hasFocus);
  }, [hasFocus]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (isSavingError) {
        handleArticleUpdate();
      }
    }, 30000);

    return () => clearTimeout(delayDebounceFn);
  }, [isSavingError]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.article_title_input_wrapper}>
        <InputText
          placeholder={t("documents.new_article_input_placeholder")}
          wrapperClass={classNames(
            styles.inputWrapper,
            !editMode && styles.newArticleMode
          )}
          className={classNames(
            styles.input,
            articleTitle && styles.inactive,
            !articleTitle && styles.empty,
            isSaving && styles.saving
          )}
          value={articleTitle}
          onChange={handleArticleTitleChange}
          maxLength={MAX_ARTICLE_TITLE_LENGTH}
          style={{ width }}
          labelClassName={styles.label}
          label={
            <div
              className={classNames(
                styles.dummy,
                hasFocus && styles.hide,
                !hasFocus && styles.maxWidth
              )}
              ref={dummy}
            >
              {articleTitle}
            </div>
          }
          onFocus={focusAndBlurHandler}
          onBlur={focusAndBlurHandler}
        />
        {hasDraft && (
          <div className={styles.autosave}>
            {!isSavingError && (
              <>
                {isSaving ? (
                  <SavingSVG className={styles.saving} />
                ) : (
                  <SavedSVG />
                )}
                {isSaving
                  ? t("documents.article_saving")
                  : t("documents.article_saved")}
              </>
            )}
            {isSavingError && !isSaving && (
              <span className={styles.savingError}>
                <>
                  <SavingErrorSVG />
                  {t("documents.article_saving_error")}
                </>
              </span>
            )}
          </div>
        )}
      </div>
      {articleId && (
        <div className={styles.header_and_actions_buttons_wrapper}>
          <ActionsButtons
            handleUpdatesHistoryOpen={handleUpdatesHistoryOpen}
            articleId={articleId}
            articleStatus={articleStatus}
            isUpdatesHistoryOpened={isUpdatesHistoryOpened}
          />
        </div>
      )}
    </div>
  );
};
