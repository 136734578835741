import React from "react";
import { Dropdown } from "semantic-ui-react";
import PropTypes from "prop-types";

const DropdownFilter = ({
  options,
  initialOptions,
  onChange,
  className,
  placeholder,
  search,
  value
}) => {
  const handleDropdownChange = (_, { value }) => {
    if (value === initialOptions[0].value) {
      value = "";
    }
    onChange(value);
  };

  const mapOptions = options.map(option => ({
    key: option.id,
    text: option.name,
    value: option.id
  }));

  const dropdownOptions = [...initialOptions, ...mapOptions];

  return (
    <Dropdown
      className={className}
      placeholder={placeholder}
      fluid
      selection
      value={value}
      search={search}
      options={dropdownOptions}
      onChange={handleDropdownChange}
    />
  );
};

DropdownFilter.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  initialOptions: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  search: PropTypes.bool
};

DropdownFilter.defaultProps = {
  className: "",
  search: false
};

export default DropdownFilter;
