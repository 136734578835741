import React from "react";
import { PropTypes } from "prop-types";
import "./DocumentDragAndDrop.scss";
import { withTranslation } from "react-i18next";

class DocumentDragAndDrop extends React.Component {
  state = {
    drag: false
  };
  dragCounter = 0;
  dropRef = React.createRef();

  componentDidMount() {
    let div = this.dropRef.current;
    div.addEventListener("dragenter", this.handleDragIn);
    div.addEventListener("dragleave", this.handleDragOut);
    div.addEventListener("dragover", this.handleDrag);
    div.addEventListener("drop", this.handleDrop);
  }

  componentWillUnmount() {
    let div = this.dropRef.current;
    div.removeEventListener("dragenter", this.handleDragIn);
    div.removeEventListener("dragleave", this.handleDragOut);
    div.removeEventListener("dragover", this.handleDrag);
    div.removeEventListener("drop", this.handleDrop);
  }

  handleDrag = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragIn = e => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true });
    }
  };

  handleDragOut = e => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ drag: false });
    }
  };

  handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: false });
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      this.props.handleDrop(files);
      this.dragCounter = 0;
    }
  };

  render() {
    return (
      <div className="dragWrapper" ref={this.dropRef}>
        {this.state.drag && (
          <div className="dragArea">
            <div className="dragText">
              <div>{this.props.t("documents.drag_and_drop.add_document")}</div>
            </div>
          </div>
        )}
        {this.props.children}
      </div>
    );
  }
}

DocumentDragAndDrop.propTypes = {
  handleDrop: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(DocumentDragAndDrop);
