import { AxiosRequestConfig, AxiosResponse } from "axios";
import { dispatch } from "../configureStore";
import { customError } from "~/actions/CustomActions.js";
import { loginFailure } from "~/actions";

import { axiosInstance } from "~/helpers/axios";

const request = async <R = any>(
  options: AxiosRequestConfig,
  jsonType = true,
  throwError = false,
  returnResponse = false
) => {
  let headers = options.headers;
  if (jsonType) {
    headers = {
      ...headers,
      "Content-Type": "application/json"
    };
  }

  options = {
    headers,
    ...options
  };

  try {
    const response = (await axiosInstance(options)) as AxiosResponse<R>;

    if (returnResponse) return response;

    return response.data;
  } catch (err: any) {
    if (throwError) throw err;

    if (err?.response?.data?.message) {
      return dispatch(customError(err.response.data.message));
    }
    if (err?.response?.data?.error_description) {
      if (err.response?.status === 401) {
        return dispatch(
          loginFailure("auth.your_session_has_expired_login_again")
        );
      }

      return dispatch(loginFailure(err.response.data.error_description));
    }
    if (err?.message) {
      return dispatch(customError(err.message));
    }

    return dispatch(customError(err.message));
  }
};

// clearer way to override settings
export const customRequest = async <R = any>({
  jsonType = true,
  throwError = false,
  returnResponse = false,
  ...options
}: AxiosRequestConfig & {
  jsonType?: boolean;
  throwError?: boolean;
  returnResponse?: boolean;
}) => {
  return request(options, jsonType, throwError, returnResponse);
};

export default request;
