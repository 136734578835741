import sanitizeHtml, { IOptions } from "sanitize-html";

const defaultTags = [...sanitizeHtml.defaults.allowedTags];
const additionalTags: IOptions["allowedTags"] = [
  "h1",
  "h2",
  "img",
  "span",
  "video",
  "iframe"
];
const blockedTags: string[] = [];

const shouldOpenInNewTab = (url: string) => {
  const matchAbsoluteUrl = new RegExp("^(?:[a-z]+:)?//", "i");
  return (
    matchAbsoluteUrl.test(url) &&
    window.location?.origin &&
    !url?.startsWith(window.location?.origin)
  );
};

const allowedAttributes: IOptions["allowedAttributes"] = {
  "*": ["id"],
  blockquote: ["style"],
  h1: ["style"],
  h2: ["style"],
  h3: ["style"],
  h4: ["style"],
  h5: ["style"],
  h6: ["style"],
  p: ["style"],
  ol: ["style"],
  li: ["style"],
  tbody: ["style"],
  span: ["style"],
  a: ["href", "name", "target"],
  img: ["src"],
  table: ["style", "border"],
  tr: ["style"],
  td: ["style", "colspan", "rowspan"],
  th: ["colspan", "rowspan"],
  iframe: ["src", "width", "height", "allowfullscreen"],
  b: [{ name: "data-matching", values: ["true"] }],
  strong: [{ name: "data-matching", values: ["true"] }]
};

export const sanitizeSettings: IOptions = {
  allowedTags: defaultTags
    .concat(additionalTags)
    .filter(tag => !blockedTags.includes(tag)),
  allowedAttributes,
  allowedSchemesByTag: {
    a: ["http", "https", "mailto", "tel"],
    img: ["data", "https"]
  },
  allowedClasses: {
    span: ["queryMatched"]
  },
  transformTags: {
    a: (tagName, attribs) => ({
      tagName,
      /*
      TODO: When linked sections not present inside rendered html,
        then transform href and link to the source document (botwise preview).
        Consider implementing on the server side.
      */
      attribs:
        attribs.href && shouldOpenInNewTab(attribs.href)
          ? Object.assign(attribs, { target: "_blank" })
          : attribs
    })
  }
};
