import { connect } from "react-redux";

import Issues from "~/components/Issues";
import {
  clearEditorState,
  saveChangedAnswer,
  editAnswerToQuestion,
  changeAnswer,
  getSingleIssue,
  expandContainer,
  openEditChangeView,
  showConfirmModal
} from "~/actions";

const mapStateToProps = state => {
  return {
    question: state.issues.singleIssue.question,
    issues: state.issues.content,
    answers: state.editor.answers,
    noAnswersFound:
      state.editor.answers.length === 0 &&
      state.editor.fetchingAnswers === false,
    comment: state.issues.singleIssue.comment ?? "",
    issueId: state.issues.singleIssue.id ?? "",
    singleIssue: state.issues?.singleIssue ?? null,
    singleIssueFetching: state.issues?.singleIssueFetching ?? false
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { id } = ownProps.match.params;

  return {
    fetchIssue: () => {
      dispatch(getSingleIssue(id));
    },
    onCloseClick: () => {
      dispatch(clearEditorState());
    },
    changeAnswer: question => {
      dispatch(editAnswerToQuestion(question));
    },
    onSaveButtonClick: backFunction => {
      dispatch(saveChangedAnswer(backFunction));
    },
    changeChosenAnswer: chosenAnswerIndex => {
      dispatch(changeAnswer(chosenAnswerIndex));
    },
    expandContainer: state => dispatch(expandContainer(state)),
    onOpenChangeView: change => {
      dispatch(openEditChangeView(change, false));
    },
    showConfirmModal: closefn =>
      dispatch(showConfirmModal("ISSUES_MODAL", closefn))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Issues);
