import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { sendUserMessage } from "~/actions";
import ButtonIcon from "~/components/atoms/ButtonIcon/ButtonIcon";
import AutocompleteInput from "~/components/molecules/AutocompleteInput/AutocompleteInput";
import styles from "./SendMessageForm.module.scss";

import { ReactComponent as SendSVG } from "~/assets/icons/send.svg";
import { useMixpanelContext } from "~/contexts/mixpanel";
import { MIXPANEL_EVENTS } from "~/contexts/mixpanel/event-types";

const SendMessageForm = ({ sendMessage }) => {
  const { t } = useTranslation();

  const { trackEvent } = useMixpanelContext();

  const handleMessageSent = (...args) => {
    trackEvent(MIXPANEL_EVENTS.CHATBOT_QUERY);

    return sendMessage(...args);
  };

  return (
    <div className={styles.form}>
      <AutocompleteInput
        className={styles.input}
        placeholder={t("send_message.placeholder")}
        onSubmit={handleMessageSent}
        clearOnEnter
        resultsPosition="top"
        icon={
          <ButtonIcon>
            <SendSVG />
          </ButtonIcon>
        }
      />
    </div>
  );
};

SendMessageForm.propTypes = {
  sendMessage: PropTypes.func.isRequired
};

SendMessageForm.defaultProps = {
  suggestions: []
};

const mapDispatchToProps = dispatch => ({
  sendMessage: (text, context) => dispatch(sendUserMessage(text, context))
});

export default connect(null, mapDispatchToProps)(SendMessageForm);
