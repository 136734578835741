import { all, call } from "redux-saga/effects";
import {
  getUsersSaga,
  inviteUserSaga,
  inviteUserSuccessSaga,
  updateUserSaga,
  updateUserSuccessSaga,
  getUsersSearchSaga,
  deleteUserSaga
} from "./users";
import { getDocuments, getFolders, getFoldersPath } from "./documents";
import { getChanges, deleteChanges } from "./changes";
import { getIssues } from "./issues";
import { getProjects } from "./projects";
import { getQuerySuggestionsSaga } from "./autocomplete";
import { publishEventsSaga, trackUserActivitySaga } from "./analytics";

export default function* rootSaga() {
  yield all([
    getQuerySuggestionsSaga(),
    getUsersSaga(),
    getDocuments(),
    getFolders(),
    getFoldersPath(),
    getChanges(),
    getIssues(),
    getProjects(),
    inviteUserSaga(),
    inviteUserSuccessSaga(),
    updateUserSaga(),
    updateUserSuccessSaga(),
    deleteChanges(),
    getUsersSearchSaga(),
    deleteUserSaga(),
    publishEventsSaga(),
    trackUserActivitySaga()
  ]);
}
