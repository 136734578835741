import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { history } from "./store/index";
import ReactTooltip from "react-tooltip";
import AppModalRoot from "~/components/AppModalRoot";
import { Sidebar } from "~/organisms/Sidebar/Sidebar";
import LoginFormContainer from "~/containers/LoginFormContainer";
import Chat from "~/components/Chat";
import Train from "~/components/Train";
import { Statistics } from "~/components/views/Statistics/Statistics";
import DocumentsContainer from "~/containers/DocumentsContainer";
import ChangesContainer from "~/containers/ChangesContainer";
import UsersContainer from "~/containers/UsersContainer";
import ErrorMessage from "~/components/ErrorMessage";
import FetchLoader from "~/components/Loader/Loader";
import TopBar from "~/organisms/TopBar/TopBar";
import i18n from "~/utils/i18n";
import { setAndGetLanguage } from "~/helpers/locale";
import moment from "moment";
import "moment/locale/pl";
import pl from "date-fns/locale/pl";
import en from "date-fns/locale/en-US";
import { DEFAULT_LANGUAGE, TENANT } from "~/constants/app";
import { registerLocale } from "react-datepicker";
import {
  CHAT,
  DOCUMENTS,
  DOCUMENTS_ID,
  EDIT_ARTICLE,
  HOME,
  KNOWLEDGE_BASE,
  KNOWLEDGE_FRAGMENTS,
  LOGIN,
  NEW_ARTICLE,
  SEARCH,
  STATISTICS,
  SYNONYMS,
  TRAIN,
  USERS,
  WORKFLOW,
  WORKFLOW_CREATE,
  WORKFLOW_EDIT
} from "~/constants/routes";
import AppAlertModal from "~/atoms/AppAlertModal/AppAlertModal";
import { DocumentPreview } from "~/molecules/DocumentPreview/DocumentPreview";
import { StoreState } from "./configureStore";
import { Synonyms } from "./components/views/Synonyms/Synonyms";
import { Search } from "./components/views/Search/Search";
import { Workflow } from "./components/views/Workflow/Workflow";
import { Helmet } from "react-helmet";
import { Intercom } from "~/molecules/Intercom/Intercom";
import classNames from "classnames";
import { Article } from "./components/views/Article/Article";
import { useTrackUserActivity } from "./hooks/useTrackUserActivity";
import { getRefreshToken } from "./helpers/tokens";
import { RouteGuard } from "./RouteGuard";

if (process.env.REACT_APP_MOCK_DEV === "true") {
  require("~/services/msw_dev/index");
}

// classes will be toggled on <body>
const loggedBodyClass = "logged";
const guestBodyClass = "guest";

interface AppProps extends ConnectedProps {}

const App = ({
  language,
  isLoggedIn,
  isSidebarExpanded,
  isError,
  isFetching
}: AppProps) => {
  useEffect(() => {
    const lang = setAndGetLanguage(language);
    i18n.changeLanguage(lang);
    moment.locale(lang);
    registerLocale(lang, lang === DEFAULT_LANGUAGE ? pl : en);
  }, [language]);

  useTrackUserActivity();

  const renderWorkflowView = () => {
    return (
      (((TENANT === "demo" || TENANT === "english-test") &&
        process.env.NODE_ENV === "production") ||
        process.env.NODE_ENV === "development") && (
        <Switch>
          <Route path={WORKFLOW} render={() => <Workflow />} />
        </Switch>
      )
    );
  };

  const renderAppContent = () => {
    return (
      <div
        className={classNames(
          "appContainer",
          isSidebarExpanded ? "appContainerExpanded" : "appContainerReduced"
        )}
      >
        <RouteGuard />
        <DocumentPreview />
        <Switch>
          <Route exact path={CHAT} component={Chat} />
          <Route exact path={SEARCH}>
            <Search />
          </Route>
          <Route path={TRAIN} component={Train} />
          <Route
            exact
            path={KNOWLEDGE_BASE}
            render={() => <Redirect to={DOCUMENTS} />}
          />
          <Route
            exact
            path={KNOWLEDGE_FRAGMENTS}
            component={ChangesContainer}
          />
          <Route path={STATISTICS} component={Statistics} />
          <Route path={NEW_ARTICLE} component={Article} />
          <Route path={EDIT_ARTICLE} render={() => <Article editMode />} />
          <Route
            exact
            path={[DOCUMENTS, DOCUMENTS_ID]}
            component={DocumentsContainer}
          />
          <Route path={SYNONYMS} component={Synonyms} />
          <Route exact path={USERS} component={UsersContainer} />
          <Route path={WORKFLOW} render={() => renderWorkflowView()} />
          <Redirect from="*" to={CHAT} />
        </Switch>
      </div>
    );
  };

  const renderContent = () => {
    if (window.location.pathname.includes(LOGIN)) {
      document.body.classList.add(guestBodyClass);
      document.body.classList.remove(loggedBodyClass);
    } else {
      document.body.classList.add(loggedBodyClass);
      document.body.classList.remove(guestBodyClass);
    }

    return (
      <Switch>
        <Route path={LOGIN} component={LoginFormContainer} />
        <Route path="*" render={() => renderAppContent()} />
      </Switch>
    );
  };

  return (
    <ConnectedRouter history={history}>
      <Helmet titleTemplate="%s | BOTWISE.io" defaultTitle="BOTWISE.io" />
      <div style={{ position: "relative" }}>
        <div id={"portal_container"} />
        {(isLoggedIn || !!getRefreshToken()) && (
          <>
            <AppModalRoot />
            <Intercom />
            <FetchLoader isFetching={isFetching} />
            {isError && <ErrorMessage />}
            <Sidebar />
            <TopBar />
            <AppAlertModal />
          </>
        )}
        {renderContent()}
        <ReactTooltip
          delayShow={100}
          place="top"
          type="light"
          effect="solid"
          className="globalTooltip"
        />
      </div>
    </ConnectedRouter>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    isLoggedIn: state.login.isAuthenticated,
    isError: state.errors.openError,
    isFetching: state.fetching.isFetching,
    lastLocation: state.router.location.pathname || "",
    language:
      (state.projects?.current?.language?.toLowerCase?.() as
        | string
        | undefined) ?? (null as null),
    userInfo: state.login,
    isSidebarExpanded: state.utility.isSidebarExpanded
  };
};

type ConnectedProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(App);
