import { connect } from "react-redux";
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { withTranslation } from "react-i18next";
import { Confirm } from "semantic-ui-react";

import { deleteChange, hideConfirmModal } from "~/actions";

class DeleteChangeModal extends Component {
  render() {
    const { t } = this.props;
    return (
      <Confirm
        basic
        size="mini"
        content={t("changes.modal.are_you_sure_deleting_this_item")}
        cancelButton={t("changes.modal.cancel")}
        confirmButton={t("changes.modal.delete")}
        open
        onCancel={this.props.cancel}
        onConfirm={() => this.props.deleteChange(this.props.modalProps.id)}
      />
    );
  }
}

DeleteChangeModal.propTypes = {
  cancel: PropTypes.func,
  deleteChange: PropTypes.func,
  modalProps: PropTypes.shape({ id: PropTypes.string.isRequired }),
  t: PropTypes.func.isRequired
};

const mapStateToProps = (state, ownProps) => {
  return state.modal;
};

const mapDispatchToProps = dispatch => {
  return {
    deleteChange: id => {
      dispatch(deleteChange(id));
      dispatch(hideConfirmModal());
    },
    cancel: () => {
      dispatch(hideConfirmModal());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(DeleteChangeModal));
