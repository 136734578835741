import React, { useCallback } from "react";

interface MatchQueryResultProps {
  text: string;
  query: string;
  htmlTag?: HTMLElement["tagName"] | null | undefined;
}

export const MatchQueryResult = ({
  text,
  query,
  htmlTag
}: MatchQueryResultProps) => {
  const matchIndex = text.toLowerCase().indexOf(query.toLowerCase());

  if (matchIndex === -1) {
    return <span>{text}</span>;
  }

  const CustomTag = (
    htmlTag === undefined ? "span" : htmlTag === null ? "" : htmlTag
  ) as any;

  return (
    <CustomTag>
      {text.substring(0, matchIndex)}
      <b>{text.substr(matchIndex, query.length)}</b>
      {text.substring(matchIndex + query.length, text.length)}
    </CustomTag>
  );
};
