import React, { useCallback, useEffect, useState } from "react";
import { isArray, isEqual } from "lodash";
import { v4 as uuid } from "uuid";
import { useDrop } from "react-dnd";
import { Checkbox, CheckboxProps } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import styles from "./SuggestionsEditor.module.scss";
import ButtonIcon from "~/atoms/ButtonIcon/ButtonIcon";
import Suggestion from "./Suggestion";

import type { SuggestionType } from "~/actions/EditorActions";

import { ReactComponent as PlusIconSVG } from "~/assets/icons/plus.svg";

export const DND_SUGGESTION_TYPE = "DND_SUGGESTION";

export type EditSuggestionType = {
  text: string;
  featured: boolean;
  id: string;
};

interface SuggestionsProps {
  value?: Omit<SuggestionType, "query">[];
  onChange: (newSugggestions: Omit<SuggestionType, "query">[]) => void;
  combinedSuggestionQuery: boolean;
  onCombinedSuggestionQueryChange: (
    event: React.FormEvent<HTMLInputElement>,
    data: CheckboxProps
  ) => void;
}

const SuggestionsEditor = ({
  combinedSuggestionQuery,
  onChange,
  onCombinedSuggestionQueryChange,
  value
}: SuggestionsProps) => {
  const [suggestions, setSuggestions] = useState<EditSuggestionType[]>(
    value?.map?.(suggestion => ({ ...suggestion, id: uuid() })) ?? []
  );
  const [newTagInputValue, setNewTagInputValue] = useState("");

  const { t } = useTranslation();

  const [, drop] = useDrop(() => ({
    accept: DND_SUGGESTION_TYPE,
    drop: () => {}
  }));

  useEffect(() => {
    const newSuggestions: Omit<SuggestionType, "query">[] = suggestions.map(
      ({ featured, text }) => ({ featured, text })
    );
    onChange(newSuggestions);
  }, [suggestions]);

  const handleAddition = useCallback(() => {
    if (newTagInputValue) {
      setSuggestions(oldSuggestions => {
        if (oldSuggestions.find(({ text }) => text === newTagInputValue))
          return oldSuggestions;

        return [
          ...oldSuggestions,
          {
            text: newTagInputValue,
            featured: false,
            id: uuid()
          }
        ];
      });

      return setNewTagInputValue("");
    }

    return;
  }, [newTagInputValue]);

  const handleTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    return setNewTagInputValue(value);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;

    if (key === "Enter") {
      return handleAddition();
    }
  };

  const handleRemoveSuggestion = (suggestionId: string) => {
    if (!suggestions.find(({ id }) => id === suggestionId)) {
      return;
    }

    return setSuggestions(oldSuggestions =>
      [...oldSuggestions].filter(({ id }) => id !== suggestionId)
    );
  };

  const toggleFeatureSuggestion = (suggestionId: string) => {
    if (!suggestions.find(({ id }) => id === suggestionId)) {
      return;
    }

    return setSuggestions(oldSuggestions =>
      [...oldSuggestions].map(suggestion =>
        suggestion.id === suggestionId
          ? { ...suggestion, featured: !suggestion.featured }
          : suggestion
      )
    );
  };

  const handleEditSuggestion = (newValue: string, suggestionId: string) => {
    if (!suggestions.find(({ id }) => id === suggestionId)) {
      return;
    }

    return setSuggestions(oldSuggestions =>
      [...oldSuggestions].map(suggestion =>
        suggestion.id === suggestionId
          ? { ...suggestion, text: newValue }
          : suggestion
      )
    );
  };

  const moveItem = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const item = suggestions[dragIndex];

      if (!item) return;

      return setSuggestions(prevState => {
        const newSuggestions = prevState.filter(
          (_, index) => index !== dragIndex
        );
        newSuggestions.splice(hoverIndex, 0, item);

        return [...newSuggestions];
      });
    },
    [suggestions]
  );

  return (
    <>
      <Checkbox
        className={styles.checkboxSuggestions}
        label={t("suggestions_editor.combine_suggestions_with_question")}
        checked={combinedSuggestionQuery}
        onChange={onCombinedSuggestionQueryChange}
      />
      <div className={styles.suggestions}>
        <h3 className={styles.suggestionsTitle}>
          {t("suggestions_editor.add_selection_option")}
        </h3>
        <div className={styles.suggestionsNewTag}>
          <input
            className={styles.suggestionsNewTagInput}
            type="text"
            onChange={handleTagChange}
            value={newTagInputValue}
            onKeyUp={handleKeyUp}
          />
          <ButtonIcon onClick={handleAddition}>
            <PlusIconSVG />
          </ButtonIcon>
        </div>
        {suggestions.length > 0 && (
          <ul className={styles.suggestionsList} ref={drop}>
            {suggestions.map(({ featured, id, text }, index) => (
              <Suggestion
                key={id}
                featured={featured}
                id={id}
                value={text}
                index={index}
                moveItem={moveItem}
                handleRemoveSuggestion={handleRemoveSuggestion}
                toggleFeatureSuggestion={toggleFeatureSuggestion}
                handleEditSuggestion={handleEditSuggestion}
              />
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default SuggestionsEditor;
