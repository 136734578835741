import classNames from "classnames";
import React from "react";

import { ReactComponent as NoResultsSearch } from "~/assets/search_no_results.svg";

import styles from "./SearchNotFound.module.scss";

export const SearchNotFound = ({
  text,
  extendClassName
}: {
  text?: string;
  extendClassName?: string;
}) => (
  <div className={classNames(styles.noResults, extendClassName)}>
    {text && <p className={styles.noResultsText}>{text}</p>}
    <NoResultsSearch />
  </div>
);
