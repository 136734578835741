import { Location } from "history";
import React, {
  JSXElementConstructor,
  ReactElement,
  useCallback,
  useEffect,
  useState
} from "react";
import { Prompt } from "react-router-dom";

interface Props {
  when?: boolean | undefined;
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  navigate: (path: string) => void;
}

const RouteLeavingGuard = ({ when, navigate, children }: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const closeModal = () => {
    setModalVisible(false);
  };
  const handleBlockedNavigation = useCallback(
    (nextLocation: Location): boolean => {
      if (!confirmedNavigation) {
        setModalVisible(true);
        setLastLocation(nextLocation);
        return false;
      } else {
        return true;
      }
    },
    [confirmedNavigation]
  );
  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  return (
    <>
      <Prompt
        when={when && !confirmedNavigation}
        message={handleBlockedNavigation}
      />
      {React.cloneElement(children, {
        open: modalVisible,
        closeModal: closeModal,
        confirm: handleConfirmNavigationClick
      })}
    </>
  );
};
export default RouteLeavingGuard;
