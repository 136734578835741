import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import styles from "./DefaultAvatar.module.scss";
import { ReactComponent as Avatar } from "~/assets/user_avatar.svg";

const DefaultAvatar = ({ className }) => {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <Avatar className={styles.avatar} alt={t("confirm_modal.avatar_alt")} />
    </div>
  );
};

DefaultAvatar.propTypes = {
  className: PropTypes.string
};

export default DefaultAvatar;
