import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Form, Modal } from "semantic-ui-react";

import { inviteUser, hideConfirmModal } from "~/actions";
import { withTranslation } from "react-i18next";
import { WithMixpanelContext } from "~/contexts/mixpanel";
import { MIXPANEL_EVENTS } from "~/contexts/mixpanel/event-types";

class InviteUserModal extends Component {
  state = {
    email: "",
    role: "",
    isValid: true
  };
  roleOptions = [
    { text: this.props.t("users.role_options.consultant"), value: "user" },
    { text: this.props.t("users.role_options.teacher"), value: "editor" },
    { text: this.props.t("users.role_options.manager"), value: "manager" }
  ];

  handleChange = (e, { name, value }) => {
    name === "email" && this.validateEmail(value);
    this.setState({ [name]: value });
  };

  submit = () => {
    this.props.inviteUser(this.state.email, this.state.role);
    this.props.trackEvent(MIXPANEL_EVENTS.NEW_USER_ADD);
    this.props.close();
  };

  validateEmail = value => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.setState({ isValid: regex.test(String(value).toLowerCase()) });
  };

  render() {
    const { email, role, isValid } = this.state;
    return (
      <Modal size="mini" open onClose={this.props.close} basic>
        <Modal.Header>{this.props.t("users.invite_user")}</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group grouped>
              <Form.Input
                placeholder={this.props.t("users.user_email")}
                name="email"
                value={email}
                onChange={this.handleChange}
                error={!isValid}
              />
              <Form.Select
                options={this.roleOptions}
                placeholder={this.props.t("users.role")}
                name="role"
                value={role}
                onChange={this.handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            negative
            onClick={this.props.close}
            content={this.props.t("users.cancel")}
          />
          <Button
            positive
            onClick={this.submit}
            content={this.props.t("users.send")}
            disabled={!isValid || !email.length}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

InviteUserModal.propTypes = {
  inviteUser: PropTypes.func,
  close: PropTypes.func,
  t: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    inviteUser: (email, role) => dispatch(inviteUser(email, role)),
    close: () => dispatch(hideConfirmModal())
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(WithMixpanelContext()(InviteUserModal)));
