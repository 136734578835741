import classNames from "classnames";
import { debounce } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { Dropdown, DropdownProps, Loader } from "semantic-ui-react";
import {
  changeFiltersAndFetch,
  getUsersActivity,
  getUsersActivityNextPage,
  usersActivityPageChange,
  usersActivitySearch
} from "~/actions/StatisticsActions";
import { Search } from "~/atoms/Search/Search";
import { SearchNotFound } from "~/atoms/SearchNotFound/SearchNotFound";
import { useSelector, useThunkDispatch } from "~/configureStore";
import { listLoadingOptions } from "~/constants/app";
import { activityPeriods, ACTIVITY_PERIOD } from "~/constants/statistics";
import { UsersActivityHeader } from "~/molecules/Statistics/UsersActivity/UsersActivityHeader";
import { UsersActivityRow } from "~/molecules/Statistics/UsersActivity/UsersActivityRow";
import { UserActivityRowSkeleton } from "~/components/skeletons/UserActivityRowSkeleton";

import styles from "./UsersActivity.module.scss";

export const UsersActivity = () => {
  const [loadingNextPage, setLoadingNextPage] = useState(false);

  const { currentPage, error, loading, search, totalPages, users } =
    useSelector(({ statistics }) => statistics.activity);

  const { sortBy = null, sortDirection = "desc" } = useSelector(
    ({ statistics }) => statistics.activity.filters
  );

  const { t } = useTranslation();

  const dispatch = useThunkDispatch();

  useEffect(() => {
    dispatch(getUsersActivity());

    return () => {
      dispatch(usersActivityPageChange(0));
    };
  }, []);

  const debouncedSearch = useRef(
    debounce(() => dispatch(getUsersActivity(true)), 200)
  ).current;

  const handleSearch = useCallback(
    (value: string) => {
      dispatch(usersActivityPageChange(0));
      dispatch(usersActivitySearch(value));

      debouncedSearch();
    },
    [dispatch]
  );

  const handlePeriod = useCallback(
    (
      _event: React.SyntheticEvent<HTMLElement, Event>,
      { value }: DropdownProps
    ) => {
      dispatch(changeFiltersAndFetch({ period: value as ACTIVITY_PERIOD }));
    },
    [dispatch]
  );

  const noUserFound = useMemo(
    () => !loading && !error && !users.length,
    [loading, users, error]
  );

  const skeletonsLoaders = (n = 5) =>
    [...new Array(n)].map((_e, index) => (
      <UserActivityRowSkeleton className={styles.skeleton} key={index} />
    ));

  const loadNextPage = useCallback(
    (pageNumber: number) => {
      dispatch(getUsersActivityNextPage(pageNumber)).finally(() =>
        setLoadingNextPage(false)
      );
    },
    [dispatch]
  );

  const handleScrollLoading = useCallback(
    debounce((e: React.UIEvent<HTMLDivElement>) => {
      e.stopPropagation();

      if (loadingNextPage || loading) return;

      const target = e.target as HTMLDivElement;

      const { scrollTop, offsetHeight, scrollHeight } = target;

      if (
        scrollTop >
        scrollHeight - offsetHeight - listLoadingOptions.offsetBottom
      ) {
        const newPage = currentPage + 1;

        if (newPage >= totalPages) return;

        setLoadingNextPage(true);

        return loadNextPage(newPage);
      }
    }, listLoadingOptions.debounceTime),
    [currentPage, loadingNextPage, totalPages, loading]
  );

  return (
    <div className={styles.wrapper}>
      <h2>{t("statistics.users_activity")}</h2>
      <Dropdown
        onChange={handlePeriod}
        defaultValue={ACTIVITY_PERIOD.LAST_30_DAYS}
        options={activityPeriods.map(period => ({
          ...period,
          text: t(period.text)
        }))}
      />
      <Search
        placeholder={t("statistics.users_activity_search_placeholder")}
        wrapperClass={styles.searchWrapper}
        className={styles.search}
        items={[]}
        open={false}
        value={search}
        onSearchChange={handleSearch}
      />
      {!error && !noUserFound && (
        <UsersActivityHeader sortBy={sortBy} sortDirection={sortDirection} />
      )}
      <div
        onScroll={handleScrollLoading}
        className={classNames(
          styles.usersTable,
          noUserFound && styles.noUserFound
        )}
      >
        <>
          {users.length > 0 &&
            !loading &&
            !error &&
            users.map(user => (
              <UsersActivityRow key={user.user.userName} userData={user} />
            ))}
          {noUserFound && (
            <SearchNotFound
              extendClassName={styles.searchNotFound}
              text={t("users.search_not_found")}
            />
          )}
          {loading && !error && skeletonsLoaders()}
          {error && (
            <SearchNotFound
              extendClassName={styles.searchNotFound}
              text={t("statistics.users_activity_error")}
            />
          )}
          {loadingNextPage && (
            <div className={styles.newPageLoader}>
              <Loader size="small" active={true} />
            </div>
          )}
        </>
      </div>
    </div>
  );
};
