import { useEffect } from "react";

export const useHtmlSnippetEvent = (
  ref: HTMLElement | null,
  event: (e: HTMLElement) => void
) => {
  useEffect(() => {
    if (ref) {
      event(ref);
    }
  }, [event, ref]);
};
