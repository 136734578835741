import classNames from "classnames";
import { debounce } from "lodash";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import ReactTooltip from "react-tooltip";
import { Icon, IconProps } from "semantic-ui-react";
import { v4 as uuid } from "uuid";

import styles from "./InputText.module.scss";

interface InputTextProps
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "value" | "type" | "onChange" | "onKeyUp"
  > {
  label?: React.ReactNode;
  defaultValue?: string;
  value?: string;
  onChange?: (value: string) => void;
  debounceTime?: number;
  icon?: IconProps["name"];
  iconClass?: string;
  wrapperClass?: string;
  error?: string;
  onKeyUp?: (
    value: string,
    event?: React.KeyboardEvent<HTMLInputElement>
  ) => void;
  onKeyUpKey?: string;
  labelClassName?: string;
}

const InputText = forwardRef<HTMLInputElement, InputTextProps>(
  (
    {
      label,
      value,
      // eslint-disable-next-line
      className,
      onChange,
      debounceTime = 0,
      icon,
      iconClass,
      wrapperClass,
      // eslint-disable-next-line
      maxLength,
      error,
      onKeyUp,
      onKeyUpKey,
      labelClassName,
      defaultValue,
      ...rest
    }: InputTextProps,
    ref
  ) => {
    const [inputValue, setInputValue] = useState<string>(defaultValue || "");

    const handleInputChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value: inputValue } = e.target;

        if (typeof value !== "undefined" && typeof onChange === "function") {
          onChange(inputValue);
        }

        return setInputValue(inputValue);
      },
      [value, onChange]
    );

    const handleKeyUp = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (
          typeof onKeyUpKey === "string" &&
          e.key === onKeyUpKey &&
          typeof onKeyUp === "function"
        ) {
          return onKeyUp(inputValue, e);
        }
      },
      [onKeyUp, inputValue, onKeyUpKey]
    );

    useEffect(() => {
      if (typeof onChange === "function" && typeof value === "undefined") {
        debounce(() => onChange(inputValue), debounceTime)();
      }
    }, [inputValue, value, onChange, debounce]);

    const tooltipId = useMemo(() => uuid(), []);

    return (
      <label
        className={classNames(
          styles.wrapper,
          wrapperClass,
          typeof error === "string" && styles.error
        )}
      >
        {label && (
          <span className={classNames(styles.label, labelClassName)}>
            {label}
          </span>
        )}
        <input
          type="text"
          className={classNames(styles.input, className)}
          value={typeof value === "string" ? value : inputValue}
          ref={ref}
          onChange={handleInputChange}
          maxLength={maxLength}
          data-for={tooltipId}
          data-tip={typeof error === "string" ? error : null}
          onKeyUp={handleKeyUp}
          data-hj-allow
          {...rest}
        />
        {error && (
          <ReactTooltip
            id={tooltipId}
            place="top"
            type="error"
            effect="solid"
          />
        )}
        {icon && <Icon name={icon} className={classNames(iconClass)} />}
        {typeof maxLength === "number" && (
          <span className={styles.maxLength}>
            {(typeof value === "string" ? value : inputValue)?.length ?? 0}/
            {maxLength}
          </span>
        )}
      </label>
    );
  }
);

InputText.displayName = "ForwardRef(InputText)";

export { InputText };
