import React from "react";
import { HintType } from "~/services/api-types";
import type { StoreState } from "~/configureStore";
import sanitizeHtml from "sanitize-html";
import { connect } from "react-redux";
import { Label } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { FileLabel } from "~/components/FileLabel";
import { sanitizeSettings } from "~/constants/sanitizeSettings";
import { TitleWithContext } from "~/atoms/TitleWithContext/TitleWithContext";

import styles from "./ChangesDetailsFragment.module.scss";

const getImgFromPlainAnswer = (answer: string) => {
  const temp_div = document.createElement("div");
  temp_div.innerHTML = answer;
  const imgList = temp_div.getElementsByTagName("img");
  return imgList;
};

interface ChangesDetailsFragmentProps {
  fragment: HintType;
  preview?: boolean;
  openImg: (i: string, image: HTMLImageElement) => void;
  isWelcomeMessageChatbot: boolean;
  isWelcomeMessageSearchEngine: boolean;
}

export const ChangesDetailsFragment = ({
  fragment,
  preview = false,
  openImg,
  isWelcomeMessageChatbot,
  isWelcomeMessageSearchEngine
}: ChangesDetailsFragmentProps) => {
  const { t } = useTranslation();
  const { id, question = "", context, answerText = "", answer = "" } = fragment;

  const images = getImgFromPlainAnswer(answer);
  const hasImages = images.length > 0;

  const handleImageOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    openImg(id, images[0]);
  };

  const renderLabels = () => {
    return (
      <>
        {isWelcomeMessageChatbot && (
          <Label className={styles.descriptionWelcomeLabel} horizontal>
            {t("changes.details.welcome_message.chatbot")}
          </Label>
        )}
        {isWelcomeMessageSearchEngine && (
          <Label className={styles.descriptionWelcomeLabel} horizontal>
            {t("changes.details.welcome_message.search_engine")}
          </Label>
        )}
      </>
    );
  };

  const renderShortPreview = () => {
    return (
      <>
        {renderLabels()}
        {question && (
          <TitleWithContext className={styles.title} contextParts={context}>
            {question}
          </TitleWithContext>
        )}
        {answerText && (
          <p className={styles.descriptionAnswerShortened}>{answerText}</p>
        )}
      </>
    );
  };

  return (
    <>
      {!preview
        ? renderShortPreview()
        : answer && (
            <div
              className={styles.changesDetailsAnswerContainer}
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(answer, { ...sanitizeSettings })
              }}
            />
          )}
      {hasImages && (
        <FileLabel name="Grafika" extension="jpg" onClick={handleImageOpen} />
      )}
    </>
  );
};

const mapStateToProps = (
  state: StoreState,
  ownProps: ChangesDetailsFragmentProps
) => {
  return {
    isWelcomeMessageChatbot:
      state.projects.current?.defaultGreeting?.id === ownProps.fragment.id,
    isWelcomeMessageSearchEngine:
      state.projects.current?.searchWelcomeMessage?.id === ownProps.fragment.id
  };
};

export default connect(mapStateToProps, null)(ChangesDetailsFragment);
