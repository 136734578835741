import { useCallback } from "react";
import { useSelector } from "~/configureStore";
import { getOffsetTop } from "~/helpers/scroll";

const SCROLL_OFFSET = 75;
const CURRENT_QUERY_MATCH_CLASS = "currentOccurrence";
const CURRENT_QUERY_MATCH_SELECTOR = `.${CURRENT_QUERY_MATCH_CLASS}`;
export const HIGHLIGHTED_SPAN_SELECTOR = `span.queryMatched`;

export const useScrollToCurrentMatch = (scrollWrapper: HTMLElement | null) => {
  const { currentPosition } = useSelector(
    ({ searchNew }) => searchNew.queryMatches
  );
  return useCallback(() => {
    if (scrollWrapper) {
      scrollToQueryMatch(scrollWrapper, currentPosition);
    }
  }, [scrollWrapper, currentPosition]);
};

export function scrollToQueryMatch(
  scrollWrapper: HTMLElement,
  matchNumber: number
) {
  const currentOccurrence = scrollWrapper.querySelector(
    CURRENT_QUERY_MATCH_SELECTOR
  );
  const scrollTargets = Array.from(
    scrollWrapper.querySelectorAll(HIGHLIGHTED_SPAN_SELECTOR)
  );
  if (matchNumber >= 0 && matchNumber <= scrollTargets.length - 1) {
    const scrollTarget = scrollTargets.at(matchNumber);
    if (scrollTarget == currentOccurrence) {
      return;
    }
    if (scrollTarget && scrollTarget instanceof HTMLElement) {
      const offsetTop = getOffsetTop(
        scrollTarget,
        scrollWrapper.offsetParent as HTMLElement
      );
      const yDiff =
        offsetTop - scrollWrapper.scrollTop - scrollWrapper.offsetTop;
      const yScrollInPixels = yDiff - SCROLL_OFFSET;

      if (yDiff > scrollWrapper.clientHeight || yDiff < 0) {
        scrollWrapper.scrollBy({
          top: yScrollInPixels,
          left: 0
        });
      }
      scrollTarget.classList.add(CURRENT_QUERY_MATCH_CLASS);
    }
  }
  currentOccurrence?.classList.remove(CURRENT_QUERY_MATCH_CLASS);
}
