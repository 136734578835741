export enum MIXPANEL_EVENTS {
  CHATBOT_QUERY = "chatbot_query",
  SEARCH_QUERY = "search_query",
  CHATBOT_VISIT = "chatbot_visit",
  SEARCH_VISIT = "search_visit",
  ISSUES_VISIT = "issues_visit",
  FRAGMENTS_VISIT = "fragments_visit",
  FRAGMENTS_QUERY_SEARCH = "fragments_querySearch",
  FRAGMENT_REMOVAL = "fragment_removal",
  FRAGMENT_EDIT = "fragment_edit",
  FRAGMENT_ADD = "fragment_add",
  SYNONYMS_VISIT = "synonyms_visit",
  SYNONYMS_ADD = "synonyms_add",
  SYNONYMS_EDIT = "synonyms_edit",
  STATS_GENERAL_VISIT = "statsGeneral_visit",
  STATS_USERS_VISIT = "statsUsers_visit",
  QUERIES_REPORT_GENERATION = "queriesReport_generation",
  NEW_USER_ADD = "newUser_add",
  NEW_DOC_ADD = "newDoc_add",
  DOC_REMOVAL = "doc_removal",
  DOC_VIEW_DOCUMENTS = "docView_documents",
  DOC_VIEW_SEARCH = "docView_search",
  DOC_VIEW_CHATBOT = "docView_chatbot",
  ANSWER_REPORT = "answer_report",
  RELATED_OPEN = "related_open",
  TABLE_OF_CONTENTS_ITEM_CLICK = "tableOfContents_itemClick",
  SEARCH_FILTERS_MODIFICATION_DATE = "filters_modificationDate",
  SEARCH_FILTERS_DOCUMENT_TYPE = "filters_documentType",
  SEARCH_FILTERS_SOURCE = "filters_source",
  SEARCH_FILTERS_AUTHOR = "filters_author"
}
