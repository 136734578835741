import { AppThunkDispatch } from "~/configureStore";
import { DEFAULT_ALERT_DISPLAY_TIME } from "~/reducers/AppAlert";
import { convertToArticle, publishDraft } from "~/services/ArticlesService";
import { ALERT } from "./AppAlertActions";

export const publishDraftAction =
  (articleId: number, description?: string) => async () => {
    try {
      await publishDraft(articleId as number, description);
    } catch (err) {}
  };

export const convertToArticleAction =
  (articleId: number) => async (dispatch: AppThunkDispatch) => {
    dispatch({
      type: ALERT,
      showModal: true,
      alert: "documents.convert_converting_message"
    });

    try {
      await convertToArticle(articleId).then(() => {
        dispatch({
          type: ALERT,
          alert: "documents.convert_converted_message"
        });
      });
    } catch (err) {
      dispatch({
        type: ALERT,
        alert: "documents.convert_converting_failed_message"
      });

      throw err;
    } finally {
      setTimeout(
        () =>
          dispatch({
            type: ALERT,
            showModal: false,
            alert: ""
          }),
        DEFAULT_ALERT_DISPLAY_TIME
      );
    }
  };
