import React from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { withTranslation } from "react-i18next";
import ButtonIcon from "~/atoms/ButtonIcon/ButtonIcon";
import {
  Button,
  Dropdown,
  Icon,
  Radio,
  Table,
  Breadcrumb,
  Input,
  Modal,
  Divider
} from "semantic-ui-react";
import { isArray, isEqual, truncate } from "lodash";
import { ReactComponent as PreviewIcon } from "~/assets/preview.svg";
import { ReactComponent as DownloadIcon } from "~/assets/download.svg";
import { ReactComponent as DeleteIcon } from "~/assets/delete.svg";
import { ReactComponent as FolderIcon } from "~/assets/folder 2 copy.svg";
import { ReactComponent as ConfluenceIcon } from "~/assets/icons/confluence.svg";
import { ReactComponent as SharepointIcon } from "~/assets/icons/sharepoint.svg";
import { ReactComponent as NextcloudIcon } from "~/assets/icons/nextcloud.svg";
import { ReactComponent as AddFolderIcon } from "~/assets/add-folder.svg";
import { ReactComponent as UpIcon } from "~/assets/up_small.svg";
import { canUserDownloadDocuments } from "~/constants/app";

import styles from "./Documents.module.scss";
import "./index.scss";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import { debounce } from "lodash";
import DocumentDragAndDrop from "./DocumentDragAndDrop";

import * as api from "~/services/DocumentsService";

import {
  addFolder,
  updateFolder,
  toggleDocumentUse,
  customError,
  uploadFileAction,
  updateFileAction,
  updateWebDocument,
  showConfirmModal,
  setCurrentFolderAction,
  updateFoldersPath
} from "~/actions";

import { Events, scrollSpy } from "react-scroll";

import { getFilteredFiles } from "./utils";

import { FileLabel } from "~/components/FileLabel";

import LoaderInline from "~/components/LoaderInline/LoaderInline";

import { DOCUMENTS, EDIT_ARTICLE, NEW_ARTICLE } from "~/constants/routes";
import { listLoadingOptions } from "~/constants/app";
import { WithMixpanelContext } from "~/contexts/mixpanel";
import { MIXPANEL_EVENTS } from "~/contexts/mixpanel/event-types";
import { Link, generatePath } from "react-router-dom";
import { TagLabel } from "~/atoms/TagLabel/TagLabel";
import { DocumentStatuses } from "~/services/api-types";

const filesize = require("filesize");

const FOLDER_TYPES = {
  CONFLUENCE: "CONFLUENCE",
  SHAREPOINT: "SHAREPOINT",
  NEXTCLOUD: "NEXTCLOUD"
};

const FolderLabel = ({ name }) => {
  const getFolderIcon = () => {
    const folderName = name?.replace(/\s/g, "")?.toUpperCase();

    switch (folderName) {
      case FOLDER_TYPES.CONFLUENCE:
        return <ConfluenceIcon />;
      case FOLDER_TYPES.SHAREPOINT:
        return <SharepointIcon />;
      case FOLDER_TYPES.NEXTCLOUD:
        return <NextcloudIcon />;
      default:
        return <FolderIcon />;
    }
  };

  return (
    <>
      {getFolderIcon()}
      <div className="folderName">
        {truncate(name, { length: 60, separator: /,?\.* +/ })}
      </div>
    </>
  );
};

FolderLabel.propTypes = {
  name: PropTypes.string.isRequired
};

const DocumentLabel = props => (
  <FileLabel
    {...props}
    iconSize={20}
    bigLabel
    noBorder
    labelStyle={{ color: "#000" }}
  />
);

class DocumentsComponent extends React.Component {
  state = {
    totalPage: 0,
    breadPath: [
      {
        name: this.props.t("documents.breadcrumb.documents"),
        id: 0
      }
    ],
    activeId: 0,
    prevId: null,
    modalFolderId: null,
    folderPathName: this.props.t("documents.breadcrumb.documents"),
    newFolder: null,
    // two ids one of rendered(cliclked row) and other of edited document/folder
    editIdx: null,
    editId: null,
    fileWindowOpen: false,
    movedId: null,
    moveType: null,
    folderToMoveId: null,
    foldersMenu: [],
    foldersTree: [
      {
        name: this.props.t("documents.breadcrumb.documents"),
        id: null
      }
    ],
    menuPosition: null,
    height: 0,
    headHeight: 0,
    fileToUpdate: null,
    sortParam: "versionUpdatedAt",
    sortDir: "desc",
    openMenuItem: null,
    isActionAuthorized: true,
    pagingDoc: 0,
    pagingFolder: 0,
    isFileUploading: false,
    previewDocId: null
  };

  fileUploaderRef = React.createRef();

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    if (this.props.foldersPath.length) {
      this.updateBreadcrumbPath();
    }

    if (
      this.props.foldersPath.length <= 1 ||
      !this.props.foldersPath[this.props.foldersPath.length - 1].id ||
      this.props.initialId
    ) {
      this.fetchData({
        parentId: this.props.initialId || ""
      });
    }
    // tableScroll
    const table = document.getElementsByClassName("tableScroll")[0];
    table.addEventListener("scroll", this.pageScroll);
    Events.scrollEvent.register("begin", function () {});

    Events.scrollEvent.register("end", function () {});

    scrollSpy.update();
    const actAut = this.props.authorizedRoles.indexOf(this.props.userRole) >= 0;
    this.setState({
      isActionAuthorized: actAut
    });
    this.setState({ foldersMenu: this.props.moveFoldersList });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.fetchError?.openError && prevProps.fetchError?.openError) {
      this.fetchData({
        parentId: this.props.initialId || ""
      });
    }

    if (!isEqual(this.props.userRole, prevProps.userRole)) {
      const actAut =
        this.props.authorizedRoles.indexOf(this.props.userRole) >= 0;
      this.setState({
        isActionAuthorized: actAut
      });
    }

    const menuItems = this.props.foldersPath !== prevProps.foldersPath;
    const tableToUpdate =
      this.props.folders !== prevProps.folders ||
      this.props.documents !== prevProps.documents ||
      prevState.editIdx !== this.state.editIdx ||
      prevState.newFolder !== this.state.newFolder ||
      this.state.fileWindowOpen !== prevState.fileWindowOpen ||
      this.state.openMenuItem !== prevState.openMenuItem;
    if (tableToUpdate) {
      if (
        this.props.docPageElements < 25 &&
        this.state.pagingDoc === this.props.totalPagesDoc - 1
      ) {
        this.setState(prevState => ({
          pagingDoc: prevState.pagingDoc + 1
        }));
      }
      if (
        this.props.folderPageElements < 25 &&
        this.state.pagingFolder === this.props.totalPagesFolders - 1
      ) {
        this.setState(prevState => ({
          pagingFolder: prevState.pagingFolder + 1
        }));
      }
    }
    if (!isEqual(this.props.moveFoldersList, prevProps.moveFoldersList)) {
      this.setState({ foldersMenu: this.props.moveFoldersList });
    }

    if (
      this.props.foldersPath.length &&
      !isEqual(prevProps.foldersPath, this.props.foldersPath)
    ) {
      this.updateBreadcrumbPath();
    }

    ReactTooltip.rebuild();
  }

  fetchData = ({
    pageNumber = 0,
    parentId = null,
    withFolder = true,
    withDoc = true,
    sort = false,
    pageNumberDoc = 0,
    docId,
    reset = false
  }) => {
    if (!sort?.by) {
      sort = {
        by: this.state.sortParam,
        direction: this.state.sortDir
      };
    }
    this.props.refresh(
      pageNumber,
      parentId,
      withFolder,
      sort,
      pageNumberDoc,
      withDoc,
      docId
    );
    this.setState({
      isFileUploading: false
    });
    if ((this.state.pagingDoc === 0 && pageNumberDoc === 0) || reset) {
      this.setState({
        pagingDoc: 1
      });
    }
  };

  pageScroll = debounce(event => {
    const fetchDocuments =
      this.props.totalPagesDoc !== this.state.pagingDoc &&
      this.props.totalElemDoc;
    const fetchFolders =
      this.props.totalPagesFolders !== this.state.pagingFolder &&
      this.props.totalElemFolders;

    if (
      event.target.clientHeight + event.target.scrollTop >
        event.target.scrollHeight - listLoadingOptions.offsetBottom &&
      (fetchDocuments || fetchFolders)
    ) {
      let sort = { by: this.state.sortParam, direction: this.state.sortDir };
      this.fetchData({
        pageNumber: this.state.pagingFolder + 1,
        parentId: this.state.activeId,
        withFolder: fetchFolders,
        withDoc: fetchDocuments,
        sort,
        pageNumberDoc: this.state.pagingDoc + 1
      });
      this.setState(prevState => ({
        pagingFolder: fetchFolders
          ? prevState.pagingFolder + 1
          : prevState.pagingFolder,
        pagingDoc: fetchDocuments
          ? prevState.pagingDoc + 1
          : prevState.pagingDoc
      }));
    }
  }, listLoadingOptions.debounceTime);

  updateBreadcrumbPath = () => {
    const breadcrumbs = isArray(this.props.foldersPath)
      ? this.props.foldersPath
      : [];

    if (breadcrumbs.length && breadcrumbs[0].id !== 0) {
      if (breadcrumbs[breadcrumbs.length - 1].id !== 0) {
        breadcrumbs.push({
          name: this.props.t("documents.breadcrumb.documents"),
          id: 0
        });
        breadcrumbs.reverse();
      } else {
        breadcrumbs.reverse();
      }
    }

    const activeId = breadcrumbs.length
      ? breadcrumbs[breadcrumbs.length - 1].id
      : null;

    this.setState({
      breadPath: breadcrumbs,
      activeId: activeId
    });
  };

  updateWindowDimensions = () => {
    this.setState({
      wrapperHeight: this.container.offsetHeight,
      headHeight: this.headContainer.offsetHeight
    });
  };

  componentWillUnmount() {
    const table = document.getElementsByClassName("tableScroll")[0];
    table.removeEventListener("scroll", this.pageScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");

    clearTimeout(this.checkIfFinishedTimeout);
    clearTimeout(this.breadCrumbTimeout);
  }

  selectFolderMove = (e, data) => {
    if (data.id === this.state.movedId) return;
    this.setState(prevState => ({
      foldersTree: [
        ...prevState.foldersTree,
        {
          id: data.id,
          name: data.name
        }
      ],

      fileWindowOpen: true,
      prevId: prevState.modalFolderId,
      prevName: prevState.folderPathName,
      modalFolderId: data.id,
      folderPathName: data.name,
      folderToMoveId: data.id
    }));
    this.props.getFoldersPath(0, data.id);
    e.stopPropagation();
  };

  returnFolder = (e, data) => {
    const returnId =
      this.state.foldersTree[this.state.foldersTree.length - 2].id;
    this.props.getFoldersPath(0, returnId);
    const newFoldersTree = [...this.state.foldersTree];
    newFoldersTree.pop();
    this.setState(prevState => ({
      foldersTree: [...newFoldersTree],
      folderToMoveId: returnId
    }));
  };

  returnHeader = () => {
    const newId = this.state.breadPath[this.state.breadPath.length - 2].id;
    this.fetchData({
      parentId: newId
    });

    this.props.setCurrentFolder(newId);
    this.setState(
      prevState => ({
        breadPath: [
          ...prevState.breadPath.slice(0, prevState.breadPath.length - 1)
        ],
        activeId: newId,
        pagingFolder: 0,
        pagingDoc: 0
      }),
      () => this.props.setFoldersPath(this.state.breadPath)
    );
  };

  checkIfFinished = breadPath => {
    if (this.props.isFetching)
      return (this.checkIfFinishedTimeout = setTimeout(
        () => this.checkIfFinished(breadPath),
        200
      ));

    return this.setState(
      prevState => ({
        breadPath: [...prevState.breadPath, breadPath]
      }),
      () => this.props.setFoldersPath(this.state.breadPath)
    );
  };

  selectFolder = data => {
    this.fetchData({
      parentId: data.id
    });

    if (!this.props.isFetching) {
      this.breadCrumbTimeout = setTimeout(
        () =>
          this.checkIfFinished({
            name: data.name,
            id: data.id
          }),
        200
      );
    }
    this.props.setCurrentFolder(data.id);
    this.setState(prevState => ({
      activeId: data.id,
      pagingFolder: 0,
      pagingDoc: 0
    }));
  };

  handleFileMove = (e, id, type) => {
    this.props.getFoldersPath(0, this.state.activeId);
    const position = e.target.getBoundingClientRect();
    this.setState({
      foldersTree: [...this.state.breadPath],
      menuPosition: position,
      fileWindowOpen: true,
      movedId: id,
      moveType: type
    });
  };

  updateDocumentOption = (docSource, docId) => {
    switch (docSource) {
      case "internal":
        return (
          <Dropdown.Item
            onClick={e => {
              this.updateClick(e, docId);
            }}
          >
            {this.props.t("documents.update.add_new_version")}
          </Dropdown.Item>
        );
      case "web":
        return (
          <Dropdown.Item
            onClick={e => {
              this.props.updateWebDocument(docId);
            }}
          >
            {this.props.t("documents.update")}
          </Dropdown.Item>
        );
      default:
        return null;
    }
  };

  renderMenuItems = (object, type, idx, isActionAuthorized) => {
    const isDoc = type === "doc";

    return (
      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => {
            this.setState({
              editIdx: idx,
              editId: object.id
            });
          }}
        >
          {this.props.t("documents.update.rename")}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={e => {
            this.handleFileMove(e, object.id, type);
          }}
        >
          {this.props.t("documents.update.move")}
        </Dropdown.Item>
        {object.isArticle && isActionAuthorized && (
          <Dropdown.Item
            onClick={() =>
              this.props.history.replace(
                generatePath(EDIT_ARTICLE, { id: object.id })
              )
            }
          >
            {this.props.t("documents.article_edit")}
          </Dropdown.Item>
        )}
        {type === "doc" &&
          !object.isArticle &&
          this.updateDocumentOption(object.source.toLowerCase(), object.id)}
        {!object.isArticle && isActionAuthorized && isDoc && (
          <Dropdown.Item
            onClick={() =>
              this.props.convertToArticle(
                object.id,
                object.name,
                this.fetchData
              )
            }
          >
            {this.props.t("documents.article_convert")}
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    );
  };

  openMoreMenu = (event, idx) => {
    this.setState({
      openMenuItem: idx
    });
  };

  renderActionButtons = (object, type, idx) => {
    const isDoc = type === "doc";
    const isUnpublished = object.status === DocumentStatuses.UNPUBLISHED;

    const tipData = (
      <Dropdown
        key={idx}
        item
        icon={{ className: "custom-icon-name" }}
        open={this.state.openMenuItem === idx}
        fluid
        borderless="true"
        direction="left"
        className="moreButton"
        openOnFocus={true}
        style={{ position: "relative" }}
        onFocus={e => this.openMoreMenu(e, idx)}
        onBlur={e => this.openMoreMenu(e, null)}
        onMouseOver={e => this.openMoreMenu(e, idx)}
        onMouseLeave={e => this.openMoreMenu(e, null)}
      >
        {this.renderMenuItems(object, type, idx, this.state.isActionAuthorized)}
      </Dropdown>
    );
    return (
      <div className="buttonGroup">
        <Button.Group
          basic
          fluid
          className={isDoc ? "actionButtons" : "foldersAction"}
          floated="right"
        >
          {isDoc && !isUnpublished && (
            <>
              <Button
                data-tip={this.props.t("documents.preview")}
                onClick={() => {
                  this.props.preview(object);
                  this.props.history.replace(`${DOCUMENTS}/${object.id}`);
                }}
              >
                <PreviewIcon />
              </Button>
            </>
          )}
          {isDoc &&
            !isUnpublished &&
            canUserDownloadDocuments(this.props.userRole) && (
              <Button
                data-tip={this.props.t("documents.download")}
                onClick={() => {
                  this.props.download(object.id);
                }}
              >
                <DownloadIcon />
              </Button>
            )}
          {this.state.isActionAuthorized && (
            <Button
              className={isDoc ? "removeDoc" : "removeFolder"}
              data-tip={this.props.t("documents.delete")}
              onClick={e => {
                this.removeFile(e, type, object);
              }}
            >
              <DeleteIcon />
            </Button>
          )}
        </Button.Group>
        {this.state.isActionAuthorized && tipData}
      </div>
    );
  };

  removeFile = (e, type, object) => {
    e.stopPropagation();
    type === "doc"
      ? this.props.confirmDeleteFile(object.id, this.state.activeId)
      : this.props.confirmDeleteFolder(object.id, this.state.activeId);

    this.props.trackEvent(MIXPANEL_EVENTS.DOC_REMOVAL);
  };

  renderFolders = (folder, idx) => {
    let nameElement;
    if (this.state.editIdx === idx) {
      nameElement = (
        <Input
          autoFocus
          iconPosition="left"
          className="changeName"
          defaultValue={folder.name}
          onBlur={e => this.handleChangeName(e, idx, "folder")}
          onKeyPress={e => this.handleFolderNameEnter(e)}
          onKeyDown={e => this.handleInputEsc(e)}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      );
    } else {
      nameElement = <FolderLabel name={folder.name} />;
    }
    return (
      <Table.Row
        key={folder.id}
        onClick={() => {
          ReactTooltip.hide();
          this.selectFolder(folder);
        }}
      >
        <Table.Cell collapsing>
          <p></p>
        </Table.Cell>
        <Table.Cell
          className="documentName"
          data-tip={
            !this.state.editIdx && folder.name.length > 45 ? folder.name : ""
          }
        >
          {nameElement}
        </Table.Cell>
        <Table.Cell collapsing>
          {moment(folder.updatedAt).format("lll") || ""}
        </Table.Cell>
        <Table.Cell collapsing>
          {folder.author ? folder.author.userName : ""}
        </Table.Cell>
        <Table.Cell collapsing> </Table.Cell>
        <Table.Cell collapsing>
          {this.renderActionButtons(folder, "folder", idx)}
        </Table.Cell>
      </Table.Row>
    );
  };

  renderElementFromObject = (object, idx) => {
    let documentName;
    const isDraft = object.articleDraft && object.isArticle;

    if (object.type === "CUSTOM_ERROR") {
      // error handling while fetching documents from link
      // if document not exists only folders are rendered
      return;
    }
    if (this.state.editIdx === idx) {
      documentName = (
        <Input
          autoFocus
          iconPosition="left"
          className="changeName"
          defaultValue={object.name}
          onBlur={e => this.handleChangeName(e, idx, "doc")}
          onKeyPress={e => this.handleFolderNameEnter(e)}
        />
      );
    } else {
      documentName =
        isDraft && !object.name ? (
          <>
            <FileLabel
              isArticle={true}
              iconSize={20}
              bigLabel
              noBorder
              labelStyle={{ color: "#000" }}
            />
            <span className="draftNamePlaceholder">
              {this.props.t("documents.draft_article_name_placeholder")}
            </span>
          </>
        ) : (
          <DocumentLabel {...object} />
        );
    }
    return (
      <Table.Row key={object.id}>
        <Table.Cell
          collapsing
          data-tip={
            !this.props.toggleDocumentUseAuthorizedRoles.includes(
              this.props.userRole
            )
              ? this.props.t("documents.you_dont_have_rights_document_use")
              : ""
          }
        >
          <Radio
            toggle
            onChange={() =>
              this.props
                .toggleFileUse(object.id, !object.enabled)
                .then(() =>
                  this.fetchData({ parentId: this.state.activeId, reset: true })
                )
            }
            checked={object.enabled}
            disabled={
              !this.props.toggleDocumentUseAuthorizedRoles.includes(
                this.props.userRole
              )
            }
          />
        </Table.Cell>
        <Table.Cell
          className="documentName"
          data-tip={
            !this.state.editIdx && object.name && object.name.length > 45
              ? !object.isArticle
                ? `${object.name}.${object.extension}`
                : `${object.name}`
              : ""
          }
          onClick={() => {
            if (
              this.state.editIdx !== idx &&
              object.status !== DocumentStatuses.UNPUBLISHED
            ) {
              this.props.preview(object);
              this.props.history.replace(`${DOCUMENTS}/${object.id}`);
            } else if (
              isDraft &&
              object.status === DocumentStatuses.UNPUBLISHED
            ) {
              this.props.history.replace(
                generatePath(EDIT_ARTICLE, { id: object.id })
              );
            }
          }}
        >
          {documentName}
          {isDraft && (
            <TagLabel
              className="draftLabel"
              tag={
                object.status === DocumentStatuses.UNPUBLISHED
                  ? this.props.t("documents.draft_article_label")
                  : this.props.t("documents.draft_article_label_changes")
              }
            />
          )}
        </Table.Cell>
        <Table.Cell collapsing>
          {object.versionUpdatedAt
            ? moment(object.versionUpdatedAt).format("lll")
            : "-"}
        </Table.Cell>
        <Table.Cell collapsing>{object.author.userName}</Table.Cell>
        <Table.Cell collapsing>
          {object.size ? filesize(object.size) : "-"}
        </Table.Cell>
        <Table.Cell collapsing>
          {this.renderActionButtons(object, "doc", idx)}
        </Table.Cell>
      </Table.Row>
    );
  };

  handleFileUpload = () => {
    // eslint-disable-next-line
    this.fileUploaderRef?.current?.click();
  };

  handleUpload = event => {
    this.setState({
      isFileUploading: true
    });
    event.preventDefault();
    const files = event.target.files;
    const filteredFiles = getFilteredFiles(files);
    this.props.uploadDocs(filteredFiles, this.state.activeId).then(() => {
      this.fetchData({ parentId: this.state.activeId });
      if (files.length !== filteredFiles.length) {
        // zostawiam to na potem
        // const refusedFiles = difference(
        //   getFilenames(files), getFilenames(filteredFiles)
        // );
        this.props.customError(
          this.props.t("documents.some_files_couldnt_be_added") // Lista odrzuconych plik├│w: \n ${refusedFiles.join('\n')}`
        );
      }
    });
    this.props.trackEvent(MIXPANEL_EVENTS.NEW_DOC_ADD);
  };

  updateClick = (event, fileId) => {
    this.setState(prevState => ({
      fileToUpdate: fileId
    }));
    this.fileUpdate.click();
    event.stopPropagation();
  };

  handleUpdateFile = event => {
    event.preventDefault();
    const file = event.target.files[0];
    event.target.value = null; // reset value to allow same file to be uploaded
    const filteredFiles = getFilteredFiles([file]);

    this.props.updateDoc(filteredFiles[0], this.state.fileToUpdate).then(() => {
      this.setState({
        fileToUpdate: null,
        pagingFolder: 0,
        pagingDoc: 0
      });
      this.fileUpdate = null;
      this.fetchData({ parentId: this.state.activeId });
      if (filteredFiles.length !== 1) {
        // zostawiam to na potem
        // const refusedFiles = difference(
        //   getFilenames(files), getFilenames(filteredFiles)
        // );
        this.props.customError(
          this.props.t(
            "documents.unsupported_file_extension_file_could_not_be_updated"
          ) // Lista odrzuconych plik├│w: \n ${refusedFiles.join('\n')}`
        );
      }
    });
  };

  handleDrop = files => {
    this.setState({
      isFileUploading: true
    });
    const filteredFiles = getFilteredFiles(files);
    this.props.uploadDocs(filteredFiles, this.state.activeId).then(() => {
      this.fetchData({ parentId: this.state.activeId });
      if (files.length !== filteredFiles.length) {
        // zostawiam to na potem
        // const refusedFiles = difference(
        //   getFilenames(files), getFilenames(filteredFiles)
        // );
        this.props.customError(
          this.props.t("documents.some_files_couldnt_be_added") // Lista odrzuconych plik├│w: \n ${refusedFiles.join('\n')}`
        );
      }
    });
    this.setState({
      pagingFolder: 0,
      pagingDoc: 0
    });
  };

  /**
   *
   * @param {import("~/services/api-types").DocumentType} data
   * @param {number} idx
   */
  selectBread = (data, idx) => {
    this.fetchData({ parentId: data.id });

    this.props.setCurrentFolder(data.id);

    this.setState(
      prevState => ({
        breadPath: [...prevState.breadPath.slice(0, idx + 1)],
        activeId: data.id,
        pagingFolder: 0,
        pagingDoc: 0
      }),
      () => this.props.setFoldersPath(this.state.breadPath)
    );
  };

  renderBreadcrumb = () => {
    const brt = this.state.breadPath.map((obj, idx) => (
      <React.Fragment key={obj.id}>
        <Breadcrumb.Section
          data-tip={obj.name.length > 15 ? obj.name : null}
          data-delay-show={1500}
          className="customBread"
          link
          active={this.state.activeId === obj.id}
          onClick={() => this.selectBread(obj, idx)}
        >
          {obj.name}
        </Breadcrumb.Section>
        {this.state.breadPath.length - 1 !== idx && (
          <span className="divider">{">"}</span>
        )}
      </React.Fragment>
    ));
    return <Breadcrumb>{brt}</Breadcrumb>;
  };

  addNewFolder = () => {
    const newFolderRow = (
      <Table.Row>
        <Table.Cell collapsing>
          <p></p>
        </Table.Cell>
        <Table.Cell className="documentName">
          <Input
            autoFocus
            icon="add"
            iconPosition="left"
            className="newFolderName"
            placeholder={this.props.t("documents.new_folder.placeholder")}
            onBlur={e => this.handleFolderName(e)}
            onKeyPress={e => this.handleFolderNameEnter(e)}
            onKeyDown={e => this.handleInputEsc(e)}
          />
        </Table.Cell>
        <Table.Cell collapsing />
        <Table.Cell collapsing />
        <Table.Cell collapsing />
        <Table.Cell collapsing />
      </Table.Row>
    );
    this.setState(prevState => ({
      newFolder: newFolderRow
    }));
  };

  handleFolderNameEnter = e => {
    if (e.key === "Enter") {
      e.target.blur();
    }
  };

  handleInputEsc = e => {
    if (e.key === "Escape") {
      this.setState({
        newFolder: null,
        editIdx: null,
        editId: null
      });
    }
  };

  handleFolderName = data => {
    const fName = data.target.value;
    const parentId = this.state.activeId || null;
    this.setState({
      newFolder: null,
      editIdx: null,
      editId: null
    });
    if (!fName) return;
    this.props
      .addFolder(fName, parentId)
      .then(() => this.fetchData({ parentId: this.state.activeId }));
    this.setState({
      pagingFolder: 0,
      pagingDoc: 0
    });
  };

  handleChangeName = (data, idx, type) => {
    const fileName = data.target.value;

    this.setState({
      editIdx: null,
      editId: null
    });
    if (!fileName) return;
    type !== "doc"
      ? this.props
          .updateFolder(fileName, this.state.editId)
          .then(() => this.fetchData({ parentId: this.state.activeId }))
      : this.props
          .nameDocument(this.state.editId, fileName)
          .then(() =>
            this.fetchData({ parentId: this.state.activeId, withFolder: false })
          );
    this.setState({
      pagingFolder: 0,
      pagingDoc: 0
    });
  };

  closeWindowModal = () => {
    this.setState({
      movedId: null,
      moveType: null,
      folderToMoveId: null,
      fileWindowOpen: false,
      prevId: 0,
      prevName: this.props.t("documents.breadcrumb.documents"),
      pagingFolder: 0,
      pagingDoc: 0
    });
    this.fetchData({ parentId: this.state.activeId });
  };

  moveFile = id => {
    const toMoveId = this.state.folderToMoveId || null;
    if (this.state.moveType === "doc") {
      this.props
        .moveDocument(this.state.movedId, toMoveId)
        .then(() => this.closeWindowModal());
    } else {
      this.props
        .moveFolder(this.state.movedId, toMoveId)
        .then(() => this.closeWindowModal());
    }
  };

  handleSortingBy = field => {
    let dir;
    if (this.state.sortParam !== field) {
      // first time clicked, sort descending
      dir = "desc";
    } else {
      // flip sorting direction
      dir = this.state.sortDir === "desc" ? "asc" : "desc";
    }
    this.setState({
      sortParam: field,
      sortDir: dir,
      pagingDoc: 0,
      pagingFolder: 0
    });
    let sort = { by: field, direction: dir };
    const withoutFolders = field !== "size";
    this.fetchData({
      parentId: this.state.activeId,
      withFolder: withoutFolders,
      sort
    });
  };

  renderMoveModal = e => {
    const isRoot = this.state.foldersTree.length - 1 === 0;
    const isDisabled =
      this.state.folderToMoveId === null ||
      this.state.folderToMoveId === this.state.movedId ||
      this.state.folderToMoveId === this.state.activeId;
    return (
      <Modal
        open={this.state.fileWindowOpen}
        onClose={this.closeWindowModal}
        className="moveModal"
        dimmer="blurring"
        // it's calculated from event so it's need to be inline
        style={{
          top: `${
            this.state.menuPosition ? this.state.menuPosition.top : " "
          }px`,
          left: `${
            this.state.menuPosition ? this.state.menuPosition.right + 250 : " "
          }px`
        }}
      >
        <Modal.Header className="moveModalHeader">
          {!isRoot && (
            <Icon
              className="arrowIcon"
              name="angle left"
              size="normal"
              onClick={this.returnFolder}
            />
          )}
          {this.state.foldersTree[this.state.foldersTree.length - 1].name}
        </Modal.Header>
        <Modal.Content>
          <div className="moveModalContent">
            {this.state.foldersMenu.map((fld, idx) => (
              <div
                key={fld.id}
                className={
                  this.state.folderToMoveId === fld.id
                    ? "selectedLocation"
                    : "folderLocation"
                }
                onClick={e => {
                  this.setState(prevState => ({
                    folderToMoveId:
                      prevState.folderToMoveId === fld.id ? null : fld.id
                  }));
                }}
              >
                {<FolderLabel name={fld.name} />}
                <Icon
                  disabled={this.state.movedId === fld.id}
                  className="arrowIcon"
                  name="angle right"
                  size="big"
                  onClick={e => this.selectFolderMove(e, fld)}
                />
              </div>
            ))}
          </div>
          <Divider className="moveDivider" />
          <div className="moveButtonWrapper">
            <Button
              disabled={isDisabled}
              className="moveButton"
              onClick={this.moveFile}
            >
              {this.props.t("documents.move_here")}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  };

  fileUploadLoader = () => {
    return (
      <Table.Row>
        <Table.Cell className="inlineLoader" colSpan="6">
          <LoaderInline
            style={{
              width: "100%",
              padding: "0px",
              marginRight: "-10%"
            }}
            isFetching={this.state.isFileUploading}
          />
        </Table.Cell>
      </Table.Row>
    );
  };

  getTable = () => {
    return (
      <Table className="documentTable" celled selectable>
        <Table.Header fullWidth>
          <Table.Row unstackable="true" className="tableHeaders">
            <Table.HeaderCell className="enabled not-sortable">
              {this.props.t("documents.use")}
            </Table.HeaderCell>
            <Table.HeaderCell
              className={this.state.sortParam === "name" ? "active" : ""}
              onClick={() => this.handleSortingBy("name")}
            >
              {this.props.t("documents.name")}
              {this.state.sortParam === "name" && (
                <UpIcon className={`upIcon ${this.state.sortDir}`} />
              )}
            </Table.HeaderCell>
            <Table.HeaderCell
              className={
                this.state.sortParam === "versionUpdatedAt" ? "active" : ""
              }
              onClick={() => this.handleSortingBy("versionUpdatedAt")}
            >
              {this.props.t("documents.date_modified")}
              {this.state.sortParam === "versionUpdatedAt" && (
                <UpIcon className={`upIcon ${this.state.sortDir}`} />
              )}
            </Table.HeaderCell>
            <Table.HeaderCell
              className={this.state.sortParam === "createdBy" ? "active" : ""}
              onClick={() => this.handleSortingBy("createdBy")}
            >
              {this.props.t("documents.author")}
              {this.state.sortParam === "createdBy" && (
                <UpIcon className={`upIcon ${this.state.sortDir}`} />
              )}
            </Table.HeaderCell>
            <Table.HeaderCell
              className={this.state.sortParam === "size" ? "active" : ""}
              onClick={() => this.handleSortingBy("size")}
            >
              {this.props.t("documents.size")}
              {this.state.sortParam === "size" && (
                <UpIcon className={`upIcon ${this.state.sortDir}`} />
              )}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {this.props.t("documents.actions")}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body className={`tableBody`}>
          {this.state.newFolder ? this.state.newFolder : null}
          {this.props.folders.map((fld, idx) => this.renderFolders(fld, idx))}
          {this.state.isFileUploading && this.fileUploadLoader()}
          {this.props.documents.map((doc, idx) =>
            this.renderElementFromObject(doc, this.props.folders.length + idx)
          )}
        </Table.Body>
      </Table>
    );
  };

  render() {
    const locationName =
      this.state.breadPath.find(path => path.id === this.state.activeId)
        ?.name ?? null;

    const rootPath = this.state.breadPath.length - 1 === 0;
    const fetchDocuments =
      this.props.totalPagesDoc !== this.state.pagingDoc &&
      this.props.totalElemDoc;
    const fetchFolders =
      this.props.totalPagesFolders !== this.state.pagingFolder &&
      this.props.totalElemFolders;
    return (
      <DocumentDragAndDrop handleDrop={this.handleDrop}>
        {this.renderMoveModal()}
        <div className="documentTableWrapper" ref={el => (this.container = el)}>
          <div
            className="documentsHeaderSection"
            ref={el => (this.headContainer = el)}
          >
            <div className="documentHeaderPath">
              {!rootPath && (
                <ButtonIcon
                  disabled={this.props.isFetching}
                  className="backButton"
                  onClick={this.returnHeader}
                >
                  <Icon className="arrowIcon" name="angle left" size="big" />
                </ButtonIcon>
              )}
              <div className="documentLocation">{locationName}</div>
            </div>
            {this.state.isActionAuthorized && (
              <div className={styles.addButtons}>
                <Button
                  className="addFolder"
                  onClick={() => {
                    this.addNewFolder();
                  }}
                >
                  <AddFolderIcon />
                </Button>
                <Dropdown
                  icon="add"
                  floating
                  labeled
                  button
                  className={`icon ${styles.addDocumentButton}`}
                  text={this.props.t("documents.add_document")}
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      text={this.props.t("documents.from_file")}
                      onClick={this.handleFileUpload}
                    />
                    <Dropdown.Item
                      text={this.props.t("documents.from_url")}
                      onClick={() =>
                        this.props.uploadFromUrl(
                          this.state.activeId,
                          () => {
                            this.fetchData({ parentId: this.state.activeId });
                          },
                          () => this.setState({ isFileUploading: true })
                        )
                      }
                    />
                    <Link
                      className="item"
                      style={{ color: "#020525" }}
                      to={NEW_ARTICLE}
                    >
                      <Dropdown.Item
                        text={this.props.t("documents.new_article")}
                      />
                    </Link>
                  </Dropdown.Menu>
                </Dropdown>
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={this.fileUploaderRef}
                  onChange={this.handleUpload}
                  multiple
                />
              </div>
            )}
          </div>
          {this.renderBreadcrumb()}
          <div
            className="tableScroll"
            style={{
              height: `${
                this.state.wrapperHeight - this.state.headHeight - 70
              }px`
            }}
          >
            {this.getTable()}
            <LoaderInline
              isFetching={
                (fetchDocuments || fetchFolders) && this.state.activeId === 0
              }
            />
          </div>
        </div>
        {this.state.isActionAuthorized && (
          <>
            <input
              type="file"
              style={{ display: "none" }}
              ref={e => (this.fileUpdate = e)}
              onChange={e => {
                this.handleUpdateFile(e);
              }}
              multiple
            />
          </>
        )}
      </DocumentDragAndDrop>
    );
  }
}

DocumentsComponent.defaultProps = {
  documents: [],
  folders: [],
  authorizedRoles: ["MANAGER", "ADMIN"],
  toggleDocumentUseAuthorizedRoles: ["MANAGER", "ADMIN", "EDITOR"]
};

DocumentsComponent.propTypes = {
  folders: PropTypes.array,
  documents: PropTypes.array,
  totalPagesDoc: PropTypes.number,
  totalPagesFolders: PropTypes.number,
  refresh: PropTypes.func,
  initialId: PropTypes.number,
  totalElemDoc: PropTypes.number,
  totalElemFolders: PropTypes.number,
  foldersPath: PropTypes.array,
  authorizedRoles: PropTypes.array,
  toggleDocumentUseAuthorizedRoles: PropTypes.array,
  userRole: PropTypes.string,
  foldersPath: PropTypes.array,
  moveFoldersList: PropTypes.array,
  folderPageElements: PropTypes.number,
  docPageElements: PropTypes.number,
  getFoldersPath: PropTypes.func,
  download: PropTypes.func,
  confirmDeleteFile: PropTypes.func,
  setFoldersPath: PropTypes.func,
  confirmDeleteFolder: PropTypes.func,
  customError: PropTypes.func,
  setCurrentFolder: PropTypes.func,
  uploadDocs: PropTypes.func.isRequired,
  uploadFromUrl: PropTypes.func.isRequired,
  updateWebDocument: PropTypes.func.isRequired,
  addFolder: PropTypes.func,
  updateFolder: PropTypes.func,
  nameDocument: PropTypes.func,
  moveFolder: PropTypes.func,
  moveDocument: PropTypes.func,
  t: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  modalInfo: PropTypes.shape({
    modalType: PropTypes.string
  }),
  history: PropTypes.shape({
    replace: PropTypes.func
  }),
  fetchError: PropTypes.shape({
    openError: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  }),
  preview: PropTypes.func,
  toggleFileUse: PropTypes.func,
  updateDoc: PropTypes.func,
  isFetching: PropTypes.bool,
  trackEvent: PropTypes.func.isRequired,
  convertToArticle: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
  return {
    toggledDoc: state.documents,
    folderPageElements: state.documents.folders.numberOfElements,
    docPageElements: state.documents.numberOfElements,
    foldersPath: state.documents.foldersPath ?? [],
    moveFoldersList: state.documents.moveFoldersList,
    initialId: state.search.parentId ?? state.documents.currentFolder,
    docPath: state.search.location,
    userRole: state.login.role,
    modalInfo: state.modal,
    fetchError: state.errors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addFolder: (name, id) => dispatch(addFolder(name, id)),
    updateFolder: (name, id) => dispatch(updateFolder(name, id)),
    nameDocument: (name, id) => dispatch(api.nameDocument(id, name)),
    moveDocument: (id, parentId) => dispatch(api.moveDocuments(id, parentId)),
    moveFolder: (id, parentId) => dispatch(api.moveFolders(id, parentId)),
    uploadDocs: (files, parentId) =>
      dispatch(uploadFileAction(files, parentId)),
    uploadFromUrl: (parentId, uploadCallback, onUploadStart) =>
      dispatch(
        showConfirmModal("UPLOAD_FROM_URL", {
          parentId,
          uploadCallback,
          onUploadStart
        })
      ),
    updateDoc: (file, docId) => dispatch(updateFileAction(file, docId)),
    updateWebDocument: docId => dispatch(updateWebDocument(docId)),
    toggleFileUse: (id, enabled) => dispatch(toggleDocumentUse(id, enabled)),
    customError: message => dispatch(customError(message)),
    setCurrentFolder: id => dispatch(setCurrentFolderAction(id)),
    setFoldersPath: foldersPath => dispatch(updateFoldersPath(foldersPath)),
    convertToArticle: (id, name, refetch) =>
      dispatch(
        showConfirmModal("CONVERT_TO_ARTICLE", {
          documentId: id,
          documentName: name,
          refetch: refetch
        })
      )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(WithMixpanelContext()(DocumentsComponent)));
