import React, { useCallback, useEffect } from "react";
import { isEqual } from "lodash";
import { useSelector, useThunkDispatch } from "~/configureStore";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Wrapper from "~/atoms/Wrapper/Wrapper";
import ButtonIcon from "~/atoms/ButtonIcon/ButtonIcon";

import { ReactComponent as EditSVG } from "~/assets/icons/edit.svg";
import { ReactComponent as RemoveSVG } from "~/assets/icons/close.svg";

import styles from "./Create.module.scss";
import Tooltip from "~/atoms/Tooltip/Tooltip";
import Button from "~/atoms/Button/Button";
import { Flow } from "~/atoms/Flow/Flow";
import { getCurrentWorkflow } from "~/services/localStorage/workflows";
import {
  createWorkflowAction,
  initWorkflowAction,
  removeCurrentWorkflowAction
} from "~/actions/workflow/actions";
import classNames from "classnames";

interface CreateWorkflowProps {
  openModal: () => void;
  modalOpened: boolean;
}

export const Create = ({ openModal, modalOpened }: CreateWorkflowProps) => {
  const { t } = useTranslation();

  const dispatch = useThunkDispatch();

  const { currentWorkFlow } = useSelector(
    ({ workflows }) => workflows,
    (left, right) => isEqual(left, right)
  );

  const removeCurrentWorkflow = useCallback(
    () => dispatch(removeCurrentWorkflowAction()),
    [dispatch]
  );

  useEffect(() => {
    const localData = getCurrentWorkflow();

    if (!modalOpened && !currentWorkFlow?.name) {
      if (!localData) {
        openModal();
      } else {
        dispatch(initWorkflowAction(localData));
      }
    }
  }, [openModal, modalOpened, currentWorkFlow?.name]);

  const saveWorkflow = useCallback(() => {
    dispatch(createWorkflowAction(currentWorkFlow!));
  }, [currentWorkFlow]);

  const WrapperButtons = useCallback(() => {
    return (
      <div className={styles.headerButtons}>
        {currentWorkFlow && (
          <Tooltip
            tip={t("workflow.header.delete_flow")}
            wrapperClassName={styles.headerButton}
          >
            <ButtonIcon
              onClick={removeCurrentWorkflow}
              className={classNames(styles.iconButton, styles.iconButtonRemove)}
            >
              <RemoveSVG />
            </ButtonIcon>
          </Tooltip>
        )}
        {currentWorkFlow?.name && (
          <>
            <Tooltip
              wrapperClassName={classNames(styles.headerButton)}
              tip={t("workflow.modal.edit_details")}
            >
              <ButtonIcon
                onClick={openModal}
                className={classNames(styles.iconButtonEdit, styles.iconButton)}
              >
                <EditSVG />
              </ButtonIcon>
            </Tooltip>
            {currentWorkFlow.name}
          </>
        )}
        <Button
          disabled={!currentWorkFlow}
          onClick={saveWorkflow}
          className={styles.save}
        >
          {t("workflow.header.save")}
        </Button>
      </div>
    );
  }, [currentWorkFlow?.name, openModal]);

  return (
    <Wrapper
      className={styles.wrapper}
      ExtendedHeader={WrapperButtons}
      headerClassName={styles.wrapperHeader}
    >
      <Helmet
        title={
          t("workflow.create_workflow") +
          (currentWorkFlow?.name ? ` - ${currentWorkFlow.name}` : "")
        }
      />
      <Flow className={styles.flow} />
    </Wrapper>
  );
};
