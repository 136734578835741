import { connect } from "react-redux";
import MessageList from "~/components/MessageList";
import { resetIssueIndex } from "~/actions/IssuesActions";

const mapStateToProps = (state, ownProps) => {
  return {
    messages: state.messages,
    isFetching: state.fetching.isFetching
  };
};

const mapDispatchToProps = dispatch => {
  return {
    resetIssueIndex: () => dispatch(resetIssueIndex())
  };
};

const MessageListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageList);

export default MessageListContainer;
