import { useEffect, useState } from "react";

export const useGetElementById = (id: string) => {
  const [element, setElement] = useState<null | HTMLElement>(null);

  useEffect(() => {
    const element = document.getElementById(id);

    setElement(element);
  }, [id]);

  return element;
};
