import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonIcon from "../ButtonIcon/ButtonIcon";
import { InputText } from "../Inputs/InputText/InputText";

import styles from "./SynonymInput.module.scss";

import { ReactComponent as PlusSVG } from "~/assets/icons/plus.svg";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import classNames from "classnames";
import { useOnClickOutside } from "~/hooks/useOnClickOutside";

interface SynonymInputProps {
  maxLength?: number;
  handleSynonymsChange?: (synonyms: string[]) => void;
  handleSynonymUpdate?: (synonym: string[]) => void;
  error?: string;
  isListView?: boolean;
  synonyms?: string[];
  handleSynonymDelete?: (synonym: string) => void;
  wrapperClass?: string;
  inputClass?: string;
  synonymsListClass?: string;
}

export const SynonymInput = ({
  maxLength,
  handleSynonymsChange,
  error: synonymError,
  isListView = false,
  synonyms: addedSynonyms,
  handleSynonymDelete,
  inputClass,
  synonymsListClass,
  wrapperClass,
  handleSynonymUpdate
}: SynonymInputProps) => {
  const { t } = useTranslation();

  const [newSynonymValue, setNewSynonymValue] = useState<string>("");
  const [synonyms, setSynonyms] = useState<string[]>(addedSynonyms || []);
  const [error, setError] = useState<string | undefined>(undefined);

  const [isInputVisible, setIsInputVisible] = useState(
    isListView ? false : true
  );

  useEffect(() => {
    if (typeof addedSynonyms !== "undefined") {
      setSynonyms(addedSynonyms);
    }
  }, [addedSynonyms]);

  useEffect(() => {
    if (typeof handleSynonymsChange === "function" && !addedSynonyms) {
      handleSynonymsChange(synonyms);
    }
  }, [handleSynonymsChange, synonyms]);

  const handleSynonymChange = useCallback(
    (synonym: string) => {
      if (error) {
        setError(undefined);
      }

      setNewSynonymValue(synonym);
    },
    [error]
  );

  const addSynonym = (synonym: string) => {
    let value: string[] = [];

    if (synonym.includes(",")) {
      value = synonym
        .split(",")
        .map(s => s.trim())
        .filter(s => s);
    } else {
      value = [synonym];
    }

    return setSynonyms(prevState => {
      if (prevState.find(s => value.includes(s))) {
        setError(t("synonyms.this_synonym_already_exists"));

        return prevState;
      }

      if (typeof handleSynonymUpdate === "function") {
        handleSynonymUpdate(value);
      }

      handleSynonymChange("");

      return [...prevState, ...value];
    });
  };

  const removeSynonym = (synonym: string) => {
    if (isListView && typeof handleSynonymDelete === "function") {
      return handleSynonymDelete(synonym);
    }

    return setSynonyms(prevState => {
      if (prevState.find(currentSynonym => currentSynonym === synonym)) {
        return prevState.filter(currentSynonym => currentSynonym !== synonym);
      }

      return prevState;
    });
  };

  const handleAddButtonClick = useCallback(
    (synonym: string) => {
      if (isListView && !isInputVisible) {
        return setIsInputVisible(true);
      }

      return addSynonym(synonym);
    },
    [isInputVisible, isListView]
  );

  const inputContainerRef = useRef<HTMLDivElement>(null);

  const handleInputClose = useCallback(() => {
    if (isListView) {
      return setIsInputVisible(false);
    }
    return;
  }, [isListView]);

  useOnClickOutside(inputContainerRef, handleInputClose);

  return (
    <div className={classNames(styles.wrapper, wrapperClass)}>
      {!isListView && (
        <div className={classNames(styles.inputContainer, inputClass)}>
          {((isListView && isInputVisible) || !isListView) && (
            <InputText
              label={
                !isListView
                  ? t("synonyms.create_synonyms_for_this_term")
                  : undefined
              }
              placeholder={t("synonyms.type_in_synonym")}
              value={newSynonymValue}
              onChange={handleSynonymChange}
              error={error || synonymError}
              wrapperClass={styles.inputContainerInput}
              maxLength={maxLength}
              onKeyUp={addSynonym}
              onKeyUpKey="Enter"
            />
          )}
          <ButtonIcon
            onClick={() => handleAddButtonClick(newSynonymValue)}
            className={styles.inputContainerBtn}
          >
            <PlusSVG />
          </ButtonIcon>
        </div>
      )}
      {!isListView && synonymError && <ErrorMessage error={synonymError} />}
      {synonyms.length > 0 && (
        <ul className={classNames(styles.synonyms, synonymsListClass)}>
          {synonyms.map(synonym => (
            <Synonym key={synonym} onDelete={removeSynonym}>
              {synonym}
            </Synonym>
          ))}
          {isListView && (
            <div
              className={classNames(styles.inputContainer, inputClass)}
              ref={inputContainerRef}
            >
              {((isListView && isInputVisible) || !isListView) && (
                <InputText
                  label={
                    !isListView
                      ? t("synonyms.create_synonyms_for_this_term")
                      : undefined
                  }
                  placeholder={t("synonyms.type_in_synonym")}
                  value={newSynonymValue}
                  onChange={handleSynonymChange}
                  error={error || synonymError}
                  wrapperClass={styles.inputContainerInput}
                  maxLength={maxLength}
                  onKeyUp={addSynonym}
                  onKeyUpKey="Enter"
                />
              )}
              <ButtonIcon
                onClick={() => handleAddButtonClick(newSynonymValue)}
                className={styles.inputContainerBtn}
              >
                <PlusSVG />
              </ButtonIcon>
            </div>
          )}
        </ul>
      )}
    </div>
  );
};

interface SynonymProps {
  children: string;
  onDelete: (synonym: string) => void;
}

export const Synonym = ({ children, onDelete }: SynonymProps) => (
  <li className={styles.synonym}>
    <span className={styles.synonymText}>{children}</span>
    <button onClick={() => onDelete(children)} className={styles.synonymDelete}>
      <PlusSVG />
    </button>
  </li>
);
