import { MOCK_API_URL } from "~/services/api-urls";

/**
 * Properties that are expected to be different in every environment.
 * In the future the logic might be replaced with a properties file configurable for each environment separately.
 */
export const ENVIRONMENT = process.env.NODE_ENV;
export const VERSION = "1.0.0";
const ROOT_DOMAIN = "botwise.io";
const WHITELABEL_ROOT_DOMAIN = "gptwise.ai";

export const PRODUCTION_API_URL = `https://api-production.botwise.io`;
export const STAGING_API_URL = `https://api-staging.botwise.io`;

const CUSTOM_TENANT_API_MAPPING = {
  test: PRODUCTION_API_URL,
  demo: STAGING_API_URL
};

const getTenant = () => {
  let hostname = window.location.hostname;
  let hostnameEnding = "." + ROOT_DOMAIN;
  if (hostname.endsWith(hostnameEnding)) {
    return hostname.slice(0, -hostnameEnding.length);
  } else if (isWhiteLabel()) {
    return hostname.slice(0, -(WHITELABEL_ROOT_DOMAIN.length + 1));
  } else if (ENVIRONMENT === "development") {
    return "demo";
  }
  return "general";
};

export const isWhiteLabel = () => {
  return window.location.hostname.endsWith("." + WHITELABEL_ROOT_DOMAIN);
};

export const TENANT = process.env.REACT_APP_TENANT || getTenant();

export const getApiUrl = () => {
  if (process.env.NODE_ENV === "test") {
    return MOCK_API_URL;
  }

  if (process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL;
  }
  if (ENVIRONMENT === "production") {
    let hostname = window.location.hostname;
    if (
      !hostname.endsWith("." + ROOT_DOMAIN) &&
      !hostname.endsWith("." + WHITELABEL_ROOT_DOMAIN)
    ) {
      return "";
    }
    if (TENANT in CUSTOM_TENANT_API_MAPPING) {
      return CUSTOM_TENANT_API_MAPPING[
        TENANT as keyof typeof CUSTOM_TENANT_API_MAPPING
      ];
    }
    if (TENANT.endsWith("test")) {
      return STAGING_API_URL;
    }
    return PRODUCTION_API_URL;
  }
  return "";
};

export const API_URL = getApiUrl();
