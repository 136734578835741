import request from "./request";
import { STATISTICS_USERS_ACTIVITY } from "~/constants/api";
import { UsersActivityResponseType } from "./api-types";
import { Stats, UsersActivityFilters } from "~/reducers/statistics";

const statsUrl = "/stats";

export const getStats = () => {
  return request<Stats>({
    url: statsUrl,
    method: "GET"
  });
};

export const getChartData = ({
  type,
  ...params
}: {
  type: string;
  range: string;
  group?: string;
}) => {
  var requestUrl = statsUrl + "/" + type;
  for (const param in params) {
    if (!requestUrl.includes("?")) {
      requestUrl += "?";
    } else {
      requestUrl += "&";
    }
    requestUrl += param + "=" + encodeURIComponent(params[param as "range"]);
  }

  return request({
    url: requestUrl,
    method: "GET"
  });
};

export const getUsersActivity = (
  userName: string,
  page: number,
  filters: UsersActivityFilters
) => {
  const { period, sortBy, sortDirection } = filters;

  let url = `${STATISTICS_USERS_ACTIVITY}?query=${encodeURIComponent(
    userName
  )}&period=${period}&page=${page}`;

  if (sortBy) {
    url += `&sort=${sortBy},${sortDirection}`;
  }

  return request<UsersActivityResponseType>({
    url: url,
    method: "GET"
  });
};
