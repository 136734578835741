import React, { useEffect, useState } from "react";
import classNames from "classnames";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import { v4 as uuid } from "uuid";
import Wrapper from "~/atoms/Wrapper/Wrapper";

import styles from "./TableOfContents.module.scss";
import { useMixpanelContext } from "~/contexts/mixpanel";
import { MIXPANEL_EVENTS } from "~/contexts/mixpanel/event-types";
import Tooltip from "../Tooltip/Tooltip";

const MAX_CONTENT_LENGTH = {
  h1: 68,
  h2: 68,
  h3: 65,
  h4: 65,
  h5: 65,
  h6: 65
};

const formatElements = (headings: HTMLHeadingElement[]): ContentItem[] => {
  if (!headings.length) {
    return [];
  }

  const result = headings.map((heading, index) => {
    if (heading.textContent?.trim()?.length) {
      const anchor =
        heading.id || heading.textContent.replace(/\s/g, "") + `-${index}`;

      heading.id = anchor;

      return {
        anchor,
        text: heading.textContent,
        type: heading.tagName.toLowerCase(),
        level: Number(heading.tagName.toLowerCase().replace("h", "")),
        id: uuid()
      };
    }

    return undefined;
  });

  return result.filter(value => value !== undefined) as ContentItem[];
};

interface ContentItem {
  anchor: string;
  text: string;
  level: number;
  id: string;
  type: "h1" | "h2" | "h3";
}

interface TableOfContentsProps {
  className?: string;
  items: NodeListOf<HTMLHeadingElement> | null;
  articleMode?: boolean;
  headerExtend?: React.ComponentType<{}> | undefined;
  onAnchorClick?: (anchor: string) => void;
}

export const TableOfContents = ({
  className,
  items,
  articleMode = false,
  headerExtend,
  onAnchorClick
}: TableOfContentsProps) => {
  const { t } = useTranslation();

  const [currentActiveAnchor, setCurrentActiveAnchor] = useState<
    string | undefined
  >();

  const [headings, setHeadings] = useState<ContentItem[]>([]);

  useEffect(() => {
    if (items && Array.from(items)?.length) {
      const headings = formatElements(Array.from(items));
      setHeadings(headings);
    } else {
      setHeadings([]);
    }
  }, [items]);

  const { trackEvent } = useMixpanelContext();

  useEffect(() => {
    if (window.location.hash) {
      setCurrentActiveAnchor(window.location.hash.replace("#", ""));
    }
  }, []);

  const handleAnchorClick = (anchor: string) => {
    if (typeof onAnchorClick === "function") {
      onAnchorClick(anchor);

      setCurrentActiveAnchor(anchor);
    }

    const item = document.getElementById(anchor);

    if (item) {
      item.scrollIntoView();
      setCurrentActiveAnchor(anchor);

      const path = window.location.origin + window.location.pathname;
      const search = window.location.search ? "?" + window.location.search : "";
      const hash = "#" + anchor;

      window.history.replaceState(null, "", path + hash + search);
    }

    trackEvent(MIXPANEL_EVENTS.TABLE_OF_CONTENTS_ITEM_CLICK);
  };

  return (
    <Wrapper
      className={classNames(styles.contents, className)}
      headerClassName={styles.contentsHeader}
      wrapperTitleClassName={styles.contentsHeaderTitle}
      title={!articleMode ? t("documents.preview_modal.table_of_contents") : ""}
      ExtendedHeader={headerExtend}
    >
      <ul className={styles.list}>
        {headings.map(({ anchor, text, level, id, type }) => (
          <li key={id} className={styles.listItem}>
            <button
              className={classNames(
                styles.listItemAnchor,
                currentActiveAnchor === anchor && styles.listItemAnchorActive,
                styles[`listItemAnchorLevel-${level}`]
              )}
              onClick={e => handleAnchorClick(anchor)}
            >
              {text.length > MAX_CONTENT_LENGTH[type] ? (
                <>
                  <Tooltip tip={text} place="right">
                    {text.substring(0, MAX_CONTENT_LENGTH[type] - 3) + "..."}
                  </Tooltip>
                </>
              ) : (
                text
              )}
            </button>
          </li>
        ))}
      </ul>
    </Wrapper>
  );
};
