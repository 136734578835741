import { sagaMiddleware, configureStore, rootReducer } from "./store";
import rootSaga from "./sagas";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import {
  useDispatch,
  useSelector as defaultUseSelector,
  TypedUseSelectorHook
} from "react-redux";

const store = configureStore();
const dispatch = store.dispatch;
const state = store.getState();

sagaMiddleware.run(rootSaga);

export type StoreState = typeof state;

export type AppThunkDispatch = ThunkDispatch<
  ReturnType<typeof rootReducer>,
  void,
  AnyAction
>;

const useThunkDispatch = () => useDispatch<AppThunkDispatch>();

export const useSelector: TypedUseSelectorHook<StoreState> = defaultUseSelector;

export { store, dispatch, useThunkDispatch };
