import React, { useEffect } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import Wrapper from "~/components/atoms/Wrapper/Wrapper";
import ButtonIcon from "~/components/atoms/ButtonIcon/ButtonIcon";
import { clearRelatedMessage } from "~/actions";
import { searchAction } from "~/actions/SearchActions";
import { AppThunkDispatch, StoreState } from "~/configureStore";
import type { HintType, MessageType } from "~/services/api-types";
import { SearchResults } from "../SearchResults/SearchResults";
import { useTrackMixpanelEvent } from "~/hooks/useTrackMixpanelEvent";
import { MIXPANEL_EVENTS } from "~/contexts/mixpanel/event-types";

import { ReactComponent as CloseSVG } from "~/assets/icons/close.svg";

import styles from "./Related.module.scss";

interface RelatedProps {
  chatMessages: MessageType[];
  isFetching: boolean;
  relatedMessage: string;
  fetchRelated: (text: string) => void;
  handleClose: () => void;
}

const Related = ({
  handleClose,
  relatedMessage,
  fetchRelated
}: RelatedProps) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (relatedMessage?.trim()) {
      fetchRelated(relatedMessage);
    }
  }, [relatedMessage, fetchRelated]);

  useTrackMixpanelEvent(MIXPANEL_EVENTS.RELATED_OPEN);

  const CloseButton = () => (
    <>
      <ButtonIcon
        onClick={handleClose}
        className={styles.closeButton}
        data-for="closeRelatedButton"
        data-tip={t("related.close")}
      >
        <CloseSVG className={styles.closeButtonIcon} />
      </ButtonIcon>
      <ReactTooltip
        id="closeRelatedButton"
        place="top"
        type="light"
        effect="solid"
      />
    </>
  );

  return (
    <Wrapper
      title={t("related.see_related")}
      showTitle={true}
      className={styles.wrapper}
      ExtendedHeader={CloseButton}
    >
      <div className={styles.results}>
        <SearchResults resultsOnly />
      </div>
    </Wrapper>
  );
};

const mapStateToProps = ({
  messages,
  chat: { relatedMessage }
}: StoreState) => ({
  chatMessages: messages,
  relatedMessage
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  fetchRelated: (text: string) => {
    dispatch(searchAction(text));
    dispatch(clearRelatedMessage());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Related);
