import React, { ReactElement, useEffect, useState, useRef } from "react";
import classnames from "classnames";
import { previewDocPartWithId, previewDocWithId } from "~/actions";

import styles from "./LeftMessage.module.scss";
import BotAvatar from "~/components/BotAvatar";
import MessageTimer from "~/components/MessageTimer";
import { ReactComponent as Doc } from "~/assets/Doc.svg";
import ReactTooltip from "react-tooltip";
import { useSelector, useThunkDispatch } from "~/configureStore";
import {
  DocumentType,
  DocumentFragmentAnchorsType
} from "~/services/api-types";

type LeftMessageProps = {
  message: React.ReactNode;
  timer?: boolean;
  info?: boolean;
  disableUserInfo?: boolean;
  document?: DocumentType;
  documentAnchors?: DocumentFragmentAnchorsType;
  buttons?: ReactElement;
  previousMessageSender?: "BOT" | "USER";
  customObject?: React.ReactNode;
  lastMessage?: boolean;
  messageContainerClass?: string;
};

export const LeftMessage = ({
  message,
  timer,
  info = false,
  disableUserInfo = false,
  document,
  documentAnchors,
  buttons,
  previousMessageSender,
  customObject,
  lastMessage = false,
  messageContainerClass
}: LeftMessageProps) => {
  const botName = useSelector(
    state => state.projects.current?.uiName ?? "Botwise"
  );

  const [blockHovered, setBlockHovered] = useState(false);
  const [alignActionsLeft, setAlignActionsLeft] = useState(false);

  const messageContainerRef = useRef<HTMLDivElement>(null);

  const dispatch = useThunkDispatch();

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  useEffect(() => {
    setAlignActionsLeft((messageContainerRef.current?.clientWidth ?? 0) < 200);
  }, [messageContainerRef.current]);

  const handleDocPreview = (
    doc: DocumentType,
    documentAnchors?: DocumentFragmentAnchorsType
  ) =>
    dispatch(
      documentAnchors
        ? previewDocPartWithId(doc.id, documentAnchors)
        : previewDocWithId(doc.id)
    );

  return (
    <div className={styles.item}>
      <div className={styles.messageHeader}>
        {!disableUserInfo && previousMessageSender !== "BOT" && (
          <span className={styles.header}>{botName}</span>
        )}
        {document && (
          <>
            <Doc className={styles.docImg} />
            {/* TODO: Refactor using existing Tooltip component */}
            <span
              className={styles.headerName}
              onClick={() => handleDocPreview(document, documentAnchors)}
              data-tip={document.name.length > 45 ? document.name : ""}
              data-delay-show={1500}
            >
              {`${document.name}.${document.extension}`}
            </span>
          </>
        )}
      </div>
      <div className={styles.flex}>
        {!disableUserInfo && previousMessageSender !== "BOT" ? (
          <BotAvatar className={styles.avatar} />
        ) : (
          <div className={styles.avatar} />
        )}
        <div
          className={classnames(
            styles.block,
            !lastMessage && styles.blockHiddenActions,
            messageContainerClass
          )}
          onMouseEnter={() => setBlockHovered(true)}
          onMouseLeave={() => setBlockHovered(false)}
          ref={messageContainerRef}
        >
          <div className={styles.flex}>
            <div className={styles.bubble}>
              <div
                className={classnames(
                  styles.message,
                  {
                    [styles.info]: info,
                    [styles.messageMiniMargin]: disableUserInfo
                  },
                  "left-message-editor-container"
                )}
              >
                {message}
                {customObject}
              </div>
            </div>
          </div>
          {buttons &&
            React.cloneElement(buttons, {
              ...buttons.props,
              hovered: blockHovered,
              alignLeft: alignActionsLeft
            })}
          {timer && <MessageTimer />}
        </div>
      </div>
    </div>
  );
};
