import React, { useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector, useThunkDispatch } from "~/configureStore";
import { hideConfirmModal } from "~/actions";
import { Modal } from "semantic-ui-react";
import Button from "~/atoms/Button/Button";
import classNames from "classnames";

import styles from "./ConvertToArticleModal.module.scss";
import { convertToArticleAction } from "~/actions/ArticlesActions";

interface ConvertToArticleModalProps {
  open: boolean;
  documentId: number;
  documentName: string;
  refetch: (filters: { parentId: number; reset: boolean }) => void;
}

export const ConvertToArticleModal = ({
  open = true,
  documentId,
  documentName,
  refetch
}: ConvertToArticleModalProps) => {
  const { t } = useTranslation();

  const currentFolderId = useSelector(
    ({ documents: { currentFolder } }) => currentFolder as number
  );

  const dispatch = useThunkDispatch();

  const cancel = useCallback(() => {
    dispatch(hideConfirmModal());
  }, [dispatch]);

  const handleConfirm = useCallback(() => {
    dispatch(convertToArticleAction(documentId)).then(() => {
      refetch({ parentId: currentFolderId, reset: true });
      cancel();
    });
  }, [documentId, dispatch, currentFolderId]);

  return (
    <Modal open={open} className={styles.modal} size="tiny">
      <div className={styles.content}>
        <h4>{t("documents.convert_article_are_you_sure_modal")}</h4>
        <h5>
          <Trans i18nKey="documents.convert_article_are_you_sure_modal_sub">
            <strong>{{ documentName }}</strong> file will be converted to
            article.
          </Trans>
        </h5>
        <div className={styles.footer}>
          <Button
            className={classNames(styles.button, styles.confirm)}
            onClick={handleConfirm}
          >
            {t("documents.new_article_leave_modal_yes")}
          </Button>
          <Button className={classNames(styles.button)} onClick={cancel}>
            {t("modal.cancel")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
