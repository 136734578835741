import React, { useCallback, useMemo } from "react";
import ButtonIcon from "~/atoms/ButtonIcon/ButtonIcon";
import Tooltip from "~/atoms/Tooltip/Tooltip";
import { useTranslation } from "react-i18next";
import { useThunkDispatch } from "~/configureStore";
import { copyURL, downloadDocument } from "~/actions/DocumentsActions";
import CopyToClipboard from "react-copy-to-clipboard";
import { DOCUMENTS_ID } from "~/constants/routes";
import { generatePath } from "react-router-dom";
import { DocumentStatuses } from "~/services/api-types";
import classNames from "classnames";

import { ReactComponent as Download } from "~/assets/icons/download.svg";
import { ReactComponent as UpdatesHistory } from "~/assets/icons/time-left.svg";
import { ReactComponent as Share } from "~/assets/icons/share.svg";

import styles from "./ActionsButtons.module.scss";

interface ActionsButtonsProps {
  articleStatus: DocumentStatuses | null;
  articleId: number;
  isUpdatesHistoryOpened: boolean;
  handleUpdatesHistoryOpen: () => void;
}

export const ActionsButtons = ({
  articleId,
  articleStatus,
  handleUpdatesHistoryOpen,
  isUpdatesHistoryOpened
}: ActionsButtonsProps) => {
  const { t } = useTranslation();

  const dispatch = useThunkDispatch();

  const handleArticleDownload = useCallback(() => {
    if (articleId) {
      dispatch(downloadDocument(articleId));
    }
  }, [dispatch, articleId]);

  const onCopy = useCallback(() => {
    dispatch(copyURL());
  }, [dispatch]);

  const isUnpublished = useMemo(
    () => articleStatus === DocumentStatuses.UNPUBLISHED,
    [articleStatus]
  );

  return (
    <div className={styles.actionsButtonsWrapper}>
      <Tooltip
        size="small"
        light
        tip={
          isUnpublished
            ? t("documents.new_article_share_tip_unpublished")
            : t("documents.new_article_share_tip")
        }
      >
        <CopyToClipboard
          onCopy={onCopy}
          text={
            window.location.origin +
            generatePath(DOCUMENTS_ID, { id: articleId })
          }
        >
          <ButtonIcon
            disabled={isUnpublished}
            onClick={() => {}}
            className={classNames(
              styles.shareButton,
              isUnpublished && styles.disabled
            )}
          >
            <Share className={styles.shareIcon} />
          </ButtonIcon>
        </CopyToClipboard>
      </Tooltip>
      <Tooltip
        size="small"
        light
        tip={
          isUnpublished
            ? t("documents.new_article_download_tip_unpublished")
            : t("documents.new_article_download_tip")
        }
      >
        <ButtonIcon
          disabled={isUnpublished}
          onClick={handleArticleDownload}
          className={classNames(
            styles.downloadButton,
            isUnpublished && styles.disabled
          )}
        >
          <Download className={styles.downloadIcon} />
        </ButtonIcon>
      </Tooltip>
      {!isUpdatesHistoryOpened && (
        <Tooltip
          size="small"
          light
          tip={
            isUnpublished
              ? t("documents.new_article_updates_history_tip_unpublished")
              : t("documents.new_article_updates_history_tip")
          }
        >
          <ButtonIcon
            onClick={handleUpdatesHistoryOpen}
            disabled={isUnpublished}
            className={classNames(
              styles.downloadButton,
              isUnpublished && styles.disabled
            )}
          >
            <UpdatesHistory className={styles.downloadIcon} />
          </ButtonIcon>
        </Tooltip>
      )}
    </div>
  );
};
