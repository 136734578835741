import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DocumentType } from "~/services/api-types";
import { Trans } from "react-i18next";
import moment from "moment";
import FolderBreadcrumbs from "~/atoms/FolderBreadcrumbs/FolderBreadcrumbs";
import { getFileIcon } from "~/components/Documents/utils";
import classNames from "classnames";
import { useThunkDispatch } from "~/configureStore";
import { previewDocWithId } from "~/actions/DocumentsActions";
import { SearchResultContent } from "./SearchResultContent";
import { GroupedByDocumentType } from "~/reducers/search-new";
import { trackEvent } from "~/helpers/tracking";
import { useInView } from "react-intersection-observer";

import styles from "./SearchResult.module.scss";

const MAX_BREADCRUMBS_LENGTH = 3;
const MAX_BREADCRUMB_LENGTH = 30;

const RELATED_MAX_BREADCRUMBS_LENGTH = 3;
const RELATED_MAX_BREADCRUMB_LENGTH = 5;

interface SearchResultProps {
  item: GroupedByDocumentType;
  resultsOnly?: boolean;
  searchEventId: string;
  expanded?: boolean;
  scrollToMatch?: () => void;
}

export const SearchResult = ({
  item,
  resultsOnly,
  searchEventId,
  expanded,
  scrollToMatch
}: SearchResultProps) => {
  const date = useMemo(
    () => moment(item.latestEditor.updatedAt).fromNow(),
    [item.latestEditor.updatedAt]
  );
  const user = useMemo(
    () => item.latestEditor.editor,
    [item.latestEditor.editor]
  );

  const dispatch = useThunkDispatch();

  const previewDocument = useCallback(
    (documentId: number) => dispatch(previewDocWithId(documentId)),
    [dispatch]
  );

  const { ref, inView } = useInView({
    threshold: 0.5
  });
  const [seen, setSeen] = useState(false);

  useEffect(() => {
    if (item.document?.id && inView && !seen) {
      trackEvent({
        type: "DOCUMENT_SHOWN_IN_RESULTS",
        searchEventId,
        documentId: item.document.id,
        position: item.position
      });
      setSeen(true);
    }
  }, [item.document?.id, item.position, searchEventId, seen, inView]);

  return (
    <div
      className={classNames(styles.result, resultsOnly && styles.relatedResult)}
    >
      {item.document && (
        <header ref={ref} className={styles.header}>
          {
            <div className={styles.document}>
              <div
                className={styles.documentIcon}
                onClick={
                  item.document
                    ? () => previewDocument((item.document as DocumentType).id)
                    : () => {}
                }
              >
                {getFileIcon(item.document.extension, item.document.isArticle)}
              </div>
              <h2
                className={styles.documentName}
                tabIndex={0}
                onClick={
                  item.document
                    ? () => previewDocument((item.document as DocumentType).id)
                    : () => {}
                }
              >
                {item.document.name}
              </h2>
              <FolderBreadcrumbs
                className={styles.breadcrumbs}
                breadcrumbClass={styles.breadcrumb}
                fileExtension={item.document.extension}
                fileName={item.document.name}
                isArticle={item.document.isArticle}
                documentId={item.document.id}
                breadcrumbs={item.document.location}
                maxLength={
                  !resultsOnly
                    ? MAX_BREADCRUMBS_LENGTH
                    : RELATED_MAX_BREADCRUMBS_LENGTH
                }
                maxBreadcrumbLength={
                  !resultsOnly
                    ? MAX_BREADCRUMB_LENGTH
                    : RELATED_MAX_BREADCRUMB_LENGTH
                }
              />
            </div>
          }
          {!resultsOnly && (
            <div className={styles.modification}>
              <Trans i18nKey="search.search_result.last_modified_by">
                Last modified
                <b>{{ date }}</b>
                by
                <b>{{ user }}</b>
              </Trans>
            </div>
          )}
        </header>
      )}
      {item.content.length > 0 &&
        item.content.map(result =>
          result.answer ? (
            <SearchResultContent
              key={result.id}
              id={result.id}
              html={result.answer}
              question={result.question}
              context={result.context}
              single={item.content.length === 1}
              document={result.document}
              documentAnchors={result.documentAnchors}
              position={result.position}
              searchEventId={searchEventId}
              expanded={expanded}
              scrollToMatch={scrollToMatch}
            />
          ) : null
        )}
    </div>
  );
};
