import styles from "./Changes.module.scss";
import React from "react";
import { Link } from "react-router-dom";
import { Button, Icon, Dropdown, Input, Label } from "semantic-ui-react";
import CustomButton from "~/atoms/Button/Button";
import moment from "moment";
import { debounce, get } from "lodash";
import { ImageWindow } from "~/components/ImageWindow/index";
import { FileLabel } from "~/components/FileLabel";
import { PropTypes } from "prop-types";
import DatePicker from "react-datepicker";
import { getLocaleISO } from "~/helpers/locale";
import { listLoadingOptions } from "~/constants/app";
import DropdownFilter from "./DropdownFilter";
import Table from "~/components/atoms/Table/Table";
import ChangesDetails from "./ChangesDetails";
import ChangesDetailsFragment from "./ChangesDetailsFragment";
import { withTranslation } from "react-i18next";
import {
  KNOWLEDGE_FRAGMENTS,
  TRAIN_FRAGMENT_BRAND_NEW,
  TRAIN_FRAGMENT_EDIT
} from "~/constants/routes";
import { WithMixpanelContext } from "~/contexts/mixpanel";
import { MIXPANEL_EVENTS } from "~/contexts/mixpanel/event-types";
import { DocumentAnchorsLink } from "~/atoms/DocumentAnchorsLink/DocumentAnchorsLink";
import classnames from "classnames";

import "react-datepicker/dist/react-datepicker.css";

import "./index.scss";

const initialFilters = {
  searchValue: "",
  searchParam: "",
  periodFilter: "all",
  startDate: null,
  endDate: null,
  author: "",
  document: ""
};

class ChangesComponent extends React.Component {
  state = {
    titleAndFiltersOffset: null,
    isOpen: false,
    imgSrc: [],
    openIdx: null,
    openChange: null,
    paginPage: 0,
    openMenuItem: null,
    deleted: false,
    searchMenu: false,
    accordionFilter: false,
    prevPath: "",
    sortName: "updatedAt",
    sortDir: "desc",
    documentsList: [],
    totalElements: 0,
    filters: initialFilters
  };

  titleRef = React.createRef();
  filtersDiv = React.createRef();

  componentDidMount() {
    this.props.getUsersList();
    this.props.getDocumentsList();
    this.listObserver = new MutationObserver(this.checkListHeight);
    if (this.props.prevLocation === TRAIN_FRAGMENT_EDIT) {
      this.setState(
        {
          filters: this.props.filters,
          sortDir: this.props.sortDir,
          sortName: this.props.sortName
        },
        this.applyFilters
      );
    } else {
      this.props.getChanges(0);
    }
    this.setState({ totalElements: this.props.totalElements });
    this.getOffset();

    this.props.trackEvent(MIXPANEL_EVENTS.FRAGMENTS_VISIT);
  }

  componentWillUnmount() {
    if (
      [TRAIN_FRAGMENT_EDIT, KNOWLEDGE_FRAGMENTS].includes(
        this.props.history?.location?.pathname
      )
    ) {
      this.props.copyChangesParams(
        this.state.filters,
        this.state.sortName,
        this.state.sortDir
      );
    } else {
      this.props.clear();
    }
    this.listObserver.disconnect();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.documentsList !== this.props.documentsList &&
      this.props.documentsList !== this.state.documentsList
    ) {
      this.setState({
        documentsList: [...this.props.documentsList]
      });
    }
    if (
      prevProps.totalElements !== this.props.totalElements ||
      this.props.issuesData !== prevProps.issuesData
    ) {
      this.setState({ totalElements: this.props.totalElements });
      this.checkIssuesDataCount();
    }
    if (prevState.accordionFilter !== this.state.accordionFilter) {
      this.getOffset();
    }
  }

  checkIssuesDataCount = () => {
    if (
      this.props.asIssues &&
      this.state.filters === initialFilters &&
      this.props.issuesData?.length > 0
    ) {
      this.setState({ totalElements: this.props.issuesData.length });
    }
  };

  checkListHeight = () => {
    const elementsList = document.querySelectorAll(
      ".changesScrollElementsList > .ResTable"
    );
    if (!elementsList.length) {
      return;
    }
    const tableHeight =
      document.getElementsByClassName("changesScroll")[0].clientHeight;
    const elementsListHeight = [...elementsList]
      .map(element => element.clientHeight)
      .reduce((a, b) => a + b, 0);
    if (
      elementsListHeight < tableHeight &&
      this.props.totalPages === 1 &&
      this.props.totalPages > this.state.paginPage
    ) {
      // elemenets on the list are not enought to fill
      // so it's a short list and we should remove loader
      this.setState(state => ({ paginPage: state.paginPage + 1 }));
    }
  };

  setSearchValue = e => {
    this.setState({
      filters: {
        ...this.state.filters,
        searchValue: e.target.value
      }
    });
  };

  applyFilters = (pageNumber = 0) => {
    const { startDate, endDate, searchValue, searchParam, author, document } =
      this.state.filters;
    let searchReq = {};
    let filters = {};
    const changeAuthor = author || "";
    const documentId = document || "";
    let sort = {
      by: this.state.sortName,
      direction: this.state.sortDir
    };
    if (searchValue) {
      searchReq = {
        query: searchValue,
        queryFields: searchParam
      };
    }
    if (startDate) {
      const start = moment(startDate).format("YYYY-MM-DD");
      filters["modifiedSince"] = start;
    }
    if (endDate) {
      const end = moment(endDate).format("YYYY-MM-DD");
      filters["modifiedUntil"] = end;
    }

    if (!this.props.isFetching) {
      this.props.getChanges(
        pageNumber,
        sort,
        searchReq,
        filters,
        changeAuthor,
        documentId
      );

      this.setState({ paginPage: pageNumber });
    }
  };

  handleSorting = (name, dir) => {
    let direction;
    if (dir === false || dir === true) {
      direction = !!dir ? "asc" : "desc";
    } else if (this.state.sortName !== name) {
      // first time clicked, sort descending
      direction = "desc";
    } else {
      // flip sorting direction
      direction = this.state.sortDir === "desc" ? "asc" : "desc";
    }
    this.setState(
      {
        sortName: name,
        sortDir: direction,
        paginPage: 0
      },
      this.applyFilters
    );
  };

  pageScroll = debounce(event => {
    const distanceScrolled = event.target.clientHeight + event.target.scrollTop;
    const totalScrollWithOffset =
      event.target.scrollHeight - listLoadingOptions.offsetBottom;
    if (
      distanceScrolled > totalScrollWithOffset &&
      !this.props.isFetching &&
      this.state.paginPage < this.props.totalPages
    ) {
      this.applyFilters(this.state.paginPage + 1);
    }
  }, listLoadingOptions.debounceTime);

  openImg = (i, image) => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
      openIdx: i,
      openSrc: get(image, "src", "")
    }));
  };

  getOffset = () => {
    return this.setState({
      titleAndFiltersOffset:
        this.filtersDiv?.current?.offsetHeight ??
        0 + this.titleRef?.current?.offsetHeight ??
        0
    });
  };

  openMoreMenu = (e, idx) => {
    this.setState({
      openMenuItem: idx
    });
  };

  handleDeleteChange = id => {
    this.props.trackEvent(MIXPANEL_EVENTS.FRAGMENT_REMOVAL);
    this.props.deleteChange(id);
    this.setState({
      deleted: true
    });
  };

  renderMenuItems = object => {
    return (
      <Dropdown.Menu>
        <Dropdown.Item
          className="mItem"
          as={Link}
          to={TRAIN_FRAGMENT_EDIT}
          onClick={() => {
            this.props.openEditChangeView(object);
            this.props.setLocation(TRAIN_FRAGMENT_EDIT);
          }}
        >
          {this.props.t("changes.edit")}
        </Dropdown.Item>
        <Dropdown.Item
          className="mItem"
          onClick={() => this.handleDeleteChange(object.id)}
        >
          {this.props.t("changes.delete")}
        </Dropdown.Item>
      </Dropdown.Menu>
    );
  };

  renderMoreButton = change => {
    return (
      <Dropdown
        item
        className="dropMenu"
        icon={{
          className: "custom-icon-name"
        }}
        open={this.state.openMenuItem === change.id}
        borderless="true"
        openOnFocus={true}
        style={{
          position: "relative",
          minWidth: "25px",
          paddingRight: "10px",
          marginTop: "6px"
        }}
        onFocus={e => this.openMoreMenu(e, change.id)}
        onBlur={e => this.openMoreMenu(e, null)}
        onMouseOver={e => this.openMoreMenu(e, change.id)}
        onMouseLeave={e => this.openMoreMenu(e, null)}
      >
        {this.renderMenuItems(change)}
      </Dropdown>
    );
  };

  openAccordion = idx => {
    this.setState(prevState => ({
      openChange: prevState.openChange === idx ? null : idx
    }));
  };

  renderSuggestions = suggestions => {
    if (suggestions.length === 0) return null;

    return suggestions.map(sugg => (
      <Button
        key={sugg.id}
        size="tiny"
        compact
        className={classnames(
          "suggestions",
          sugg.featured && "suggestionsFeatured"
        )}
      >
        {sugg.text}
      </Button>
    ));
  };

  renderImages = (images, index, fullList = false) => {
    const ImageLabel = ({ image }) => (
      <FileLabel
        labelStyle={{ color: "#000" }}
        name={this.props.t("changes.image")}
        extension="jpg"
        onClick={e => {
          this.openImg(index, image);
          e.stopPropagation();
        }}
      />
    );

    if (images.length) {
      if (fullList) {
        return Array.from(images, (item, i) => (
          <div key={i} className={styles.FileLabelWrapper}>
            <ImageLabel image={item} />
          </div>
        ));
      }
      return (
        <div className="tableImgList">
          <ImageLabel image={images[0]} />
          {images.length > 1 && (
            <div className="nrBox">
              <span className="greyText"> {`+${images.length - 1}`} </span>
            </div>
          )}
        </div>
      );
    }
  };

  setSearchParam = searchParam => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          searchParam
        },
        searchMenu: false
      },
      this.state.filters.searchValue ? this.applyFilters : null
    );
  };

  handleSearch = () => {
    this.props.trackEvent(MIXPANEL_EVENTS.FRAGMENTS_QUERY_SEARCH);
    this.applyFilters();
  };

  onKeyPress = event => {
    if (event.keyCode === 13) {
      this.handleSearch();
    }
  };

  renderSearchMenu = () => (
    <Dropdown
      icon=""
      open={this.state.searchMenu}
      onMouseLeave={this.openSearchMenu}
    >
      <Dropdown.Menu className="searchMenu">
        <Dropdown.Item
          className="mItem"
          active={this.state.filters.searchParam === ""}
          onClick={() => this.setSearchParam("")}
        >
          {this.props.t("changes.filter.everywhere")}
        </Dropdown.Item>
        <Dropdown.Item
          className="mItem"
          active={this.state.filters.searchParam === "question"}
          onClick={() => this.setSearchParam("question")}
        >
          {this.props.t("changes.filter.in_questions")}
        </Dropdown.Item>
        <Dropdown.Item
          className="mItem"
          active={this.state.filters.searchParam === "answer"}
          onClick={() => this.setSearchParam("answer")}
        >
          {this.props.t("changes.filter.in_answers")}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  setYear = () => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          startDate: moment().subtract(1, "year").toDate(),

          endDate: moment().toDate(),
          periodFilter: "year"
        }
      },
      this.applyFilters
    );
  };

  setQuarter = () => {
    const start = moment().subtract(1, "quarter").toDate();

    const end = moment().toDate();
    this.setState(
      {
        filters: {
          ...this.state.filters,
          startDate: start,
          endDate: end,
          periodFilter: "quarter"
        }
      },
      this.applyFilters
    );
  };

  setMonth = () => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          startDate: moment().subtract(30, "days").toDate(),

          endDate: moment().toDate(),
          periodFilter: "month"
        }
      },
      this.applyFilters
    );
  };

  setWeek = () => {
    this.setState(
      {
        filters: {
          ...this.state.filters,
          startDate: moment().subtract(7, "days").toDate(),

          endDate: moment().toDate(),
          periodFilter: "week"
        }
      },
      this.applyFilters
    );
  };

  resetPeriod = () =>
    this.setState(
      {
        filters: {
          ...this.state.filters,
          startDate: null,
          endDate: null,
          periodFilter: "all"
        }
      },
      this.applyFilters
    );

  setStartDate = startDate =>
    this.setState(
      {
        filters: {
          ...this.state.filters,
          startDate,
          periodFilter: "range"
        }
      },
      this.applyFilters
    );

  setEndDate = endDate =>
    this.setState(
      {
        filters: {
          ...this.state.filters,
          endDate,
          periodFilter: "range"
        }
      },
      this.applyFilters
    );

  timePeriod = () => {
    const periods = [
      {
        key: "week",
        text: this.props.t("changes.time_periods.last_week"),
        value: "week",
        onClick: this.setWeek
      },
      {
        key: "month",
        text: this.props.t("changes.time_periods.last_month"),
        value: "month",
        onClick: this.setMonth
      },
      {
        key: "quarter",
        text: this.props.t("changes.time_periods.last_quarter"),
        value: "quarter",
        onClick: this.setQuarter
      },
      {
        key: "year",
        text: this.props.t("changes.time_periods.last_year"),
        value: "year",
        onClick: this.setYear
      },
      {
        key: "all",
        text: this.props.t("changes.time_periods.all"),
        value: "all",
        onClick: this.resetPeriod
      }
    ];

    if (this.state.filters.periodFilter === "range") {
      periods.push({
        key: "range",
        text: this.props.t("changes.time_periods.date_range"),
        value: "range"
      });
    }

    return (
      <Dropdown
        className={`timePeriod ${this.state.filters.periodFilter ? "act" : ""}`}
        placeholder={this.props.t("changes.time_periods.pick_range")}
        fluid
        selection
        options={periods}
        value={this.state.filters.periodFilter}
      />
    );
  };

  setAuthor = author =>
    this.setState(
      {
        filters: {
          ...this.state.filters,
          author
        }
      },
      this.applyFilters
    );

  usersDropDown = () => {
    let usersNames = [
      {
        key: "all",
        text: this.props.t("changes.user_dropdown.all_users"),
        value: "all",
        onClick: () => this.setAuthor("")
      },
      {
        key: "BotwiseAI",
        text: "BotwiseAI",
        value: "BotwiseAI",
        onClick: () => this.setAuthor("BotwiseAI")
      }
    ];
    const propsUsers = this.props.usersList.map(user => ({
      key: user.userName,
      text: user.userName,
      value: user.userName,
      onClick: () => this.setAuthor(user.userName)
    }));

    usersNames.push(...propsUsers);

    return (
      <Dropdown
        className={`author ${this.state.filters.author ? "act" : ""}`}
        placeholder={this.props.t("changes.user_dropdown.author")}
        fluid
        selection
        options={usersNames}
        value={this.state.filters.author}
      />
    );
  };

  initialDocumentsList = [
    {
      key: "all",
      text: this.props.t("changes.documents_list.all"),
      value: "all"
    }
  ];

  setDocument = document =>
    this.setState(
      ({ filters }) => ({
        filters: {
          ...filters,
          document
        }
      }),
      this.applyFilters
    );

  resetFilters = () => {
    this.setState(
      {
        filters: initialFilters
      },
      this.applyFilters
    );
  };

  resetFiltersButton = () => {
    return (
      <Button className="filterResetButton" onClick={this.resetFilters}>
        <Icon name="close" />
        {this.props.t("changes.reset_filters")}
      </Button>
    );
  };

  maxDate = new Date();

  accordionIcon = (id, size = "big") => (
    <Icon
      className={styles.arrowIcon}
      name={`angle ${this.state.openChange === id ? "up" : "down"}`}
      size={size}
      onClick={() => this.openAccordion(id)}
    />
  );

  newResultButtons = change => (
    <div
      className={classnames(
        styles.rowButtonsContainer,
        this.props.asIssues && styles.rowButtonsContainerWrap
      )}
    >
      {this.props.asIssues && this.props.resultButton && (
        <div className={styles.rowButtonsContainerWrapButton}>
          {this.props.resultButton({
            ...change
          })}
        </div>
      )}
      {!this.props.asIssues && (
        <>
          {change.document && change.documentAnchors && (
            <DocumentAnchorsLink
              className={styles.anchorsLink}
              document={change.document}
              documentAnchors={change.documentAnchors}
            />
          )}
          {this.renderMoreButton(change)}
        </>
      )}
      {this.accordionIcon(change.id)}
    </div>
  );

  getContents = () => {
    const { changes, issuesData, asIssues, chosenAnswer } = this.props;

    const data =
      asIssues &&
      this.state.filters === initialFilters &&
      issuesData?.length > 0
        ? issuesData
        : changes;

    return data.map(item => {
      const details = [
        {
          size: "col-8",
          content: this.state.openChange === item.id && (
            <ChangesDetails
              key={`details-for-${item.id}`}
              suggestions={this.renderSuggestions(item.suggestions)}
              content={
                <ChangesDetailsFragment
                  fragment={item}
                  preview
                  openImg={this.openImg}
                />
              }
              document={item.document}
              hideDetails={() => this.openAccordion(item.id)}
              previewDoc={() => this.props.preview(item.document)}
            />
          ),
          id: `column-details-${item.id}`,
          rowItemClassname: styles.changesRowItem
        },
        {
          size: "col-2",
          content:
            this.state.openChange === item.id &&
            this.accordionIcon(item.id, "large"),
          id: `column-icon-${item.id}`,
          rowItemClassname: styles.changesRowItem
        }
      ];

      const mappedChange = [
        {
          size: "col-2",
          content: (
            <div className={styles.authorDateContainer}>
              <span className={styles.date}>
                {moment(item.updatedAt).format("lll")}
              </span>
              <span className={styles.author}>{item.modifiedBy?.userName}</span>
            </div>
          ),
          id: `column-date-username${item.id}`,
          rowItemClassname: styles.changesRowItem
        },
        {
          size: "col-8",
          content: (
            <ChangesDetailsFragment
              fragment={{
                ...item,
                answerText:
                  this.state.openChange !== item.id ? item.answerText : ""
              }}
              openImg={this.openImg}
            />
          ),
          id: `column-fragment-${item.id}`,
          onRowItemClick: () => this.openAccordion(item.id),
          rowItemClassname: styles.changesRowItem
        },
        {
          size: "col-2",
          content: this.newResultButtons(item),
          id: `column-actions-${item.id}`,
          rowItemClassname: styles.changesRowItem
        }
      ];

      this.state.openChange === item.id &&
        details.map(detail => mappedChange.push(detail));

      return {
        contents: mappedChange,
        rowClassname: classnames(
          this.state.openChange === item.id && styles.changesRowActive,
          asIssues && chosenAnswer === item.id && styles.learnChosen
        )
      };
    });
  };

  sortOptions = [
    {
      key: 1,
      text: this.props.t("changes.table_head.modified_date_asc"),
      value: "updatedAt_asc",
      onClick: () => this.handleSorting("updatedAt", "asc")
    },
    {
      key: 2,
      text: this.props.t("changes.table_head.modified_date_desc"),
      value: "updatedAt_desc",
      onClick: () => this.handleSorting("updatedAt", "desc")
    },

    {
      key: 3,
      text: this.props.t("changes.table_head.author_asc"),
      value: "createdBy_asc",
      onClick: () => this.handleSorting("createdBy", "asc")
    },

    {
      key: 4,
      text: this.props.t("changes.table_head.author_desc"),
      value: "createdBy_desc",
      onClick: () => this.handleSorting("createdBy", "desc")
    }
  ];

  tableHead = () => [
    {
      title: this.props.t("changes.table_head.date_author"),
      size: "col-2"
    },
    {
      title: this.props.t("changes.table_head.entry"),
      size: "col-7"
    },
    {
      title: (
        <div className={styles.sortByContainer}>
          <span className={styles.sortBy}>
            {this.props.t("changes.table_head.sort")}
          </span>
          <Dropdown
            className={styles.sortByDropdown}
            value={this.state.sortName + "_" + this.state.sortDir}
            options={this.sortOptions}
          />
        </div>
      ),
      size: "col-3"
    }
  ];

  render() {
    return (
      <>
        {this.state.isOpen && (
          <ImageWindow
            src={this.state.openSrc}
            click={() => this.openImg(null)}
          />
        )}
        <div className={classnames("changesPageWrapper", this.props.className)}>
          <div
            className="changesTableWrapper"
            ref={el => (this.container = el)}
          >
            <div ref={this.filtersDiv}>
              <div className="searchSection">
                <Input
                  input={{ input: { ["data-hj-allow"]: "" } }}
                  className={`filterInput ${
                    this.state.filters.searchValue ? "active" : ""
                  }`}
                  placeholder={this.props.t(
                    "changes.filter.search_by_question_or_answer"
                  )}
                  onChange={this.setSearchValue}
                  value={this.state.filters.searchValue}
                  onKeyDown={this.onKeyPress}
                >
                  <Label
                    className="sIconLabel srch"
                    onClick={this.handleSearch}
                  >
                    <Icon className="search" />
                  </Label>
                  <input />
                  <Label className="sIconLabel">
                    <div
                      onMouseLeave={() =>
                        this.setState({
                          searchMenu: false
                        })
                      }
                      onMouseOver={() =>
                        this.setState({
                          searchMenu: true
                        })
                      }
                    >
                      {this.renderSearchMenu()}
                      <Icon className="arrowIcon" name="angle down" />
                    </div>
                  </Label>
                </Input>
                <p className={styles.elementsFound}>
                  {this.props.t("changes.filter.results_found")}
                  <span className={styles.elementsFound__count}>
                    {this.state.totalElements}
                  </span>
                </p>
                <Button
                  className="moreFiltersButton"
                  onClick={() =>
                    this.setState(prevState => ({
                      accordionFilter: !prevState.accordionFilter
                    }))
                  }
                >
                  {this.state.accordionFilter
                    ? `- ${this.props.t("changes.filter.hide_filters")}`
                    : `+ ${this.props.t("changes.filter.show_filters")}`}
                </Button>
                {!this.props.asIssues && (
                  <CustomButton className="addIssueButton" onClick={() => {}}>
                    <Link to={TRAIN_FRAGMENT_BRAND_NEW}>
                      {this.props.t("changes.add_fragment")}
                    </Link>
                  </CustomButton>
                )}
              </div>
              {this.state.accordionFilter && (
                <div className="filterAccordion">
                  {this.timePeriod()}
                  <DatePicker
                    locale={getLocaleISO()}
                    placeholderText={this.props.t(
                      "changes.date_picker.pick_date_from"
                    )}
                    className={`${this.state.filters.startDate ? "act" : ""}`}
                    calendarClassName="calendarDays"
                    selected={this.state.filters.startDate}
                    onChange={date => this.setStartDate(date)}
                    selectsStart
                    startDate={this.state.filters.startDate}
                    endDate={this.state.filters.endDate}
                    dateFormat={this.props.t("changes.date_picker.date_format")}
                    maxDate={this.maxDate}
                  />
                  <DatePicker
                    locale={getLocaleISO()}
                    placeholderText={this.props.t(
                      "changes.date_picker.pick_date_to"
                    )}
                    className={`${this.state.filters.endDate ? "act" : ""}`}
                    calendarClassName="calendarDays"
                    selected={this.state.filters.endDate}
                    onChange={date => this.setEndDate(date)}
                    selectsEnd
                    startDate={this.state.filters.startDate}
                    endDate={this.state.filters.endDate}
                    minDate={this.state.filters.startDate}
                    dateFormat={this.props.t("changes.date_picker.date_format")}
                    maxDate={this.maxDate}
                  />
                  {this.usersDropDown()}
                  <DropdownFilter
                    className={`documentsDropDown${
                      this.state.filters.document ? " act" : ""
                    }`}
                    placeholder={this.props.t(
                      "changes.filter.dropdown.placeholder"
                    )}
                    options={this.state.documentsList}
                    initialOptions={this.initialDocumentsList}
                    onChange={this.setDocument}
                    search={true}
                    value={this.state.filters.document}
                  />
                  {this.resetFiltersButton()}
                </div>
              )}
            </div>
            <Table
              items={this.getContents()}
              tableHead={this.tableHead()}
              currentPage={this.state.paginPage}
              loadingFunc={this.applyFilters}
              withLoading={
                this.props.asIssues &&
                this.state.filters === initialFilters &&
                this.props.issuesData?.length > 0
                  ? false
                  : true
              }
              totalPages={this.props.totalPages}
              renderTrigger={!this.state.accordionFilter}
              scrollContainerClass={this.props.scrollContainerClass}
            />
          </div>
        </div>
      </>
    );
  }
}

ChangesComponent.defaultProps = {
  changes: []
};

ChangesComponent.propTypes = {
  getChanges: PropTypes.func,
  deleteChange: PropTypes.func,
  clear: PropTypes.func,
  preview: PropTypes.func,
  changes: PropTypes.array,
  delete: PropTypes.func,
  openEditChangeView: PropTypes.func,
  getUsersList: PropTypes.func,
  usersList: PropTypes.array,
  totalPages: PropTypes.number,
  getDocumentsList: PropTypes.func,
  documentsList: PropTypes.array,
  isFetching: PropTypes.bool,
  copyChangesParams: PropTypes.func,
  filters: PropTypes.object,
  setLocation: PropTypes.func,
  prevLocation: PropTypes.string,
  history: PropTypes.object,
  sortName: PropTypes.string,
  sortDir: PropTypes.string,
  totalElements: PropTypes.number,
  issuesData: PropTypes.arrayOf(PropTypes.object),
  asIssues: PropTypes.bool,
  chosenAnswer: PropTypes.string,
  className: PropTypes.string,
  resultButton: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  t: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
  scrollContainerClass: PropTypes.string
};

export default withTranslation()(WithMixpanelContext()(ChangesComponent));
