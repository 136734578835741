import { DefaultControl } from "../DefaultControl/DefaultControl";

import styles from "./ControlGroup.module.scss";

interface ControlGroupProps {
  children: React.ReactElement<{ onClick: () => void }>[];
}

export const ControlGroup = ({ children }: ControlGroupProps) => {
  return (
    <div className={styles.group}>
      {children.map((child, index) => (
        <DefaultControl
          onClick={child.props.onClick}
          className={styles.groupControl}
          key={index}
        >
          {child}
        </DefaultControl>
      ))}
    </div>
  );
};
