import { connect } from "react-redux";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Confirm } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import { hideConfirmModal } from "~/actions";

class CloseIssuesModal extends Component {
  render() {
    const {
      cancel,
      modalProps: { close }
    } = this.props;

    const closeModal = () => {
      close();
      cancel();
    };

    return (
      <Confirm
        basic
        size="small"
        content={
          <>
            <h3 style={{ color: "white" }}>
              {this.props.t("issues.modal.are_you_sure_leaving")}
            </h3>
            <p>
              {this.props.t(
                "issues.modal.if_you_proceed_changes_will_not_be_saved"
              )}
            </p>
          </>
        }
        cancelButton={this.props.t("issues.modal.no")}
        confirmButton={this.props.t("issues.modal.yes")}
        open
        onCancel={cancel}
        onConfirm={closeModal}
      />
    );
  }
}

CloseIssuesModal.propTypes = {
  cancel: PropTypes.func,
  modalProps: PropTypes.shape({
    close: PropTypes.func
  }),
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return state.modal;
};

const mapDispatchToProps = dispatch => {
  return {
    cancel: () => {
      dispatch(hideConfirmModal());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(CloseIssuesModal));
