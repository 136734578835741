import React, { useCallback, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import Button from "~/atoms/Button/Button";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";
import { useToggle } from "~/hooks/useToggle";

import styles from "./SearchInput.module.scss";
import AutocompleteInput from "../AutocompleteInput/AutocompleteInput";
import { useSelector, useThunkDispatch } from "~/configureStore";
import {
  clearSearch,
  saveLastChatMessage,
  searchAction
} from "~/actions/SearchActions";
import { useMixpanelContext } from "~/contexts/mixpanel";
import { MIXPANEL_EVENTS } from "~/contexts/mixpanel/event-types";
import { SearchPosition } from "~/molecules/SearchPosition/SearchPosition";
import { nextFinderIndex } from "~/actions/SearchActions";

interface SearchInputProps {
  className?: string;
  suggestionsClass?: string;
}

export const SearchInput = ({
  className,
  suggestionsClass
}: SearchInputProps) => {
  const { t } = useTranslation();

  const [isInputFocused, setIsInputFocused] = useToggle(false);

  const [searchValue, setSearchValue] = useState("");

  const {
    prevChatQuestion,
    currentQuestion,
    items: { suggestions },
    loading
  } = useSelector(({ searchNew }) => searchNew);

  const shouldFetchPreviousMessage = useMemo(
    () => prevChatQuestion && prevChatQuestion !== currentQuestion,
    [prevChatQuestion]
  );

  const { trackEvent } = useMixpanelContext();

  const dispatch = useThunkDispatch();

  const handleReset = useCallback(() => dispatch(clearSearch()), [dispatch]);

  const handleSearch = useCallback(
    (message?: string) => {
      if (shouldFetchPreviousMessage) dispatch(saveLastChatMessage(""));

      trackEvent(MIXPANEL_EVENTS.SEARCH_QUERY);

      return dispatch(searchAction(message || searchValue));
    },
    [searchValue, dispatch, shouldFetchPreviousMessage]
  );

  useEffect(() => {
    if (shouldFetchPreviousMessage) {
      handleSearch(prevChatQuestion);
    }
  }, [handleSearch]);

  return (
    <>
      <div
        className={classNames(
          styles.wrapper,
          isInputFocused && styles.wrapperFocused,
          className
        )}
      >
        <AutocompleteInput
          onChange={setSearchValue}
          onSubmit={handleSearch}
          resultsPosition="bottom"
          placeholder={t("search.search_input.placeholder")}
          className={styles.autocomplete}
          icon={undefined}
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => setIsInputFocused(false)}
          onConsecutiveEnter={() => dispatch(nextFinderIndex())}
          hideSuggestionsOnClick
          defaultValue={currentQuestion}
        />
        {currentQuestion && (
          <>
            <SearchPosition />
            <Button onClick={handleReset} className={styles.resetButton}>
              <Icon name="close" className={styles.resetButtonIcon} />
              {t("search.search_input.clear")}
            </Button>
          </>
        )}
        <Button onClick={() => handleSearch()} className={styles.button}>
          <Icon name="search" className={styles.buttonIcon} />
          {t("search.search_input.search")}
        </Button>
      </div>
      {!loading && suggestions.length > 0 && (
        <ul className={classNames(styles.suggestions, suggestionsClass)}>
          {suggestions.map(({ query, text }) => (
            <li className={styles.suggestion} key={text}>
              <button
                className={styles.suggestionButton}
                onClick={() => handleSearch(query)}
              >
                {text}
              </button>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
