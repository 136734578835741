import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import styles from "./MessageTimer.module.scss";

export default class MessageTimerComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: null
    };
  }

  componentDidMount() {
    this.timeInterval = setInterval(
      () => this.setState({ date: this.props.startDate }),
      1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.timeInterval);
  }

  render() {
    return (
      <div className={this.props.className}>
        <span className={styles.timer}>
          {moment(this.props.startDate).fromNow()}
        </span>
      </div>
    );
  }
}

MessageTimerComponent.defaultProps = {
  date: new Date(),
  className: ""
};

MessageTimerComponent.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  className: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired
};
