import { uniq } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Loader, SearchResultData } from "semantic-ui-react";
import { getSynonymsAction } from "~/actions/SynonymsActions";
import Button from "~/atoms/Button/Button";
import { ErrorMessage } from "~/atoms/ErrorMessage/ErrorMessage";
import { Search } from "~/atoms/Search/Search";
import { SearchNotFound } from "~/atoms/SearchNotFound/SearchNotFound";
import Wrapper from "~/atoms/Wrapper/Wrapper";
import { useSelector, useThunkDispatch } from "~/configureStore";
import { USER_ROLES } from "~/constants/app";
import { HOME } from "~/constants/routes";
import { MIXPANEL_EVENTS } from "~/contexts/mixpanel/event-types";
import { useToggle } from "~/hooks/useToggle";
import { useTrackMixpanelEvent } from "~/hooks/useTrackMixpanelEvent";
import { SynonymsModal } from "~/molecules/SynonymsModal/SynonymsModal";
import { SynonymsRow } from "~/molecules/SynonymsRow/SynonymsRow";

import styles from "./Synonyms.module.scss";

export const Synonyms = () => {
  const { t } = useTranslation();

  const currentUserRole = useSelector(({ login: { role } }) => role);

  const { push: redirect } = useHistory();

  useTrackMixpanelEvent(MIXPANEL_EVENTS.SYNONYMS_VISIT);

  useEffect(() => {
    if (
      typeof currentUserRole !== "undefined" &&
      ![USER_ROLES.MANAGER, USER_ROLES.ADMIN, USER_ROLES.EDITOR].includes(
        currentUserRole as USER_ROLES
      )
    ) {
      redirect(HOME);
    }
  }, [currentUserRole]);

  const [isModalOpened, toggleModalOpened] = useToggle();
  const [isLoading, toggleLoading] = useToggle();
  const [searchValue, setSearchValue] = useState("");
  const [fetchingError, setFetchingError] = useState<string | undefined>(
    undefined
  );

  const terms = useSelector(({ synonyms }) => synonyms.content);

  const synonyms = useMemo(
    () =>
      terms.filter(
        term =>
          term.primary.includes(searchValue) ||
          term.synonyms.find(synonym => synonym.includes(searchValue))
      ),
    [terms, searchValue]
  );

  const dispatch = useThunkDispatch();

  const fetchSynonyms = useCallback(async () => {
    toggleLoading(true);
    setFetchingError(undefined);

    try {
      await dispatch(getSynonymsAction());
    } catch (err) {
      setFetchingError(t("synonyms.error.couldnt_fetch_synonyms_try_again"));
    } finally {
      toggleLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchSynonyms();
  }, []);

  const handleSearch = (result: string) => {
    if (result) {
      setSearchValue(result);
    } else {
      setSearchValue("");
    }
  };

  return (
    <Wrapper
      title={t("synonyms.synonyms")}
      ExtendedHeader={() => (
        <Button
          className={styles.button}
          onClick={() => toggleModalOpened(true)}
        >
          {t("synonyms.add_new_term")}
        </Button>
      )}
      className={styles.wrapper}
      headerClassName={styles.wrapperHeader}
    >
      {isLoading && (
        <div className={styles.loading}>
          <Loader size="medium" active />
        </div>
      )}
      <Search
        className={styles.search}
        placeholder={t("synonyms.search_placeholder")}
        items={uniq(
          synonyms
            .map(synonym => [synonym.primary, ...synonym.synonyms])
            .flat(1)
        )}
        onResultSelect={handleSearch}
        onSearchChange={handleSearch}
        clear
      />
      {!isLoading && fetchingError && <ErrorMessage error={fetchingError} />}
      {synonyms.length > 0 && (
        <div className={styles.resultsHeader}>
          <p className={styles.resultsHeaderTitle}>{t("synonyms.term")}</p>
          <p className={styles.resultsHeaderTitle}>{t("synonyms.synonyms")}</p>
        </div>
      )}
      <div className={styles.results}>
        {synonyms.length > 0 ? (
          synonyms.map(term => <SynonymsRow key={term.id} {...term} />)
        ) : (
          <SearchNotFound
            extendClassName={styles.resultsNotFound}
            text={t("synonyms.search_not_found")}
          />
        )}
      </div>
      <SynonymsModal isOpen={isModalOpened} handleClose={toggleModalOpened} />
    </Wrapper>
  );
};
