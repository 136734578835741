import { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { updateWorkflowTreeAction } from "~/actions/workflow/actions";
import { NodeTypes } from "~/components/types";
import { useThunkDispatch } from "~/configureStore";
import ChangesContainer from "~/containers/ChangesContainer";
import { useGetElementById } from "~/hooks/useGetElementById";
import { NodeEditData } from "~/reducers/types";
import { HintType } from "~/services/api-types";
import Button from "../Button/Button";
import { FLOW_MODAL_CONTAINER_ID } from "../Flow/Flow";
import { Modal, ModalHandle } from "../Modal/Modal";

interface KnowledgeEntryModalProps {
  nodeData: Pick<NodeEditData, "position" | "id">;
  currentFragment?: HintType;
  onModalClose?: () => void;
}

export const KnowledgeEntryModal = ({
  nodeData,
  onModalClose,
  currentFragment
}: KnowledgeEntryModalProps) => {
  const { t } = useTranslation();

  const [pickedKnowledgeFragment, setPickedKnowledgeFragment] = useState<
    HintType | undefined
  >(undefined);

  const modalRef = useRef<ModalHandle>(null);

  useEffect(() => {
    if (
      (currentFragment && !pickedKnowledgeFragment) ||
      (pickedKnowledgeFragment &&
        currentFragment &&
        pickedKnowledgeFragment.id !== currentFragment.id)
    ) {
      setPickedKnowledgeFragment(currentFragment);
    }
  }, [currentFragment, pickedKnowledgeFragment]);

  const dispatch = useThunkDispatch();

  const saveKnowledgeEntry = useCallback(
    (knowledgeEntryId: string) =>
      dispatch(
        updateWorkflowTreeAction({
          node: {
            ...nodeData,
            data: {
              id: knowledgeEntryId
            },
            type: NodeTypes.KNOWLEDGE_ENTRY
          }
        })
      ),
    [nodeData, dispatch]
  );

  const handleModalClose = useCallback(() => {
    if (typeof onModalClose === "function") {
      onModalClose();
    }

    modalRef.current?.closeModal();
  }, [onModalClose]);

  const handlePickKnowledgeFragment = useCallback(
    (value: HintType) => {
      saveKnowledgeEntry(value.id);
      setPickedKnowledgeFragment(value);

      handleModalClose();
    },
    [saveKnowledgeEntry, handleModalClose]
  );

  const portalContainer = useGetElementById(FLOW_MODAL_CONTAINER_ID);

  return portalContainer
    ? createPortal(
        <Modal
          ref={modalRef}
          withoutFooter
          title="knowledge_entry_modal_content.select_entry"
          opened
        >
          <div>
            <ChangesContainer
              asIssues
              issuesData={[]}
              resultButton={(result: any) => (
                <div>
                  <Button onClick={() => handlePickKnowledgeFragment(result)}>
                    {t("knowledge_entry_modal_content.choose")}
                  </Button>
                </div>
              )}
              chosenAnswer={pickedKnowledgeFragment?.id}
            />
          </div>
        </Modal>,
        portalContainer
      )
    : null;
};
