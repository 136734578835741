import { getOffsetTop } from "~/helpers/scroll";
import { DocumentFragmentAnchorsType } from "~/services/api-types";
import styles from "./DocumentPreview.module.scss";

const SCROLL_OFFSET = 75;

export function scrollAndHighlightFragment(
  scrollWrapper: HTMLElement,
  {
    titleAnchor,
    contentStartAnchor,
    contentEndAnchor
  }: DocumentFragmentAnchorsType
) {
  const titleOrContentAnchor = titleAnchor || contentStartAnchor;
  if (titleOrContentAnchor) {
    const scrollTarget = window.document.getElementById(titleOrContentAnchor);

    if (scrollTarget) {
      const offsetTop = getOffsetTop(
        scrollTarget,
        scrollWrapper.offsetParent as HTMLElement
      );
      const yScrollInPixels =
        offsetTop -
        scrollWrapper.scrollTop -
        scrollWrapper.offsetTop -
        SCROLL_OFFSET;

      scrollWrapper.scrollBy({
        top: yScrollInPixels,
        left: 0,
        behavior: "smooth"
      });

      const titleElement = titleAnchor
        ? document.getElementById(titleAnchor)
        : null;
      const contentElements = selectElementsBetweenIds(
        contentStartAnchor,
        contentEndAnchor
      );
      if (titleElement) {
        addTemporaryStyle(titleElement, styles.titleHighlight);
      }
      if (contentElements) {
        if (contentElements.length > 1) {
          const stopAtElement = contentElements.find(cannotBeEncapsulated);
          const elementsToEncapsulate = contentElements.slice(
            0,
            stopAtElement && contentElements.indexOf(stopAtElement)
          );
          const elementsToHighlight = stopAtElement
            ? contentElements.slice(contentElements.indexOf(stopAtElement))
            : [];
          encapsulateWithTemporaryStyle(
            elementsToEncapsulate,
            styles.contentHighlight
          );
          elementsToHighlight.forEach(x =>
            addTemporaryStyle(x, styles.contentHighlight)
          );
        } else {
          contentElements.forEach(x =>
            addTemporaryStyle(x, styles.contentHighlight)
          );
        }
      }
    }
  }
}

function encapsulateWithTemporaryStyle(elements: HTMLElement[], style: string) {
  const tempElem = document.createElement("div");
  if (elements && elements.length > 0) {
    elements[0].parentNode?.insertBefore(tempElem, elements[0]);
    elements.forEach(x => tempElem.appendChild(x));
    addTemporaryStyle(tempElem, style);

    setTimeout(() => {
      const fragment = document.createDocumentFragment();
      while (tempElem.firstChild) {
        fragment.appendChild(tempElem.firstChild);
      }
      tempElem.parentNode?.replaceChild(fragment, tempElem);
    }, 2500);
  }
}

function addTemporaryStyle(element: HTMLElement, style: string) {
  element.classList.add(style);
  setTimeout(() => element.classList.remove(style), 2500);
}

function selectElementsBetweenIds(
  startElementId: string | null,
  endElementId: string | null
) {
  if (startElementId && endElementId) {
    const startElement = document.getElementById(startElementId);
    const endElement = document.getElementById(endElementId);
    if (startElement && endElement) {
      const elements = selectElementsBetweenElements(startElement, endElement);
      return elements;
    }
  }
  return null;
}

function selectElementsBetweenElements(
  startElement: HTMLElement,
  endElement: HTMLElement
) {
  const elements = [];
  let currentElement = startElement;
  while (currentElement !== endElement) {
    elements.push(currentElement);
    const nextElement = currentElement.nextElementSibling as HTMLElement | null;
    if (nextElement) {
      currentElement = nextElement;
    } else {
      break;
    }
  }
  elements.push(endElement);
  return elements;
}

function cannotBeEncapsulated(element: HTMLElement) {
  return ["tr", "td"].includes(element.tagName.toLowerCase());
}
