import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Modal } from "semantic-ui-react";
import Button from "~/atoms/Button/Button";
import { DOCUMENTS } from "~/constants/routes";
import styles from "./OverwriteWarningModal.module.scss";

interface OverwriteWarningModalProps {
  modifiedBy: string;
  isOpen: boolean;
  handleClose: () => void;
}

export const OverwriteWarningModal = ({
  handleClose,
  isOpen,
  modifiedBy
}: OverwriteWarningModalProps) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  return (
    <Modal
      className={styles.modal}
      open={isOpen}
      size="tiny"
      closeOnDimmerClick
    >
      <div className={styles.wrapper}>
        <p className={styles.heading}>
          <Trans i18nKey="documents.edit_modal_heading">
            Are you sure you want to edit? User
            <strong>{{ modifiedBy } as any}</strong> is editing this document
            now and
            <span className={styles.overwrite}>you might overwrite</span> their
            changes.
          </Trans>
        </p>
        <div className={styles.buttons}>
          <Button
            className={styles.cancelButton}
            onClick={() => push(DOCUMENTS)}
          >
            {t("documents.new_article_footer_cancel_button")}
          </Button>
          <Button className={styles.continueButton} onClick={handleClose}>
            {t("documents.edit_modal_continue_button")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
