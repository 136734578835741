import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PropTypes } from "prop-types";
import { Button, Input, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import { MIXPANEL_EVENTS } from "~/contexts/mixpanel/event-types";
import { WithMixpanelContext } from "~/contexts/mixpanel";
import { uploadFromURL, hideConfirmModal } from "~/actions";

import styles from "./UploadFromURLModal.module.scss";

const UploadFromURLModal = props => {
  const [url, setURL] = useState("");
  const [isValidUrl, setValidUrl] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setValidUrl(validateUrl(url));
  }, [url]);

  const validateUrl = url => {
    try {
      new URL(url);
    } catch (_) {
      return false;
    }
    return true;
  };

  const handleChange = e => {
    const prependHttp = x => (x.indexOf("://") === -1 ? "http://" + x : x);
    setURL(prependHttp(e.target.value) || "");
  };

  const handleUpload = () => {
    if (typeof props.onUploadStart === "function") {
      props.onUploadStart();
    }

    props.upload(url, props.parentId).then(() => {
      props.uploadCallback();
      props.trackEvent(MIXPANEL_EVENTS.NEW_DOC_ADD);
    });

    props.hide();
  };

  return (
    <Modal open onClose={props.hide} size="tiny">
      <Modal.Header>{t("documents.upload_from_url.choose_url")}</Modal.Header>
      <Modal.Content>
        <Input
          fluid
          onChange={handleChange}
          placeholder={t("documents.upload_from_url.choose_url_placeholder")}
          error={url && !isValidUrl}
          action={
            <Button
              className={styles.button}
              disabled={!isValidUrl}
              onClick={handleUpload}
            >
              {t("documents.upload_from_url.add")}
            </Button>
          }
        />
      </Modal.Content>
    </Modal>
  );
};

UploadFromURLModal.propTypes = {
  upload: PropTypes.func.isRequired,
  uploadCallback: PropTypes.func,
  hide: PropTypes.func.isRequired,
  parentId: PropTypes.number,
  onUploadStart: PropTypes.func,
  trackEvent: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    upload: (url, parentId) => dispatch(uploadFromURL(url, parentId)),
    hide: () => dispatch(hideConfirmModal())
  };
};

export default connect(
  null,
  mapDispatchToProps
)(WithMixpanelContext()(UploadFromURLModal));
