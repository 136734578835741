import moment, { Moment } from "moment";
import { DATE_TIME_ENTRY_FORMAT } from "~/constants/app";

export const formatDate = (
  date: string | Date | Moment,
  format: string = DATE_TIME_ENTRY_FORMAT
) => {
  if (!date || !moment(date).isValid()) return "";

  return moment(date).format(format);
};
