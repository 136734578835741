import { connect } from "react-redux";
import { isEmpty } from "lodash";

import Issues from "~/components/Issues";
import {
  changeAnswer,
  clearEditorState,
  expandContainer,
  openEditChangeView,
  saveChangedAnswer,
  showConfirmModal
} from "~/actions";

const mapStateToProps = (state, ownProps) => {
  return {
    question: state.editor.question,
    fetchingAnswers: state.editor.fetchingAnswers,
    answers: state.editor?.answers ?? [],
    noAnswersFound:
      state.editor.answers.length === 0 &&
      state.editor.fetchingAnswers === false,
    editorChosenAnswer: !isEmpty(state.editor?.chosenAnswer)
      ? state.editor.chosenAnswer
      : null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCloseClick: () => {
      dispatch(clearEditorState());
    },
    onSaveButtonClick: backFunction => {
      dispatch(saveChangedAnswer(backFunction, true));
    },
    changeChosenAnswer: chosenAnswerIndex => {
      dispatch(changeAnswer(chosenAnswerIndex));
    },
    expandContainer: state => dispatch(expandContainer(state)),
    onOpenChangeView: change => {
      dispatch(openEditChangeView(change, false));
    },
    showConfirmModal: closefn =>
      dispatch(showConfirmModal("ISSUES_MODAL", closefn))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Issues);
