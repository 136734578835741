import classNames from "classnames";
import React from "react";
import { ControlButton } from "react-flow-renderer";

import styles from "./DefaultControl.module.scss";

interface DefaultControlProps {
  children: React.ReactElement;
  onClick: () => void;
  className?: string;
}

export const DefaultControl = ({
  children,
  onClick,
  className
}: DefaultControlProps) => {
  return (
    <ControlButton
      onClick={onClick}
      className={classNames(styles.button, className)}
    >
      {children}
    </ControlButton>
  );
};
