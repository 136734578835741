import {
  ADD_SYNONYMS,
  DELETE_SYNONYM,
  GET_SYNONYMS,
  UPDATE_SYNONYM
} from "~/actions/types";
import { SynonymsDataType, SynonymTermType } from "~/services/api-types";

type SynonymsState = Pick<
  SynonymsDataType,
  "content" | "totalElements" | "totalPages" | "numberOfElements"
>;

const defaultState: SynonymsState = {
  content: [],
  numberOfElements: 0,
  totalElements: 0,
  totalPages: 0
};

export interface GetSynonymsAction extends SynonymsDataType {
  type: typeof GET_SYNONYMS;
  withLoader: false;
}

export interface AddSynonymsAction {
  type: typeof ADD_SYNONYMS;
  payload: SynonymTermType;
}

export interface DeleteSynonymAction {
  type: typeof DELETE_SYNONYM;
  payload: {
    id: string;
  };
}

export interface UpdateSynonymAction {
  type: typeof UPDATE_SYNONYM;
  payload: SynonymTermType;
}

type ActionTypes =
  | GetSynonymsAction
  | AddSynonymsAction
  | DeleteSynonymAction
  | UpdateSynonymAction;

export const synonyms = (
  state: SynonymsState = defaultState,
  action: ActionTypes
): SynonymsState => {
  switch (action.type) {
    case GET_SYNONYMS:
      return {
        content: action.content,
        numberOfElements: action.numberOfElements,
        totalElements: action.totalElements,
        totalPages: action.totalPages
      };
    case ADD_SYNONYMS:
      return {
        ...state,
        content: [action.payload, ...state.content],
        numberOfElements: state.numberOfElements + 1,
        totalElements: state.totalElements + 1
      };
    case DELETE_SYNONYM:
      return {
        ...state,
        content: [
          ...state.content.filter(synonym => synonym.id !== action.payload.id)
        ],
        numberOfElements: state.numberOfElements - 1,
        totalElements: state.totalElements - 1
      };
    case UPDATE_SYNONYM:
      return {
        ...state,
        content: [
          ...state.content.map(synonym => {
            if (synonym.id === action.payload.id) {
              return action.payload;
            }

            return synonym;
          })
        ]
      };
    default:
      return state;
  }
};
