import axios from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getRequest } from "~/helpers/axios";

interface FetchOptions<R> {
  params?: Record<string, any>;
  defaultValue?: R;
  silentFetch?: boolean;
}

interface ReturnValue<R> {
  isLoading: boolean;
  result: R | undefined;
  error: unknown;
  refetch: (silentFetch: boolean) => Promise<void>;
}

export const useFetch = <R extends any = any>(
  route: string,
  options?: FetchOptions<R>
): ReturnValue<R> => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [result, setResult] = useState<R | undefined>(options?.defaultValue);
  const [error, setError] = useState<unknown>();

  const cancelTokenSource = axios.CancelToken.source();

  const fetch = useCallback(
    async (silentFetch?: boolean) => {
      if (!silentFetch) {
        setIsLoading(true);
        setError(undefined);
        setResult(options?.defaultValue);
      }

      try {
        const response = await getRequest<R>(route, {
          params: options?.params,
          cancelToken: cancelTokenSource.token
        });

        setResult(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    },
    [options?.params, options?.defaultValue, route]
  );

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      fetch();
    }

    return () => {
      mounted = false;
      cancelTokenSource.cancel();
    };
  }, []);

  return useMemo(
    () => ({ isLoading, result, error, refetch: fetch }),
    [isLoading, result, error, fetch]
  );
};
