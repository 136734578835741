import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";
import sanitizeHtml from "sanitize-html";
import { useSelector } from "~/configureStore";
import { useToggle } from "~/hooks/useToggle";
import HTMLParagraphWithLinks from "~/components/HTMLParagraphWithLinks";
import Button from "~/atoms/Button/Button";

import styles from "./FeaturedSnippetContent.module.scss";
import { sanitizeSettings } from "~/constants/sanitizeSettings";
import { useOnQueryMatchInside } from "~/hooks/scroll/useOnQueryMatchInside";
import { useQueryMatchScroll } from "~/hooks/scroll/useSearchMatchScroll";

const MAX_RESULT_HEIGHT = 180;

interface FeaturedSnippetContentProps {
  html: string;
  scrollToMatch?: () => void;
}

export const FeaturedSnippetContent = ({
  html,
  scrollToMatch = () => {}
}: FeaturedSnippetContentProps) => {
  const [isExpanded, toggleIsExpanded] = useToggle(false);
  const [canBeExpanded, setCanBeExpanded] = useState(false);

  const { currentQuestion } = useSelector(({ searchNew }) => searchNew);

  const __html = useMemo(
    () => sanitizeHtml(html, sanitizeSettings),
    [html, currentQuestion]
  );

  const computeIfCanBeExpanded = useCallback(
    (htmlElement: HTMLElement | null) => {
      if (htmlElement) {
        const contentHeight = htmlElement.clientHeight;
        setCanBeExpanded(contentHeight > MAX_RESULT_HEIGHT);
      }
    },
    [__html]
  );

  const { t } = useTranslation();

  const resultRowRef = useRef<HTMLDivElement>(null);

  useQueryMatchScroll(
    isExpanded,
    () => toggleIsExpanded(true),
    scrollToMatch,
    resultRowRef.current
  );

  return (
    <div className={styles.content} ref={resultRowRef}>
      <HTMLParagraphWithLinks
        html={__html}
        className={classNames(
          styles.contentText,
          canBeExpanded && !isExpanded && styles.contentTextHidden
        )}
        onDoubleClick={() => toggleIsExpanded()}
        eventsOnHtmlElement={[computeIfCanBeExpanded]}
        as="div"
      />
      {canBeExpanded && (
        <Button
          className={classNames(
            styles.showMore,
            isExpanded && styles.showMoreExpanded
          )}
          onClick={() => toggleIsExpanded()}
        >
          {!isExpanded
            ? t("search.search_result_content.show_more")
            : t("search.search_result_content.show_less")}
        </Button>
      )}
      {canBeExpanded && (
        <span
          data-for="infotip"
          data-tip={t(
            "search.search_result_content.click_twice_on_text_to_toggle"
          )}
          className={styles.info}
        >
          <Icon name="info" />
          <ReactTooltip
            id="infotip"
            place="left"
            effect="solid"
            textColor="#3d3d3d"
            backgroundColor="#eeeff5"
          />
        </span>
      )}
    </div>
  );
};
