import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import classNames from "classnames";
import {
  ISSUES,
  ISSUES_SOLVE_ID,
  TRAIN_FRAGMENT,
  TRAIN_FRAGMENT_BRAND_NEW,
  TRAIN_FRAGMENT_EDIT,
  ISSUES_SOLVE_NEW,
  KNOWLEDGE_BASE
} from "~/constants/routes";

import ChangeAnswerContainer from "~/containers/ChangeAnswerContainer";
import EditAnswerContainer from "~/containers/EditAnswerContainer";
import IssuesListContainer from "~/containers/IssuesListContainer";
import IssuesContainer from "~/containers/IssuesContainer";
import { NewFragmentForm } from "../EditAnswer/NewFragmentForm";

const TrainComponent = props => {
  return (
    <div
      className={classNames(
        "train-main-container",
        props.expandContainer && "train-main-container-expanded"
      )}
    >
      <Switch>
        <Route exact path={ISSUES} component={IssuesListContainer} />
        <Route exact path={ISSUES_SOLVE_ID} component={IssuesContainer} />
        <Route exact path={TRAIN_FRAGMENT} component={ChangeAnswerContainer} />
        <Route exact path={ISSUES_SOLVE_NEW} component={EditAnswerContainer} />
        <Route
          exact
          path={TRAIN_FRAGMENT_BRAND_NEW}
          component={NewFragmentForm}
        />
        <Route
          exact
          path={TRAIN_FRAGMENT_EDIT}
          component={EditAnswerContainer}
        />
        <Route
          exact
          path={KNOWLEDGE_BASE}
          render={() => <Redirect to={DOCUMENTS} />}
        />
        <Redirect to={ISSUES} />
      </Switch>
    </div>
  );
};

TrainComponent.propTypes = {
  expandContainer: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  expandContainer: state.issues?.expandContainer ?? false
});

export default connect(mapStateToProps)(TrainComponent);
