import classNames from "classnames";
import React, { useCallback, useEffect, useRef } from "react";
import { Editor as EditorInstance } from "tinymce";
import RichTextEditor from "~/components/RichTextEditor";

import styles from "./Editor.module.scss";

const CUSTOM_BODY_CLASS = "article-app-container";

interface EditorProps {
  editorContent: string;
  editorRef: React.RefObject<EditorInstance>;
  setHtml: React.Dispatch<React.SetStateAction<HTMLBodyElement | null>>;
  handleEditorFocus: () => void;
  onEditorBlur: () => void;
  onEditorContentChange: (value: string, editor: EditorInstance) => void;
}

export const Editor = ({
  editorContent,
  onEditorContentChange,
  onEditorBlur,
  setHtml,
  editorRef,
  handleEditorFocus
}: EditorProps) => {
  useEffect(() => {
    document.body.classList.add(CUSTOM_BODY_CLASS);

    return () => {
      document.body.classList.remove(CUSTOM_BODY_CLASS);
    };
  }, []);

  const handleClickOnContainer = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      const target = e.target;
      const toolbar = document.getElementById("rte-toolbar-container");

      // do not pass focus if clicked element is child of toolbar;
      if (
        !(target instanceof HTMLElement) ||
        !toolbar ||
        (toolbar && target instanceof HTMLElement && !toolbar.contains(target))
      ) {
        handleEditorFocus();
      }
    },
    [handleEditorFocus]
  );

  return (
    <div
      className={classNames(styles.RTEWrapper, "RTEScrollCatcher")}
      onClick={handleClickOnContainer}
    >
      <RichTextEditor
        editorRef={editorRef}
        value={editorContent}
        onChange={onEditorContentChange}
        onBlur={onEditorBlur}
        setHtml={setHtml}
        additionalOffset={64}
        contentStyle="h1,h2,h3,h4,h5 { scroll-margin-top: 40px } img { max-width: 100%;height: auto; max-height: 500px; resize: vertical; } body { font-family: Poppins, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif }"
      />
    </div>
  );
};
