import classNames from "classnames";
import React from "react";
import styles from "./TagLabel.module.scss";

export function TagLabel({
  tag,
  className
}: {
  tag: string;
  className?: string;
}) {
  return <div className={classNames(styles.tag, className)}>{tag}</div>;
}
