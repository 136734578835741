import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Menu } from "semantic-ui-react";
import { filterMenuItems } from "~/helpers";
import { MENU_ITEMS } from "~/constants/app";
import { MenuItem } from "./MenuItem";

import styles from "./SidebarMenu.module.scss";

const SidebarMenu = ({ role }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(filterMenuItems(MENU_ITEMS, role));
  }, [role]);

  return (
    <Menu vertical className={styles.sidebarMenu}>
      {items?.length &&
        items.map(({ name, icon, href, items }) => (
          <MenuItem
            href={href}
            name={name}
            icon={icon}
            items={items}
            key={name}
          />
        ))}
    </Menu>
  );
};

SidebarMenu.propTypes = {
  role: PropTypes.string.isRequired
};

const mapStateToProps = ({ login: { role } }) => ({ role });

export default connect(mapStateToProps)(SidebarMenu);
