import { connect } from "react-redux";

import EditAnswer from "~/components/EditAnswer";
import {
  saveEditedAnswer,
  updateChange,
  customError,
  expandContainer
} from "~/actions";
import { clearEditorState } from "~/actions/ClearActions";

const mapStateToProps = (state, ownProps) => {
  // this will be used later
  // if (state.editor?.change?.changeByEdit) {
  //   return {
  //     isEditingChange: true,
  //     isFetching: state.fetching.isFetching,
  //     question: state.editor.change.question,
  //     answer: {
  //       ...state.editor.change,
  //       text: state.editor.change.answer
  //     }
  //   };
  // }
  if (state.editor.isEditingChange) {
    return {
      isEditingChange: true,
      isFetching: state.fetching.isFetching,
      question: state.editor.change.question,
      answer: {
        ...state.editor.change,
        text: state.editor.change.answer
      }
    };
  }

  if (state.editor?.change?.createNew) {
    return {
      isFetching: state.fetching.isFetching,
      question: state.editor.question,
      answer: {
        answer: state.editor.change.answer,
        suggestions: state.editor.change.suggestions,
        createNew: state.editor.change.createNew,
        issueId: state.editor.change.issueId ?? null
      }
    };
  }

  return {
    isFetching: state.fetching.isFetching,
    question: state.editor.question,
    answer: state.editor.answers[state.editor.chosenAnswerIndex]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    saveAnswer: (answer, backFunction, createNew, issueId) => {
      dispatch(saveEditedAnswer(answer, backFunction, createNew, issueId));
    },
    updateChange: (answer, backFunction, changeByEdit, issueId) => {
      dispatch(updateChange(answer, backFunction, changeByEdit, issueId));
    },
    clearState: () => dispatch(clearEditorState()),
    customError: message => dispatch(customError(message)),
    expandContainer: state => dispatch(expandContainer(state))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAnswer);
