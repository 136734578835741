import React from "react";
import PropTypes from "prop-types";
import { isWhiteLabel } from "~/constants/config";

import styles from "./BotAvatar.module.scss";

import { ReactComponent as Avatar } from "~/assets/chatbot_avatar.svg";
import { ReactComponent as WhitelabelAvatar } from "~/assets/computer.svg";

const BotAvatar = props => {
  return (
    <div className={props.className}>
      {!isWhiteLabel() ? (
        <Avatar className={styles.avatar} />
      ) : (
        <WhitelabelAvatar className={styles.avatar} />
      )}
    </div>
  );
};

BotAvatar.propTypes = {
  className: PropTypes.string
};

export default BotAvatar;
