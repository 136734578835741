import React from "react";
import PropTypes from "prop-types";
import { LeftMessage } from "~/components/LeftMessage/LeftMessage";
import RichTextEditor from "~/components/RichTextEditor";
import SuggestionsEditor from "~/components/SuggestionsEditor";
import DocumentSelectionContainer from "~/containers/DocumentSelectionContainer";
import styles from "./EditableBotMessage.module.scss";
import classNames from "classnames";

const Message = props => {
  return (
    <div className={classNames(styles.RTEWrapper, "RTEScrollCatcher")}>
      <RichTextEditor
        pinToolbar={props.pinToolbar}
        value={props.text}
        onChange={props.handleTextChange}
      />
      <SuggestionsEditor
        value={props.suggestions}
        onChange={props.handleSuggestionsChange}
        combinedSuggestionQuery={props.combinedSuggestionQuery}
        onCombinedSuggestionQueryChange={
          props.handleCombinedSuggestionQueryChange
        }
      />
      <DocumentSelectionContainer
        value={props.documentId}
        onChange={props.handleDocumentsChange}
      />
    </div>
  );
};

Message.propTypes = {
  text: PropTypes.string,
  documentId: PropTypes.string,
  handleTextChange: PropTypes.func,
  handleDocumentsChange: PropTypes.func,
  handleSuggestionsChange: PropTypes.func,
  pinToolbar: PropTypes.bool,
  suggestions: PropTypes.arrayOf(PropTypes.string.isRequired),
  combinedSuggestionQuery: PropTypes.bool.isRequired,
  handleCombinedSuggestionQueryChange: PropTypes.func.isRequired
};

const EditableBotMessageComponent = props => {
  return (
    <LeftMessage
      disableUserInfo={props.disableUserInfo}
      messageContainerClass={props.messageContainerClass}
      message={<Message {...props} />}
    />
  );
};

EditableBotMessageComponent.propTypes = {
  pinToolbar: PropTypes.bool,
  handleTextChange: PropTypes.func,
  handleSuggestionsChange: PropTypes.func,
  text: PropTypes.string.isRequired,
  disableUserInfo: PropTypes.bool,
  suggestions: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  messageContainerClass: PropTypes.string,
  combinedSuggestionQuery: PropTypes.bool,
  handleCombinedSuggestionQueryChange: PropTypes.func.isRequired,
  handleDocumentsChange: PropTypes.func.isRequired,
  documentId: PropTypes.number
};

EditableBotMessageComponent.defaultProps = {
  suggestions: [],
  disableUserInfo: false,
  messageContainerClass: "",
  combinedSuggestionQuery: false
};

export default EditableBotMessageComponent;
