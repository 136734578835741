let isPromise = obj => Promise.resolve(obj) === obj;

export function apiMiddleware({ dispatch, getState }) {
  return next => action => {
    let promise;

    if (action.payload) {
      const PAYLOAD = action.payload;

      if (isPromise(PAYLOAD)) {
        promise = PAYLOAD;
      } else if (isPromise(PAYLOAD.promise)) {
        promise = PAYLOAD.promise;
      } else if (
        typeof PAYLOAD === "function" ||
        typeof PAYLOAD.promise === "function"
      ) {
        promise = PAYLOAD.promise ? PAYLOAD.promise() : PAYLOAD();

        if (!isPromise(promise)) {
          return next({
            ...action,
            payload: promise
          });
        }
      } else {
        return next(action);
      }
    } else {
      return next(action);
    }

    const TYPE = action.type;

    const [requestType, successType, failureType] = [
      `${TYPE}_REQUEST`,
      `${TYPE}_SUCCESS`,
      `${TYPE}_FAILURE`
    ];

    dispatch({
      type: requestType
    });

    return promise.then(
      response =>
        dispatch({
          type: successType,
          payload: {
            ...promise,
            ...response
          }
        }),
      error =>
        dispatch({
          type: failureType,
          payload: {
            ...promise,
            ...error
          }
        })
    );
  };
}
