import {
  GetQuerySuggestionsAction,
  GetQuerySuggestionsSuccessAction
} from "~/reducers/autocomplete";
import {
  GET_QUERY_SUGGESTIONS,
  GET_QUERY_SUGGESTIONS_SUCCESS,
  GET_QUERY_SUGGESTIONS_FAILURE
} from "./types";
import type { AutocompleteSuggestionType } from "~/services/api-types";

export function getQuerySuggestions(query: string): GetQuerySuggestionsAction {
  return {
    type: GET_QUERY_SUGGESTIONS,
    query,
    withLoader: false
  };
}

export function getQuerySuggestionsSuccess(
  suggestions: AutocompleteSuggestionType[]
): GetQuerySuggestionsSuccessAction {
  return {
    type: GET_QUERY_SUGGESTIONS_SUCCESS,
    suggestions: suggestions,
    withLoader: false
  };
}

export function getQuerySuggestionsFailure(error: unknown) {
  return {
    type: GET_QUERY_SUGGESTIONS_FAILURE,
    error,
    withLoader: false
  };
}
