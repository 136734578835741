import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal, TextArea } from "semantic-ui-react";
import {
  createIssue,
  fetchIssuesLabels as fetchIssuesLabelsAction,
  hideConfirmModal
} from "~/actions";
import { useTranslation } from "react-i18next";
import ButtonIcon from "~/atoms/ButtonIcon/ButtonIcon";
import { isArray } from "lodash";
import Button from "~/atoms/Button/Button";
import classNames from "classnames";
import TagsInput from "~/atoms/TagsInput/TagsInput";
import type { AppThunkDispatch, StoreState } from "~/configureStore";

import { ReactComponent as CloseIcon } from "~/assets/icons/close.svg";
import { ReactComponent as OtherReasonIcon } from "~/assets/icons/other_reason_icon.svg";

import styles from "./ReportMessageModal.module.scss";
import { useMixpanelContext } from "~/contexts/mixpanel";
import { MIXPANEL_EVENTS } from "~/contexts/mixpanel/event-types";

type ReportReason = {
  text: string;
  icon?: JSX.Element | null;
};

interface ReportMessageModalProps {
  reportReasons: ReportReason[];
  id: number;
}

const ReportMessageModal = ({
  closeModal,
  reportIssue,
  reportReasons,
  id,
  labels,
  fetchIssuesLabels
}: ReportMessageModalProps & WithStoreProps) => {
  const { t } = useTranslation();

  const { trackEvent } = useMixpanelContext();

  const [pickedReason, setPickedReason] = useState<string>("");
  const [anotherReason, setAnotherReason] = useState(false);
  const [anotherReasonValue, setAnotherReasonValue] = useState<string>("");

  const [addedTags, setAddedTags] = useState<string[]>([]);

  useEffect(() => {
    fetchIssuesLabels();
  }, [fetchIssuesLabels]);

  const handleTagsChange = (tags: string[]) => setAddedTags(tags);

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;

    return setAnotherReasonValue(value);
  };

  const toggleAnotherReason = useCallback(() => {
    if (pickedReason && !anotherReason) {
      setPickedReason("");
    }

    return setAnotherReason(!anotherReason);
  }, [anotherReason, pickedReason]);

  const pickReason = useCallback(
    (comment: string) => {
      if (anotherReason) {
        setAnotherReason(false);
      }

      if (pickedReason === comment) {
        return setPickedReason("");
      }

      return setPickedReason(comment);
    },
    [pickedReason, anotherReason]
  );

  const sendReportedIssue = useCallback(() => {
    reportIssue({
      answerMessageId: id,
      comment: pickedReason || anotherReasonValue,
      labels: addedTags
    });
    trackEvent(MIXPANEL_EVENTS.ANSWER_REPORT);
    closeModal();
  }, [pickedReason, id, addedTags, anotherReasonValue]);

  return (
    <Modal open onClose={closeModal} className={styles.modal}>
      <header className={styles.header}>
        <h3 className={styles.heading}>
          {t("report_message_modal.report_answer")}
        </h3>
        <ButtonIcon className={styles.closeButton} onClick={closeModal}>
          <CloseIcon className={styles.closeButtonIcon} />
        </ButtonIcon>
        <p className={styles.description}>
          {t("report_message_modal.why_answer_is_incorrect")}
        </p>
      </header>
      <div className={styles.reasons}>
        {isArray(reportReasons) &&
          reportReasons.map(({ text, icon }) => (
            <Button
              key={text}
              className={classNames(
                styles.reasonButton,
                pickedReason === text && styles.reasonButtonPicked
              )}
              onClick={() => pickReason(text)}
            >
              {icon ?? null} {text}
            </Button>
          ))}
        <Button
          className={classNames(
            styles.reasonButton,
            anotherReason && styles.reasonButtonPicked
          )}
          onClick={toggleAnotherReason}
        >
          <OtherReasonIcon /> {t("report_message.placeholder") as string}
        </Button>
        {anotherReason && (
          <TextArea
            className={styles.otherReason}
            value={anotherReasonValue}
            onChange={onChange}
            placeholder={t("report_message.placeholder")}
          />
        )}
      </div>
      <div className={styles.tags}>
        <h3 className={styles.tagsHeading}>{t("issues.label")}</h3>
        <TagsInput
          items={labels}
          handleTagsChange={handleTagsChange}
          className={styles.tagsInput}
          limit={3}
        />
      </div>
      <footer className={styles.modalFooter}>
        <Button
          onClick={sendReportedIssue}
          disabled={!pickedReason && !anotherReasonValue}
          className={styles.modalFooterButton}
        >
          {t("report_message_modal.save")}
        </Button>
        <Button
          className={classNames(
            styles.modalFooterButton,
            styles.modalFooterButtonClose
          )}
          secondary
          onClick={closeModal}
        >
          {t("report_message_modal.cancel")}
        </Button>
      </footer>
    </Modal>
  );
};

const mapStateToProps = ({ issues: { labels } }: StoreState) => ({
  labels
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  reportIssue: (data: {
    answerMessageId: number;
    comment: string;
    labels?: string[];
  }) => dispatch(createIssue(data)),
  closeModal: () => dispatch(hideConfirmModal()),
  fetchIssuesLabels: () => dispatch(fetchIssuesLabelsAction())
});

type WithStoreProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ReportMessageModal);
