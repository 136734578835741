import {
  FETCH_ANSWER_REQUEST,
  FETCH_ANSWER_SUCCESS,
  FETCH_ANSWER_FAILURE,
  SAVE_ANSWER_FAILURE,
  EDIT_CHANGE,
  CHANGE_CHOSEN_ANSWER,
  CLEAR_ANSWER,
  FETCH_SEARCH_REQUEST,
  FETCH_SEARCH_SUCCESS,
  FETCH_SEARCH_FAILURE,
  SET_DOCUMENTS_LOCATION,
  SAVE_SEARCH_RESULTS,
  CHANGE_UPDATE
} from "./types";
import { clearEditorState } from "./ClearActions";
import { getBotSuggestion } from "~/services/ChatbotService";
import * as api from "~/services/ChangesService";
import { markIssue } from "./IssuesActions";
import { requestActionStart, requestActionEnd } from "./CustomActions";
import { get } from "lodash";
import { AppThunkDispatch } from "~/configureStore";

export type SuggestionType = {
  text: string;
  query: string;
  featured: boolean;
};

export interface NewFragmentData {
  answer: string;
  question: string;
  suggestions: SuggestionType[];
  documentId: number | null;
}

export function fetchAnswerRequest(question: string) {
  return {
    type: FETCH_ANSWER_REQUEST,
    question
  };
}

export function fetchAnswerSuccess(answers: any) {
  return {
    type: FETCH_ANSWER_SUCCESS,
    answers
  };
}

export function fetchAnswerFailure(error: unknown) {
  return {
    type: FETCH_ANSWER_FAILURE,
    error
  };
}

export function fetchSearchRequest(question: string) {
  return {
    type: FETCH_SEARCH_REQUEST,
    question
  };
}

export function fetchSearchSuccess(answers: any) {
  return {
    type: FETCH_SEARCH_SUCCESS,
    answers
  };
}

export function fetchSearchFailure(error: unknown) {
  return {
    type: FETCH_SEARCH_FAILURE,
    error
  };
}

export function setDocumentLocation(parentId: number, location: string) {
  return {
    type: SET_DOCUMENTS_LOCATION,
    parentId,
    location
  };
}

export function setPreviousSearchResult(question: string, count: number) {
  return {
    type: SAVE_SEARCH_RESULTS,
    question,
    count
  };
}

export function clearAnswer() {
  return {
    type: CLEAR_ANSWER
  };
}

export function saveAnswerFailure(error: unknown) {
  return {
    type: SAVE_ANSWER_FAILURE,
    error
  };
}

export function changeAnswer(data: any) {
  return {
    type: CHANGE_CHOSEN_ANSWER,
    data
  };
}

const updateStoreChange = (change: any) => ({
  type: CHANGE_UPDATE,
  change
});

export function openEditChangeView(change: any, isEditing = true) {
  return {
    type: EDIT_CHANGE,
    change,
    isEditing
  };
}

export function updateAnswer(text: string, suggestions: SuggestionType[]) {
  return (dispatch: AppThunkDispatch, getState: () => any) => {
    const answerIndex = getState().editor.currentAnswerIndex;
    const answer = getState().editor.answers[answerIndex];
    var newAnswer;
    if (text === undefined && suggestions !== undefined) {
      newAnswer = {
        ...answer,
        suggestions: suggestions
      };
      dispatch(fetchAnswerSuccess(newAnswer));
    }
    if (suggestions === undefined && text !== undefined) {
      newAnswer = {
        ...answer,
        text: text
      };
      dispatch(fetchAnswerSuccess(newAnswer));
    }
  };
}

export function fetchSearchQuestion(question: string) {
  return (dispatch: AppThunkDispatch) => {
    dispatch(fetchSearchRequest(question));
    getBotSuggestion(question)
      .then(json => dispatch(fetchSearchSuccess(json)))
      .catch(error => dispatch(fetchSearchFailure([])));
  };
}

export function editAnswerToQuestion(question: string) {
  return (dispatch: AppThunkDispatch) => {
    if (!question) {
      return dispatch(fetchAnswerSuccess([]));
    }

    dispatch(fetchAnswerRequest(question));

    getBotSuggestion(question)
      .then(json => dispatch(fetchAnswerSuccess(json)))
      //TODO: Empty dict should be send only in case of 404 responses
      .catch(error => dispatch(fetchAnswerSuccess([])));
  };
}

export function editAnswerToQuestionKey(responseKey: any) {
  return (dispatch: AppThunkDispatch, getState: () => any) => {
    const question = getState().messages[responseKey].question;
    dispatch(editAnswerToQuestion(question));
  };
}

export const addNewFragment =
  (data: NewFragmentData) => async (dispatch: AppThunkDispatch) => {
    dispatch(requestActionStart("/new-fragment"));

    try {
      const result = await api.hintQuestionAnswerPair(
        data.question,
        data.answer,
        data.suggestions,
        data.documentId
      );
      dispatch(requestActionEnd());

      if (result.error) {
        return Promise.reject();
      }
    } catch (err) {
      dispatch(saveAnswerFailure(err));
    }
  };

export function saveEditedAnswer(
  answer: any,
  backFunction: any,
  createNew: any,
  issueIdx: any
) {
  return (dispatch: AppThunkDispatch, getState: () => any) => {
    const {
      question,
      chosenAnswer: { issueId }
    } = getState().editor;
    const text = get(answer, "text");
    const suggestions = answer.suggestions;
    const documentId = answer.documentId;
    dispatch(
      requestActionStart("/changes", {
        question,
        text,
        suggestions,
        documentId
      })
    );

    const issueIdToSolve = issueId ?? issueIdx ?? null;

    api.hintQuestionAnswerPair(question, text, suggestions, documentId).then(
      () => {
        issueIdToSolve && dispatch(markIssue(issueIdToSolve));
        dispatch(clearEditorState());
        setTimeout(() => backFunction(), 1000);
        setTimeout(() => {
          backFunction();
          dispatch(requestActionEnd());
        }, 1000);
      },
      error => dispatch(saveAnswerFailure(error))
    );
  };
}

export function updateChange(
  answer: any,
  backFunction: any,
  changeByEdit: any,
  issueId: any
) {
  return (dispatch: AppThunkDispatch, getState: () => any) => {
    const id = answer.id;
    const question = answer.question;
    const text = get(answer, "text");
    const suggestions = answer.suggestions;
    const documentId = answer.documentId;

    if (!!changeByEdit) {
      api.hintQuestionAnswerPair(question, text, suggestions, documentId).then(
        () => {
          issueId && dispatch(markIssue(issueId));
          setTimeout(() => {
            dispatch(clearEditorState());
            dispatch(requestActionEnd());
          }, 1000);
          setTimeout(() => backFunction(), 1000);
          // we are turning back second time because the edited answer is chosen by default
          setTimeout(() => backFunction(), 1000);
        },
        error => dispatch(saveAnswerFailure(error))
      );
    }

    api.updateChange(id, question, text, suggestions, documentId).then(
      json => {
        dispatch(clearEditorState());
        backFunction();
        if (json) {
          dispatch(updateStoreChange(json));
        }
        issueId && dispatch(markIssue(issueId));
        issueId && setTimeout(() => backFunction(), 500);
      },
      error => dispatch(saveAnswerFailure(error))
    );
  };
}

export function saveChangedAnswer(backFunction: any, isInlineEdit = false) {
  return (dispatch: AppThunkDispatch, getState: () => any) => {
    const {
      answer: text = "",
      suggestions = [],
      document = {},
      question = "",
      issueId = ""
    } = getState()?.editor?.chosenAnswer;

    const documentId = document?.id ?? null;

    dispatch(
      requestActionStart("/changes", {
        question,
        text,
        suggestions,
        documentId
      })
    );
    api.hintQuestionAnswerPair(question, text, suggestions, documentId).then(
      () => {
        !isInlineEdit && dispatch(markIssue(issueId));
        setTimeout(() => {
          dispatch(clearEditorState());
          dispatch(requestActionEnd());
        }, 1000);
        // backFunction()
        setTimeout(() => backFunction(), 1000);
      },
      error => dispatch(saveAnswerFailure(error))
    );
  };
}
