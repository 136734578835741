import {
  TOGGLE_DOCUMENT_SUCCESS,
  DELETE_DOCUMENT_SUCCESS,
  GET_DOCUMENTS_SUCCESS,
  LIST_DOCUMENTS_SUCCESS,
  CLEAR_DOCUMENTS_STATE,
  GET_FOLDERS_SUCCESS,
  GET_FOLDERS_PATH_SUCCESS,
  GET_MOVE_FOLDERS_PATH_SUCCESS,
  CURRENT_FOLDER,
  UPDATE_FOLDERS_PATH
} from "~/actions/types";
import { createReducer } from "./createReducer";
import { get, isArray } from "lodash";

const initialState = {
  list: [],
  content: [],
  totalPages: 0,
  number: 0,
  folders: [],
  foldersPath: [],
  moveFoldersList: [],
  openError: null,
  currentFolder: 0
};

const documents = createReducer(initialState, {
  [CURRENT_FOLDER]: (state, { payload }) => {
    return {
      ...state,
      currentFolder: payload
    };
  },
  [LIST_DOCUMENTS_SUCCESS]: (state, { payload }) => ({
    ...state,
    list: [...Object.values(payload)]
  }),
  [GET_DOCUMENTS_SUCCESS]: (state, { payload }) => {
    if (get(state, "content") && payload.pageNumber > 0) {
      return {
        ...state,
        content: [...state.content, ...payload.documents.content]
      };
    } else if (payload.pageNumber === 0 && !payload.documents?.content) {
      return {
        ...state,
        content: [...state.content, payload.documents]
      };
    } else {
      return {
        ...state,
        ...payload.documents
      };
    }
  },
  [TOGGLE_DOCUMENT_SUCCESS]: (state, { payload }) => {
    let documents = state.content.map(document => {
      if (document.id === payload.id) {
        return { ...document, enabled: !document.enabled };
      }
      return document;
    });

    return { ...state, content: documents };
  },
  [DELETE_DOCUMENT_SUCCESS]: (state, { payload }) => ({
    ...state,
    content: state.content.filter(({ id }) => id !== payload.id)
  }),
  [GET_FOLDERS_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      folders:
        get(state.folders, "content") && payload.pageNumber > 0
          ? {
              ...state.folders,
              content: [...state.folders.content, ...payload.folders.content]
            }
          : payload.folders
    };
  },
  [GET_FOLDERS_PATH_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      foldersPath: isArray(payload.folders) ? payload.folders : []
    };
  },
  [UPDATE_FOLDERS_PATH]: (state, { payload }) => {
    return {
      ...state,
      foldersPath: payload
    };
  },
  [GET_MOVE_FOLDERS_PATH_SUCCESS]: (state, { payload: { folders } }) => ({
    ...state,
    moveFoldersList: isArray(folders?.content) ? folders.content : []
  }),
  [CLEAR_DOCUMENTS_STATE]: () => initialState
});

export default documents;
