import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import { useSelector } from "~/configureStore";

import styles from "./AppAlertModal.module.scss";

import "./AppAlertModal.scss";

const AppAlertModal = () => {
  const { t } = useTranslation();

  const isVisible = useSelector(({ appAlert: { showModal } }) => showModal);

  const uploading = useSelector(({ appAlert: { uploading } }) => uploading);
  const uploaded = useSelector(({ appAlert: { uploaded } }) => uploaded);
  const updated = useSelector(({ appAlert: { updated } }) => updated);
  const updating = useSelector(({ appAlert: { updating } }) => updating);
  const copied = useSelector(({ appAlert: { copied } }) => copied);

  const alertMessage = useSelector(
    ({ appAlert: { alertMessage } }) => alertMessage
  );

  return (
    <CSSTransition
      in={isVisible}
      timeout={200}
      classNames="alert-modal"
      unmountOnExit
    >
      <div className={styles.wrapper}>
        {uploading.length > 0 && (
          <p className={styles.item}>
            {t("file_queue_modal.uploading", { files: uploading.length })}
          </p>
        )}
        {uploading.length > 0 && uploaded.length > 0 && (
          <span className={styles.separator} />
        )}
        {uploaded.length > 0 && (
          <p className={styles.item}>
            {t("file_queue_modal.uploaded", { files: uploaded.length })}
          </p>
        )}
        {(updating || updated) && (
          <p className={styles.item}>
            <>
              {updating && t("file_queue_modal.updating")}
              {updated && t("file_queue_modal.updated")}
            </>
          </p>
        )}
        {copied && (
          <p className={styles.item}>{t("file_queue_modal.copied")}</p>
        )}
        {alertMessage && <p className={styles.item}>{t(alertMessage)}</p>}
      </div>
    </CSSTransition>
  );
};

export default AppAlertModal;
