import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./Table.module.scss";

const TableRowItem = ({ content, size, rowItemClassname, onClick }) => (
  <div
    className={classnames(
      styles.tableRowsRowItem,
      onClick && styles.tableRowsRowItemClickable,
      size,
      rowItemClassname
    )}
    onClick={onClick}
  >
    {content}
  </div>
);

TableRowItem.propTypes = {
  content: PropTypes.any.isRequired,
  size: PropTypes.string.isRequired,
  rowItemClassname: PropTypes.string,
  onClick: PropTypes.func
};

TableRowItem.defaultProps = {
  rowItemClassname: "",
  onClick: null
};

export default TableRowItem;
