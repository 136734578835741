import { useEffect } from "react";
import { useMixpanelContext } from "~/contexts/mixpanel";
import { MIXPANEL_EVENTS } from "~/contexts/mixpanel/event-types";

export const useTrackMixpanelEvent = (eventName: MIXPANEL_EVENTS) => {
  const { trackEvent } = useMixpanelContext();

  useEffect(() => {
    trackEvent(eventName);
  }, [eventName]);
};
