import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  INVITE_USER_REQUEST,
  INVITE_USER_SUCCESS,
  INVITE_USER_FAILURE,
  CLOSE_USERS_TAB,
  CHANGE_USERS_TAB,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  REMOVE_USER,
  GET_USERS_BY_NAME_REQUEST,
  GET_USERS_BY_NAME_SUCCESS,
  REMOVE_USER_SUCCESS
} from "./types";

export const getUsers = (group, pageNumber, isList) => ({
  type: GET_USERS_REQUEST,
  group,
  pageNumber,
  isList,
  withLoader: false
});

export const getUsersSuccess = (group, users) => ({
  type: GET_USERS_SUCCESS,
  group,
  users
});

export const changeUsersTab = activeTab => ({
  type: CHANGE_USERS_TAB,
  activeTab
});

export const closeUsersTab = () => ({
  type: CLOSE_USERS_TAB
});

export const getUsersFailure = error => ({
  type: GET_USERS_FAILURE,
  error
});

export const inviteUser = (email, role) => ({
  type: INVITE_USER_REQUEST,
  email,
  role,
  withLoader: false
});

export const inviteUserSuccess = user => ({
  type: INVITE_USER_SUCCESS,
  user
});

export const inviteUserFailure = error => ({
  type: INVITE_USER_FAILURE,
  error
});

export const updateUser = (id, role, prevRole) => ({
  type: UPDATE_USER_REQUEST,
  id,
  role,
  prevRole
});

export const updateUserSuccess = (id, role) => ({
  type: UPDATE_USER_SUCCESS,
  id,
  role
});

export const updateUserFailure = error => ({
  type: UPDATE_USER_FAILURE,
  error
});

export const removeUser = id => ({
  type: REMOVE_USER,
  id
});

export const removeUserSucess = id => ({
  type: REMOVE_USER_SUCCESS,
  id
});

export const getUsersByName = userName => ({
  type: GET_USERS_BY_NAME_REQUEST,
  userName,
  withLoader: false
});

export const getUsersByNameSuccess = users => ({
  type: GET_USERS_BY_NAME_SUCCESS,
  users
});
