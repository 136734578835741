import React, { useMemo } from "react";
import { Segment } from "semantic-ui-react";
import {
  Chart as ChartJS,
  CategoryScale,
  LineElement,
  LinearScale,
  PointElement,
  Title
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { chartColors } from "~/constants/app";
import { useSelector } from "~/configureStore";

ChartJS.register(CategoryScale, LineElement, LinearScale, PointElement, Title);

const LineChart: React.FC = () => {
  const {
    users: { data, timeRange, type }
  } = useSelector(({ statistics }) => statistics);

  const labels = useMemo(() => {
    const dateFormat =
      timeRange[timeRange.length - 1] === "h" ? "HH:mm" : "D/M HH:mm";

    return data.labels.map(label => moment(label).format(dateFormat));
  }, [data.labels, timeRange]);

  const getRGBAString = (rgb: number[], alpha: number) => {
    return "rgba(" + rgb[0] + "," + rgb[1] + "," + rgb[2] + "," + alpha + ")";
  };

  const datasets = useMemo(() => {
    return Object.values(data.datasets || {}).map((dataset, index) => ({
      ...dataset,
      backgroundColor: getRGBAString(chartColors[index], 0.2),
      borderColor: getRGBAString(chartColors[index], 1)
    }));
  }, [data.datasets]);

  return (
    <Segment>
      <Line
        data={{ datasets, labels }}
        options={{
          maintainAspectRatio: true,
          responsive: true
        }}
      />
    </Segment>
  );
};

export default LineChart;
