import React from "react";
import PropTypes from "prop-types";
import { withRouter, RouteComponentProps, Redirect } from "react-router-dom";
import {
  Form,
  Grid,
  Dimmer,
  Loader,
  Image,
  InputOnChangeData
} from "semantic-ui-react";
import { PopupButton } from "react-calendly";
import loginBackground from "~/assets/login_form_background.svg";
import botwiseLogoBlack from "~/assets/full_logo_black.svg";
import styles from "./LoginForm.module.scss";
import { WithTranslation, withTranslation, Trans } from "react-i18next";
import { getConfigRequestService } from "~/services/LoginService";
import { USER_ROLES } from "~/constants/app";
import { getRefreshToken, removeTokens } from "~/helpers/tokens";
import { CHAT, LOGIN } from "~/constants/routes";
import { isWhiteLabel } from "~/constants/config";

interface ChildrenType {
  children: React.ReactNode;
}

interface LoginBlockWrapper extends ChildrenType {
  loading: boolean;
}

const errorMessages = {
  username: "login.error_login",
  password: "login.error_password"
};

const LabelError = ({ text }: { text: string | false }) =>
  text ? <div className={styles.labelError}>{text}</div> : null;

LabelError.propTypes = {
  text: PropTypes.string
};

const FormError = ({ error }: { error: number | false }) =>
  error ? <div className={styles.formError}>{error}</div> : null;

FormError.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const LoginBlockWrapper = ({ children, loading }: LoginBlockWrapper) => (
  <div className={styles.loginFormWrapper}>
    <Dimmer inverted active={loading} className="formLoader">
      <Loader size="large" />
    </Dimmer>
    {children}
  </div>
);

LoginBlockWrapper.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool
};

const LoginFormHeader = ({ children }: ChildrenType) => (
  <h2 className={styles.loginHeader}>{children}</h2>
);

LoginFormHeader.propTypes = {
  children: PropTypes.node
};

const OptionsWrapper = ({ children }: ChildrenType) => (
  <div className={styles.optionsWrapper}>{children}</div>
);

OptionsWrapper.propTypes = {
  children: PropTypes.node
};

const SignInWrapper = ({ children }: ChildrenType) => (
  <div className={styles.signInWrapper}>{children}</div>
);

SignInWrapper.propTypes = {
  children: PropTypes.node
};

const CalendlyPopupText = ({ text, url }: { text: string; url: string }) => {
  const rootElement = document.getElementById("root");

  if (!rootElement) return null;

  return <PopupButton rootElement={rootElement} text={text} url={url} />;
};

CalendlyPopupText.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string
};

export interface LoginFormProps extends RouteComponentProps {
  getConfigRequestService: typeof getConfigRequestService;
  refreshLogin: (userName: string, email: string, role: USER_ROLES) => void;
  logIn: (username: string, password: string) => void;
  loggingIn: boolean;
  loggedIn: boolean;
  error: any;
  containerTestId?: string;
}

interface LoginFormState {
  username: string;
  password: string;
  usernameError: string | undefined;
  passwordError: string | undefined;
  formError: string | number | undefined;
  hasToken: boolean;
}

class LoginForm extends React.Component<
  LoginFormProps & WithTranslation,
  LoginFormState
> {
  constructor(props: LoginFormProps & WithTranslation) {
    super(props);
    this.state = {
      username: "",
      password: "",
      usernameError: undefined,
      passwordError: undefined,
      formError: undefined,
      hasToken: !!getRefreshToken()
    };
  }

  componentDidMount() {
    const { hasToken } = this.state;
    if (hasToken) {
      this.props.getConfigRequestService().then(response => {
        if (
          response &&
          "role" in response &&
          "email" in response &&
          "userName" in response
        ) {
          const { role, userName, email } = response;
          this.pageForward();
          this.props.refreshLogin(userName, email, role);
        } else {
          this.removeToken();
        }
      });
    }
  }

  componentDidUpdate(
    prevProps: LoginFormProps & WithTranslation,
    prevState: LoginFormState
  ) {
    if (prevState.hasToken !== !!getRefreshToken()) {
      this.setState({ hasToken: !!getRefreshToken() });
    }

    if (prevProps.error !== this.props.error && this.props.error) {
      this.setState({
        formError: this.props.error
      });
    }
  }

  removeToken = () => {
    removeTokens();
    this.setState({ hasToken: false });
  };

  handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    { value }: InputOnChangeData
  ) => {
    switch (e.target.name) {
      case "password":
        return this.setState({ [e.target.name]: value });
      case "username":
        return this.setState({ [e.target.name]: value });
      default:
        break;
    }
  };

  handleSubmit = () => {
    const { username, password } = this.state;
    if (!username || !password) {
      !username && this.setFieldError("username");
      !password && this.setFieldError("password");
      return;
    }
    this.clearFieldErrors();
    this.props.logIn(username, password);

    this.pageForward();
  };

  pageForward = () => {
    const urlPathname = window.location.pathname;
    if (!urlPathname.includes(LOGIN)) {
      const docId = urlPathname.substring(urlPathname.lastIndexOf("/") + 1);
      this.props.history.push({
        pathname: `${urlPathname}`,
        state: {
          id: docId || null
        }
      });
    }
  };

  clearFieldErrors = () =>
    this.setState({
      usernameError: undefined,
      passwordError: undefined,
      formError: undefined
    });

  setFieldError = (field: "password" | "username") => {
    switch (field) {
      case "password":
        return this.setState({
          passwordError: this.props.t(errorMessages[field]) as string
        });
      case "username":
        return this.setState({
          ["usernameError"]: this.props.t(errorMessages[field]) as string
        });
      default:
        break;
    }
  };

  getFieldError = (field: "password" | "username") =>
    this.state[`${field}Error`];
  getFieldErrorClass = (field: "password" | "username") =>
    this.state[`${field}Error`] ? styles.hasError : "";
  getFormErrorClass = () =>
    this.state.formError === 400 ? styles.hasFormError : "";

  // handleRememberMeSetting = (
  //   ev: React.FormEvent<HTMLInputElement>,
  //   { checked }: CheckboxProps
  // ) => {
  // };

  render() {
    const { username, password, hasToken } = this.state;
    const disableLoginPage = hasToken;

    if (hasToken) {
      return <Redirect to={CHAT} />;
    }

    return !disableLoginPage ? (
      <Grid
        verticalAlign="middle"
        className={styles.loginFormGrid}
        data-testid={this.props.containerTestId}
      >
        <Grid.Row>
          <Grid.Column width={2} only="computer" />
          <Grid.Column width={2} only="tablet" />
          <Grid.Column mobile={16} tablet={12} computer={6}>
            {!isWhiteLabel() && (
              <Image src={botwiseLogoBlack} className={styles.logo} />
            )}
            <Form
              className="loginForm"
              size="large"
              onSubmit={this.handleSubmit}
            >
              <LoginBlockWrapper loading={this.props.loggingIn}>
                <LoginFormHeader>
                  {this.props.t("login.login_header")}
                </LoginFormHeader>
                <Form.Input
                  fluid
                  name="username"
                  placeholder={this.props.t("login.username_placeholder")}
                  value={username}
                  onChange={this.handleChange}
                  onFocus={this.clearFieldErrors}
                  className={[
                    styles.formInput,
                    this.getFieldErrorClass("username"),
                    this.getFormErrorClass()
                  ].join(" ")}
                  label={
                    <LabelError text={this.getFieldError("username") || ""} />
                  }
                />
                <Form.Input
                  fluid
                  name="password"
                  placeholder={this.props.t("login.password_placeholder")}
                  type="password"
                  value={password}
                  onChange={this.handleChange}
                  onFocus={this.clearFieldErrors}
                  className={[
                    styles.formInput,
                    this.getFieldErrorClass("password"),
                    this.getFormErrorClass()
                  ].join(" ")}
                  label={
                    <LabelError text={this.getFieldError("password") || ""} />
                  }
                />
                {this.state.formError && (
                  <FormError
                    error={
                      typeof this.state.formError === "string"
                        ? this.props.t(this.state.formError)
                        : this.state.formError || 0
                    }
                  />
                )}
                <Form.Button fluid size="large" className={styles.formButton}>
                  {this.props.t("login")}
                </Form.Button>
                <OptionsWrapper>
                  <Form.Checkbox
                    label={this.props.t("login.remember_me")}
                    // this feature is not yet implemented in API
                    // checked={rememberMeSetting}
                    // onChange={this.handleRememberMeSetting}
                    className={styles.optionsWrapperCheckbox}
                  />
                  <a href="mailto:kontakt@botwise.io">
                    {this.props.t("login.forgot_password")}
                  </a>
                </OptionsWrapper>
                <p className={styles.termsWrapper}>
                  <Trans i18nKey="login.terms_privacy">
                    <span className={styles.byLogging}>
                      Logując się akceptujesz nasze
                    </span>
                    <a
                      className={styles.byLoggingLink}
                      href={this.props.t("login.terms_and_conditions_link")}
                      target="_blank"
                      rel="noreferrer"
                    >
                      warunki świadczenia usług
                    </a>
                    <span className={styles.byLogging}>i</span>
                    <a
                      className={styles.byLoggingLink}
                      href={this.props.t("login.privacy_policy_link")}
                      target="_blank"
                      rel="noreferrer"
                    >
                      politykę prywatności
                    </a>
                  </Trans>
                </p>
                <SignInWrapper>
                  <p>
                    <>
                      {this.props.t("login.dont_have_account")}
                      <CalendlyPopupText
                        text={this.props.t("login.get_demo")}
                        url={this.props.t("login.calendly_url")}
                      />
                    </>
                  </p>
                </SignInWrapper>
              </LoginBlockWrapper>
            </Form>
          </Grid.Column>
          <Grid.Column width={8} only="computer">
            <img
              src={loginBackground}
              style={{ float: "left", maxHeight: "100vh" }}
              alt={this.props.t("login.background_alt")}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    ) : (
      ""
    );
  }
}

export default withTranslation()(withRouter(LoginForm));
