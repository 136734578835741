import React, { useCallback, useState, useEffect } from "react";
import classNames from "classnames";
import { Label, Menu, Icon, Dropdown } from "semantic-ui-react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "~/configureStore";
import styles from "./SidebarMenu.module.scss";

interface Item {
  name: string;
  authorizedRoles?: string[];
  href?: string;
  label?: string;
}

interface MenuItemProps {
  name: string;
  icon?: React.ElementType;
  href: string;
  items: Item[];
}

export const MenuItem = ({
  name,
  icon: MenuIcon,
  href,
  items = []
}: MenuItemProps) => {
  const isSidebarExpanded = useSelector(
    ({ utility: { isSidebarExpanded } }) => isSidebarExpanded
  );
  const [submenuExpanded, setSubmenuExpanded] = useState(isSidebarExpanded);
  useEffect(() => {
    setSubmenuExpanded(isSidebarExpanded);
  }, [isSidebarExpanded]);

  const { pathname } = useLocation();

  const { t } = useTranslation();
  const isActive = href === pathname;
  const urlRoot =
    href.lastIndexOf("/") !== 0
      ? href.substring(0, href.lastIndexOf("/"))
      : href;
  const subActive = pathname.includes(urlRoot);

  const toggleSubmenu = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();

      return setSubmenuExpanded(!submenuExpanded);
    },
    [setSubmenuExpanded, submenuExpanded]
  );

  const renderDropMenuItems = (items: Item[]) => {
    return (
      <Dropdown.Menu className={styles.dropDownMenu}>
        {items.map(({ name, href }) => {
          const isActive = href === pathname;
          return (
            <Dropdown.Item
              active={isActive}
              key={t(name).toString()}
              as={Link}
              to={href}
            >
              {t(name)}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    );
  };

  const renderSubmenuItems = (items: Item[]) => {
    return items.map(({ name, label, href }: Item) => {
      const isActive = href === pathname;

      return (
        <Menu.Item
          active={isActive}
          key={t(name).toString()}
          as={Link}
          to={href}
          className={styles.submenu}
        >
          {label && (
            <Label size="small" color="teal">
              {label}
            </Label>
          )}
          <span>{t(name)}</span>
        </Menu.Item>
      );
    });
  };

  const renderMenuHeader = () => {
    return (
      <Menu.Header
        onClick={
          isSidebarExpanded && items.length > 0
            ? () => setSubmenuExpanded(true)
            : undefined
        }
      >
        <div
          className={
            !isSidebarExpanded ? styles.iconHoverBackground : undefined
          }
        />
        {MenuIcon && <MenuIcon />}
        <div className={isSidebarExpanded ? styles.fadeIn : styles.fadeOut}>
          {t(name)}
        </div>
        {items.length > 0 && (
          <Icon
            class={classNames(
              "icon",
              "angle",
              submenuExpanded ? "up" : "down",
              isSidebarExpanded ? styles.fadeIn : styles.fadeOut
            )}
            onClick={items.length > 0 ? toggleSubmenu : undefined}
          />
        )}
      </Menu.Header>
    );
  };

  return (
    <Menu.Item
      active={isActive || (items.length > 0 && subActive)}
      key={t(name).toString()}
      as={Link}
      to={href}
      className={classNames(
        items?.length > 0 && styles.sub,
        items.length && (subActive || submenuExpanded) && styles.sub_active,
        items.length && subActive && styles.sub_active_active,
        items.length &&
          !submenuExpanded &&
          subActive &&
          styles.sub_active_closed,
        items.length &&
          isSidebarExpanded &&
          submenuExpanded &&
          styles.sub_active_expanded
      )}
      onMouseOver={
        !submenuExpanded && !isSidebarExpanded ? toggleSubmenu : undefined
      }
    >
      {renderMenuHeader()}
      {items.length > 0 &&
        submenuExpanded &&
        (isSidebarExpanded
          ? renderSubmenuItems(items)
          : renderDropMenuItems(items))}
    </Menu.Item>
  );
};

// export default MenuItem;
