import { connect } from "react-redux";
import MessageOption from "~/components/MessageOption";

import { addUserMessage, fetchBotResponse } from "~/actions";
import { AppThunkDispatch } from "~/configureStore";

const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    sendMessage: (text: string, query: string) => {
      dispatch(addUserMessage({ text: text }));
      dispatch(fetchBotResponse(query));
    }
  };
};

export type ConnectedOptionProps = ReturnType<typeof mapDispatchToProps>;

export default connect(null, mapDispatchToProps)(MessageOption);
