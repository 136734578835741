import { isEmpty, isObject } from "lodash";
import queryString from "query-string";
import { generatePath } from "react-router-dom";
import { DOCUMENTS } from "~/constants/routes";
import { ParamsType } from "./types";

export function stringifyUrlQuery(url: string, params: ParamsType) {
  if (!url || typeof url !== "string") return "";
  if (!params || !isObject(params)) return url;

  return (
    url +
    "?" +
    queryString.stringify(params, { arrayFormat: "comma", skipNull: true })
  );
}

export const getDocumentIdFromUrl = (
  url: string,
  ignoredUrls: string[] = []
) => {
  if (!url || typeof url !== "string") {
    return null;
  }

  if (url.includes(DOCUMENTS)) {
    const params = url.split("/");

    const lastFragment = params[params.length - 1];

    if (lastFragment.includes("#")) {
      const parsed = lastFragment.slice(0, lastFragment.indexOf("#"));
      if (!isNaN(Number(parsed))) {
        return Number(parsed);
      }

      return null;
    }

    if (lastFragment.includes("?")) {
      const parsed = lastFragment.slice(0, lastFragment.indexOf("?"));

      if (!isNaN(Number(parsed))) {
        return Number(parsed);
      }

      return null;
    }

    const id = Number(lastFragment);

    if (isNaN(id)) {
      return null;
    }

    if (!isEmpty(ignoredUrls)) {
      const shouldIgnore = ignoredUrls.some(
        ignoredUrl => generatePath(ignoredUrl, { id: id }) === url
      );

      return shouldIgnore ? null : id;
    }

    return id;
  }

  return null;
};
