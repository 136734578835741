import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Loader, Modal } from "semantic-ui-react";
import ButtonIcon from "~/atoms/ButtonIcon/ButtonIcon";
import { InputText } from "~/atoms/Inputs/InputText/InputText";
import { SynonymInput } from "~/atoms/SynonymInput/SynonymInput";
import Button from "~/atoms/Button/Button";
import { useSelector, useThunkDispatch } from "~/configureStore";
import { addNewSynonymAction } from "~/actions/SynonymsActions";
import LoaderInline from "~/components/LoaderInline/LoaderInline";
import { ErrorMessage } from "~/components/atoms/ErrorMessage/ErrorMessage";

import styles from "./SynonymsModal.module.scss";

import { ReactComponent as CloseSVG } from "~/assets/icons/close.svg";
import { useMixpanelContext } from "~/contexts/mixpanel";
import { MIXPANEL_EVENTS } from "~/contexts/mixpanel/event-types";

export const MAX_TERM_SYNONYM_LENGTH = 100;

interface SynonymsModalProps {
  handleClose: () => void;
  isOpen: boolean;
}

export const SynonymsModal = ({ handleClose, isOpen }: SynonymsModalProps) => {
  const { t } = useTranslation();

  const { trackEvent } = useMixpanelContext();

  const [termValue, setTermValue] = useState<string>("");
  const [termError, setTermError] = useState<string | undefined>(undefined);
  const [synonymValue, setSynonymValue] = useState<string[]>([]);
  const [synonymError, setSynonymError] = useState<string | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(false);

  const handleTermChange = useCallback(
    (term: string) => {
      if (termError) {
        setTermError(undefined);
      }

      return setTermValue(term);
    },
    [termError]
  );

  const handleSynonymsChange = useCallback(
    (synonyms: string[]) => {
      if (synonymError) {
        setSynonymError(undefined);
      }

      return setSynonymValue(synonyms);
    },
    [synonymError]
  );

  const [savingError, setSavingError] = useState<string | undefined>(undefined);

  const currentSynonyms = useSelector(({ synonyms }) => synonyms.content);

  const dispatch = useThunkDispatch();

  const handleAddNewSynonyms = useCallback(
    async (term: string, synonyms: string[]) => {
      setLoading(true);
      try {
        await dispatch(addNewSynonymAction(term, synonyms));

        setTermValue("");
        setSynonymValue([]);
        trackEvent(MIXPANEL_EVENTS.SYNONYMS_ADD);
      } catch (err) {
        setSavingError(
          t("synonyms.error.sorry_an_error_occurred_try_again_later")
        );
      } finally {
        setLoading(false);
      }
    },
    [dispatch]
  );

  const checkTerm = useCallback(() => {
    if (
      currentSynonyms.find(
        synonym => synonym.primary.toLowerCase() === termValue?.toLowerCase()
      )
    ) {
      return setTermError(t("synonyms.error.this_term_already_exists"));
    }

    return setTermError(undefined);
  }, [currentSynonyms, termValue]);

  const handleSubmit = useCallback(() => {
    let error = false;
    if (!termValue) {
      error = true;
      setTermError(t("synonyms.error.term_is_required"));
    }
    if (synonymValue.length === 0) {
      error = true;
      setSynonymError(t("synonyms.error.you_have_to_add_at_least_one_synonym"));
    }
    if (error) return;

    return handleAddNewSynonyms(termValue, synonymValue).then(() =>
      handleClose()
    );
  }, [termValue, synonymValue]);

  return (
    <Modal open={isOpen} size="tiny" closeOnDimmerClick>
      <div className={styles.container}>
        <header className={styles.header}>
          <h3 className={styles.heading}>{t("synonyms.add_new_term")}</h3>
          <ButtonIcon className={styles.closeBtn} onClick={handleClose}>
            <CloseSVG className={styles.closeBtnSvg} />
          </ButtonIcon>
          <p className={styles.description}>{t("synonyms.cant_find_term")}</p>
        </header>
        <InputText
          value={termValue}
          onChange={handleTermChange}
          label={t("synonyms.add_new_term")}
          maxLength={MAX_TERM_SYNONYM_LENGTH}
          error={termError}
          onBlur={checkTerm}
          placeholder={t("synonyms.type_in_term")}
        />
        <SynonymInput
          // synonyms={synonymValue}
          handleSynonymsChange={handleSynonymsChange}
          maxLength={MAX_TERM_SYNONYM_LENGTH}
          error={synonymError}
        />
        {!loading && savingError && (
          <ErrorMessage className={styles.error} error={savingError} />
        )}
        <Button
          onClick={handleSubmit}
          disabled={!termValue || synonymValue.length === 0 || loading}
          className={styles.button}
        >
          {t("synonyms.save")}
        </Button>
        <Button
          onClick={handleClose}
          className={styles.button}
          secondary
          negative
        >
          {t("synonyms.cancel")}
        </Button>
        {loading && (
          <div className={styles.loading}>
            <Loader size="medium" />
          </div>
        )}
      </div>
    </Modal>
  );
};
