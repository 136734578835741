import { AppThunkDispatch, StoreState } from "~/configureStore";
import {
  addNewSynonym,
  deleteSynonymTerm,
  getAllSynonyms,
  updateSynonymTerm
} from "~/services/SynonymsService";
import { customError, showConfirmModal } from ".";
import {
  ADD_SYNONYMS,
  DELETE_SYNONYM,
  GET_SYNONYMS,
  UPDATE_SYNONYM
} from "./types";

export const getSynonymsAction = () => async (dispatch: AppThunkDispatch) => {
  try {
    const data = await getAllSynonyms();

    return dispatch({
      type: GET_SYNONYMS,
      ...data,
      withLoader: false
    });
  } catch (err) {
    throw err;
  }
};

export const addNewSynonymAction =
  (term: string, synonyms: string[]) => async (dispatch: AppThunkDispatch) => {
    try {
      const data = await addNewSynonym(term, synonyms);

      return dispatch({
        type: ADD_SYNONYMS,
        payload: data
      });
    } catch (err) {
      throw err;
    }
  };

export const deleteSynonymTermAction =
  (id: string, shouldDispatchError = false) =>
  async (dispatch: AppThunkDispatch) => {
    try {
      await deleteSynonymTerm(id);

      return dispatch({
        type: DELETE_SYNONYM,
        payload: {
          id
        }
      });
    } catch (err) {
      if (shouldDispatchError) {
        dispatch(customError("synonyms.error.couldnt_remove_term"));
      }
      throw err;
    }
  };

export const updateSynonymAction =
  (
    id: string,
    term: string,
    synonyms: string[],
    removing = false,
    shouldDispatchError = false
  ) =>
  async (dispatch: AppThunkDispatch, getState: () => StoreState) => {
    const {
      synonyms: { content }
    } = getState();

    const updatedTerm = content.find(term => term.id === id);

    const editedSynonyms = removing
      ? updatedTerm?.synonyms.filter(snm => !synonyms.includes(snm)) ?? []
      : [...(updatedTerm?.synonyms ?? []), ...synonyms];

    if (editedSynonyms.length === 0) {
      return dispatch(
        showConfirmModal("DELETE_SYNONYM_TERM", {
          id,
          removeFn: deleteSynonymTermAction
        })
      );
    }

    try {
      const updatedData = await updateSynonymTerm(term, editedSynonyms, id);

      return dispatch({
        type: UPDATE_SYNONYM,
        payload: updatedData
      });
    } catch (err) {
      if (shouldDispatchError) {
        dispatch(customError("synonyms.error.couldnt_update_synonyms"));
      }
      throw err;
    }
  };
