import { connect } from "react-redux";

import Users from "~/components/Users/Users";
import {
  getUsers,
  showConfirmModal,
  changeUsersTab,
  closeUsersTab,
  getUsersByName
} from "~/actions";
import {
  MODAL_TYPE_DELETE_USER,
  MODAL_TYPE_INVITE_USER,
  MODAL_TYPE_UPDATE_USER
} from "~/actions/types";

const mapStateToProps = (state, ownProps) => {
  let usersProps = {
    allUsers: state.users.content,
    activePage: state.users.activePage,
    totalPages: state.users.totalPages,
    group: state.users.group
  };
  return usersProps;
};

const mapDispatchToProps = dispatch => {
  return {
    confirmDeleteUser: (id, userName, role) => {
      dispatch(
        showConfirmModal(MODAL_TYPE_DELETE_USER, {
          id,
          userName,
          role
        })
      );
    },
    inviteUser: () => dispatch(showConfirmModal(MODAL_TYPE_INVITE_USER, {})),
    editUser: (id, role, name) =>
      dispatch(showConfirmModal(MODAL_TYPE_UPDATE_USER, { id, role, name })),
    changeUsersTab: activeTab => dispatch(changeUsersTab(activeTab)),
    closeUsersTab: () => dispatch(closeUsersTab()),
    fetchUsers: (group, pageNumber) =>
      dispatch(getUsers(group, pageNumber - 1)),
    getUsersSearch: userName => dispatch(getUsersByName(userName))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
