import React, { useLayoutEffect } from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import ButtonIcon from "~/components/atoms/ButtonIcon/ButtonIcon";
import { logout } from "~/actions";
import { useSelector } from "~/configureStore";
import { Breadcrumbs } from "~/components/organisms/Breadcrumbs/Breadcrumbs";
import { debounce } from "lodash";
import { useToggle } from "~/hooks/useToggle";
import { withRouter } from "react-router-dom";

import { ReactComponent as HelpSVG } from "~/assets/icons/help.svg";
import { ReactComponent as LogoutSVG } from "~/assets/icons/logout.svg";

import styles from "./TopBar.module.scss";

const SHRINK_SCROLL_OFFSET = 30;
const SCROLL_DEBOUNCE = 15;

const TopBar = ({ user, logout, role }) => {
  const isSidebarExpanded = useSelector(
    ({ utility: { isSidebarExpanded } }) => isSidebarExpanded
  );
  const { t } = useTranslation();

  const [isScrolled, toggleIsScrolled] = useToggle();
  // const [userInitials, setUserInitials] = useState(""); //Uncomment when user initials will be needed

  // useEffect(() => {
  //   setUserInitials(getUserInitials(user)); // remember about importing getUserInitials func from helpers
  // }, [user]);

  const handleDocumentScroll = debounce(() => {
    const scrollHeight =
      document.body.scrollHeight || document.documentElement.scrollHeight;
    const offsetHeight =
      document.body.offsetHeight || document.documentElement.offsetHeight;

    if (scrollHeight > offsetHeight) {
      const scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;

      toggleIsScrolled(scrollTop >= SHRINK_SCROLL_OFFSET);
    }
  }, SCROLL_DEBOUNCE);

  useLayoutEffect(() => {
    document.addEventListener("scroll", handleDocumentScroll);

    return () => {
      document.removeEventListener("scroll", handleDocumentScroll);
    };
  }, []);

  return (
    <div
      className={classNames(
        styles.topBar,
        isSidebarExpanded ? styles.topBarExpanded : styles.topBarReduced,
        isScrolled && styles.topBarBg
      )}
    >
      <span
        className={classNames(styles.topBar_item, styles.topBar_item_active)}
      >
        <Breadcrumbs />
      </span>
      <div className={styles.topBar_menu}>
        {/* <a href="mailto:kontakt@botwise.io" className={styles.topBar_menu_icon}>
          <HelpSVG />
        </a> */}
        {/* <div className={styles.topBar_menu_userInitials}>{userInitials}</div> */}
        <div className={styles.topBar_menu_userDetails}>
          <span className={styles.topBar_menu_userDetails_name}>{user}</span>
          {/* <span className={styles.topBar_menu_userDetails_email}>
            <a href="mailto:mateusz@email.com">mateusz@email.com</a>
          </span> */}
          <a
            href="mailto:kontakt@botwise.io"
            className={styles.topBar_menu_userDetails_icon}
          >
            <HelpSVG
              className="help"
              data-for="help"
              data-tip={t("top_bar.help")}
            />
            <ReactTooltip
              id="help"
              place="bottom"
              type="light"
              effect="solid"
              className={styles.topBar_button_tooltip}
            />
          </a>
          <ButtonIcon
            className={styles.topBar_menu_userDetails_icon}
            onClick={logout}
            data-for="logout"
            data-tip={t("top_bar.logout")}
          >
            <>
              <LogoutSVG className="logout" />
              <ReactTooltip
                id="logout"
                place="bottom"
                type="light"
                effect="solid"
              />
            </>
          </ButtonIcon>
        </div>
      </div>
    </div>
  );
};

TopBar.propTypes = {
  user: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired
};

const mapStateToProps = ({ login: { user, role } }) => ({ user, role });

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TopBar));
