import { call, put, takeLatest } from "redux-saga/effects";
import { GET_PROJECTS_REQUEST } from "~/actions/types";
import { getProjectsSuccess, getProjectsFailure } from "~/actions";
import { getProjectsRequest } from "~/services/ProjectsService";
import { ProjectsResponseType } from "~/services/api-types";

function* getProjectsData() {
  try {
    const apiResponse = call(getProjectsRequest);

    const projects = (yield apiResponse) as ProjectsResponseType;

    if (projects?.content !== undefined) {
      yield put(getProjectsSuccess(projects.content));
    }
  } catch (error) {
    yield put(getProjectsFailure(error));
  }
}

export function* getProjects() {
  yield takeLatest(GET_PROJECTS_REQUEST, getProjectsData);
}
