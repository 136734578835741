import React from "react";
import PropTypes from "prop-types";
import { Dropdown, Icon } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import styles from "./DocumentSelection.module.scss";

const scrollToBottom = element => () =>
  setTimeout(() => {
    const scrollElement = document.getElementsByClassName(element)[0];
    scrollElement.scrollTop = scrollElement.scrollHeight;
  }, 10);

class DocumentSelectionComponent extends React.Component {
  componentDidMount() {
    this.props.refresh();
  }

  handleChange = (e, { name, value }) => this.props.onChange(value);

  render() {
    return (
      <Dropdown
        icon="chevron down"
        search
        className={styles.DocumentSelectionDropdown}
        placeholder={
          <div>
            <Icon name="search" />{" "}
            {this.props.t("documents.selection.pick_a_document")}
          </div>
        }
        fluid
        selection
        defaultValue={this.props.value}
        options={this.props.options}
        onChange={this.handleChange}
        onOpen={scrollToBottom("scrollCatcher")}
      />
    );
  }
}

DocumentSelectionComponent.propTypes = {
  refresh: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.number
    }).isRequired
  ).isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func,
  t: PropTypes.func.isRequired
};

DocumentSelectionComponent.defaultProps = {
  options: []
};

export default withTranslation()(DocumentSelectionComponent);
