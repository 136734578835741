import { CUSTOM_ERROR, DUMMY_REQUEST, DUMMY_SUCCESS } from "./types";

export function customError(message) {
  return {
    type: CUSTOM_ERROR,
    error: {
      message
    }
  };
}

export const requestActionStart = (url, request = {}) => ({
  type: DUMMY_REQUEST,
  data: {
    url,
    request
  }
});

export const requestActionEnd = () => ({
  type: DUMMY_SUCCESS
});
