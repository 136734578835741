import React, { useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
  Search as SemanticSearch,
  StrictSearchProps as SemanticSearchProps,
  SearchResultData as SemanticSearchResultData,
  SearchProps as SemanticSearchData,
  Icon
} from "semantic-ui-react";
import { MatchQueryResult } from "../MatchQueryResult/MatchQueryResult";

import styles from "./Search.module.scss";

interface SearchProps
  extends Omit<
    SemanticSearchProps,
    | "input"
    | "results"
    | "icon"
    | "onSearchChange"
    | "onFocus"
    | "onBlur"
    | "onResultSelect"
  > {
  placeholder?: string;
  items: string[];
  clear?: boolean;
  onSearchChange?: (
    value: string,
    ev?: React.MouseEvent<HTMLElement, MouseEvent> | undefined
  ) => void;
  onResultSelect?: (value: string, data?: SemanticSearchResultData) => void;
  icon?: boolean;
  inputClassName?: string;
  wrapperClass?: string;
  onFocus?: () => void;
  onBlur?: () => void;
}

export const Search = ({
  className,
  placeholder,
  items,
  onSearchChange,
  clear,
  onResultSelect,
  icon = true,
  inputClassName,
  wrapperClass,
  onFocus,
  onBlur,
  ...rest
}: SearchProps) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const results = useMemo(
    () =>
      items
        .map(item => ({ title: item, key: item }))
        .filter(item =>
          item.title.toLowerCase().includes(searchValue.toLowerCase())
        ),
    [items, searchValue]
  );

  const handleSearch = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    data: SemanticSearchData
  ) => {
    if (typeof data.value === "string") {
      setSearchValue(data.value);
      setIsSearchOpen(true);
      if (typeof onSearchChange === "function") {
        onSearchChange(data.value, e);
      }
    } else {
      setSearchValue("");
      if (typeof onSearchChange === "function") {
        onSearchChange("", e);
      }
    }
  };

  const handleClearQuery = useCallback(() => {
    if (typeof onSearchChange === "function") {
      onSearchChange("");
    }

    if (typeof onResultSelect === "function") {
      onResultSelect("");
    }

    setSearchValue("");
    setIsSearchOpen(false);
  }, []);

  const handleResultSelect = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: SemanticSearchResultData
  ) => {
    setIsSearchOpen(false);

    if (data.result.title) {
      setSearchValue(data.result.title);

      if (typeof onResultSelect === "function") {
        onResultSelect(data.result.title, data);
      }
    }
  };

  return (
    <div className={wrapperClass}>
      <SemanticSearch
        className={classNames(styles.search, className)}
        icon={
          icon && (
            <>
              <Icon className="search" />
              {clear && searchValue && (
                <Icon
                  className={classNames("trash", styles.clear)}
                  tabIndex={0}
                  onClick={handleClearQuery}
                />
              )}
            </>
          )
        }
        value={searchValue}
        results={results}
        onSearchChange={handleSearch}
        open={isSearchOpen}
        onFocus={() => setIsSearchOpen(true)}
        onBlur={() => setIsSearchOpen(false)}
        input={{
          input: { ["data-hj-allow"]: "" },
          className: classNames(
            styles.input,
            clear && searchValue && styles.withClear,
            !icon && styles.inputNoIcon,
            inputClassName
          ),
          placeholder:
            typeof placeholder === "string"
              ? placeholder
              : t("search.search_placeholder"),
          ...(typeof onBlur === "function" ? { onBlur } : {}),
          ...(typeof onFocus === "function" ? { onFocus } : {})
        }}
        resultRenderer={({ title }) => (
          <MatchQueryResult query={searchValue} text={title} />
        )}
        noResultsMessage={t("search_component.no_results_found")}
        onResultSelect={handleResultSelect}
        {...rest}
      />
    </div>
  );
};
