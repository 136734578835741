import React, { useCallback, useMemo } from "react";
import { DocumentType, HintType } from "~/services/api-types";
import { Trans } from "react-i18next";
import moment from "moment";
import FolderBreadcrumbs from "~/atoms/FolderBreadcrumbs/FolderBreadcrumbs";
import { getFileIcon } from "~/components/Documents/utils";
import classNames from "classnames";
import { useThunkDispatch } from "~/configureStore";
import { previewDocWithId } from "~/actions/DocumentsActions";
import { FeaturedSnippetContent } from "./FeaturedSnippetContent";
import { DocumentAnchorsLink } from "~/atoms/DocumentAnchorsLink/DocumentAnchorsLink";

import styles from "./FeaturedSnippet.module.scss";

const MAX_BREADCRUMBS_LENGTH = 3;
const MAX_BREADCRUMB_LENGTH = 30;

const RELATED_MAX_BREADCRUMBS_LENGTH = 3;
const RELATED_MAX_BREADCRUMB_LENGTH = 5;

interface FeaturedSnippetProps {
  item: HintType;
  resultsOnly?: boolean;
  scrollToMatch?: () => void;
}

export const FeaturedSnippet = ({
  item,
  resultsOnly,
  scrollToMatch
}: FeaturedSnippetProps) => {
  const date = useMemo(
    () => moment(item.updatedAt).fromNow(),
    [item.updatedAt]
  );
  const user = useMemo(
    () => item.modifiedBy.userName,
    [item.modifiedBy.userName]
  );

  const dispatch = useThunkDispatch();

  const previewDocument = useCallback(
    (documentId: number) => dispatch(previewDocWithId(documentId)),
    [dispatch]
  );

  const { document, documentAnchors } = item;

  return (
    <div
      className={classNames(styles.result, resultsOnly && styles.relatedResult)}
    >
      {item.question && (
        <h2 className={styles.title}>
          {item.question}
          {documentAnchors && document && (
            <DocumentAnchorsLink
              document={document}
              documentAnchors={documentAnchors}
            />
          )}
        </h2>
      )}
      {item.context && (
        <span className={styles.context}>
          {item.context && item.context.length > 0 && item.context.join(" → ")}
        </span>
      )}
      {item.answer && (
        <FeaturedSnippetContent
          html={item.answer}
          scrollToMatch={scrollToMatch}
        />
      )}
      <header className={styles.header}>
        {item.document && (
          <div className={styles.document}>
            <div
              className={styles.documentIcon}
              onClick={
                item.document
                  ? () => previewDocument((item.document as DocumentType).id)
                  : () => {}
              }
            >
              {getFileIcon(item.document.extension, item.document.isArticle)}
            </div>
            <h2
              className={styles.documentName}
              tabIndex={0}
              onClick={
                item.document
                  ? () => previewDocument((item.document as DocumentType).id)
                  : () => {}
              }
            >
              {item.document.name}
            </h2>
            <FolderBreadcrumbs
              className={styles.breadcrumbs}
              breadcrumbClass={styles.breadcrumb}
              fileExtension={item.document.extension}
              fileName={item.document.name}
              isArticle={item.document.isArticle}
              documentId={item.document.id}
              breadcrumbs={item.document.location}
              maxLength={
                !resultsOnly
                  ? MAX_BREADCRUMBS_LENGTH
                  : RELATED_MAX_BREADCRUMBS_LENGTH
              }
              maxBreadcrumbLength={
                !resultsOnly
                  ? MAX_BREADCRUMB_LENGTH
                  : RELATED_MAX_BREADCRUMB_LENGTH
              }
            />
          </div>
        )}
        {!resultsOnly && (
          <div className={styles.modification}>
            <Trans i18nKey="search.search_result.last_modified_by">
              Last modified
              <b>{{ date }}</b>
              by
              <b>{{ user }}</b>
            </Trans>
          </div>
        )}
      </header>
    </div>
  );
};
