import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, CheckboxProps } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import { getLocaleISO } from "~/helpers/locale";
import moment from "moment";
import { useSelector, useThunkDispatch } from "~/configureStore";
import { changeSearchFiltersAndFetch } from "~/actions/SearchActions";
import { useMixpanelContext } from "~/contexts/mixpanel";
import { MIXPANEL_EVENTS } from "~/contexts/mixpanel/event-types";

import styles from "./FiltersSettings.module.scss";

export enum FilterPeriodTypes {
  MODIFIED_ALL = "all",
  MODIFIED_LAST_WEEK = "last_week",
  MODIFIED_LAST_MONTH = "last_month",
  MODIFIED_LAST_YEAR = "last_year",
  MODIFIED_RANGE = "range"
}

export const FiltersSettings = () => {
  const periodModification = useSelector(
    ({ searchNew: { filtersUsed } }) => filtersUsed?.periodModification
  );

  const modifiedSince = useSelector(
    ({ searchNew: { filtersUsed } }) => filtersUsed?.modifiedSince
  );
  const modifiedUntil = useSelector(
    ({ searchNew: { filtersUsed } }) => filtersUsed?.modifiedUntil
  );

  const maxDate = new Date();
  const { t } = useTranslation();
  const { trackEvent } = useMixpanelContext();

  const dispatch = useThunkDispatch();

  const handleStartDateChange = useCallback(
    (date: Date | null) => {
      dispatch(
        changeSearchFiltersAndFetch({
          modifiedSince: date ? moment(date).format("YYYY-MM-DD") : "",
          modifiedUntil:
            periodModification === FilterPeriodTypes.MODIFIED_RANGE
              ? modifiedUntil
              : "",
          periodModification: FilterPeriodTypes.MODIFIED_RANGE
        })
      );
      trackEvent(MIXPANEL_EVENTS.SEARCH_FILTERS_MODIFICATION_DATE);
    },
    [dispatch, modifiedUntil]
  );

  const handleEndDateChange = useCallback(
    (date: Date | null) => {
      dispatch(
        changeSearchFiltersAndFetch({
          modifiedSince:
            periodModification === FilterPeriodTypes.MODIFIED_RANGE
              ? modifiedSince
              : "",
          modifiedUntil: date ? moment(date).format("YYYY-MM-DD") : "",
          periodModification: FilterPeriodTypes.MODIFIED_RANGE
        })
      );
      trackEvent(MIXPANEL_EVENTS.SEARCH_FILTERS_MODIFICATION_DATE);
    },
    [dispatch, modifiedSince]
  );

  const handleModifiedChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => {
      let startDate, endDate;

      if (data.name === FilterPeriodTypes.MODIFIED_ALL) {
        startDate = "";
        endDate = "";
      }
      if (data.name === FilterPeriodTypes.MODIFIED_LAST_WEEK) {
        startDate = moment().subtract(7, "days").toDate();
        endDate = moment().toDate();
      }
      if (data.name === FilterPeriodTypes.MODIFIED_LAST_MONTH) {
        startDate = moment().subtract(30, "days").toDate();
        endDate = moment().toDate();
      }
      if (data.name === FilterPeriodTypes.MODIFIED_LAST_YEAR) {
        startDate = moment().subtract(1, "year").toDate();
        endDate = moment().toDate();
      }

      dispatch(
        changeSearchFiltersAndFetch({
          modifiedSince: startDate
            ? moment(startDate).format("YYYY-MM-DD")
            : "",
          modifiedUntil: endDate ? moment(endDate).format("YYYY-MM-DD") : "",
          periodModification: data.name as FilterPeriodTypes
        })
      );
      trackEvent(MIXPANEL_EVENTS.SEARCH_FILTERS_MODIFICATION_DATE);
    },
    [dispatch]
  );

  return (
    <div className={styles.filtersSettings}>
      <h6>{t("search.filters.settings_modified_header")}</h6>
      <div className={styles.filtersSettingsWrapper}>
        <Checkbox
          name={FilterPeriodTypes.MODIFIED_ALL}
          className={styles.checkboxItem}
          radio
          label={t("search.filters.settings_modified_all")}
          checked={periodModification === FilterPeriodTypes.MODIFIED_ALL}
          onChange={handleModifiedChange}
        />
        <Checkbox
          name={FilterPeriodTypes.MODIFIED_LAST_WEEK}
          className={styles.checkboxItem}
          radio
          label={t("search.filters.settings_modified_last_week")}
          checked={periodModification === FilterPeriodTypes.MODIFIED_LAST_WEEK}
          onChange={handleModifiedChange}
        />
        <Checkbox
          name={FilterPeriodTypes.MODIFIED_LAST_MONTH}
          className={styles.checkboxItem}
          radio
          label={t("search.filters.settings_modified_last_month")}
          checked={periodModification === FilterPeriodTypes.MODIFIED_LAST_MONTH}
          onChange={handleModifiedChange}
        />
        <Checkbox
          name={FilterPeriodTypes.MODIFIED_LAST_YEAR}
          className={styles.checkboxItem}
          radio
          label={t("search.filters.settings_modified_last_year")}
          checked={periodModification === FilterPeriodTypes.MODIFIED_LAST_YEAR}
          onChange={handleModifiedChange}
        />
        <div className={styles.datePickerItem}>
          <Checkbox
            name={FilterPeriodTypes.MODIFIED_RANGE}
            className={styles.checkboxItem}
            radio
            label={t("search.filters.settings_modified_date_range")}
            checked={periodModification === FilterPeriodTypes.MODIFIED_RANGE}
            onChange={handleModifiedChange}
          />
          <div className={styles.wrapper}>
            <DatePicker
              placeholderText={t(
                "search.filters.settings_modified_date_range_from_placeholder"
              )}
              locale={getLocaleISO()}
              dateFormat="dd/MM/yyyy"
              onChange={handleStartDateChange}
              selected={
                modifiedSince &&
                periodModification === FilterPeriodTypes.MODIFIED_RANGE
                  ? moment(modifiedSince, "YYYY-MM-DD").toDate()
                  : null
              }
              selectsStart
              maxDate={maxDate}
              className={styles.from}
            />
            <DatePicker
              placeholderText={t(
                "search.filters.settings_modified_date_range_to_placeholder"
              )}
              locale={getLocaleISO()}
              dateFormat="dd/MM/yyyy"
              onChange={handleEndDateChange}
              selected={
                modifiedUntil &&
                periodModification === FilterPeriodTypes.MODIFIED_RANGE
                  ? moment(modifiedUntil, "YYYY-MM-DD").toDate()
                  : null
              }
              selectsEnd
              maxDate={maxDate}
              minDate={moment(modifiedSince, "YYYY-MM-DD").toDate()}
              className={styles.to}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
