import type {
  IsUserActiveAction,
  TrackEventAction
} from "~/reducers/analytics";
import { TrackedEvent } from "~/services/analytics/types";
import {
  TRACK_EVENT,
  CLEAR_EVENTS_QUEUE,
  PUBLISH_EVENTS_FAILURE,
  IS_USER_ACTIVE
} from "./types";

export function trackEvent(event: TrackedEvent): TrackEventAction {
  return {
    type: TRACK_EVENT,
    event
  };
}

export function clearEventsQueue() {
  return {
    type: CLEAR_EVENTS_QUEUE
  };
}

export function publishEventsFailure() {
  return {
    type: PUBLISH_EVENTS_FAILURE
  };
}

export function trackUserActivity(isActive: boolean): IsUserActiveAction {
  return {
    type: IS_USER_ACTIVE,
    payload: isActive
  };
}
