import React from "react";
import { useTranslation } from "react-i18next";
import LoaderInline from "~/components/LoaderInline/LoaderInline";

import styles from "./FallbackLoader.module.scss";

export const FallbackLoader = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <LoaderInline isFetching label={t("fallback_loader.loading")} />
    </div>
  );
};
