import {
  GET_CHANGES_SUCCESS,
  CLEAR_CHANGES_STATE,
  DELETE_CHANGES_SUCCESS,
  COPY_CHANGES_PARAMS,
  SET_LOCATION,
  CHANGE_UPDATE
} from "~/actions/types";

const initialState = {
  content: [],
  totalPages: 0,
  number: 0,
  filtersCopy: {},
  sortName: "updatedAt",
  sortDir: "desc",
  location: ""
};

const changes = (state = initialState, action) => {
  switch (action.type) {
    case GET_CHANGES_SUCCESS:
      return {
        ...action.changes,
        filtersCopy: state.filtersCopy,
        sortDir: state.sortDir,
        sortName: state.sortName,
        content: action.pageNumber
          ? [...state.content, ...action.changes.content]
          : action.changes.content
      };
    case DELETE_CHANGES_SUCCESS:
      return {
        ...state,
        totalElements: Number(state.totalElements) - 1,
        content: state.content.filter(item => item.id !== action.deletedId)
      };
    case CLEAR_CHANGES_STATE:
      return initialState;
    case CHANGE_UPDATE: {
      const editedChange = state.content.find(
        ({ id }) => id === action.change.id
      );
      if (!action.change.id || !editedChange) return state;
      return {
        ...state,
        content: [
          {
            ...editedChange,
            ...action.change
          },
          ...state.content.filter(({ id }) => id !== action.change.id)
        ]
      };
    }
    case COPY_CHANGES_PARAMS:
      return {
        ...state,
        filtersCopy: action.filters,
        sortName: action.sortName,
        sortDir: action.sortDir
      };
    case SET_LOCATION:
      return {
        ...state,
        location: action.location
      };
    default:
      return state;
  }
};

export default changes;
