export const LOAD_WORKFLOWS_REQUEST = "LOAD_WORKFLOWS_REQUEST";
export const LOAD_WORKFLOWS_SUCCESS = "LOAD_WORKFLOWS_SUCCESS";
export const LOAD_WORKFLOWS_FAILURE = "LOAD_WORKFLOWS_FAILURE";

export const WORKFLOW_INIT = "WORKFLOW_INIT";
export const SET_WORKFLOW_METADATA = "SET_WORKFLOW_METADATA";
export const UPDATE_WORKFLOW_TREE = "UPDATE_WORKFLOW_TREE";
export const REMOVE_WORKFLOW_ITEM = "REMOVE_WORKFLOW_ITEM";
export const CLEAR_WORKFLOW_TREE = "CLEAR_WORKFLOW_TREE";
export const UPDATE_WORKFLOW_TREE_NODES = "UPDATE_WORKFLOW_TREE_NODES";

export const REMOVE_WORKFLOW = "REMOVE_WORKFLOW";
