import { useCallback } from "react";
import { useSelector } from "~/configureStore";
import { HIGHLIGHTED_SPAN_SELECTOR } from "~/hooks/scroll/scrollToQueryMatch";

export const useOnQueryMatchInside = (
  resultRowElement: HTMLDivElement | null
) => {
  const { currentPosition } = useSelector(
    ({ searchNew }) => searchNew.queryMatches
  );

  return useCallback(() => {
    if (resultRowElement) {
      const queryMatches = Array.from(
        window.document.querySelectorAll(HIGHLIGHTED_SPAN_SELECTOR)
      );
      if (currentPosition >= 0 && currentPosition <= queryMatches.length - 1) {
        const currentQueryMatch = queryMatches.at(currentPosition);
        return (
          currentQueryMatch && resultRowElement.contains(currentQueryMatch)
        );
      }
    }
    return false;
  }, [currentPosition, resultRowElement]);
};
