import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { TENANT } from "~/constants/app";
import { getBreadcrumbName } from "~/constants/breadcrumbsMap";
import { Link } from "react-router-dom";

import styles from "./Breadcrumbs.module.scss";

export const Breadcrumbs = () => {
  const { t } = useTranslation();

  const getLinkBreadcrumbItem = (
    value: string,
    isFirst: boolean,
    path: string
  ) => {
    const displayValue = getBreadcrumbName("/" + value);
    return (
      value && (
        <span className={styles.breadcrumbsCommon}>
          <span
            className={classNames(
              styles.breadcrumbsSeparator,
              isFirst && styles.breadcrumbsFirst
            )}
          >
            {"/"}
          </span>
          <Link to={path}>{displayValue ? t(displayValue) : value}</Link>
        </span>
      )
    );
  };

  const getCurrentBreadcrumbItem = (value: string, isFirst: boolean) => {
    const displayValue = getBreadcrumbName("/" + value);
    return (
      value && (
        <span>
          <span
            className={classNames(
              styles.breadcrumbsSeparator,
              isFirst && styles.breadcrumbsFirst
            )}
          >
            {"/"}
          </span>
          <span className={styles.breadcrumbsCurrent}>
            {displayValue ? t(displayValue) : value}
          </span>
        </span>
      )
    );
  };

  const isPathToShow = (path: string) => {
    const isId = /^\d+$/.test(path);
    return path != "train" && !isId;
  };

  const getHiddenElementsOffset = (paths: string[]) => {
    let hiddenElementsOffset = 0;
    const reversedPaths = paths.slice().reverse();
    for (let path of reversedPaths) {
      if (!isPathToShow(path)) hiddenElementsOffset++;
      else break;
    }
    return hiddenElementsOffset;
  };

  const getBreadcrumbs = (path: string) => {
    const paths = path.split("/");
    let currentPath = "";
    let anyValue = false;
    let hiddenElementsOffset = getHiddenElementsOffset(paths);
    const isIssue = paths.includes("issues"); // todo - temporary, ugly solution
    return paths.map((value, index) => {
      if (value && isPathToShow(value)) {
        const isCurrent = index === paths.length - hiddenElementsOffset - 1;
        const isFirst = !anyValue;
        anyValue = true;
        currentPath += "/" + value;
        return isCurrent
          ? getCurrentBreadcrumbItem(value, isFirst)
          : getLinkBreadcrumbItem(value, isFirst, currentPath);
      } else if (value && isIssue && value == "train") {
        currentPath += "/" + value;
      }
    });
  };

  return (
    <div className={styles.breadcrumbs}>
      <span className={styles.breadcrumbsFirst}>{TENANT}</span>
      {getBreadcrumbs(window.location.pathname)}
    </div>
  );
};
