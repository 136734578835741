import Cookies from "js-cookie";
import { COOKIES_LANGUAGE, DEFAULT_LANGUAGE } from "~/constants/app";

type IESupportingNavigator = Navigator & { userLanguage?: string | null };

const findUserLanguage = () => {
  const language =
    (window.navigator as IESupportingNavigator)?.userLanguage ||
    window.navigator.language;

  if (language?.slice(0, 2) !== "en" && language?.slice(0, 2) !== "pl") {
    return "en";
  }

  return language?.slice(0, 2) ?? DEFAULT_LANGUAGE;
};

export const getLocaleISO = () => Cookies.get(COOKIES_LANGUAGE);

export const setAndGetLanguage = (language: string | null) => {
  const currentLanguage = Cookies.get(COOKIES_LANGUAGE);

  if (!currentLanguage) {
    if (language) {
      Cookies.set(COOKIES_LANGUAGE, language, {
        sameSite: "lax",
        path: "/",
        expires: Date.now() + 3600 * 24 * 31
      });

      return language;
    }

    Cookies.set(COOKIES_LANGUAGE, findUserLanguage(), {
      sameSite: "lax",
      path: "/",
      expires: Date.now() + 3600 * 24 * 31
    });

    return findUserLanguage();
  }

  if (language && currentLanguage !== language) {
    Cookies.set(COOKIES_LANGUAGE, language, {
      sameSite: "lax",
      path: "/",
      expires: Date.now() + 3600 * 24 * 31
    });

    return language;
  }

  return currentLanguage;
};
