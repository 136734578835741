import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { refreshLoginData } from "./actions";
import { useSelector, useThunkDispatch } from "./configureStore";
import { LOGIN } from "./constants/routes";
import { getRefreshToken } from "./helpers/tokens";

export const RouteGuard = () => {
  const isLoggedIn = useSelector(
    ({ login: { isAuthenticated } }) => isAuthenticated
  );

  const dispatch = useThunkDispatch();

  const { push } = useHistory();

  useEffect(() => {
    if (!isLoggedIn && getRefreshToken()) {
      dispatch(refreshLoginData());
    }
  }, []);

  useEffect(() => {
    if (!isLoggedIn && !getRefreshToken()) {
      push(LOGIN);
    }
  });

  return null;
};
