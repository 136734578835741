import { Suspense, useEffect, useMemo } from "react";
import ReactFlow, {
  Background,
  MiniMap,
  useNodesState,
  addEdge,
  ReactFlowProvider
} from "react-flow-renderer";
import { debounce, isEqual } from "lodash";
import { updateWorkflowTreeNodes } from "~/actions/workflow/actions";
import { FallbackLoader } from "../FallbackLoader/FallbackLoader";
import { useSelector, useThunkDispatch } from "~/configureStore";
import { SupportingQuestion } from "./nodes/SupportingQuestion/SupportingQuestion";
import { KnowledgeBaseEntry } from "./nodes/KnowledgeBaseEntry/KnowledgeBaseEntry";
import { NewResponse } from "./nodes/NewResponse/NewResponse";
import { StartNode } from "./nodes/StartNode/StartNode";
import { InputEdge } from "./edges/InputEdge/InputEdge";
import { ButtonEdge } from "./edges/ButtonEdge/ButtonEdge";
import { Controls } from "./controls/Controls";

import { NodeTypes, EdgeTypes } from "~/components/types";
import { WorkflowData } from "~/reducers/types";
import { PropsType } from "~/helpers/types";

export const FLOW_MODAL_CONTAINER_ID = "FLOW_MODAL_CONTAINER_ID";

const customNodes = (
  isPreview: boolean = false
): Record<
  NodeTypes,
  | typeof NewResponse
  | typeof StartNode
  | typeof SupportingQuestion
  | typeof KnowledgeBaseEntry
> => ({
  start: (props: PropsType<typeof StartNode>) => (
    <StartNode {...props} previewMode={isPreview} />
  ),
  create: (props: PropsType<typeof NewResponse>) => (
    <NewResponse {...props} previewMode={isPreview} />
  ),
  supporting_question: (props: PropsType<typeof SupportingQuestion>) => (
    <SupportingQuestion {...props} previewMode={isPreview} />
  ),
  knowledge_entry: (props: PropsType<typeof KnowledgeBaseEntry>) => (
    <KnowledgeBaseEntry {...props} previewMode={isPreview} />
  )
});

const customEdges: Record<EdgeTypes, typeof ButtonEdge | typeof InputEdge> = {
  button: ButtonEdge,
  input: InputEdge
};

interface FlowDefaultProps {
  className?: string;
  containerTestId?: string;
}

interface FlowPreviewProps extends FlowDefaultProps {
  previewMode?: true;
  data: WorkflowData;
}

interface FlowEditProps extends FlowDefaultProps {
  previewMode?: undefined;
  data?: undefined;
}

type FlowProps = FlowPreviewProps | FlowEditProps;

export const Flow = ({
  className,
  containerTestId,
  data,
  previewMode
}: FlowProps) => {
  const currentWorkflow = useSelector(
    ({ workflows }) => workflows.currentWorkFlow,
    (left, right) => isEqual(left, right)
  );

  const dispatch = useThunkDispatch();

  const stateNodes = useMemo(
    () => currentWorkflow?.tree?.nodes || [],
    [currentWorkflow?.tree.nodes]
  );

  const [nodes, setNodes, onNodesChange] = useNodesState(stateNodes);

  useEffect(() => {
    if (!isEqual(stateNodes, nodes)) {
      setNodes(stateNodes);
    }
  }, [stateNodes]);

  const stateUpdater = () =>
    nodes.length && stateNodes.length
      ? dispatch(updateWorkflowTreeNodes(nodes))
      : undefined;

  useEffect(() => {
    const debounceCall = debounce(stateUpdater, 1000);

    debounceCall();

    return () => {
      debounceCall.cancel();
    };
  }, [nodes]);

  const edges = useMemo(() => {
    return currentWorkflow?.tree.edges || [];
  }, [currentWorkflow?.tree.edges]);

  const nodesComponent = useMemo(() => customNodes(previewMode), [previewMode]);

  if (!currentWorkflow && !previewMode) return null;

  return (
    <Suspense fallback={<FallbackLoader />}>
      <ReactFlowProvider>
        <ReactFlow
          className={className}
          nodes={previewMode ? data.tree.nodes : nodes}
          edges={previewMode ? data.tree.edges : edges}
          nodeTypes={nodesComponent}
          edgeTypes={customEdges}
          onNodesChange={previewMode ? undefined : onNodesChange}
          // onEdgesChange={previewMode ? undefined : console.log}
          snapToGrid
          snapGrid={[15, 15]}
          data-testid={containerTestId}
          elementsSelectable={previewMode ? false : true}
        >
          {!previewMode && (
            <>
              <Background gap={16} />
              <Controls />
            </>
          )}
          <MiniMap />
        </ReactFlow>
        <div
          data-testid={FLOW_MODAL_CONTAINER_ID}
          id={FLOW_MODAL_CONTAINER_ID}
        />
      </ReactFlowProvider>
    </Suspense>
  );
};
