import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { Loader } from "semantic-ui-react";
import { debounce } from "lodash";
import classNames from "classnames";
import { listLoadingOptions } from "~/constants/app";
import { useSelector, useThunkDispatch } from "~/configureStore";
import {
  searchPageAction,
  updateFinderMaxIndex
} from "~/actions/SearchActions";
import LoaderInline from "~/components/LoaderInline/LoaderInline";
import { SearchResult } from "~/molecules/SearchResult/SearchResult";
import { FeaturedSnippet } from "~/molecules/FeaturedSnippet/FeaturedSnippet";

import { ReactComponent as EmptySearch } from "~/assets/search_empty.svg";
import { ReactComponent as NoResultsSearch } from "~/assets/search_no_results.svg";

import styles from "./SearchResults.module.scss";
import { SearchGreetingMessage } from "~/molecules/SearchGreetingMessage/SearchGreetingMessage";
import { useToggle } from "~/hooks/useToggle";
import {
  scrollToQueryMatch,
  useScrollToCurrentMatch
} from "../../../hooks/scroll/scrollToQueryMatch";

const PAGE_LIMIT_WHEN_NO_QUERY_MATCHES = 5;

interface SearchResultsProps {
  className?: string;
  resultsOnly?: boolean;
}

export const SearchResults = ({
  className,
  resultsOnly
}: SearchResultsProps) => {
  const { t } = useTranslation();
  const [offset, setOffset] = useState(0);
  const [loadingNewPage, setLoadingNewPage] = useState(false);

  const dispatch = useThunkDispatch();

  const {
    currentQuestion,
    error,
    page,
    items: {
      featuredSnippet,
      results: { content: results, totalElements, totalPages },
      searchEventId
    },
    loading,
    queryMatches: { currentPosition, totalPositions }
  } = useSelector(({ searchNew }) => searchNew);

  const currentProject = useSelector(({ projects: { current } }) => current);
  const [expandAllResults, setExpandAllResults] = useToggle(false);

  const defaultGreeting = useMemo(() => {
    if (!currentProject?.searchWelcomeMessage) return null;

    return currentProject.searchWelcomeMessage;
  }, [currentProject]);

  let timeout: NodeJS.Timeout | null = null;

  const loadNextPage = useCallback(
    (page: number) => {
      setLoadingNewPage(true);
      dispatch(searchPageAction(currentQuestion, page)).finally(() => {
        timeout = setTimeout(() => setLoadingNewPage(false), 100);
      });
    },
    [dispatch, currentQuestion]
  );

  useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [timeout]);

  const isNoResults = useMemo(
    () => !results.length && currentQuestion && !featuredSnippet,
    [results, currentQuestion, featuredSnippet]
  );

  const resultsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (resultsRef.current && results.length && !resultsOnly) {
      // app container bottom padding 32px
      const value = resultsRef.current.offsetTop + 32;

      setOffset(value);
    }
  }, [results.length, resultsOnly, loading]);

  const scrollToMatch = useScrollToCurrentMatch(resultsRef.current);

  useEffect(() => {
    if (loadingNewPage || loading) return;

    if (
      currentPosition == totalPositions - 1 &&
      (currentPosition !== -1 || page < PAGE_LIMIT_WHEN_NO_QUERY_MATCHES)
    ) {
      const newPage = page + 1;

      if (newPage >= totalPages) return;

      loadNextPage(newPage);
    }
  }, [
    currentPosition,
    totalPositions,
    page,
    totalPages,
    loadingNewPage,
    loading,
    loadNextPage
  ]);

  const handleScrollLoading = useCallback(
    debounce((e: React.UIEvent<HTMLDivElement>) => {
      e.stopPropagation();

      if (loadingNewPage || loading) return;

      const target = e.target as HTMLDivElement;

      const { scrollTop, offsetHeight, scrollHeight } = target;

      if (
        scrollTop >
        scrollHeight - offsetHeight - listLoadingOptions.offsetBottom
      ) {
        const newPage = page + 1;

        if (newPage >= totalPages) return;

        return loadNextPage(newPage);
      }
    }, listLoadingOptions.debounceTime),
    [loadNextPage, totalPages, loadingNewPage, loading]
  );

  useEffect(() => {
    if (!loadingNewPage && !loading && page === 0 && totalPages > 1) {
      if (resultsRef.current && resultsRef.current.parentElement) {
        let elementsHeight = 0;
        for (let i = 0; i < resultsRef.current.children.length; i++) {
          elementsHeight =
            elementsHeight + resultsRef.current.children[i].clientHeight;
        }
        const containerHeight = resultsRef.current.parentElement.clientHeight;
        let pagesToLoad = Math.floor(containerHeight / elementsHeight);
        pagesToLoad = Math.min(pagesToLoad, totalPages - (page + 1));
        for (let i = 1; i <= pagesToLoad; i++) {
          loadNextPage(page + i);
        }
      }
    }
  }, [loading, totalPages, loadingNewPage, page, results, loadNextPage]);

  useEffect(() => {
    dispatch(updateFinderMaxIndex());
  }, [results]);

  return (
    <div className={classNames(className)}>
      {!resultsOnly && !loading && totalElements > 0 && (
        <div className={styles.header}>
          <span className={styles.headerResults}>
            {t("search.search_results.found_count_results", {
              count: totalElements
            })}
          </span>
          <label>
            <>
              <input
                type="checkbox"
                defaultChecked={expandAllResults}
                onChange={() =>
                  expandAllResults
                    ? setExpandAllResults(false)
                    : setExpandAllResults(true)
                }
              />
              {t("search.search_results.all_expanded_checkbox")}
            </>
          </label>
        </div>
      )}
      <div
        style={
          !resultsOnly
            ? {
                maxHeight: `calc(100vh - ${offset}px)`,
                overflow: "auto",
                padding: !loading && results.length > 1 ? "0 8px 24px 0px" : "0"
              }
            : {}
        }
        ref={resultsRef}
        onScroll={handleScrollLoading}
      >
        {!loading && !error && featuredSnippet && (
          <FeaturedSnippet
            key={featuredSnippet.id}
            item={featuredSnippet}
            resultsOnly={resultsOnly}
            scrollToMatch={scrollToMatch}
          />
        )}
        {!loading &&
          !error &&
          results.map(item => (
            <SearchResult
              key={item.content[0].id}
              item={item}
              resultsOnly={resultsOnly}
              searchEventId={searchEventId}
              expanded={expandAllResults}
              scrollToMatch={scrollToMatch}
            />
          ))}
        {loadingNewPage && <LoaderInline isFetching />}
      </div>
      {loading && (
        <div className={styles.loader}>
          <Loader active size="medium" />
        </div>
      )}
      {!loading &&
        !featuredSnippet &&
        results.length === 0 &&
        (defaultGreeting && !currentQuestion && !resultsOnly ? (
          <SearchGreetingMessage message={defaultGreeting.answer} />
        ) : (
          <SearchNotFound
            text={
              isNoResults
                ? t("search.search_results.results_not_found")
                : undefined
            }
          >
            {isNoResults ? <NoResultsSearch /> : <EmptySearch />}
          </SearchNotFound>
        ))}
    </div>
  );
};

const SearchNotFound = ({
  children,
  text
}: {
  children: JSX.Element;
  text?: string;
}) => (
  <div className={styles.noResults}>
    {text && <p className={styles.noResultsText}>{text}</p>}
    {React.cloneElement(children, { className: styles.noResultsImage })}
  </div>
);
