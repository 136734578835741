import {
  GET_ISSUES_REQUEST,
  GET_ISSUES_SUCCESS,
  GET_ISSUES_FAILURE,
  SET_ISSUE_INDEX,
  RESET_ISSUE_INDEX,
  GET_SINGLE_ISSUE_REQUEST,
  GET_SINGLE_ISSUE_SUCCESS,
  GET_SINGLE_ISSUE_FAILURE,
  EXPAND_CONTAINER,
  SET_ISSUES_LABELS
} from "./types";
import { changeAnswer, editAnswerToQuestion } from "./EditorActions";
import * as api from "~/services/IssuesService";
import { requestActionStart, requestActionEnd } from "./CustomActions";
import i18next from "i18next";
import type { IssuesType } from "~/services/api-types";

export const getIssues = (pageNumber: number, labels?: string[]) => ({
  type: GET_ISSUES_REQUEST,
  pageNumber,
  labels
});

export const getIssuesSuccess = (issues: IssuesType) => ({
  type: GET_ISSUES_SUCCESS,
  issues
});

export const getIssuesFailure = (error: any) => ({
  type: GET_ISSUES_FAILURE,
  error
});

export function setIssueIndex(index: any) {
  return {
    type: SET_ISSUE_INDEX,
    index
  };
}

export function resetIssueIndex(index: any) {
  return {
    type: RESET_ISSUE_INDEX,
    index
  };
}

export function getSingleIssueRequest() {
  return {
    type: GET_SINGLE_ISSUE_REQUEST
  };
}

export function getSingleIssueSuccess(data: any) {
  return {
    type: GET_SINGLE_ISSUE_SUCCESS,
    data
  };
}

export function getSingleIssueFailure() {
  return {
    type: GET_SINGLE_ISSUE_FAILURE
  };
}

export const getSingleIssue = (id: any) => (dispatch: any) => {
  dispatch(getSingleIssueRequest());

  return api
    .getSingleIssue(id)
    .then(data => dispatch(getSingleIssueSuccess(data)))
    .catch(err => dispatch(getSingleIssueFailure()));
};

/**
 *
 * @param {{ answerMessageId: number; comment: string; labels?: string[] }} data
 * @void
 */

export function createIssue(data: any) {
  return (_: any, getState: any) => {
    const answerMessage = getState().messages[data.answerMessageId];

    let comment = i18next.t("issues_actions.bot_returned_wrong_answer");

    if (data.comment !== undefined && data.comment !== "") {
      comment = data.comment;
    }

    const body = {
      answerMessageId: answerMessage.id,
      comment,
      labels: data.labels ?? []
    };

    api.reportIssue(body);
  };
}

export function setIssue(index: any) {
  return (dispatch: any, getState: any) => {
    const { content } = getState().issues;

    if (content.length === 0) {
      return dispatch(getIssues(0));
    }

    const question = getState().issues.content?.[index]?.question;
    if (question) {
      dispatch(editAnswerToQuestion(question));
      dispatch(setIssueIndex(index));
    }
  };
}

export const expandContainer = (state: any) => (dispatch: any) =>
  dispatch({
    type: EXPAND_CONTAINER,
    state
  });

export function nextIssue() {
  return (dispatch: any, getState: any) => {
    const index = getState().issues.index + 1;
    dispatch(setIssue(index));
  };
}

export function markIssue(id: any) {
  return (dispatch: any) => {
    if (id) {
      dispatch(requestActionStart(`/issues/${id}/solve`, { id }));
      api.solveIssue(id).then(() => {
        dispatch(requestActionEnd());
        dispatch(changeAnswer({ issueId: null }));
      });
    }
  };
}

function fetchLabels(labels: any) {
  return {
    type: SET_ISSUES_LABELS,
    payload: labels
  };
}

export const fetchIssuesLabels = () => async (dispatch: any) => {
  try {
    const data = await api.getIssuesLabels();

    return dispatch(fetchLabels(data));
  } catch (err) {
    // the error will be never be thrown because request() catches all errors
  }
};
