import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import MessageListContainer from "~/containers/MessageListContainer";
import SendMessageForm from "~/components/SendMessageForm";
import ReactTooltip from "react-tooltip";
import { CSSTransition } from "react-transition-group";
import { connect } from "react-redux";
import { toggleRelatedContainer } from "~/actions/ChatActions";
import { MIXPANEL_EVENTS } from "~/contexts/mixpanel/event-types";
import Wrapper from "~/components/atoms/Wrapper/Wrapper";
import Related from "~/components/organisms/Related/Related";
import ButtonIcon from "~/components/atoms/ButtonIcon/ButtonIcon";
import { useTrackMixpanelEvent } from "~/hooks/useTrackMixpanelEvent";

import { ReactComponent as ExpandSVG } from "~/assets/icons/expand.svg";

import styles from "./Chat.module.scss";
import "./Chat.scss";

const ChatComponent = ({ toggleRelated }) => {
  const { t } = useTranslation();
  const [relatedOpened, setRelatedOpened] = useState(false);

  const toggleRelatedOpened = () => {
    toggleRelated(!relatedOpened);

    return setRelatedOpened(!relatedOpened);
  };
  const handleClose = () => {
    toggleRelated(false);

    return setRelatedOpened(false);
  };

  useTrackMixpanelEvent(MIXPANEL_EVENTS.CHATBOT_VISIT);

  const ExpandButton = () => (
    <>
      <ButtonIcon
        onClick={toggleRelatedOpened}
        className={classNames(
          styles.chatExpandButton,
          relatedOpened && styles.chatExpandButtonOpened
        )}
        data-for="toggleRelatedButton"
        data-tip={
          relatedOpened ? t("chat.close_related") : t("chat.open_related")
        }
      >
        <ExpandSVG className={styles.chatExpandButtonIcon} />
      </ButtonIcon>
      <ReactTooltip
        id="toggleRelatedButton"
        place="top"
        type="light"
        effect="solid"
      />
    </>
  );

  return (
    <div className={styles.chatWrapper}>
      <Helmet title={t("chat.chatbot")} />
      <Wrapper
        className={styles.chat}
        title={t("chat.chatbot")}
        ExtendedHeader={ExpandButton}
      >
        <MessageListContainer chatMode />
        <SendMessageForm relatedOpened={relatedOpened} />
      </Wrapper>
      <CSSTransition
        in={relatedOpened}
        timeout={200}
        classNames="related"
        unmountOnExit
      >
        <Related handleClose={handleClose} />
      </CSSTransition>
    </div>
  );
};

ChatComponent.propTypes = {
  toggleRelated: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  toggleRelated: isOpen => dispatch(toggleRelatedContainer(isOpen))
});

export default connect(null, mapDispatchToProps)(ChatComponent);
