import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";
import {
  DocumentFragmentAnchorsType,
  DocumentType
} from "~/services/api-types";
import { Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import sanitizeHtml from "sanitize-html";
import { useSelector } from "~/configureStore";
import { useToggle } from "~/hooks/useToggle";
import HTMLParagraphWithLinks from "~/components/HTMLParagraphWithLinks";
import Button from "~/atoms/Button/Button";
import { Collapse } from "react-collapse";
import { TitleWithContext } from "~/atoms/TitleWithContext/TitleWithContext";
import { DocumentAnchorsLink } from "~/atoms/DocumentAnchorsLink/DocumentAnchorsLink";

import styles from "./SearchResultContent.module.scss";
import { trackEvent } from "~/helpers/tracking";
import { useInView } from "react-intersection-observer";
import { sanitizeSettings } from "~/constants/sanitizeSettings";
import { useOnQueryMatchInside } from "~/hooks/scroll/useOnQueryMatchInside";
import { useQueryMatchScroll } from "~/hooks/scroll/useSearchMatchScroll";

const MAX_RESULT_HEIGHT = 80;

interface SearchResultContentProps {
  id: string;
  html: string;
  question: string;
  context: string[];
  single: boolean;
  document: DocumentType | null;
  documentAnchors: DocumentFragmentAnchorsType | null;
  position: number;
  searchEventId: string;
  expanded?: boolean;
  scrollToMatch?: () => void;
}

export const SearchResultContent = ({
  id,
  html,
  question,
  context,
  single,
  document,
  documentAnchors,
  position,
  searchEventId,
  expanded,
  scrollToMatch = () => {}
}: SearchResultContentProps) => {
  const [isExpanded, toggleIsExpanded] = useToggle(false);
  const [isAccordionOpen, toggleIsAccordionOpen] = useToggle(expanded ?? false);

  useEffect(() => {
    toggleIsAccordionOpen(expanded ?? false);
  }, [expanded]);

  const [canBeExpanded, setCanBeExpanded] = useState(false);

  const { currentQuestion } = useSelector(({ searchNew }) => searchNew);

  const { ref, inView } = useInView({
    threshold: 0.5
  });

  const resultRowRef = useRef<HTMLDivElement>(null);

  useQueryMatchScroll(
    isExpanded && isAccordionOpen,
    () => {
      toggleIsAccordionOpen(true);
      toggleIsExpanded(true);
    },
    scrollToMatch,
    resultRowRef.current
  );

  const [seen, setSeen] = useState(false);

  useEffect(() => {
    if (inView && !seen) {
      trackEvent({
        type: "FRAGMENT_SHOWN_IN_RESULTS",
        searchEventId,
        fragmentId: id,
        position
      });
      setSeen(true);
    }
  }, [id, searchEventId, inView, seen]);

  const __html = useMemo(
    () => sanitizeHtml(html, sanitizeSettings),
    [html, currentQuestion]
  );

  const computeIfCanBeExpanded = useCallback(
    (htmlElement: HTMLElement | null) => {
      if (htmlElement) {
        const contentHeight = htmlElement.clientHeight;
        setCanBeExpanded(!expanded && contentHeight > MAX_RESULT_HEIGHT);
      }
    },
    [__html, expanded]
  );

  const tryToggleExpanded = useCallback(() => {
    if (canBeExpanded) {
      toggleIsExpanded();
    }
  }, [canBeExpanded, toggleIsExpanded]);

  const { t } = useTranslation();

  const renderLinkToDocumentPart = () => {
    return documentAnchors && document ? (
      <DocumentAnchorsLink
        className={styles.documentAnchor}
        document={document}
        documentAnchors={documentAnchors}
      />
    ) : null;
  };

  const renderResultRowWithTitle = () => (
    <div
      ref={resultRowRef}
      className={classNames(
        styles.contentQuestionAndAnswer,
        single && styles.single
      )}
    >
      <div
        className={styles.questionWrapper}
        onClick={() => toggleIsAccordionOpen()}
      >
        <TitleWithContext contextParts={context}>
          {question}
          {renderLinkToDocumentPart()}
        </TitleWithContext>
        <Icon
          className="arrowIcon"
          name={`angle ${isAccordionOpen ? "up" : "down"}`}
          size="big"
        />
      </div>
      <Collapse isOpened={isAccordionOpen}>
        <div
          className={classNames(
            styles.answerContainer,
            isAccordionOpen && styles.open
          )}
        >
          <HTMLParagraphWithLinks
            html={__html}
            className={classNames(styles.answer)}
            as="div"
          />
          <div className={styles.collapse}>
            <Icon
              className="arrowIcon"
              name={`angle up`}
              size="big"
              onClick={() => toggleIsAccordionOpen()}
            />
          </div>
        </div>
      </Collapse>
    </div>
  );

  const renderResultRowWithContentOnly = () => (
    <div ref={resultRowRef}>
      <HTMLParagraphWithLinks
        html={__html}
        className={classNames(
          styles.contentText,
          canBeExpanded && !isExpanded && styles.contentTextHidden
        )}
        onDoubleClick={tryToggleExpanded}
        eventsOnHtmlElement={[computeIfCanBeExpanded]}
        as="div"
      />
      <div
        className={classNames(
          styles.contentActions,
          isExpanded && styles.contentActionsExpanded,
          !canBeExpanded && styles.contentActionsCannotBeExpanded
        )}
      >
        {canBeExpanded && (
          <Button className={styles.showMore} onClick={tryToggleExpanded}>
            {!isExpanded
              ? t("search.search_result_content.show_more")
              : t("search.search_result_content.show_less")}
          </Button>
        )}
        <i></i>
        {renderLinkToDocumentPart()}
      </div>
    </div>
  );

  return (
    <div
      ref={ref}
      className={classNames(styles.content, single && styles.single)}
    >
      {question ? renderResultRowWithTitle() : renderResultRowWithContentOnly()}
    </div>
  );
};
