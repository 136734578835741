import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./ErrorMessage.module.scss";
import { Message } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

const ErrorComponent = ({ isError, closeModal, errorMessage }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.errorDimmer} />
      <Message
        negative
        className={styles.errorMessage}
        visible={isError}
        onDismiss={closeModal}
      >
        <Message.Header>{t("error_message.an_error_occured")}</Message.Header>
        <p>{t(errorMessage)}</p>
      </Message>
    </>
  );
};

ErrorComponent.defaultProps = {
  isError: false,
  errorMessage: ""
};

ErrorComponent.propTypes = {
  isError: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  errorMessage: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {
  return {
    isError: state.errors.openError,
    errorMessage: state.errors.message
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => dispatch({ type: "CLEAR_ERROR_STATE" })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorComponent);
