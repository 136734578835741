import { connect } from "react-redux";
import { get } from "lodash";

import Changes from "~/components/Changes";
import {
  getChanges,
  openEditChangeView,
  showConfirmModal,
  clearChanges,
  previewDocWithId,
  getUsers,
  listDocuments,
  copyChangesParams,
  setLocation
} from "~/actions";

const mapStateToProps = (state, ownProps) => {
  return {
    changes: state.changes.content,
    totalPages: state.changes.totalPages,
    totalElements: state.changes.totalElements,
    activePage: state.changes.number + 1,
    usersList: get(state.users, "usersNames"),
    documentsList: get(state.documents, "list"),
    isFetching: state.fetching.isFetching,
    filters: state.changes.filtersCopy,
    sortDir: state.changes.sortDir,
    sortName: state.changes.sortName,
    prevLocation: state.changes.location
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getChanges: (
      pageNumber,
      sorting,
      searchParam,
      filters,
      author,
      documentId
    ) => {
      dispatch(
        getChanges(
          pageNumber,
          sorting,
          searchParam,
          filters,
          author,
          documentId
        )
      );
    },
    openEditChangeView: change => {
      dispatch(openEditChangeView(change));
    },
    deleteChange: (id, index) => {
      dispatch(showConfirmModal("DELETE_CHANGE", { id, index }));
    },
    clear: () => dispatch(clearChanges()),
    preview: doc => {
      dispatch(previewDocWithId(doc.id));
    },
    getUsersList: () => dispatch(getUsers(null, null, true)),
    getDocumentsList: () => dispatch(listDocuments()),
    copyChangesParams: (filters, sortName, sortDir) =>
      dispatch(copyChangesParams(filters, sortName, sortDir)),
    setLocation: location => dispatch(setLocation(location))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Changes);
