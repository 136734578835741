import classNames from "classnames";
import { isArray } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { Icon } from "semantic-ui-react";

import styles from "./TagsInput.module.scss";

import { ReactComponent as CloseIcon } from "~/assets/icons/close.svg";

export interface TagsInputProps {
  className?: string;
  listClassName?: string;
  items: string[];
  addedItems?: string[];
  handleTagsChange: (tags: string[]) => void;
  limit?: number;
  readonly?: boolean;
}

const TagsInput = ({
  className,
  items,
  addedItems,
  handleTagsChange,
  limit = Infinity,
  readonly = false,
  listClassName
}: TagsInputProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [tags, setTags] = useState<string[]>(addedItems ?? []);
  const [itemsVisible, setItemsVisible] = useState(false);

  const searchInputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (addedItems) {
      setTags(addedItems);
    }
  }, [addedItems]);

  useEffect(() => {
    if (typeof handleTagsChange === "function") {
      handleTagsChange(tags);
    }
  }, [handleTagsChange, tags]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    return setSearchValue(value);
  };

  const handleAddTag = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" && searchValue && !readonly) {
        return handleTagAddition(searchValue);
      }
    },
    [searchValue, readonly]
  );

  const handleInputFocus = useCallback(() => {
    if (!searchValue && !itemsVisible) {
      setItemsVisible(true);
    }
  }, [itemsVisible, searchValue]);

  const handleOutsideClick = useCallback(
    (e: DocumentEventMap["click"] | DocumentEventMap["touchstart"]) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(e.target as HTMLElement) &&
        itemsVisible
      ) {
        setItemsVisible(false);
      }
    },
    [itemsVisible]
  );

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("touchstart", handleOutsideClick);

    () => {
      document.removeEventListener("click", handleOutsideClick);
      document.removeEventListener("touchstart", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  const handleContainerClick = (e: React.MouseEvent<HTMLInputElement>) => {
    if (searchInputRef.current) {
      if (searchInputRef.current.contains(e.target as HTMLElement)) {
        return;
      }

      return searchInputRef.current.focus();
    }
  };

  const handleTagAddition = useCallback(
    (tag: string) => {
      if (
        !tags.includes(tag) &&
        (typeof limit === "number" ? tags.length < limit : true)
      ) {
        const oldTags = [...tags];
        const newTags = [
          ...oldTags.slice(0, limit > 0 ? limit - 1 : Infinity),
          tag
        ];

        setTags(newTags);
        setSearchValue("");
      }
    },
    [tags]
  );

  const handleTagDelete = useCallback(
    (tag: string) => {
      return setTags([...tags].filter(item => item !== tag));
    },
    [tags]
  );

  const filteredItems = isArray(items)
    ? items
        .filter(item => !tags.includes(item))
        .filter(item => item.toLowerCase().includes(searchValue.toLowerCase()))
    : [];

  return (
    <div
      className={classNames(styles.wrapper, className)}
      ref={containerRef}
      data-testid="wrapper_container"
    >
      <div
        className={styles.inputWrapper}
        onClick={handleContainerClick}
        data-testid="tags_input_container"
      >
        {tags.map((tag, index) => (
          <Tag
            className={styles.inputWrapperTag}
            buttonClassName={styles.inputWrapperTagBtn}
            key={tag + index}
            tag={tag}
            removeTag={handleTagDelete}
          />
        ))}
        <input
          className={styles.inputWrapperInput}
          value={searchValue}
          onChange={handleSearch}
          ref={searchInputRef}
          onFocus={handleInputFocus}
          placeholder={
            !readonly && !searchValue && tags.length === 0
              ? t("tags_input.empty")
              : ""
          }
          data-testid="tags_input"
          onKeyUp={handleAddTag}
          data-hj-allow
        />
        {readonly && (
          <Icon name={`caret ${itemsVisible || searchValue ? "up" : "down"}`} />
        )}
      </div>
      {searchValue !== "" || itemsVisible ? (
        <ul
          className={classNames(
            styles.tags,
            typeof limit === "number" &&
              tags.length >= limit &&
              styles.tagsDisabled,
            listClassName
          )}
          {...(typeof limit === "number" && tags.length >= limit
            ? {
                ["data-tip"]: t("tags_input.you_reached_max_limit_of_tags"),
                ["data-for"]: "tags_limit"
              }
            : {})}
        >
          {!readonly &&
            searchValue &&
            !tags.includes(searchValue) &&
            !items.includes(searchValue) && (
              <li className={styles.tagsTag}>
                <button
                  className={styles.tagsTagButton}
                  onClick={() => handleTagAddition(searchValue)}
                  disabled={typeof limit === "number" && tags.length >= limit}
                >
                  <>
                    {searchValue} ({t("tags_input.new_label")})
                  </>
                </button>
              </li>
            )}
          {filteredItems.map(item => (
            <li className={styles.tagsTag} key={item}>
              <button
                className={styles.tagsTagButton}
                onClick={() => handleTagAddition(item)}
                disabled={typeof limit === "number" && tags.length >= limit}
              >
                {item}
              </button>
            </li>
          ))}
        </ul>
      ) : null}

      {!readonly && typeof limit === "number" && tags.length >= limit && (
        <ReactTooltip id="tags_limit" place="top" type="error" effect="solid" />
      )}
    </div>
  );
};

export default TagsInput;

const Tag = ({
  tag,
  removeTag,
  className,
  buttonClassName
}: {
  tag: string;
  removeTag: (tag: string) => void;
  className?: string;
  buttonClassName?: string;
}) => {
  const { t } = useTranslation();

  return (
    <span className={classNames(className)} data-testid="chosen-tag">
      {tag}
      <button
        onClick={() => removeTag(tag)}
        aria-label={t("tags_input.remove_label")}
        className={classNames(buttonClassName)}
        data-testid="tag_remove_btn"
      >
        <CloseIcon />
      </button>
    </span>
  );
};
