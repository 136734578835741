import { Edge } from "react-flow-renderer";
import { v4 as uuid } from "uuid";
import { EdgesData, NodeTypes, EdgeTypes } from "~/components/types";
import type { NodeEditData } from "~/reducers/types";

export const generateEdge = (
  sourceId: string,
  targetId: string,
  type: EdgeTypes,
  data: EdgesData
): Edge<EdgesData> => ({
  id: uuid(),
  source: sourceId,
  target: targetId,
  type,
  data
});

export const createNodeFixture = (): NodeEditData => ({
  data: {
    supportingQuestion: undefined,
    knowledge: undefined
  },
  id: uuid(),
  position: {
    x: 150,
    y: 60
  },
  type: NodeTypes.CREATE
});
