import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  GET_USERS_REQUEST,
  INVITE_USER_REQUEST,
  INVITE_USER_SUCCESS,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  REMOVE_USER,
  GET_USERS_BY_NAME_REQUEST
} from "~/actions/types";
import {
  getUsers,
  getUsersSuccess,
  getUsersFailure,
  inviteUserSuccess,
  inviteUserFailure,
  showConfirmModal,
  updateUserSuccess,
  updateUserFailure,
  getUsersByNameSuccess,
  removeUserSucess
} from "~/actions";
import * as api from "~/services/UsersService";

function* getUsersData(action) {
  const state = yield select();
  let pageNumber =
    action.pageNumber !== undefined ? action.pageNumber : state.users.number;
  try {
    let users = yield call(
      api.getUsers,
      action.group,
      pageNumber,
      action.isList
    );
    yield put(getUsersSuccess(action.group, users));
  } catch (error) {
    yield put(getUsersFailure(error));
  }
}

export function* getUsersSaga() {
  yield takeEvery(GET_USERS_REQUEST, getUsersData);
}

export function* updateUserSaga() {
  yield takeEvery(UPDATE_USER_REQUEST, updateUser);
}

function* updateUser(action) {
  try {
    yield call(api.updateUser, action.id, action.role);
    yield put(updateUserSuccess(action.id, action.role));
  } catch (error) {
    yield put(updateUserFailure(error));
  }
}

export function* updateUserSuccessSaga() {
  yield takeEvery(UPDATE_USER_SUCCESS, userUpdated);
}

function* userUpdated() {
  yield put(showConfirmModal(UPDATE_USER_SUCCESS));
}

function* inviteUser(action) {
  try {
    const user = yield call(api.inviteUser, action.email, action.role);
    if (user.type !== "CUSTOM_ERROR") {
      yield put(inviteUserSuccess(user));
    }
  } catch (error) {
    yield put(inviteUserFailure(error));
  }
}

function* getUsersSearch(action) {
  try {
    const users = yield call(api.getUsersSearch, action.userName);
    yield put(getUsersByNameSuccess(users));
  } catch (error) {}
}

function* deleteUser(action) {
  try {
    yield call(api.deleteUser, action.id);
    yield put(removeUserSucess(action.id));
  } catch (error) {}
}

export function* getUsersSearchSaga() {
  yield takeEvery(GET_USERS_BY_NAME_REQUEST, getUsersSearch);
}

export function* deleteUserSaga() {
  yield takeEvery(REMOVE_USER, deleteUser);
}

export function* inviteUserSaga() {
  yield takeEvery(INVITE_USER_REQUEST, inviteUser);
}

export function* inviteUserSuccessSaga() {
  yield takeEvery(INVITE_USER_SUCCESS, userInvited);
}

function* userInvited(action) {
  try {
    yield put(showConfirmModal(INVITE_USER_SUCCESS));
  } catch (error) {
    console.log(error);
  }
}
