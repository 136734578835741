import React from "react";
import PropTypes from "prop-types";
import { List, Ref } from "semantic-ui-react";
import { withTranslation } from "react-i18next";

import styles from "./MessageList.module.scss";
import { BotMessage } from "~/components/BotMessage/BotMessage";
import UserMessage from "~/components/UserMessage";
import { MessageLoader } from "~/components/MessageLoader";

class MessageList extends React.Component {
  componentDidMount() {
    this.props.resetIssueIndex();
    this.items && this.scrollToLastMessage(this.items);
  }

  messagesRef = node => {
    this.items = node;
  };

  scrollToLastMessage = node => {
    const elements = node.children;
    const lastUserMessage = elements[elements.length - 3];

    this.timeout = setTimeout(lastUserMessage?.scrollIntoView(), 200);

    return this.timeout;
  };

  componentDidUpdate(prevProps) {
    if (prevProps.messages.length !== this.props.messages.length) {
      this.items && this.scrollToLastMessage(this.items);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  renderElementFromObject = (object, index) => {
    if (object.sender === "BOT") {
      let text = object.text || this.props.t("message_list.no_answer_found");
      const previousMessageSender =
        index > 0 ? this.props.messages[index - 1].sender : "";

      return (
        <BotMessage
          previousMessageSender={previousMessageSender}
          text={text}
          editable={object.editable}
          suggestions={object.suggestions}
          document={object.document}
          documentAnchors={object.documentAnchors}
          edited={object.modified}
          key={index}
          id={index}
          isLastMessage={index === this.props.messages.length - 1}
          timer
          chatMode={this.props.chatMode}
        />
      );
    } else if (object.sender === "USER") {
      // debugger
      const answerTime =
        this.props.messages.length > index + 1
          ? this.props.messages[index + 1].createdAt
          : Date.now();
      return (
        <UserMessage text={object.text} key={index} startDate={answerTime} />
      );
    }
  };

  renderTypingAnimation() {
    return (
      <BotMessage
        text=""
        customObject={<MessageLoader />}
        editable={false}
        key="RenderTypingAnimation"
        id="RenderTypingAnimationPlaceholder"
      />
    );
  }

  render() {
    return (
      <Ref innerRef={this.messagesRef}>
        <List className={styles.view}>
          {this.props.messages.map(this.renderElementFromObject)}
          {this.props.isFetching && this.renderTypingAnimation()}
        </List>
      </Ref>
    );
  }
}

MessageList.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      sender: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  isFetching: PropTypes.bool.isRequired,
  resetIssueIndex: PropTypes.bool.isRequired,
  chatMode: PropTypes.bool,
  t: PropTypes.func.isRequired
};

MessageList.defaultProps = {
  messages: [],
  isFetching: false
};

export default withTranslation()(MessageList);
