import React, { useEffect, useRef } from "react";
import ReactTooltip from "react-tooltip";
import { Input } from "semantic-ui-react";
import DefaultAvatar from "~/components/DefaultAvatar";
import { NEW_FRAGMENT_QUESTION_MAX_LENGTH } from "~/constants/app";

import styles from "./BubbleNewMessage.module.scss";

interface BubbleNewMessageProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isError: boolean;
}

export const BubbleNewMessage = ({
  value,
  onChange,
  isError
}: BubbleNewMessageProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (isError && inputRef.current) {
      ReactTooltip.show(inputRef.current);

      timeout = setTimeout(() => ReactTooltip.hide(), 5000);
    }

    return () => clearTimeout(timeout);
  }, [isError]);

  return (
    <div className={styles.container}>
      <div className={styles.bubble}>
        <Input
          value={value}
          onChange={onChange}
          maxLength={NEW_FRAGMENT_QUESTION_MAX_LENGTH}
          error={isError}
        >
          <input
            ref={inputRef}
            data-for="messageTooltip"
            data-tip={isError ? "You have to add message" : ""}
          />
        </Input>
        <ReactTooltip
          id="messageTooltip"
          place="top"
          type="error"
          effect="solid"
        />
      </div>
      <DefaultAvatar className={styles.avatar} />
    </div>
  );
};
