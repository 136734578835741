import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useThunkDispatch } from "~/configureStore";
import { hideConfirmModal } from "~/actions";
import { useHistory } from "react-router";
import { DOCUMENTS } from "~/constants/routes";
import { Modal } from "semantic-ui-react";
import Button from "~/atoms/Button/Button";

import styles from "./LeaveArticleViewModal.module.scss";
import classNames from "classnames";

export const LeaveArticleModal = ({
  open = true,
  closeModal,
  confirm,
  withDiscard = false
}: {
  open?: boolean;
  withDiscard?: boolean;
  closeModal?: () => void;
  confirm?: () => Promise<void>;
}) => {
  const { t } = useTranslation();

  const { replace } = useHistory();

  const dispatch = useThunkDispatch();

  const cancel = useCallback(() => {
    dispatch(hideConfirmModal());
    if (typeof closeModal === "function") {
      closeModal();
    }
  }, [dispatch]);

  const handleConfirm = () => {
    cancel();
    if (typeof confirm === "function") {
      confirm()?.then(() => replace(DOCUMENTS));
    }
  };

  return (
    <Modal open={open} className={styles.modal} size="tiny">
      <div className={styles.content}>
        <h4>
          {withDiscard
            ? t("documents.new_article_leave_modal_discard")
            : t("documents.new_article_are_you_sure_modal")}
        </h4>
        {!withDiscard && (
          <h5>{t("documents.new_article_are_you_sure_modal_sub")}</h5>
        )}
        <div className={styles.footer}>
          <Button
            className={classNames(styles.button, styles.confirm)}
            onClick={handleConfirm}
          >
            {t("documents.new_article_leave_modal_yes")}
          </Button>
          <Button className={classNames(styles.button)} onClick={cancel}>
            {t("modal.cancel")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
