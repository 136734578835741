import * as routes from "~/constants/routes";

const getLastRoutePart = (route: string) => {
  return route.substring(route.lastIndexOf("/"));
};

const breadcrumbsNames = new Map<string, string>([
  [getLastRoutePart(routes.LOGIN), "value1"],
  [getLastRoutePart(routes.KNOWLEDGE_BASE), "constants.knolwedge_base"],
  [getLastRoutePart(routes.KNOWLEDGE_FRAGMENTS), "constants.fragments"],
  [getLastRoutePart(routes.DOCUMENTS), "constants.documents"],
  [getLastRoutePart(routes.USERS), "constants.users"],
  [getLastRoutePart(routes.CHAT), "constants.chatbot"],
  [getLastRoutePart(routes.SEARCH), "constants.search"],
  [getLastRoutePart(routes.STATISTICS), "constants.statistics"],
  [getLastRoutePart(routes.ISSUES), "constants.issues"],
  [getLastRoutePart(routes.ISSUES_SOLVE_ID), "breadcrumbs.solve_issue"],
  [getLastRoutePart(routes.ISSUES_SOLVE_NEW), "changes.add_fragment"],
  [getLastRoutePart(routes.TRAIN_FRAGMENT), "constants.fragments"],
  [getLastRoutePart(routes.TRAIN_FRAGMENT_BRAND_NEW), "breadcrumbs.add_new"],
  [getLastRoutePart(routes.TRAIN_FRAGMENT_EDIT), "breadcrumbs.edit"],
  [getLastRoutePart(routes.SYNONYMS), "constants.synonyms"],
  [getLastRoutePart(routes.ARTICLE), "documents.new_article"],
  [getLastRoutePart(routes.NEW_ARTICLE), "breadcrumbs.add_new"],
  [
    getLastRoutePart(routes.EDIT_ARTICLE),
    "documents.new_article_header_edit_article"
  ]
]);

export const getBreadcrumbName = (path: string) => {
  return breadcrumbsNames.get(path);
};
