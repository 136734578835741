import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import Loader from "~/components/Loader/Loader";
import { loadEditWorkflowData } from "~/actions/workflow/actions";
import { ErrorMessage } from "~/atoms/ErrorMessage/ErrorMessage";
import { Flow } from "~/atoms/Flow/Flow";
import Wrapper from "~/atoms/Wrapper/Wrapper";
import { useThunkDispatch } from "~/configureStore";

import styles from "./Edit.module.scss";

export const Edit = () => {
  const { t } = useTranslation();

  const { id } = useParams<{ id?: string }>();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const dispatch = useThunkDispatch();

  const load = useCallback(async () => {
    setLoading(true);
    setError(undefined);

    if (!id) return;

    try {
      await dispatch(loadEditWorkflowData(id));
    } catch (err) {
      if (typeof err === "string") {
        setError(err);
      }

      setError(t("workflow.edit.loading_workflow_failed"));
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    load();
  }, [load]);

  if (!id)
    return <ErrorMessage error={t("workflow.edit.missing_workflow_id")} />;

  return (
    <Wrapper
      className={styles.wrapper}
      headerClassName={styles.wrapperHeader}
      title={t("workflow.edit_workflow")}
    >
      <Loader isFetching={loading} />
      {!loading && error && <ErrorMessage error={error} />}
      {!loading && !error && <Flow />}
    </Wrapper>
  );
};
