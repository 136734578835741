import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import {
  deleteSynonymTermAction,
  updateSynonymAction
} from "~/actions/SynonymsActions";
import ButtonIcon from "~/atoms/ButtonIcon/ButtonIcon";
import { SynonymInput } from "~/atoms/SynonymInput/SynonymInput";
import { useThunkDispatch } from "~/configureStore";
import { SynonymTermType } from "~/services/api-types";
import classNames from "classnames";
import { MAX_TERM_SYNONYM_LENGTH } from "../SynonymsModal/SynonymsModal";
import { showConfirmModal } from "~/actions/ModalActions";
import { MIXPANEL_EVENTS } from "~/contexts/mixpanel/event-types";
import { useMixpanelContext } from "~/contexts/mixpanel";

import styles from "./SynonymsRow.module.scss";

import { ReactComponent as TrashSVG } from "~/assets/delete.svg";

export const SynonymsRow = ({
  id,
  primary: term,
  synonyms
}: SynonymTermType) => {
  const dispatch = useThunkDispatch();

  const { trackEvent } = useMixpanelContext();

  const { t } = useTranslation();

  const handleDeleteTerm = useCallback(
    () =>
      dispatch(
        showConfirmModal("DELETE_SYNONYM_TERM", {
          id,
          removeFn: deleteSynonymTermAction
        })
      ),
    [dispatch, id]
  );

  const handleDeleteSynonym = useCallback(
    (synonym: string) =>
      dispatch(updateSynonymAction(id, term, [synonym], true, true)),
    [dispatch, id, term]
  );

  const handleUpdateSynonym = useCallback(
    (synonyms: string[]) => {
      dispatch(updateSynonymAction(id, term, synonyms, false, true));

      trackEvent(MIXPANEL_EVENTS.SYNONYMS_EDIT);
    },
    [dispatch, id, term, trackEvent]
  );

  return (
    <div className={styles.container}>
      <div className={styles.term}>{term}</div>
      <SynonymInput
        handleSynonymDelete={handleDeleteSynonym}
        isListView
        wrapperClass={styles.inputWrapper}
        inputClass={styles.input}
        synonymsListClass={styles.synonymsList}
        synonyms={synonyms}
        handleSynonymUpdate={handleUpdateSynonym}
        maxLength={MAX_TERM_SYNONYM_LENGTH}
      />
      <ButtonIcon
        onClick={handleDeleteTerm}
        data-for={id}
        data-tip={t("synonyms.term.remove")}
        secondary
        className={classNames(styles.removeTerm, styles.removeBtn)}
      >
        <TrashSVG className={styles.removeTermIcon} />
        <ReactTooltip
          className={styles.removeTermTooltip}
          id={id}
          type="info"
          place="top"
          effect="solid"
        />
      </ButtonIcon>
    </div>
  );
};
