import {
  GET_CHANGES_REQUEST,
  GET_CHANGES_SUCCESS,
  GET_CHANGES_FAILURE,
  CLEAR_CHANGES_STATE,
  DELETE_CHANGES_SUCCESS,
  DELETE_CHANGES_REQUEST,
  COPY_CHANGES_PARAMS,
  SET_LOCATION
} from "./types";

export const getChanges = (
  pageNumber,
  sorting,
  searchParams,
  filters,
  author,
  documentId
) => ({
  type: GET_CHANGES_REQUEST,
  pageNumber,
  sorting,
  searchParams,
  filters,
  author,
  documentId
});

export const getChangesSuccess = (changes, pageNumber) => ({
  type: GET_CHANGES_SUCCESS,
  changes,
  pageNumber
});

export const getChangesFailure = error => ({
  type: GET_CHANGES_FAILURE,
  error
});

export const clearChanges = () => ({
  type: CLEAR_CHANGES_STATE
});

export const getDeleteSuccess = deletedId => ({
  type: DELETE_CHANGES_SUCCESS,
  deletedId
});

export const deleteChange = id => ({
  type: DELETE_CHANGES_REQUEST,
  id
});

export const copyChangesParams = (filters, sortName, sortDir) => ({
  type: COPY_CHANGES_PARAMS,
  filters,
  sortName,
  sortDir
});

export const setLocation = location => ({
  type: SET_LOCATION,
  location
});
