import {
  GET_STATS_SUCCESS,
  CHANGE_CHART_TYPE,
  CHANGE_TIME_RANGE,
  GET_DATASET_SUCCESS,
  REMOVE_DATASET,
  GET_USERS_ACTIVITY_SUCCESS,
  USERS_ACTIVITY_REQUEST,
  USERS_ACTIVITY_ERROR,
  APPLY_USERS_ACTIVITY_FILTERS,
  USERS_ACTIVITY_SEARCH,
  GET_USERS_ACTIVITY_SEARCH_SUCCESS,
  GET_USERS_ACTIVITY_NEW_PAGE_SUCCESS,
  USERS_ACTIVITY_PAGE_CHANGE
} from "./types";
import {
  getStats,
  getChartData,
  getUsersActivity as fetchActivity
} from "~/services/StatsService";
import {
  ChangeChartTypeAction,
  ChangeTimeRangeAction,
  GetDatasetSuccessAction,
  GetStatisticsSuccessAction,
  UsersActivitySuccessAction,
  RemoveDatasetAction,
  Stats,
  UsersActivityRequestAction,
  UsersActivityErrorAction,
  UsersActivityFilters,
  ApplyUsersActivityFiltersAction,
  UsersActivitySearchAction,
  UsersActivitySearchSuccessAction,
  UsersActivityNewPageSuccessAction,
  UsersActivityPageChangeAction
} from "~/reducers/statistics";
import { AppThunkDispatch, StoreState } from "~/configureStore";
import { UsersActivityResponseType } from "~/services/api-types";

export const getStatsSuccess = (stats: Stats): GetStatisticsSuccessAction => {
  return {
    type: GET_STATS_SUCCESS,
    stats
  };
};

export const fetchStats = () => {
  return async (dispatch: AppThunkDispatch) => {
    try {
      const data = (await getStats()) as Stats;

      dispatch(getStatsSuccess(data));
    } catch (err) {
      console.log(err);
    }
  };
};

export const changeChartType = (chartType: string): ChangeChartTypeAction => {
  return {
    type: CHANGE_CHART_TYPE,
    chartType
  };
};

export const changeTimeRange = (timeRange: string): ChangeTimeRangeAction => {
  return {
    type: CHANGE_TIME_RANGE,
    timeRange
  };
};

export const getDatasetSuccess = (
  name: string,
  labels: [],
  data: []
): GetDatasetSuccessAction => {
  return {
    type: GET_DATASET_SUCCESS,
    name,
    labels,
    data
  };
};

export const removeDataset = (dataset: string): RemoveDatasetAction => {
  return {
    type: REMOVE_DATASET,
    dataset
  };
};

export const fetchDataset = (dataset: string) => {
  return (dispatch: AppThunkDispatch, getState: () => StoreState) => {
    let params: { type: string; range: string; group?: string } = {
      type: getState().statistics.users.type,
      range: getState().statistics.users.timeRange
    };

    if (dataset !== "all") {
      params["group"] = dataset;
    }
    getChartData(params)
      .then(response =>
        dispatch(getDatasetSuccess(dataset, response.labels, response.data))
      )
      .catch(error => console.error(error));
  };
};

export const fetchMissing = (datasets: string[]) => {
  return (dispatch: AppThunkDispatch, getState: () => StoreState) => {
    const data = getState().statistics.users.data;
    const currentDatasets = Object.keys(data.datasets || {});
    for (const dataset of datasets) {
      if (!(currentDatasets.indexOf(dataset) > -1)) {
        dispatch(fetchDataset(dataset));
      }
    }
  };
};

export const removeNotNeeded = (datasets: string[]) => {
  return (dispatch: AppThunkDispatch, getState: () => StoreState) => {
    const data = getState().statistics.users.data;
    var currentDatasets = Object.keys(data.datasets || {});
    for (const dataset of currentDatasets) {
      if (!(datasets.indexOf(dataset) > -1)) {
        dispatch(removeDataset(dataset));
      }
    }
  };
};

export const refetch = () => {
  return (dispatch: AppThunkDispatch, getState: () => StoreState) => {
    const data = getState().statistics.users.data;
    for (const dataset of Object.keys(data.datasets || {})) {
      dispatch(fetchDataset(dataset));
    }
  };
};

const usersActivityRequest = (loading: boolean): UsersActivityRequestAction => {
  return {
    type: USERS_ACTIVITY_REQUEST,
    loading,
    withLoader: false
  };
};

const usersActivitySuccess = (
  data: UsersActivityResponseType
): UsersActivitySuccessAction => {
  return {
    type: GET_USERS_ACTIVITY_SUCCESS,
    data
  };
};

const usersActivityError = (err: unknown): UsersActivityErrorAction => ({
  type: USERS_ACTIVITY_ERROR,
  payload: err,
  withLoader: false
});

export const usersActivitySearch = (
  userName: string
): UsersActivitySearchAction => ({
  type: USERS_ACTIVITY_SEARCH,
  userName
});

const usersActivitySearchSuccess = (
  data: UsersActivityResponseType
): UsersActivitySearchSuccessAction => {
  return {
    type: GET_USERS_ACTIVITY_SEARCH_SUCCESS,
    data
  };
};

const usersActivityNewPageSuccess = (
  data: UsersActivityResponseType
): UsersActivityNewPageSuccessAction => {
  return {
    type: GET_USERS_ACTIVITY_NEW_PAGE_SUCCESS,
    data
  };
};

export const usersActivityPageChange = (
  pageNumber: number
): UsersActivityPageChangeAction => ({
  type: USERS_ACTIVITY_PAGE_CHANGE,
  pageNumber
});

const applyFilters = (
  filters: UsersActivityFilters
): ApplyUsersActivityFiltersAction => ({
  type: APPLY_USERS_ACTIVITY_FILTERS,
  filters
});

export const getUsersActivity =
  (isSearch = false, isNewPage = false) =>
  async (dispatch: AppThunkDispatch, getState: () => StoreState) => {
    const { filters } = getState().statistics.activity;
    const userName = getState().statistics.activity.search;
    const page = getState().statistics.activity.currentPage;

    dispatch(usersActivityRequest(!isNewPage && true));
    try {
      const data = (await fetchActivity(
        userName,
        page,
        filters
      )) as UsersActivityResponseType;

      if (isSearch) {
        return dispatch(usersActivitySearchSuccess(data));
      }

      if (isNewPage) {
        return dispatch(usersActivityNewPageSuccess(data));
      }

      dispatch(usersActivitySuccess(data));
    } catch (err) {
      dispatch(usersActivityError(err));
    } finally {
      dispatch(usersActivityRequest(false));
    }
  };

export const changeFiltersAndFetch =
  (filters: UsersActivityFilters) => async (dispatch: AppThunkDispatch) => {
    dispatch(applyFilters(filters));
    dispatch(usersActivityPageChange(0));
    dispatch(getUsersActivity(true));
  };

export const getUsersActivityNextPage =
  (pageNumber: number) => async (dispatch: AppThunkDispatch) => {
    dispatch(usersActivityPageChange(pageNumber));

    return dispatch(getUsersActivity(false, true));
  };
