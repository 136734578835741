export type PageableData<ContentType = any> = {
  content: ContentType[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: PageableType;
  size: number;
  sort: SortType;
  totalElements: number;
  totalPages: number;
};

export interface AuthorType {
  userName: string;
}

export enum DocumentStatuses {
  SUCCESS = "SUCCESS",
  PROCESSING = "PROCESSING",
  FAILURE = "FAILURE",
  UNPUBLISHED = "UNPUBLISHED"
}

export type DocumentLocation = {
  author: AuthorType | null;
  createdAt: string;
  id: number;
  name: string;
  updatedAt: string;
};

export interface DocumentType {
  author: AuthorType;
  createdAt: string;
  enabled: boolean;
  extension: string;
  id: number;
  location: DocumentLocation[];
  name: string;
  parent: DocumentLocation | null;
  size: number;
  source: string;
  status: DocumentStatuses;
  updatedAt: string | null;
  url: string | null;
  version: number;
  versionUpdatedAt: string | null;
  isArticle: boolean;
  articleDraft: ArticleDraftDto;
}

export interface MessageType {
  createdAt: string;
  delay: number | null;
  document: DocumentType | null;
  editable: boolean;
  id: number;
  modified: boolean;
  question: string;
  suggestions: string[];
  text: string;
}

export interface DocumentFragmentAnchorsType {
  titleAnchor: string | null;
  contentStartAnchor: string;
  contentEndAnchor: string;
}

export interface HintType {
  answer: string;
  answerText: string;
  createdAt: string;
  createdBy: AuthorType;
  document: DocumentType | null;
  documentId: number | null;
  documentAnchors: DocumentFragmentAnchorsType | null;
  editable: boolean;
  id: string;
  modifiedBy: AuthorType;
  question: string;
  context: string[];
  suggestions: string[];
  updatedAt: string;
}

export interface SearchSuggestionType {
  query: string;
  text: string;
}

export type SearchResultsType = PageableData<HintType>;

export type SearchFiltersResponseType = {
  id: number;
  name: string;
  title: string;
};

export interface SearchResponseType {
  featuredSnippet: HintType | null;
  results: SearchResultsType;
  suggestions: SearchSuggestionType[];
  searchEventId: string;
}

export interface AuthorizationType {
  access_token: string;
  expires_in: number;
  jti: string;
  refresh_token: string;
  scope: string;
  tenant: string;
  token_type: string;
}

export type PageableType = {
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  sort: SortType;
  unpaged: boolean;
};

export type SortType = {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
};

export interface IssueType {
  answer: MessageType;
  author: AuthorType;
  closed: boolean;
  comment: string;
  createdAt: string;
  id: number;
  labels: string[];
  question: string;
}

export type IssuesType = PageableData<IssueType>;

export interface AutocompleteSuggestionContextType {
  knowledgeFragmentId: string;
  path: string[];
}

export interface AutocompleteSuggestionType {
  title: string;
  context: AutocompleteSuggestionContextType;
}

export interface SynonymTermType {
  createdAt: string;
  createdBy: AuthorType;
  id: string;
  modifiedBy: AuthorType;
  primary: string;
  synonyms: string[];
  updatedAt: string;
}

export type SynonymsDataType = PageableData<SynonymTermType>;

export interface ProjectType {
  defaultGreeting: HintType | null;
  defaultResponse: HintType | null;
  followUpMessage: HintType | null;
  searchWelcomeMessage: HintType | null;
  description: string | null;
  id: number;
  language: string;
  name: string;
  uiName: string;
}

export type ProjectsResponseType = PageableData<ProjectType>;

export interface UserActivityType {
  lastActivity: Date | null;
  lastLogin: Date | null;
  stats: {
    messagesSent: number;
    queriesSearched: 0;
    totalQuestionsAsked: number;
    timeSpentInDocuments: number;
    timeActive: number;
  };
  user: { userName: string };
}

export type UsersActivityResponseType = PageableData<UserActivityType>;

export interface ArticleDraftDto {
  id: number;
  name: string;
  createdAt: string;
  createdBy: AuthorType;
  updatedAt: string;
  modifiedBy: AuthorType;
}

export interface UpdateHistoryType {
  id: number;
  size: number;
  createdBy: {
    userName: string;
  };
  createdAt: string;
  description: string | null;
}
