import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import DocumentSelection from "~/components/DocumentSelection";
import { listDocuments } from "~/actions";

const mapStateToProps = (state, ownProps) => {
  const { t } = ownProps;
  let documentsList = state.documents.list;
  let mappedList = documentsList.map(x => ({ text: x.name, value: x.id }));
  return {
    options: [
      { text: t("documents.selection.lack"), value: null },
      ...mappedList
    ]
  };
};

const mapDispatchToProps = dispatch => {
  return {
    refresh: pageNumber => {
      dispatch(listDocuments(pageNumber - 1));
    }
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(DocumentSelection)
);
