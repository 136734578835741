import React from "react";
import { Search, SearchProps, SearchResultProps } from "semantic-ui-react";
import { truncate } from "lodash";
import classnames from "classnames";
import { connect } from "react-redux";
import { getQuerySuggestions } from "~/actions";
import { GetQuerySuggestionsAction } from "~/reducers/autocomplete";
import { AppThunkDispatch, StoreState } from "~/configureStore";
import type {
  AutocompleteSuggestionType,
  AutocompleteSuggestionContextType
} from "~/services/api-types";

import { ReactComponent as SuggestionArrowSVG } from "~/assets/suggestionArrow.svg";
import { ReactComponent as FileIcon } from "~/assets/file-icons/txt.svg";

import styles from "./AutocompleteInput.module.scss";

const AUTOCOMPLETE_INPUT_MIN_CHARS = 2;
const AUTOCOMPLETE_RESULT_MAX_CHARS = 200;

interface AutocompleteInputProps {
  className?: string;
  icon?: JSX.Element;
  resultsPosition?: "top" | "bottom";
  defaultValue?: string;
  placeholder?: string;
  onSubmit: (
    message: string,
    context?: AutocompleteSuggestionContextType | undefined
  ) => void;
  getSuggestions: (text: string) => GetQuerySuggestionsAction;
  clearOnEnter?: boolean;
  suggestions: AutocompleteSuggestionType[];
  hideSuggestionsOnClick?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (value: string) => void;
  onConsecutiveEnter?: () => void;
}

interface AutocompleteInputState {
  value: string;
  alreadyEntered: string;
  selectedQuery: string;
  showSuggestions: boolean;
  suggestions: AutocompleteSuggestionType[];
}

class AutocompleteInput extends React.Component<
  AutocompleteInputProps,
  AutocompleteInputState
> {
  static defaultProps: Omit<
    AutocompleteInputProps,
    "onSubmit" | "getSuggestions"
  > = {
    className: "",
    resultsPosition: "bottom",
    placeholder: "Type in your query...",
    suggestions: [],
    clearOnEnter: false
  };

  state: AutocompleteInputState = {
    value: "",
    alreadyEntered: "",
    selectedQuery: "",
    showSuggestions: true,
    suggestions: []
  };

  inputRef = React.createRef<HTMLInputElement>();

  onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter" && this.state.value) {
      if (
        this.state.value === this.state.alreadyEntered &&
        !(this.state.showSuggestions && this.state.suggestions.length)
      ) {
        this.props.onConsecutiveEnter?.();
      }
      this.setState({ showSuggestions: false });
    }
  };

  onKeyUp = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter" && this.state.value) {
      if (this.state.value !== this.state.alreadyEntered) {
        this.sendMessage(event);
        if (this.props.onConsecutiveEnter) {
          this.setState({
            alreadyEntered: this.state.value
          });
        }
      }
      this.inputRef?.current?.focus();
    } else if (event.key === "Escape" && this.inputRef.current) {
      this.inputRef.current.blur();
    } else {
      this.setState({ showSuggestions: true });
    }
  };

  onFocus = () => {
    this.setState({ showSuggestions: true });
  };

  onBlur = (event: React.MouseEvent<HTMLElement>) => {
    // don't hide suggestions when clicking on one
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      this.setState({ showSuggestions: false });
    }
  };

  componentDidMount() {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }

    if (this.props.defaultValue?.trim?.()) {
      this.setState({ value: this.props.defaultValue });

      if (this.props.hideSuggestionsOnClick) {
        this.inputRef.current?.blur();
      }
    }
  }

  sendMessage = (
    event: React.MouseEvent<HTMLSpanElement> | React.KeyboardEvent<HTMLElement>
  ) => {
    event.preventDefault();
    const { value } = this.state;
    if (value.trim()) {
      this.sendMessageText(value);
    }
  };

  sendMessageText = (
    text: string,
    context: AutocompleteSuggestionContextType | undefined = undefined
  ) => {
    this.props.onSubmit(text, context);

    if (this.props.clearOnEnter) {
      this.setState({ value: "" });
      if (typeof this.props.onChange === "function") {
        this.props.onChange("");
      }
    }
    this.setState({ suggestions: [] });

    if (this.props.hideSuggestionsOnClick) {
      this.setState({ showSuggestions: false });
    }

    if (this.inputRef.current) {
      if (!this.props.hideSuggestionsOnClick) {
        this.inputRef.current.focus();
      } else {
        this.inputRef.current.blur();
      }
    }
  };

  componentDidUpdate(prevProps: AutocompleteInputProps) {
    if (
      prevProps.defaultValue !== this.props.defaultValue &&
      typeof this.props.defaultValue === "string"
    ) {
      this.setState({ value: this.props.defaultValue });
      if (typeof this.props.onChange === "function") {
        this.props.onChange(this.props.defaultValue);
      }
    }
    if (
      prevProps.suggestions !== this.props.suggestions &&
      this.props.suggestions
    ) {
      this.setState({ suggestions: this.props.suggestions });
    }
  }

  handleResultSelect = (
    _: React.MouseEvent<HTMLElement>,
    data: SearchProps
  ) => {
    this.setState({ value: data.result.title });

    if (typeof this.props.onChange === "function") {
      this.props.onChange(data.result.title);
    }
    this.sendMessageText(data.result.title, data.result.context);
  };

  handleSearchChange = (
    _: React.MouseEvent<HTMLElement>,
    data: SearchProps
  ) => {
    this.setState({ value: data.value ?? "", selectedQuery: "" });
    if (data.value && data.value.length >= AUTOCOMPLETE_INPUT_MIN_CHARS) {
      this.props.getSuggestions(data.value ?? "");
    }

    if (typeof this.props.onChange === "function") {
      this.props.onChange(data.value ?? "");
    }
  };

  handleSelectionChange = (
    _: React.MouseEvent<HTMLElement>,
    data: SearchProps
  ) => {
    this.setState({ selectedQuery: data?.result?.title || "" });
  };

  renderSuggestionWithQueryMatch = (suggestion: string, query: string) => {
    const matchingPartIndex = suggestion
      .toLowerCase()
      .indexOf(query.toLowerCase());
    if (matchingPartIndex === -1) {
      return <span className={styles.regularSuggestion}>{suggestion}</span>;
    }
    return (
      <div className={styles.matchingContainer}>
        {suggestion.substring(0, matchingPartIndex)}
        <b data-matching={true}>
          {suggestion.substr(matchingPartIndex, query.length)}
        </b>
        {suggestion.substring(
          matchingPartIndex + query.length,
          suggestion.length
        )}
      </div>
    );
  };

  renderResult = (props: SearchResultProps) => {
    const query = this.state.value;
    const { title, context } = props;

    const splittedPaths = context?.path;
    let first = splittedPaths?.[0];
    let lastThree = splittedPaths?.slice(-2);
    lastThree?.splice(0, 0, "...");
    lastThree?.unshift(first);

    const paths = context?.path?.length >= 4 ? lastThree : context?.path;

    return (
      <>
        {this.renderSuggestionWithQueryMatch(
          truncate(title, {
            length: AUTOCOMPLETE_RESULT_MAX_CHARS,
            separator: /,?\.* +/
          }),
          query
        )}
        {paths && !!paths.length ? (
          <span className={styles.context}>
            {paths?.map((path: string, index: number) => {
              return (
                <span key={`${index + path}`}>
                  {index !== paths?.length - 1 ? null : (
                    <FileIcon className={styles.folderIcon} />
                  )}
                  {path}
                  {paths.length > 1 && paths.length - 1 !== index && (
                    <SuggestionArrowSVG />
                  )}
                </span>
              );
            })}
          </span>
        ) : (
          <></>
        )}
      </>
    );
  };

  render() {
    const suggestions = this.state.suggestions
      .map((s: AutocompleteSuggestionType) => ({
        ...s,
        key: s.title + s.context.path.join("")
      }))
      .filter((v, i, a) => a.findIndex(t => t.key === v.key) === i);

    const { className, resultsPosition, placeholder, icon, onBlur, onFocus } =
      this.props;

    const { showSuggestions, value } = this.state;

    return (
      <Search
        className={classnames(
          styles.input,
          className,
          `results-${resultsPosition}`
        )}
        loading={false}
        placeholder={placeholder}
        open={showSuggestions && value.length >= AUTOCOMPLETE_INPUT_MIN_CHARS}
        onResultSelect={this.handleResultSelect}
        onSearchChange={this.handleSearchChange}
        onSelectionChange={this.handleSelectionChange}
        resultRenderer={this.renderResult}
        results={suggestions}
        showNoResults={false}
        value={value}
        onKeyUp={this.onKeyUp}
        onKeyDown={this.onKeyDown}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        minCharacters={AUTOCOMPLETE_INPUT_MIN_CHARS}
        icon={icon ? <span onClick={this.sendMessage}>{icon}</span> : null}
        input={{
          input: { ref: this.inputRef, ["data-hj-allow"]: "" },
          ...(typeof onFocus === "function" ? { onFocus } : {}),
          ...(typeof onBlur === "function" ? { onBlur } : {})
        }}
      />
    );
  }
}

const mapStateToProps = ({ autocomplete }: StoreState) => ({
  suggestions: autocomplete.querySuggestions || []
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => ({
  getSuggestions: (text: string) => dispatch(getQuerySuggestions(text))
});

export default connect(mapStateToProps, mapDispatchToProps)(AutocompleteInput);
