import { connect } from "react-redux";
import { getProjects, login, loginSuccess } from "~/actions";

import LoginForm from "~/components/LoginForm";
import { getConfigRequestService } from "~/services/LoginService";
import { fetchWelcomeResponse } from "~/actions";
import { AppThunkDispatch, StoreState } from "~/configureStore";
import { USER_ROLES } from "~/constants/app";
import { Grid } from "semantic-ui-react";

const mapStateToProps = ({ login }: StoreState) => ({
  error: login.error,
  loggingIn: login.isLoggingIn,
  loggedIn: login.isAuthenticated
});

const mapDispatchToProps = (dispatch: AppThunkDispatch) => {
  return {
    logIn: (username: string, password: string) => {
      dispatch(login(username, password));
    },
    getConfigRequestService,
    refreshLogin: (user: string, email: string, role: USER_ROLES) => {
      dispatch(loginSuccess(user, email, role));
      dispatch(getProjects());
      dispatch(fetchWelcomeResponse());
    }
  };
};

const LoginFormComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm);

const LoginFormContainer = ({
  containerTestId
}: {
  containerTestId?: string;
}) => (
  <Grid stackable padded>
    <Grid.Row>
      <Grid.Column width={16} style={{ display: "flex", alignItems: "center" }}>
        <LoginFormComponent containerTestId={containerTestId} />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export default LoginFormContainer;
