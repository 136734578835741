import request from "./request";
import { USERS } from "~/components/Users/Users";

const usersUrl = "/users";

export const getUsers = (role, pageNumber, isList) =>
  isList
    ? request({
        url: usersUrl + "?list",
        method: "GET"
      })
    : request({
        url:
          usersUrl +
          "?page=" +
          encodeURIComponent(pageNumber || 0) +
          "&size=10" +
          (role && role !== USERS.ALL
            ? "&role=" + encodeURIComponent(role)
            : ""),
        method: "GET"
      });

export const getUsersSearch = userName =>
  request({
    url: usersUrl + "?query=" + encodeURIComponent(userName),
    method: "GET"
  });

export const inviteUser = (email, role) =>
  request({
    url: usersUrl,
    method: "POST",
    data: JSON.stringify({
      email,
      role
    })
  });

export const updateUser = (id, role) =>
  request({
    url: usersUrl + "/" + id,
    method: "PUT",
    data: JSON.stringify({
      role
    })
  });

export const deleteUser = id =>
  request({
    url: usersUrl + "/" + id,
    method: "DELETE"
  });
