import React from "react";
import { AuthorFilter } from "~/molecules/Filters/AuthorFilter";
import { FiltersHead } from "~/molecules/Filters/FiltersHead";
import { FiltersSettings } from "~/molecules/Filters/FiltersSettings";
import { SourceFilter } from "~/molecules/Filters/SourceFilter";
import { TypeFilter } from "~/molecules/Filters/TypeFilter";

import styles from "./Filters.module.scss";

interface FiltersProps {
  className: string;
  handleCloseFilters: () => void;
  filtersOpened: boolean;
}

export const Filters = ({
  className,
  handleCloseFilters,
  filtersOpened
}: FiltersProps) => {
  return (
    <>
      <div className={className}>
        <FiltersHead handleClose={handleCloseFilters} />
        <div className={styles.filtersInnerContent}>
          <FiltersSettings />
          <TypeFilter />
          <SourceFilter />
          <AuthorFilter />
        </div>
      </div>
      {filtersOpened && (
        <div onClick={handleCloseFilters} className={styles.overlay}></div>
      )}
    </>
  );
};
