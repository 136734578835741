import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useOnClickOutside } from "~/hooks/useOnClickOutside";
import ButtonIcon from "../ButtonIcon/ButtonIcon";
import Button from "../Button/Button";

import styles from "./Modal.module.scss";

import { ReactComponent as CloseSVG } from "~/assets/icons/close.svg";

interface ModalProps {
  children: React.ReactNode;
  title?: string;
  titleComponent?: React.ReactNode;
  defaultOpened?: boolean;
  opened?: boolean;
  withoutFooter?: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
  className?: string;
  contentClassName?: string;
  headerClassName?: string;
  titleClassName?: string;
  footerClassName?: string;
  footerBtnClassName?: string;
  confirmDisabled?: boolean;
}

export interface ModalHandle {
  closeModal: () => void;
  openModal: () => void;
  isModalOpened: boolean;
}

const Modal = forwardRef<ModalHandle, ModalProps>(
  (
    {
      title,
      titleComponent,
      defaultOpened = false,
      opened,
      className,
      contentClassName,
      headerClassName,
      titleClassName,
      footerClassName,
      withoutFooter,
      footerBtnClassName,
      confirmDisabled,
      onConfirm,
      onClose,
      children
    },
    ref
  ) => {
    const [isModalOpened, setIsModalOpened] = useState(defaultOpened);

    useEffect(() => {
      if (typeof opened === "boolean") {
        setIsModalOpened(opened);
      }
    }, [opened]);

    const { t } = useTranslation();

    const openModal = () => setIsModalOpened(true);
    const closeModal = () => setIsModalOpened(false);

    useImperativeHandle(ref, () => ({ closeModal, openModal, isModalOpened }), [
      closeModal,
      openModal,
      isModalOpened
    ]);

    const handleCloseClick = useCallback(() => {
      if (typeof onClose === "function") {
        onClose();
      }

      closeModal();
    }, [onClose]);

    const contentRef = useRef<HTMLElement>(null);

    useOnClickOutside(contentRef, () => handleCloseClick());

    return isModalOpened ? (
      <div className={classNames(styles.container, className)}>
        <section
          className={classNames(styles.content, contentClassName)}
          ref={contentRef}
        >
          <header className={classNames(styles.header, headerClassName)}>
            {title && !titleComponent && (
              <h3 className={classNames(styles.title, titleClassName)}>
                {t(title)}
              </h3>
            )}
            {!title && titleComponent && titleComponent}
            <ButtonIcon
              className={styles.closeButton}
              onClick={handleCloseClick}
            >
              <CloseSVG />
            </ButtonIcon>
          </header>
          {children}
          {!withoutFooter && (
            <footer className={classNames(styles.footer, footerClassName)}>
              <Button
                className={classNames(styles.footerBtn, footerBtnClassName)}
                onClick={() => (onConfirm ? onConfirm() : {})}
                disabled={confirmDisabled}
              >
                {t("modal.save")}
              </Button>
              <Button
                className={classNames(styles.footerBtn, footerBtnClassName)}
                onClick={handleCloseClick}
                negative
                secondary
              >
                {t("modal.cancel")}
              </Button>
            </footer>
          )}
        </section>
      </div>
    ) : null;
  }
);

Modal.displayName = "(Modal)";

export { Modal };
