import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Modal, Segment } from "semantic-ui-react";

import { hideConfirmModal } from "~/actions";

const UserInvitedModal = ({ close }) => {
  const { t } = useTranslation();

  return (
    <Modal size="mini" open closeOnDimmerClick={false} onClose={close} basic>
      <Modal.Content>
        <Segment.Group style={{ border: "unset" }}>
          <Segment vertical textAlign="center" style={{ border: "unset" }}>
            {t("users.modal.invitation_send_to_email")}
          </Segment>
        </Segment.Group>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={close} content="OK" />
      </Modal.Actions>
    </Modal>
  );
};

UserInvitedModal.propTypes = {
  close: PropTypes.func
};

const mapStateToProps = state => {
  return state.modal.modalProps;
};

const mapDispatchToProps = dispatch => {
  return {
    close: () => dispatch(hideConfirmModal())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserInvitedModal);
