import React, { useCallback } from "react";

import styles from "./SearchPosition.module.scss";
import { useSelector, useThunkDispatch } from "~/configureStore";
import { ReactComponent as ArrowDownIcon } from "~/assets/icons/arrow_down.svg";
import { changeFinderIndex } from "~/actions/SearchActions";

const SearchPosition = () => {
  const { currentPosition, currentMaxPositions, estimatedPositions } =
    useSelector(
      ({
        searchNew: {
          items: {
            results: { content, totalPages }
          },
          page,
          queryMatches: { currentPosition, totalPositions: currentMaxPositions }
        }
      }) => ({
        estimatedPositions: estimateQueryMatches(
          currentMaxPositions,
          content.map(x => x.content.length).reduce((x, y) => x + y, 0),
          page,
          totalPages
        ),
        currentMaxPositions,
        currentPosition
      })
    );

  const dispatch = useThunkDispatch();

  const moveDown = useCallback(() => {
    dispatch(changeFinderIndex(currentPosition + 1));
  }, [currentPosition]);

  const moveUp = useCallback(() => {
    if (currentPosition > 0) {
      dispatch(changeFinderIndex(currentPosition - 1));
    }
  }, [currentPosition]);

  return (
    <>
      <button className={styles.button} onClick={moveUp}>
        <ArrowDownIcon className={styles.iconUp} />
      </button>
      <span className={styles.counter}>
        {currentPosition + 1 > 0 ? currentPosition + 1 : "-"}
        {" / "}
        {estimatedPositions == currentMaxPositions
          ? currentMaxPositions
          : `${estimatedPositions}+`}
      </span>
      <button className={styles.button} onClick={moveDown}>
        <ArrowDownIcon />
      </button>
    </>
  );
};

function estimateQueryMatches(
  queryMatches: number,
  obtainedResults: number,
  page: number,
  totalPages: number
) {
  return (
    queryMatches +
    (page < totalPages - 1
      ? Math.floor((obtainedResults / (page + 1)) * (totalPages - page))
      : 0)
  );
}

export { SearchPosition };
