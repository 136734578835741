import {
  LOAD_WORKFLOWS_REQUEST,
  LOAD_WORKFLOWS_SUCCESS,
  LOAD_WORKFLOWS_FAILURE,
  SET_WORKFLOW_METADATA,
  UPDATE_WORKFLOW_TREE,
  WORKFLOW_INIT,
  CLEAR_WORKFLOW_TREE,
  REMOVE_WORKFLOW,
  REMOVE_WORKFLOW_ITEM,
  UPDATE_WORKFLOW_TREE_NODES
} from "~/actions/workflow/types";
import { NodesData, NodeTypes } from "~/components/types";
import type {
  Workflow,
  WorkflowData,
  WorkflowMetadata,
  WorkflowTreeUpdateData
} from "./types";
import { v4 as uuid } from "uuid";
import { saveCurrentWorkflow } from "~/services/localStorage/workflows";
import { Node, NodeChange } from "react-flow-renderer";

export interface WorkflowsState {
  workflows: {
    data: Workflow[];
    loadingWorkflows: boolean;
    error: string | undefined;
  };
  currentWorkFlow: undefined | WorkflowData;
}

const workflowsDefaultState: WorkflowsState = {
  currentWorkFlow: undefined,
  workflows: {
    data: [],
    loadingWorkflows: false,
    error: undefined
  }
};

export interface LoadWorkflowsRequest {
  type: typeof LOAD_WORKFLOWS_REQUEST;
}

export interface LoadWorkflowsSuccess {
  type: typeof LOAD_WORKFLOWS_SUCCESS;
  data: Workflow[];
}

export interface LoadWorkflowsFailure {
  type: typeof LOAD_WORKFLOWS_FAILURE;
  error: unknown;
}

// in case of editing we need to init workflow data
export interface InitWorkflow {
  type: typeof WORKFLOW_INIT;
  data: WorkflowData;
}

export interface SetWorkflowMetadata {
  type: typeof SET_WORKFLOW_METADATA;
  data: WorkflowMetadata;
}

export interface ClearWorkflowTree {
  type: typeof CLEAR_WORKFLOW_TREE;
}

export interface RemoveWorkflowAction {
  type: typeof REMOVE_WORKFLOW;
  id: string;
}

export interface UpdateWorkflowTree {
  type: typeof UPDATE_WORKFLOW_TREE;
  data: WorkflowTreeUpdateData;
}

export interface UpdateWorkflowTreeNodes {
  type: typeof UPDATE_WORKFLOW_TREE_NODES;
  nodes: Node<NodesData>[];
}

export interface RemoveWorkflowItem {
  type: typeof REMOVE_WORKFLOW_ITEM;
  data: {
    nodeId?: string;
    edgeId?: string;
  };
}

type WorkflowsActions =
  | LoadWorkflowsRequest
  | LoadWorkflowsSuccess
  | LoadWorkflowsFailure
  | InitWorkflow
  | SetWorkflowMetadata
  | UpdateWorkflowTree
  | ClearWorkflowTree
  | RemoveWorkflowAction
  | RemoveWorkflowItem
  | UpdateWorkflowTreeNodes;

export const workflows = (
  state: WorkflowsState = workflowsDefaultState,
  action: WorkflowsActions
): WorkflowsState => {
  switch (action.type) {
    case LOAD_WORKFLOWS_REQUEST:
      return {
        ...state,
        workflows: {
          ...state.workflows,
          loadingWorkflows: true,
          error: undefined
        }
      };
    case LOAD_WORKFLOWS_SUCCESS:
      return {
        ...state,
        workflows: {
          error: undefined,
          data: action.data,
          loadingWorkflows: false
        }
      };
    case LOAD_WORKFLOWS_FAILURE:
      const error =
        typeof action.error === "string"
          ? action.error
          : "something went wrong";

      return {
        ...state,
        workflows: {
          data: [],
          loadingWorkflows: false,
          error
        }
      };
    case WORKFLOW_INIT:
      return {
        ...state,
        currentWorkFlow: action.data
      };
    case CLEAR_WORKFLOW_TREE:
      return {
        ...state,
        currentWorkFlow: undefined
      };
    case REMOVE_WORKFLOW:
      return {
        workflows: {
          ...state.workflows,
          data: [...state.workflows.data].filter(item => item.id !== action.id)
        },
        currentWorkFlow: undefined
      };
    case SET_WORKFLOW_METADATA:
      const currentWorkFlow = {
        ...(state.currentWorkFlow || {
          tree: {
            nodes: [
              {
                id: uuid(),
                position: {
                  x: 20,
                  y: 50
                },
                data: {
                  question: ""
                },
                type: NodeTypes.START
              }
            ],
            edges: []
          }
        }),
        ...action.data
      };

      saveCurrentWorkflow(currentWorkFlow);

      return {
        ...state,
        currentWorkFlow
      };
    case REMOVE_WORKFLOW_ITEM: {
      if (action.data.edgeId) {
        return {
          ...state,
          currentWorkFlow: {
            ...state.currentWorkFlow!,
            tree: {
              ...state.currentWorkFlow!.tree!,
              edges: state.currentWorkFlow!.tree.edges!.filter(
                edge => edge.id !== action.data.edgeId
              )
            }
          }
        };
      }

      if (action.data.nodeId) {
        return {
          ...state,
          currentWorkFlow: {
            ...state.currentWorkFlow!,
            tree: {
              edges: state.currentWorkFlow!.tree.edges!.filter(edge => {
                if (
                  edge.source === action.data.nodeId ||
                  edge.target === action.data.nodeId
                )
                  return false;

                return true;
              }),
              nodes: state.currentWorkFlow!.tree.nodes!.filter(
                node => node.id !== action.data.nodeId
              )
            }
          }
        };
      }

      return state;
    }
    case UPDATE_WORKFLOW_TREE: {
      const nodes = [...(state.currentWorkFlow?.tree.nodes || [])];
      const edges = [...(state.currentWorkFlow?.tree.edges || [])];

      if (action.data.node) {
        const existNodeIndex = nodes.findIndex(
          node => node.id === action.data.node!.id
        );

        // const addDefaultNode = () => {
        // nodes.push({
        //   ...createNodeFixture(),
        //   position: {
        //     x: nodes[nodes.length - 1].position.x + 300,
        //     y: nodes[nodes.length - 1].position.y + 50
        //   }
        // });

        // this does not work because state is already updated (shit)
        // setTimeout(
        //   () =>
        //     edges.push(
        //       generateEdge(action.data.node!.id, createNodeFixture.id)
        //     ),
        //   200
        // );
        // edges.push(generateEdge(action.data.node!.id, createNodeFixture.id));
        // };

        if (existNodeIndex > -1) {
          const currentNodeData = nodes[existNodeIndex];
          nodes.splice(existNodeIndex, 1, {
            ...currentNodeData,
            ...action.data.node
          });

          // if (currentNodeData.type === NodeTypes.START) {
          //   addDefaultNode();
          // }
        } else {
          nodes.push(action.data.node);
          // addDefaultNode();
        }
      }

      if (action.data.edge) {
        const existEdgeIndex = edges.findIndex(
          edge => edge.id === action.data.edge!.id
        );

        if (existEdgeIndex > -1) {
          const currentEdgeData = nodes[existEdgeIndex];
          edges.splice(existEdgeIndex, 1, {
            ...currentEdgeData,
            ...action.data.edge
          });
        } else {
          edges.push(action.data.edge);
        }
      }

      const currentWorkFlow = {
        ...state.currentWorkFlow!,
        tree: {
          nodes,
          edges
        }
      };

      saveCurrentWorkflow(currentWorkFlow);

      return {
        ...state,
        currentWorkFlow
      };
    }
    case UPDATE_WORKFLOW_TREE_NODES: {
      const currentWorkFlow = {
        ...state.currentWorkFlow!,
        tree: {
          ...state.currentWorkFlow!.tree,
          nodes: action.nodes
        }
      };

      saveCurrentWorkflow(currentWorkFlow);

      return {
        ...state,
        currentWorkFlow
      };
    }
    default:
      return state;
  }
};
