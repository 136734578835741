import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Form, Modal } from "semantic-ui-react";
import { hideConfirmModal, updateUser } from "~/actions";
import { USER_ROLE_OPTIONS } from "~/constants/app";
import { useTranslation } from "react-i18next";

const UpdateUserModal = ({
  modalProps: { id, role, name },
  close,
  updateUser
}) => {
  const { t } = useTranslation();
  const [userRole, setUserRole] = useState("");
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [userRoles, setUserRoles] = useState(USER_ROLE_OPTIONS);
  const [isValid, setIsValid] = useState(true);

  const setRoles = useCallback(
    () =>
      USER_ROLE_OPTIONS.filter(option => option.value !== role).map(
        ({ text, value }) => ({ text: t(text), value })
      ),
    [role, t]
  );

  useEffect(() => {
    setUserId(id);
    setUserRoles(setRoles);
    setUserName(name);
  }, [id, role, name, setRoles]);

  const handleChange = (e, { value }) => {
    setUserRole(value);
  };

  const validate = () => {
    if (userRole === "" || userId === "") return false;
    return true;
  };

  const submit = () => {
    if (!validate()) return setIsValid(false);
    setIsValid(true);

    updateUser(userId, userRole, role);
    close();
  };

  return (
    <Modal size="mini" open onClose={close} basic>
      <Modal.Header>{t("users.modal.edit_user_role")}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group grouped>
            <Form.Input
              placeholder={t("users.modal.username")}
              name="name"
              value={userName}
              disabled
              style={{ opacity: "1" }}
            />
            <Form.Select
              options={userRoles}
              placeholder={t("users.modal.role")}
              name="role"
              value={userRole}
              onChange={handleChange}
              error={!isValid}
            />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={close} content={t("users.modal.cancel")} />
        <Button
          positive
          onClick={submit}
          disabled={!isValid}
          content={t("users.modal.update")}
        />
      </Modal.Actions>
    </Modal>
  );
};

UpdateUserModal.propTypes = {
  updateUser: PropTypes.func,
  close: PropTypes.func,
  modalProps: PropTypes.shape({
    id: PropTypes.number.isRequired,
    role: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  }).isRequired
};

const mapStateToProps = ({ modal: { modalProps } }) => ({
  modalProps
});

const mapDispatchToProps = dispatch => {
  return {
    updateUser: (id, role, prevRole) =>
      dispatch(updateUser(id, role, prevRole)),
    close: () => dispatch(hideConfirmModal())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUserModal);
