import {
  CLEAR_STATS_STATE,
  GET_STATS_SUCCESS,
  CHANGE_CHART_TYPE,
  CHANGE_TIME_RANGE,
  GET_DATASET_SUCCESS,
  REMOVE_DATASET,
  GET_USERS_ACTIVITY_SUCCESS,
  USERS_ACTIVITY_REQUEST,
  USERS_ACTIVITY_ERROR,
  APPLY_USERS_ACTIVITY_FILTERS,
  USERS_ACTIVITY_SEARCH,
  GET_USERS_ACTIVITY_SEARCH_SUCCESS,
  GET_USERS_ACTIVITY_NEW_PAGE_SUCCESS,
  USERS_ACTIVITY_PAGE_CHANGE
} from "~/actions/types";
import { chartTypeOptions, timeRangeOptions } from "~/constants/app";
import {
  ACTIVITY_PERIOD,
  ACTIVITY_SORT_BY,
  ACTIVITY_SORT_DIRECTION
} from "~/constants/statistics";
import {
  UserActivityType,
  UsersActivityResponseType
} from "~/services/api-types";

export interface Dataset {
  label: string;
  data: number[];
}

type Datasets = Record<string, Dataset>;

export interface UsersActivityFilters {
  period?: ACTIVITY_PERIOD;
  sortBy?: ACTIVITY_SORT_BY;
  sortDirection?: ACTIVITY_SORT_DIRECTION;
}

export interface Stats {
  queries: {
    total: number;
    search: number;
    chat: number;
  };
  documents: number;
  answers: number;
  messages: number;
  users: number;
  issues: {
    total: number;
    open: number;
    close: number;
  };
}

export interface Users {
  type: string;
  timeRange: string;
  data: {
    labels: string[];
    datasets: Datasets | undefined;
  };
}

interface Activity {
  users: UserActivityType[] | [];
  loading: boolean;
  error: null | unknown;
  filters: UsersActivityFilters;
  search: string;
  currentPage: number;
  totalPages: number;
}

interface StatisticsState {
  stats: Stats;
  users: Users;
  activity: Activity;
}

const initialStatisticsState: StatisticsState = {
  stats: {
    queries: {
      total: 0,
      search: 0,
      chat: 0
    },
    documents: 0,
    answers: 0,
    messages: 0,
    users: 0,
    issues: {
      total: 0,
      open: 0,
      close: 0
    }
  },
  users: {
    type: chartTypeOptions[0].value,
    timeRange: timeRangeOptions[0].value,
    data: {
      labels: [],
      datasets: undefined
    }
  },
  activity: {
    users: [],
    loading: false,
    error: null,
    filters: {
      period: ACTIVITY_PERIOD.LAST_30_DAYS,
      sortBy: ACTIVITY_SORT_BY.lastActivity,
      sortDirection: "desc"
    },
    search: "",
    currentPage: 0,
    totalPages: 0
  }
};

export type GetStatisticsSuccessAction = {
  type: typeof GET_STATS_SUCCESS;
  stats: Stats;
};

export type GetDatasetSuccessAction = {
  type: typeof GET_DATASET_SUCCESS;
  name: string;
  labels: [];
  data: [];
};

export type RemoveDatasetAction = {
  type: typeof REMOVE_DATASET;
  dataset: string;
};

export type ClearStatisticsStateAction = {
  type: typeof CLEAR_STATS_STATE;
};

export type ChangeChartTypeAction = {
  type: typeof CHANGE_CHART_TYPE;
  chartType: string;
};

export type ChangeTimeRangeAction = {
  type: typeof CHANGE_TIME_RANGE;
  timeRange: string;
};

export type UsersActivitySuccessAction = {
  type: typeof GET_USERS_ACTIVITY_SUCCESS;
  data: UsersActivityResponseType;
};

export type UsersActivityRequestAction = {
  type: typeof USERS_ACTIVITY_REQUEST;
  loading: boolean;
  withLoader: false;
};

export interface UsersActivityErrorAction {
  type: typeof USERS_ACTIVITY_ERROR;
  payload: unknown;
  withLoader: false;
}

export interface ApplyUsersActivityFiltersAction {
  type: typeof APPLY_USERS_ACTIVITY_FILTERS;
  filters: UsersActivityFilters;
}

export interface UsersActivitySearchAction {
  type: typeof USERS_ACTIVITY_SEARCH;
  userName: string;
}

export type UsersActivitySearchSuccessAction = {
  type: typeof GET_USERS_ACTIVITY_SEARCH_SUCCESS;
  data: UsersActivityResponseType;
};

export type UsersActivityNewPageSuccessAction = {
  type: typeof GET_USERS_ACTIVITY_NEW_PAGE_SUCCESS;
  data: UsersActivityResponseType;
};

export type UsersActivityPageChangeAction = {
  type: typeof USERS_ACTIVITY_PAGE_CHANGE;
  pageNumber: number;
};

type StatisticsActions =
  | GetStatisticsSuccessAction
  | GetDatasetSuccessAction
  | RemoveDatasetAction
  | ClearStatisticsStateAction
  | ChangeChartTypeAction
  | ChangeTimeRangeAction
  | UsersActivityRequestAction
  | UsersActivityErrorAction
  | UsersActivitySuccessAction
  | ApplyUsersActivityFiltersAction
  | UsersActivitySearchAction
  | UsersActivitySearchSuccessAction
  | UsersActivityNewPageSuccessAction
  | UsersActivityPageChangeAction;

const statistics = (
  state: StatisticsState = initialStatisticsState,
  action: StatisticsActions
) => {
  switch (action.type) {
    case CHANGE_CHART_TYPE:
      return {
        ...state,
        users: {
          ...state.users,
          type: action.chartType
        }
      };
    case CHANGE_TIME_RANGE:
      return {
        ...state,
        users: {
          ...state.users,
          timeRange: action.timeRange
        }
      };
    case GET_DATASET_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          data: {
            labels: action.labels,
            datasets: {
              ...state.users.data.datasets,
              [action.name]: {
                label: action.name,
                data: action.data
              }
            }
          }
        }
      };
    case REMOVE_DATASET:
      const datasets = state.users.data.datasets
        ? { ...state.users.data.datasets }
        : undefined;

      if (datasets?.[action.dataset]) {
        delete datasets[action.dataset];
      }

      return {
        ...state,
        users: {
          ...state.users,
          data: {
            ...state.users.data,
            datasets
          }
        }
      };
    case GET_STATS_SUCCESS:
      return {
        ...state,
        stats: action.stats
      };
    case USERS_ACTIVITY_REQUEST:
      return {
        ...state,
        activity: {
          ...state.activity,
          loading: action.loading
        }
      };
    case USERS_ACTIVITY_ERROR:
      return {
        ...state,
        activity: {
          ...initialStatisticsState.activity,
          error: action.payload
        }
      };
    case GET_USERS_ACTIVITY_SUCCESS:
      return {
        ...state,
        activity: {
          ...state.activity,
          users: action.data.content,
          totalPages: action.data.totalPages
        }
      };
    case APPLY_USERS_ACTIVITY_FILTERS:
      return {
        ...state,
        activity: {
          ...state.activity,
          filters: {
            ...state.activity.filters,
            ...action.filters
          }
        }
      };
    case USERS_ACTIVITY_SEARCH:
      return {
        ...state,
        activity: {
          ...state.activity,
          search: action.userName
        }
      };
    case GET_USERS_ACTIVITY_SEARCH_SUCCESS:
      return {
        ...state,
        activity: {
          ...state.activity,
          users: action.data.content,
          totalPages: action.data.totalPages
        }
      };
    case USERS_ACTIVITY_PAGE_CHANGE:
      return {
        ...state,
        activity: {
          ...state.activity,
          currentPage: action.pageNumber
        }
      };
    case GET_USERS_ACTIVITY_NEW_PAGE_SUCCESS:
      return {
        ...state,
        activity: {
          ...state.activity,
          users: [...state.activity.users, ...action.data.content]
        }
      };
    case CLEAR_STATS_STATE:
      return initialStatisticsState;
    default:
      return state;
  }
};

export default statistics;
