import { useState, useEffect } from "react";

/**
 *
 * @param query example "min-width: 768px"
 * @returns {boolean} matches or not provided query
 */
export const useMatchMedia = (query: string) => {
  const [matchesMedia, setMatchesMedia] = useState(false);

  const matchMediaQuery =
    window === undefined ? null : window.matchMedia(`(${query})`);

  const match = (e: MediaQueryListEvent) => setMatchesMedia(e.matches);

  useEffect(() => {
    if (matchMediaQuery) {
      // set starting position on initialize
      setMatchesMedia(matchMediaQuery.matches);

      matchMediaQuery.addEventListener("change", match);
    }

    return () => {
      if (matchMediaQuery) {
        matchMediaQuery.removeEventListener("change", match);
      }
    };
  }, [matchMediaQuery]);

  return matchesMedia;
};
