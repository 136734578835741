import {
  CLEAR_RELATED_MESSAGE,
  RELATED_OPENED,
  SAVE_RELATED_MESSAGE
} from "~/actions/types";

const chat = (state = { relatedOpened: false, relatedMessage: "" }, action) => {
  switch (action.type) {
    case RELATED_OPENED:
      return {
        ...state,
        relatedOpened: action.payload
      };
    case SAVE_RELATED_MESSAGE:
      return {
        ...state,
        relatedMessage: action.message
      };
    case CLEAR_RELATED_MESSAGE:
      return {
        ...state,
        relatedMessage: null
      };
    default:
      return state;
  }
};

export default chat;
