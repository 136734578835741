import {
  ADD_MESSAGE,
  BOT_MESSAGE_REQUEST,
  BOT_MESSAGE_FAILURE,
  BOT_MESSAGE_SUCCESS
} from "./types";
import { getBotResponse } from "~/services/ChatbotService";
import { saveQuestionForRelated } from "./ChatActions";
import { saveLastChatMessage } from "./SearchActions";

function addMessage(message, sender) {
  return {
    type: ADD_MESSAGE,
    message: {
      ...message,
      sender
    }
  };
}

export function addBotMessage(message) {
  return function (dispatch) {
    dispatch(addMessage(message, "BOT"));
    dispatch({ type: BOT_MESSAGE_SUCCESS });
  };
}

export function addUserMessage(message) {
  return addMessage(message, "USER");
}

export function botMessageRequest() {
  return {
    type: BOT_MESSAGE_REQUEST
  };
}

export function botMessageSuccess(messages) {
  return function (dispatch, getState) {
    messages.forEach(message => {
      if (message.delay) {
        let messageExpectedIndex = getState().messages.length;
        setTimeout(
          () => dispatch(botMessageRequest()),
          Math.max(message.delay - 1000, 0)
        );
        setTimeout(() => {
          let messageCurrentIndex = getState().messages.length;
          if (messageCurrentIndex === messageExpectedIndex) {
            dispatch(addBotMessage(message));
          }
        }, message.delay);
      } else {
        dispatch(addBotMessage(message));
      }
    });
  };
}

export function botMessageFailure(error) {
  return {
    type: BOT_MESSAGE_FAILURE,
    error
  };
}

export function fetchBotResponse(message, context) {
  return dispatch => {
    dispatch(botMessageRequest());
    dispatch(saveQuestionForRelated(message));

    getBotResponse(message, context)
      .then(json => dispatch(botMessageSuccess(json)))
      .catch(error => dispatch(botMessageFailure(error)));
  };
}

export function sendUserMessage(text, context) {
  return (dispatch, getState) => {
    const messages = getState().messages;
    const lastMessage = messages[messages.length - 1];
    const lastSuggestions = lastMessage.suggestions || [];

    if (lastSuggestions.length > 0) {
      let numbers = [...Array(lastSuggestions.length).keys()]
        .map(x => x + 1)
        .map(String);
      let chooseSuggestionByNumber = numbers.indexOf(text.trim()) > -1;
      if (chooseSuggestionByNumber) {
        const chosenSuggestion = lastSuggestions[parseInt(text.trim(), 10) - 1];
        dispatch(addUserMessage({ text: chosenSuggestion.text }));
        dispatch(fetchBotResponse(chosenSuggestion.query));
        return;
      }
      for (let i = 0; i < lastSuggestions.length; i++) {
        if (text.trim() === lastSuggestions[i].text) {
          const chosenSuggestion = lastSuggestions[i];
          dispatch(addUserMessage({ text: chosenSuggestion.text }));
          dispatch(fetchBotResponse(chosenSuggestion.query));
          return;
        }
      }
    }

    dispatch(saveLastChatMessage(text));
    dispatch(addUserMessage({ text: text }));
    dispatch(fetchBotResponse(text, context));
  };
}

export function fetchWelcomeResponse() {
  return dispatch => {
    dispatch(fetchBotResponse(""));
  };
}
