import {
  CLEAR_ISSUES_STATE,
  GET_ISSUES_SUCCESS,
  SET_ISSUE_INDEX,
  RESET_ISSUE_INDEX,
  GET_SINGLE_ISSUE_SUCCESS,
  EXPAND_CONTAINER,
  SET_ISSUES_LABELS,
  GET_SINGLE_ISSUE_REQUEST,
  GET_SINGLE_ISSUE_FAILURE
} from "~/actions/types";
import type { IssuesType, IssueType } from "~/services/api-types";

interface IssuesState {
  index: number;
  content: IssueType[];
  totalPages: number;
  number: number;
  singleIssue: IssueType | {};
  singleIssueFetching: boolean;
  expandContainer: boolean;
  labels: string[];
}

const initialState: IssuesState = {
  index: -1,
  content: [],
  totalPages: 0,
  number: 0,
  singleIssue: {},
  singleIssueFetching: false,
  expandContainer: false,
  labels: []
};

interface SetLabelsAction {
  type: typeof SET_ISSUES_LABELS;
  payload: string[];
}

interface SetIssueIndexAction {
  type: typeof SET_ISSUE_INDEX;
  index: number;
}

interface ResetIssueIndexAction {
  type: typeof RESET_ISSUE_INDEX;
}

interface GetIssuesAction {
  type: typeof GET_ISSUES_SUCCESS;
  issues: IssuesType;
}

interface GetSingleIssueAction {
  type: typeof GET_SINGLE_ISSUE_SUCCESS;
  data: IssueType;
}

interface GetSingleIssueRequestAction {
  type: typeof GET_SINGLE_ISSUE_REQUEST;
}

interface GetSingleIssueFailureAction {
  type: typeof GET_SINGLE_ISSUE_FAILURE;
}

interface ClearIssuesAction {
  type: typeof CLEAR_ISSUES_STATE;
}

interface ExpandContainerAction {
  type: typeof EXPAND_CONTAINER;
  state: boolean;
}

type IssuesActions =
  | SetLabelsAction
  | SetIssueIndexAction
  | ResetIssueIndexAction
  | GetSingleIssueAction
  | ClearIssuesAction
  | GetIssuesAction
  | ExpandContainerAction
  | GetSingleIssueRequestAction
  | GetSingleIssueFailureAction;

const issues = (
  state: IssuesState = initialState,
  action: IssuesActions
): IssuesState => {
  switch (action.type) {
    case SET_ISSUE_INDEX:
      return {
        ...state,
        index: action.index
      };
    case RESET_ISSUE_INDEX:
      return {
        ...state,
        index: -1
      };
    case GET_ISSUES_SUCCESS:
      return {
        ...state,
        ...action.issues,
        content: concatResultsProperly(action.issues, state.content)
      };
    case GET_SINGLE_ISSUE_SUCCESS:
      return {
        ...state,
        singleIssue: action.data,
        singleIssueFetching: false
      };
    case GET_SINGLE_ISSUE_REQUEST:
      return {
        ...state,
        singleIssue: {},
        singleIssueFetching: true
      };
    case GET_SINGLE_ISSUE_FAILURE:
      return {
        ...state,
        singleIssue: {},
        singleIssueFetching: false
      };
    case CLEAR_ISSUES_STATE:
      return initialState;
    case EXPAND_CONTAINER:
      return { ...state, expandContainer: action.state };
    case SET_ISSUES_LABELS:
      return {
        ...state,
        labels: action.payload
      };
    default:
      return state;
  }
};

export default issues;

const concatResultsProperly = (data: IssuesType, stateIssues: IssueType[]) => {
  if (data.number > 0) {
    if (Math.abs(data.totalElements / data.size) <= 1) {
      return data.content;
    }

    return [...stateIssues, ...data.content];
  }

  return data.content;
};
