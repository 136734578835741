export type BreadcrumbsPath = { id: number; name: string }[];

export enum NodeTypes {
  START = "start",
  CREATE = "create",
  SUPPORTING_QUESTION = "supporting_question",
  KNOWLEDGE_ENTRY = "knowledge_entry"
}
export interface StartNodeData {
  question: string;
}

export interface SupportingQuestionNodeData {
  question: string;
  description: string;
}

export interface KnowlegdeNodeData {
  id: string;
}

export interface CreateNodeData {}

export type NodesData =
  | StartNodeData
  | CreateNodeData
  | SupportingQuestionNodeData
  | KnowlegdeNodeData;

export enum EdgeTypes {
  BUTTON = "button",
  INPUT = "input"
}

export interface ButtonEdgeData {
  text: string;
}

export interface InputEdgeData {
  text: string;
}

export type EdgesData = InputEdgeData | ButtonEdgeData;
